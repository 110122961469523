import React, { Component } from 'react'
import { gql } from 'apollo-boost'
import { graphql, withApollo } from 'react-apollo'
import moment from 'moment-timezone'

import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import Switch from '@material-ui/core/Switch'
import TextField from '@material-ui/core/TextField'


class PurchaseCodeDetails extends Component {

  state = {
    isEditingLocked: true
  }

  _clearPurchaseCodeCopy() {
    if (this.state.purchaseCode) { this.setState({ purchaseCode: null }) }
  }

  _handleChangeLock(isInputChecked) {
    this.setState({ isEditingLocked: isInputChecked })

    if (isInputChecked) {
      this._updatePurchaseCodeFields()
    }
  }

  _handleChangeApplicableNewEmail(val) { this._updatePurchaseCode('applicableNewEmail', val) }
  _handleChangeCode(val) { this._updatePurchaseCode('code', val) }
  _handleChangeCurrency(val) { this._updatePurchaseCode('currency', val) }
  _handleChangeIsOneTimeUse(val) { this._updatePurchaseCode('isOneTimeUse', val) }
  _handleChangeisOneTimeUsePerUser(val) { this._updatePurchaseCode('isOneTimeUsePerUser', val) }
  _handleChangeisNewUserOnly(val) { this._updatePurchaseCode('isNewUserOnly', val) }
  _handleChangeIsExpired(val) { this._updatePurchaseCode('isExpired', val) }
  _handleChangeIsForFamilyFriendlyOnly(val) { this._updatePurchaseCode('isForFamilyFriendlyOnly', val) }
  _handleChangeIsForMixerOnly(val) { this._updatePurchaseCode('isForMixerOnly', val) }
  _handleChangeIsForPremiumOnly(val) { this._updatePurchaseCode('isForPremiumOnly', val) }
  _handleChangeIsForTicketOnly(val) { this._updatePurchaseCode('isForTicketOnly', val) }

  _handleChangeDiscountEndDate(date) {
    this._updatePurchaseCode('discountEndDate', date)
  }
  _handleChangeDiscountAmount(val) { this._updatePurchaseCode('discountAmountInUsd', val) }
  _handleChangeInitialLifetimeAmount(val) {
    this._updatePurchaseCode('initialLifetimeAmountInUsd', val)
  }
  _handleChangeRemainingLifetimeAmount(val) {
    this._updatePurchaseCode('remainingLifetimeAmountInUsd', val)
  }
  _handleChangeDiscountType(val) {
    this._updatePurchaseCode('discountType', val)
    this.setState({ purchaseCodeType: this.state.purchaseCode })
  }
  _handleChangeNumberOfUses(val) {
    var intVal = Number.parseInt(val, 10)
    this._updatePurchaseCode('numberOfUses',  intVal)
  }
  _handleChangePurchaseCodeType(val) { this._updatePurchaseCode('purchaseCodeType', val) }
  _handleChangePercentageAmount(val) {this._updatePurchaseCode('percentageOff', val) }
  _handleChangeBlackoutDates(val) {
    var blackoutDates = val.split(',')
    var dateList = blackoutDates.map(date => date.trim())
    this._updatePurchaseCode('blackoutDates', dateList)
  }

  _navigate(url) {
    this.props.history.push(url)
  }

  _refetchData() {
    this.props.purchaseCodeQuery.refetch();
  }

  _setPurchaseCodeCopy() {
    var purchaseCodeObj = { ...this.props.purchaseCodeQuery.purchaseCode }

    if (purchaseCodeObj.discountEndDate) {
      purchaseCodeObj.discountEndDate = moment(purchaseCodeObj.discountEndDate).toDate()
      console.log(purchaseCodeObj.discountEndDate)
    }

    this.setState({
      purchaseCode: purchaseCodeObj
    })
  }

  _updatePurchaseCode(key, value) {
    if (!this.state.purchaseCode) { return }

    var state = { purchaseCode: { ...this.state.purchaseCode } }
    state.purchaseCode[key] = value

    this.setState(state)
  }

  _updatePurchaseCodeFields = async function() {
    if (!this.state.purchaseCode) { return }

    const code = this.state.purchaseCode

    try {
      const id = this.props.match.params.id

      const discountAmountInUsd = Number.parseFloat(code.discountAmountInUsd)
      const discountEndDate = code.discountEndDate ? moment(code.discountEndDate).format('YYYY-MM-DD') : null
      const initialLifetimeAmountInUsd = Number.parseFloat(code.initialLifetimeAmountInUsd)
      const remainingLifetimeAmountInUsd = Number.parseFloat(code.remainingLifetimeAmountInUsd)
      const percentageOff = Number.parseInt(code.percentageOff, 10)

      await this.props.client.mutate({
        mutation: PURCHASE_CODE_UPDATE_MUTATION,
        variables: {
          id,
          applicableNewEmail: code.applicableNewEmail,
          code: code.code,
          currency: code.currency,
          isOneTimeUse: code.isOneTimeUse,
          isOneTimeUsePerUser: code.isOneTimeUsePerUser,
          isExpired: code.isExpired,
          isForFamilyFriendlyOnly: code.isForFamilyFriendlyOnly,
          isForMixerOnly: code.isForMixerOnly,
          isForPremiumOnly: code.isForPremiumOnly,
          isForTicketOnly: code.isForTicketOnly,
          isNewUserOnly: code.isNewUserOnly,
          numberOfUses: code.numberOfUses,
          discountEndDate: discountEndDate,
          blackoutDates: code.blackoutDates,
          discountAmountInUsd,
          initialLifetimeAmountInUsd,
          remainingLifetimeAmountInUsd,
          purchaseCodeType: code.purchaseCodeType,
          discountType: code.discountType,
          percentageOff: percentageOff
        }
      })
    } catch (err) {
      alert(`Unable to update purchase code: ${err.message}`)
    }

    this._refetchData()
  }

  componentDidUpdate() {
    const query = this.props.purchaseCodeQuery

    if (query && query.loading) { this._clearPurchaseCodeCopy() }
    else if (!this.state.purchaseCode) { this._setPurchaseCodeCopy() }
  }

  render() {
    if (this.props.purchaseCodeQuery && this.props.purchaseCodeQuery.loading) {
      return <div>Loading</div>
    }

    if (this.props.purchaseCodeQuery && this.props.purchaseCodeQuery.error) {
      return <div>Error</div>
    }

    if (!this.state.purchaseCode) {
      return <div>Loading</div>
    }

    const code = this.state.purchaseCode

    var flatButtonStyle = { height: '100%' }
    if (this.state.isEditingLocked) {
      flatButtonStyle.visibility = 'hidden'
    }

    var blackoutDates = code.blackoutDates
    var blackoutDatesString = null

    if (blackoutDates && blackoutDates.length > 0) {
      blackoutDatesString = code.blackoutDates.map(date => {
        return date
      }).join(', ')
    }

    // const dataSourceConfig = { text: 'name', value: 'id' };

    return (
      <div>
        <h1>Purchase Code Details</h1>

        <Paper style={{ padding: '20px' }}>

          <div className='flex mt2 items-start' style={{ display: 'inline-block', textAlign: 'left' }}>
            <div>
             <FormControlLabel
               control={
                 <Switch
                   checked={this.state.isEditingLocked}
                   onChange={(e, b) => { this._handleChangeLock(b) }}
                 />
               }
               label={"Editing " + (this.state.isEditingLocked ? "Locked" : "Unlocked")}
             />
            </div>

            <div className='ml1'>
              <div className="padding">
                <TextField value={code.code || ""}
                           label="Code *required"
                           style={{width:"350px", paddingTop:"10px"}}
                           disabled={this.state.isEditingLocked}
                           onChange={(e) => { this._handleChangeCode(e.target.value) }}/>
              </div>

              <div className="padding">
                <FormControl>
                  <InputLabel style={{marginRight: '10px', width:"400px"}}>Currency</InputLabel>
                  <Select value={code.currency}
                          label="Currency"
                          onChange={(e,k,p) => { this._handleChangeCurrency(k.props.value) }}
                          disabled={this.state.isEditingLocked}>

                    <MenuItem key="USD" value="USD"> USD </MenuItem>
                    <MenuItem key="GBP" value="GBP"> GBP </MenuItem>

                  </Select>
                </FormControl>
              </div>

              <div className="padding">
                <Select label="Purchase Code Type *required"
                             value={code.purchaseCodeType}
                             disabled={this.state.isEditingLocked}
                             onChange={(e,k,p) => { this._handleChangePurchaseCodeType(e.target.value) }} >
                  <MenuItem value="Discount">Discount</MenuItem>
                  <MenuItem value="Referral">Referral</MenuItem>
                  <MenuItem value="ReferralReward">Referral Reward</MenuItem>
                  <MenuItem value="GiftCard">Gift Card</MenuItem>
                  <MenuItem value="MixerBundle">Mixer Bundle</MenuItem>
                  <MenuItem value="Voucher">Voucher</MenuItem>
                </Select>
              </div>

              {code.purchaseCodeType !== 'GiftCard' && code.purchaseCodeType !== 'MixerBundle' && code.purchaseCodeType !== 'Voucher' &&
                <span>
                  <div className="padding">
                    <Select label="Discount Type *required"
                                 value={code.discountType}
                                 disabled={this.state.isEditingLocked}
                                 onChange={(e,k,p) => { this._handleChangeDiscountType(e.target.value) }} >
                      <MenuItem value="PerTicket">Per Ticket </MenuItem>
                      <MenuItem value="PerOrder">Per Order</MenuItem>
                      <MenuItem value="Percentage">Percentage</MenuItem>
                    </Select>
                  </div>

                  <div style={{ marginLeft: '10px' }}>
                    <FormControlLabel
                      control={<Checkbox checked={code.isOneTimeUse}
                                         onChange={(e,b) => { this._handleChangeIsOneTimeUse(b) }}
                                         disabled={this.state.isEditingLocked} />}
                      label="Is One Time Use"
                    />

                    <FormControlLabel
                      control={<Checkbox checked={code.isOneTimeUsePerUser}
                                         onChange={(e,b) => { this._handleChangeisOneTimeUsePerUser(b) }}
                                         disabled={this.state.isEditingLocked} />}
                      label="Is One Time Use Per User"
                    />

                    <FormControlLabel
                      control={<Checkbox checked={code.isNewUserOnly}
                                         onChange={(e,b) => { this._handleChangeisNewUserOnly(b) }}
                                         disabled={this.state.isEditingLocked} />}
                      label="Is New User Only"
                    />

                    <FormControlLabel
                      control={<Checkbox checked={code.isExpired}
                                         onChange={(e,b) => { this._handleChangeIsExpired(b) }}
                                         disabled={this.state.isEditingLocked} />}
                      label="Is Expired"
                    />

                    <FormControlLabel
                      control={<Checkbox checked={code.isForPremiumOnly}
                                         onChange={(e,b) => { this._handleChangeIsForPremiumOnly(b) }}
                                         disabled={this.state.isEditingLocked} />}
                      label="Is For Premium Only"
                    />

                    <FormControlLabel
                      control={<Checkbox checked={code.isForFamilyFriendlyOnly}
                                         onChange={(e,b) => { this._handleChangeIsForFamilyFriendlyOnly(b) }}
                                         disabled={this.state.isEditingLocked} />}
                      label="Is For Family-Friendly Only"
                    />

                    <FormControlLabel
                      control={<Checkbox checked={code.isForMixerOnly}
                                         onChange={(e,b) => { this._handleChangeIsForMixerOnly(b) }}
                                         disabled={this.state.isEditingLocked} />}
                      label="Is For Mixer Only"
                    />

                    <FormControlLabel
                      control={<Checkbox checked={code.isForTicketOnly}
                                         onChange={(e,b) => { this._handleChangeIsForTicketOnly(b) }}
                                         disabled={this.state.isEditingLocked} />}
                      label="Is For Tickets Only"
                    />
                  </div>

                  <div className="padding">
                    <p>
                      The discount date is optional and is the last day the code is valid. Ex. '05-12-2020'
                      would set the code to expire at 11:59pm eastern on May 12th
                    </p>

                    <TextField label="Discount End Date"
                               type="date"
                               disabled={this.state.isEditingLocked}
                               defaultValue={moment(code.discountEndDate).format("YYYY-MM-DD") || ""}
                               onChange={(e) => { this._handleChangeDiscountEndDate(e.target.value)}}
                    />
                  </div>

                  {code.discountType !== 'Percentage' &&
                    <div className="padding">
                      <TextField value={code.discountAmountInUsd || ""}
                                 label="Discount Amount *required"
                                 style={{width:"350px", paddingTop:"10px"}}
                                 disabled={this.state.isEditingLocked}
                                 onChange={(e) => { this._handleChangeDiscountAmount(e.target.value) }}/>
                    </div>
                  }

                  {code.discountType === 'Percentage' &&
                    <div className="padding">
                      <TextField value={code.percentageOff || ""}
                                 label="Percentage Off (no % sign) *required"
                                 style={{width:"350px", paddingTop:"10px"}}
                                 disabled={this.state.isEditingLocked}
                                 onChange={(e) => { this._handleChangePercentageAmount(e.target.value) }}/>
                    </div>
                  }

                  <div className="padding">
                    <TextField value={ blackoutDatesString || "ex 2018-10-29, 2018-11-22"}
                               label="Blackout Dates"
                               style={{width:"350px", paddingTop:"10px"}}
                               disabled={this.state.isEditingLocked}
                               rows={4}
                               onChange={(e) => { this._handleChangeBlackoutDates(e.target.value) }}/>
                  </div>
                </span>
              }

              {(code.purchaseCodeType === 'GiftCard' || code.purchaseCodeType === 'Voucher') &&
                <span>
                  <div className="padding">
                    <TextField value={code.initialLifetimeAmountInUsd || ""}
                               label="Initial Lifetime Amount in USD *required"
                               style={{width:"350px", paddingTop:"10px"}}
                               disabled={this.state.isEditingLocked}
                               onChange={(e) => { this._handleChangeInitialLifetimeAmount(e.target.value) }}/>
                  </div>

                  <div className="padding">
                    <TextField value={code.remainingLifetimeAmountInUsd || ""}
                               label="Remaining Lifetime Amount in USD *required"
                               style={{width:"350px", paddingTop:"10px"}}
                               disabled={this.state.isEditingLocked}
                               onChange={(e) => { this._handleChangeRemainingLifetimeAmount(e.target.value) }}/>
                  </div>

                  <div className="padding">
                    <FormControlLabel
                      control={<Checkbox checked={code.isExpired}
                                         onChange={(e,b) => { this._handleChangeIsExpired(b) }}
                                         disabled={this.state.isEditingLocked} />}
                      label="Is Expired"
                    />
                  </div>
                </span>
              }

              {code.purchaseCodeType === 'MixerBundle' &&
                <span>
                  <div className="padding">
                    <Select label="Discount Type *required"
                                 value={code.discountType}
                                 disabled={this.state.isEditingLocked}
                                 onChange={(e,k,p) => { this._handleChangeDiscountType(e.target.value) }} >
                      <MenuItem value="PerTicket">Per Ticket </MenuItem>
                      <MenuItem value="PerOrder">Per Order</MenuItem>
                      <MenuItem value="Percentage">Percentage</MenuItem>
                    </Select>
                  </div>

                  {code.discountType !== 'Percentage' &&
                    <div className="padding">
                      <TextField value={code.discountAmountInUsd || ""}
                                 label="Discount Amount in USD *required"
                                 style={{width:"350px", paddingTop:"10px"}}
                                 disabled={this.state.isEditingLocked}
                                 onChange={(e) => { this._handleChangeDiscountAmount(e.target.value) }}/>
                    </div>
                  }

                  {code.discountType === 'Percentage' &&
                    <div className="padding">
                      <TextField value={code.percentageOff || ""}
                                 label="Percentage Off (no % sign) *required"
                                 style={{width:"350px", paddingTop:"10px"}}
                                 disabled={this.state.isEditingLocked}
                                 onChange={(e) => { this._handleChangePercentageAmount(e.target.value) }}/>
                    </div>
                  }

                  <div className="padding">
                    <TextField value={code.numberOfUses}
                               label="Number Of Uses Left"
                               style={{width:"350px", paddingTop:"10px"}}
                               disabled={this.state.isEditingLocked}
                               onChange={(e) => { this._handleChangeNumberOfUses(e.target.value) }}/>
                  </div>

                  <div className="padding">
                    <TextField value={(code.mixerBundleOrder && code.mixerBundleOrder.bundleAmount) || "N/A"}
                               label="Original Bundle Amount"
                               style={{width:"350px", paddingTop:"10px"}}
                               disabled={true} />
                  </div>

                  <div className="padding">
                    <p style={{fontSize:"10px"}}>Mixer bundles can only be used by one person so Applicable User is required</p>
                    <TextField value={code.applicableNewEmail || ""}
                               label="Applicable User *required"
                               style={{width:"350px", paddingTop:"10px"}}
                               disabled={this.state.isEditingLocked}
                               onChange={(e) => { this._handleChangeApplicableNewEmail(e.target.value) }}/>
                  </div>

                  <div>
                    <p style={{fontSize:"10px"}}>Be sure to check Is For Mixer Only and uncheck Is For Ticket Only</p>
                    <FormControlLabel
                      control={<Checkbox checked={code.isForMixerOnly}
                                         onChange={(e,b) => { this._handleChangeIsForMixerOnly(b) }}
                                         disabled={this.state.isEditingLocked} />}
                      label="Is For Mixer Only"
                    />
                    <FormControlLabel
                      control={<Checkbox checked={code.isForTicketOnly}
                                         onChange={(e,b) => { this._handleChangeIsForTicketOnly(b) }}
                                         disabled={this.state.isEditingLocked} />}
                      label="Is For Tickets Only"
                    />
                  </div>

                  <p>Note: standard mixer bundles are 100% off for 5 or 10 uses</p>
                </span>
              }
            </div>
          </div>
        </Paper>
      </div>
    )
  }
}

const PURCHASE_CODE_QUERY = gql`
  query PurchaseCodeQuery($id: ID!) {
    purchaseCode(id: $id) {
      id
      applicableNewEmail
      code
      currency
      blackoutDates
      discountEndDate
      discountAmountInUsd
      discountType
      initialLifetimeAmountInUsd
      isExpired
      isForFamilyFriendlyOnly
      isForMixerOnly
      isForPremiumOnly
      isForTicketOnly
      isOneTimeUse
      isOneTimeUsePerUser
      isNewUserOnly

      mixerBundleOrder {
        id
        bundleAmount
      }

      numberOfUses
      percentageOff
      purchaseCodeType
      remainingLifetimeAmountInUsd
    }
  }
`

const PURCHASE_CODE_UPDATE_MUTATION = gql`
  mutation PurchaseCodeUpdateMutation
  (
    $id: ID!, $applicableNewEmail: String, $code: String!, $currency: String!, $isOneTimeUse: Boolean!, $isOneTimeUsePerUser: Boolean!,
    $isExpired: Boolean!, $isForFamilyFriendlyOnly: Boolean!, $isForMixerOnly: Boolean!, $isForPremiumOnly: Boolean!,
    $isForTicketOnly: Boolean!, $isNewUserOnly: Boolean!, $numberOfUses: Int, $discountEndDate: Date,
    $blackoutDates: JSON, $discountAmountInUsd: Float, $initialLifetimeAmountInUsd: Float,
    $remainingLifetimeAmountInUsd: Float, $purchaseCodeType: String!, $discountType: String!, $percentageOff: Int
  ) {
    purchaseCodeUpdate(data: {
      id: $id,
      code: $code,
      currency: $currency,
      applicableNewEmail: $applicableNewEmail,
      isOneTimeUse: $isOneTimeUse,
      isOneTimeUsePerUser: $isOneTimeUsePerUser,
      isExpired: $isExpired,
      isForFamilyFriendlyOnly: $isForFamilyFriendlyOnly,
      isForMixerOnly: $isForMixerOnly,
      isForPremiumOnly: $isForPremiumOnly,
      isForTicketOnly: $isForTicketOnly,
      isNewUserOnly: $isNewUserOnly,
      numberOfUses: $numberOfUses,
      discountEndDate: $discountEndDate,
      blackoutDates: $blackoutDates,
      discountAmountInUsd: $discountAmountInUsd,
      initialLifetimeAmountInUsd: $initialLifetimeAmountInUsd,
      remainingLifetimeAmountInUsd: $remainingLifetimeAmountInUsd,
      purchaseCodeType: $purchaseCodeType,
      discountType: $discountType,
      percentageOff: $percentageOff,
    }) {
      id
      discountEndDate
    }
  }
`

export default withApollo(graphql(PURCHASE_CODE_QUERY, {
  name: 'purchaseCodeQuery',
  options: (ownProps) => {
    const id = ownProps.match.params.id

    return {
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
      variables: { id }
    }
  }
})(PurchaseCodeDetails))
