import React, { useState, useEffect } from 'react'
import { gql } from 'apollo-boost'
import { graphql, withApollo } from 'react-apollo'
import moment from 'moment-timezone'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import { withRouter } from 'react-router'

import AdapterDateFns from '@mui/lab/AdapterDateFns'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Divider from '@mui/material/Divider'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import FormLabel from '@mui/material/FormLabel'
import InputLabel from '@mui/material/InputLabel'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body1,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
}))

const TheaterData = (props) => {
  const { theater } = props

  const [requiredFieldBlankError, setRequiredFieldBlankError] = useState(false)
  const [saving, setSaving] = useState(false)
  const [locations, setLocations] = useState([])

  const [theaterState, setTheaterState] = useState({
    address: theater.address,
    location: theater.location.name,
    name: theater.name,
    slug: theater.slug,
  })

  const handleSaveChanges = async () => {
    try {
      setSaving(true)

      var { address, name, slug, location } = theaterState

      if (!address || !slug || !name || !location) {
        setRequiredFieldBlankError(true)
        alert('Please fill in required fields')

        setSaving(false)
        return
      }

      var variables = {
        id: theater.id,
        name,
        address,
        slug,
        location
      }

      var result = await props.client.mutate({
        mutation: THEATER_UPDATE_MUTATION,
        variables
      })

      alert('Theater successfully updated')
      setSaving(false)

    } catch (err) {
      setSaving(false)
      alert(`Did not save, error: ${err}`)
    }
  }

  const handleUpdateTheaterState = (key, value) => {
    var newTheaterState = {...theaterState}

    newTheaterState[key] = value
    setTheaterState(newTheaterState)
  }

  const loadLocations = async () => {
    try {
      var locationsQuery = await props.client.query({ query: LOCATIONS_LIST_QUERY })
      setLocations(locationsQuery.data.locationsList.items)

    } catch (err) {
      console.log('Unable to get locations data')
    }
  }

  useEffect(() => {
    loadLocations()
  }, [])

  return (
    <Item className="order-tile">
      <Typography sx={{ flex: '1 1 100%' }} variant="h5" className="order-tile-subtitle" component="div">
        {theater.name}
      </Typography>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header">
          <Typography className="accordion-title">Basic Details</Typography>
          <Divider />
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <Divider />

            <Stack justifyContent="space-around" direction="row" flexWrap="wrap">

              <Stack direction="column">
                <h3>Details</h3>

                <div className="padding first-entry">
                  <TextField label="ID"
                             defaultValue={theater.id}
                             disabled={true} />
                </div>

                <div className="padding">
                  <TextField label="Name"
                             helperText="*required field"
                             error={requiredFieldBlankError && !theaterState.name}
                             defaultValue={theaterState.name}
                             onChange={(e) => { handleUpdateTheaterState('name', e.target.value) }} />
                </div>

                <div className="padding">
                  <TextField label="Slug"
                             helperText="*required field"
                             error={requiredFieldBlankError && !theaterState.slug}
                             defaultValue={theaterState.slug}
                             onChange={(e) => { handleUpdateTheaterState('slug', e.target.value) }} />
                </div>
              </Stack>

              <Stack direction="column">
                <h3>Location</h3>

                <div className="padding flex-basis-0">
                  <FormControl error={requiredFieldBlankError && !theaterState.location}>
                    <InputLabel>Location</InputLabel>
                    <Select value={theaterState.location}
                            label="Location"
                            onChange={(event) => handleUpdateTheaterState('location', event.target.value)}>

                        {locations && locations.map((locationMap, idx) => (
                          <MenuItem key={idx} value={locationMap.name}> {locationMap.name} </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>*required field</FormHelperText>
                  </FormControl>
                </div>

                <div className="padding">
                  <TextField label="Address"
                             helperText="*required field"
                             error={requiredFieldBlankError && !theaterState.address}
                             defaultValue={theaterState.address}
                             onChange={(e) => { handleUpdateTheaterState('address', e.target.value) }} />
                </div>
              </Stack>

            </Stack>
          </div>

        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header">
          <Typography className="accordion-title">Shows List</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Divider />

          {theater.shows.items.length > 0 && theater.shows.items.map((show) =>
            (<ListItem key={show.id}>
               <a href={'/show/' + show.id}><ListItemText primary={show.shortName} /></a>
             </ListItem>))
           }

        </AccordionDetails>
      </Accordion>

      {!saving &&
        <div className="save-button">
          <Button variant="contained" onClick={handleSaveChanges}>
            Save changes
          </Button>
        </div>
      }

      {saving &&
        <CircularProgress />
      }

    </Item>
  )
}

function TheaterDetails(props) {
  const [loading, setLoading] = useState(true)
  const [updating, setUpdating] = useState(false)
  const [error, setError] = useState(false)

  const [theater, setTheater] = useState([])

  const getTheaterData = () => {
    try {
      var theater = props.theaterQuery.theater

      if (theater) {
        setTheater(theater)
        setLoading(false)
      }

    } catch(err) {
      console.log(err)
      setError(true)
    }
  }

  useEffect(async () => {
    if (loading) {
      await getTheaterData()
    }
  }, [props.theaterQuery, loading])

  return (
    <Box sx={{ width: '100%' }}>
      <div className='data'>

        <Typography sx={{ flex: '1 1 100%' }} variant="h3" className="page-title" component="div">
          Theater Details
        </Typography>

        {loading &&
          <CircularProgress />
        }

        {!theater && error &&
          <h2>Error</h2>
        }

        {theater && !loading &&
          <TheaterData theater={theater} client={props.client} />
        }

      </div>
    </Box>
  )
}

const THEATER_QUERY = gql`
  query TheaterQuery($id: ID) {
    theater(id: $id) {
      id
      createdAt
      name
      slug
      address

      location {
        id
        name
      }

      shows {
        items {
          id
          shortName
        }
      }
    }
  }
`

const THEATER_UPDATE_MUTATION = gql`
  mutation TheaterUpdateMutation($id: ID!, $name: String, $address: String, $slug: String, $location: String) {
    theaterUpdate(data: {
      id: $id
      name: $name
      slug: $slug
      address: $address

      location: {
        connect: { name: $location }
      }

    }) {
      id
    }
  }
`

const LOCATIONS_LIST_QUERY = gql`
  query LocationsListQuery {
    locationsList(sort: { name: ASC }) {
      items {
        id
        name
      }
    }
  }
`

export default withApollo(graphql(THEATER_QUERY, {
  name: 'theaterQuery',
  options: (ownProps) => {
    const id = ownProps.match.params.id

    return {
      variables: { id },
      notifyOnNetworkStatusChange: true
    }
  }
})(TheaterDetails))
