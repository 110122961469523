import React, { useState, useEffect } from 'react'
import AWS from 'aws-sdk'
import Dropzone from 'react-dropzone'
import { gql } from 'apollo-boost'
import { graphql, withApollo } from 'react-apollo'
import { useHistory } from 'react-router-dom'
import moment from 'moment-timezone'
import queryString from 'query-string'
import S3 from 'aws-s3'
import _ from 'underscore'

import AdapterDateFns from '@mui/lab/AdapterDateFns'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import Checkbox from '@mui/material/Checkbox'
import CheckIcon from '@mui/icons-material/Check'
import Chip from '@mui/material/Chip'
import CircularProgress from '@mui/material/CircularProgress'
import DateRangePicker from '@mui/lab/DateRangePicker'
import DescriptionIcon from '@mui/icons-material/Description'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'
import Divider from '@mui/material/Divider'
import FilterListIcon from '@mui/icons-material/FilterList'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import InputLabel from '@mui/material/InputLabel'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MobileDatePicker from '@mui/lab/MobileDatePicker'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { green, red } from '@mui/material/colors'
import Pagination from '@mui/material/Pagination'
import Popover from '@mui/material/Popover'
import Select from '@mui/material/Select'
import SortIcon from '@mui/icons-material/Sort'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'

import { CHIP_COLORS } from '../colors'

import BRClient from '../broadwayRouletteClient'

import {
  AWS_ACCESS_KEY_ID,
  AWS_ACCESS_KEY,
  AWS_TICKETS_BUCKET_URL,
  LOCALE,
  SPECIAL_SHOW_IDS,
  TODAY
} from '../constants'

import { CREDIT_CREATE_MUTATION } from '../queries'

import './TicketAssignments.css'

const options = {
  accessKeyId: AWS_ACCESS_KEY_ID,
  secretAccessKey: AWS_ACCESS_KEY,
}

const s3 = new AWS.S3(options)

const MAX_FILE_SIZE = 15000000
const MAX_RANGE_IN_DAYS = 15
const DEFAULT_RANGE_IN_DAYS = 1
const ORDERS_TO_VIEW = 30

const MATINEE = 'Matinee'
const EVENING = 'Evening'

const SHOW_PREFERENCE = {
  BOTH: 'Both',
  MUSICAL: 'Musical',
  MUSICAL_AND_SPECIAL: 'MusicalAndSpecial',
  PLAY: 'Play',
  PLAY_AND_SPECIAL: 'PlayAndSpecial',
  ALL: 'All'
}

const EXCLUSION_THRESHOLD = 2
const BELIEVEABLE_SAVE_TIME_IN_MS = 500
const CLIENT_VERSION_FOR_MUSICAL_ONLY = "2.10.0"
const ADMIN_NOTES_ID = 'cldyvkm9a00hs08l830pz7qo1'

const isProd = process.env.REACT_APP_STAGE === 'PROD' ? true : false

const getDateRange = (ownProps) => {
  const queryParams = queryString.parse(ownProps.location.search)

  var startOfPeriod = queryParams.startDate ? moment(queryParams.startDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
  var endOfPeriod = queryParams.endDate ? moment(queryParams.endDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')

  return [startOfPeriod, endOfPeriod]
}

const getDefaultShowTimeForDay = (dayOfWeek) => {
  return ({
    Monday: EVENING,
    Tuesday: EVENING,
    Wednesday: EVENING,
    Thursday: EVENING,
    Friday: EVENING,
    Saturday: EVENING,
    Sunday: MATINEE,
  })[dayOfWeek]
}

const getShowSelectionColors = (rank, isSelected) => {
  var selectionKey = isSelected ? 'selected' : 'unselected'

  return CHIP_COLORS[rank][selectionKey]
}

const getShowTime = (order) => {
  if (order.requestedShowTime) { return order.requestedShowTime }

  var dayOfWeek = moment(order.requestedShowDate).format('dddd')

  return getDefaultShowTimeForDay(dayOfWeek)
}

const ExpandMore = styled((props) => {
  const { expand, ...other } = props
  return <IconButton {...other} />
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}))

function RestaurantSelectionList(props){
  const { onChange, restaurants, restaurantsBeenToList } = props

  const handleTapRestaurant = (restaurantId) => {
    onChange(restaurantId)
  }

  return (
    <div>
      {restaurants.map(restaurant => {
        const restaurantId = restaurant.id
        var restaurantsBeenTo = restaurantsBeenToList && restaurantsBeenToList.filter(restaurant => restaurant === restaurantId)

        var isSelected = restaurantId === props.selectedRestaurantId
        var selectionColors = getShowSelectionColors('yes', isSelected)

        if (restaurantsBeenToList && restaurantsBeenTo.indexOf(restaurantId) > -1 && !isSelected) {
          selectionColors = getShowSelectionColors('no', isSelected)
        }

        return (
          <Chip key={restaurant.id}
                style={{ backgroundColor: selectionColors.background, margin: "4px" }}
                label={restaurant.name}
                onClick={() => handleTapRestaurant(restaurantId)} />
        )
      })}
    </div>
  )
}

function ShowSelection(props) {
  const { algorithmMeta, isSelected, onChange, showRank } = props

  const handleTapShow = () => {
    onChange(props.showRank.show.id)
  }

  var selectionColors = getShowSelectionColors(showRank.overallRank, isSelected)

  var showProbability = (algorithmMeta && algorithmMeta[showRank.show.id])
                        ? algorithmMeta[showRank.show.id].probability * 100
                        : null

  var label = showProbability
              ? `${showRank.show.shortName} -- ${showProbability.toFixed(2)}%`
              : `${showRank.show.shortName}`

  var showPartnerAvailability = showRank.annotations.isShowPartner && showRank.annotations.hasPartnerTicketsAvailableOnDay
  var isExcluded = showRank.annotations.isExcludedShow
  var hasOrWillSeeShow = showRank.annotations.hasOrWillSeeShow

  var iconText = ''

  if (showPartnerAvailability) {
    iconText = 'SP'
  } else if (isExcluded) {
    iconText = 'X'
  } else if (hasOrWillSeeShow) {
    iconText = 'S'
  }

  var annotationIcon = iconText ? (
    <Avatar style={{ backgroundColor: selectionColors.avatarBackground, color: selectionColors.avatarColor, size: 32 }}>
      {iconText}
    </Avatar>
  ) : null

  return (
    <Chip style={{ backgroundColor: selectionColors.background, margin: '4px' }}
          label={label}
          avatar={annotationIcon}
          onClick={() => handleTapShow()} />
  )
}

function ShowSelectionList(props) {
  const { algorithmMeta, onChange, selectedShowId, showRankings } = props

  return (
    <div>
        {showRankings.yes.map(showRank => {
           var isSelected = showRank.show.id === selectedShowId

           return <ShowSelection algorithmMeta={algorithmMeta}
                                 key={showRank.show.id}
                                 showRank={showRank}
                                 isSelected={isSelected}
                                 onChange={onChange} />
        })}

        {showRankings.maybe.map(showRank => {
           var isSelected = showRank.show.id === selectedShowId

           return <ShowSelection algorithmMeta={algorithmMeta}
                                 key={showRank.show.id}
                                 showRank={showRank}
                                 isSelected={isSelected}
                                 onChange={onChange} />
        })}

      </div>
  )
}

function FilterPopover(props) {
  const {
    getItemsToRender,
    location,
    setLocation,
    retailer,
    setRetailer,
    setShowDateRange,
    setShowOnlyUnfulfilledOrders,
    setShowTime,
    showDateRange,
    showOnlyUnfulfilledOrders,
    showTime
  } = props

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'popover' : undefined

  return (
    <div>
      <Tooltip title="Filter list">
        <IconButton aria-describedby={id} onClick={handleClick}>
          <FilterListIcon />
        </IconButton>
      </Tooltip>

      <Popover id={id}
               open={open}
               anchorEl={anchorEl}
               onClose={handleClose}
               anchorOrigin={{
                 vertical: 'bottom',
                 horizontal: 'left',
               }}>

        <div className="filters">
          <h3>Filters</h3>

          <Stack spacing={3} direction="column" justifyContent="flexStart" alignItems="center" className="filter-section">
            <FormControl>
              <InputLabel id="select-label">Location</InputLabel>
              <Select labelId="select-label"
                      id="select"
                      value={location}
                      label="Location"
                      onChange={(event) => setLocation(event.target.value)}>
                <MenuItem value="Broadway">Broadway</MenuItem>
                <MenuItem value="West End">West End</MenuItem>
              </Select>
            </FormControl>

            <FormControl>
              <InputLabel id="select-label">Retailer</InputLabel>
              <Select labelId="select-label"
                      id="select"
                      value={retailer}
                      label="Retailer"
                      onChange={(event) => setRetailer(event.target.value)}>
                <MenuItem value="All">All</MenuItem>
                <MenuItem value="Broadway Roulette">Broadway Roulette</MenuItem>
                <MenuItem value="Goldstar">Goldstar</MenuItem>
              </Select>
            </FormControl>

            <FormControl>
              <InputLabel id="select-label">Show Time</InputLabel>
              <Select labelId="select-label"
                      id="select"
                      value={showTime}
                      label="Show Time"
                      onChange={(event) => setShowTime(event.target.value)}>
                <MenuItem value="Evening">Evening</MenuItem>
                <MenuItem value="Matinee">Matinee</MenuItem>
                <MenuItem value="Both">Both</MenuItem>
              </Select>
            </FormControl>

            <FormControlLabel control={
              <Checkbox checked={showOnlyUnfulfilledOrders}
                        onChange={(event) => setShowOnlyUnfulfilledOrders(event.target.checked)} />
            } label="Show only unfulfilled orders?" />
          </Stack>
        </div>

      </Popover>
    </div>
  )
}

function OrderMenu (props) {
  const { client, currentUser, order, setUpdating } = props

  const history = useHistory()

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null);
  }

  const handleCancelOrder = async (orderId, credit) => {
    console.log('cancelling order ')
    setUpdating(true)

    try {
      var updateOrderMutation = gql`
        mutation UpdateOrderMutation($orderId: ID!) {
          orderUpdate(data: {
            id: $orderId,
            isValidOrder: false,
          }) {
            id
            isValidOrder
            requestedShowDate
            requestedShowTime
            updateHistory
          }
        }
      `

      var result = await client.mutate({
        mutation: updateOrderMutation,
        variables: {
          orderId
        }
      })

      var cancelledOrder = result.data.orderUpdate
      var orderType = 'spin'

      var cancelledOrderHistory = cancelledOrder.updateHistory ? JSON.parse(cancelledOrder.updateHistory.replace(/\n/g, " ")) : []
      var newUpdateHistory = [...cancelledOrderHistory]

      var updateHistoryEntry = {}
      updateHistoryEntry.date = moment().format('YYYY-MM-DD HH:mm A')
      updateHistoryEntry.key = 'isValidOrder'
      updateHistoryEntry.newValue = false
      updateHistoryEntry.oldValue = cancelledOrder.isValidOrder
      updateHistoryEntry.user = currentUser.firstName

      newUpdateHistory.push(updateHistoryEntry)

    } catch(err) {
      alert('Unable to cancel order: ' + orderId)
      console.log(err)
    }

    try {
      var result = await BRClient.updateOrderOnSpreadsheet(orderId, orderType, cancelledOrder.requestedShowDate, cancelledOrder.requestedShowTime, newUpdateHistory, credit)

      if (result.status !== 200) {
        alert('Order has not been updated on spreadsheet')
      }
    } catch {
      alert('Order has not been updated on spreadsheet')
    }

    window.location.reload()
  }

  const handleCreateCreditForUser = async (order) => {
    setUpdating(true)
    console.log('creating credit for order ')

    var creditToAdd = order.paymentDetails.price + order.paymentDetails.giftCardAmount + order.paymentDetails.creditAmount
    var currency = order.location.currency

    // create new credit
    var creditResult = await props.client.mutate({
      mutation: CREDIT_CREATE_MUTATION,
      variables: {
        currency: currency,
        initialAmount: creditToAdd,
        remainingAmount: creditToAdd,
        orderId: order.id,
        userId: order.user.id
      }
    })

    var credit = creditResult.data.purchaseCreditCreate
    await handleCancelOrder(order.id, credit)
  }

  const open = Boolean(anchorEl)

  return (
      <div>
        <IconButton aria-label="settings"
                    onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
        <Menu anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}>
          <MenuItem onClick={() => { history.push(`/user/${order.user.id}`) }}>Edit User Details</MenuItem>
          <MenuItem onClick={() => { history.push(`/order?id=${order.id}`) }}>Edit Order Details</MenuItem>
          <MenuItem onClick={() => { handleCancelOrder(order.id) }}>Cancel</MenuItem>
          <MenuItem onClick={() => { handleCreateCreditForUser(order) }}>Cancel + Create Credit</MenuItem>
        </Menu>

      </div>
  )
}

function NotesPopover(props) {
  const { notes, setNotes } = props

  const [anchorEl, setAnchorEl] = useState(null)
  const [saving, setSaving] = useState(false)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSaveNotes = async () => {
    try {
      setSaving(true)

      await props.client.mutate({
        mutation: CONFIG_JSON_UPDATE_MUTATION,
        variables: {
          id: ADMIN_NOTES_ID,
          value: notes
        }
      })

      setSaving(false)

    } catch (err) {
      alert(`Error while updating config: ${err.message}`)
      setSaving(false)
    }
  }

  const handleUpdateNotes = (value) => {
    var newNotes = notes
    newNotes.ticketAssignments = value

    setNotes(newNotes)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'popover' : undefined

  return (
    <div>
      <Tooltip title="Notes">
        <IconButton aria-describedby={id} onClick={handleClick}>
          <DescriptionIcon />
        </IconButton>
      </Tooltip>

      <Popover id={id}
               open={open}
               anchorEl={anchorEl}
               onClose={handleClose}
               anchorOrigin={{
                 vertical: 'bottom',
                 horizontal: 'left',
               }}>

        <div className="filters">
          <h3>Notes</h3>
          <Stack spacing={3} direction="column" justifyContent="flexStart" alignItems="center" className="filter-section">
            <TextField label="Notes"
                       className="notes-input"
                       defaultValue={notes && notes['ticketAssignments']}
                       multiline
                       maxRows={10}
                       onChange={(event) => handleUpdateNotes(event.target.value)} />

            {!saving &&
              <Button variant="contained" onClick={handleSaveNotes}>Save</Button>
            }

            {saving &&
              <CircularProgress />
            }

          </Stack>
        </div>

      </Popover>
    </div>
  )
}

function SortPopover(props) {
  const { setSortBy, setSortDirection, sortBy, sortDirection } = props

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'popover' : undefined

  return (
    <div>
      <Tooltip title="Sort list">
        <IconButton aria-describedby={id} onClick={handleClick}>
          <SortIcon />
        </IconButton>
      </Tooltip>

      <Popover id={id}
               open={open}
               anchorEl={anchorEl}
               onClose={handleClose}
               anchorOrigin={{
                 vertical: 'bottom',
                 horizontal: 'left',
               }}>

        <div className="filters">
          <h3>Sort</h3>
          <Stack spacing={3} direction="column" justifyContent="flexStart" alignItems="center" className="filter-section">
            <FormControl>
              <InputLabel id="select-label">Sort By</InputLabel>
              <Select labelId="select-label"
                      id="select"
                      value={sortBy}
                      label="Sort By"
                      onChange={(event) => setSortBy(event.target.value)}>
                <MenuItem value="name">Last Name</MenuItem>
                <MenuItem value="requestedShowDate">Show Date</MenuItem>
                <MenuItem value="orderPlacedAt">Order Placed Date</MenuItem>
              </Select>
            </FormControl>

            <FormControl>
              <InputLabel id="select-label">Sort Direction</InputLabel>
              <Select labelId="select-label"
                      id="select"
                      value={sortDirection}
                      label="Sort Direction"
                      onChange={(event) => setSortDirection(event.target.value)}>
                <MenuItem value="asc">Ascending</MenuItem>
                <MenuItem value="desc">Descending</MenuItem>
              </Select>
            </FormControl>
          </Stack>
        </div>

      </Popover>
    </div>
  )
}

function TicketAssignmentsCard(props) {
  const { blackoutDates, currentUser, order, restaurants, shows, setUpdating } = props

  const ticketDetails = order.ticketDetails
  const restaurantReservationDetails = order.restaurantReservationDetails

  const showId = ticketDetails.show ? ticketDetails.show.id : null

  const restaurantId = restaurantReservationDetails && restaurantReservationDetails.restaurant
                       ? restaurantReservationDetails.restaurant.id : null
  const madeBy = restaurantReservationDetails ? restaurantReservationDetails.madeBy : null
  const time = restaurantReservationDetails ? restaurantReservationDetails.time : null

  const [restaurantReservationDetailsState, setRestaurantReservationDetailsState] = useState({
    madeBy,
    restaurantId,
    time
  })

  const [ticketDetailsState, setTicketDetailsState] = useState({
    listPrice: ticketDetails.listPrice,
    notes: ticketDetails.notes,
    mappedBy: ticketDetails.mappedBy,
    purchasedBy: ticketDetails.purchasedBy,
    purchaseDate: ticketDetails.purchaseDate ? moment(ticketDetails.purchaseDate).format('YYYY-MM-DDTHH:MM') : null,
    purchasePrice: ticketDetails.purchasePrice,
    seats: ticketDetails.seats,
    section: ticketDetails.section,
    showTime: ticketDetails.showTime,
    sourceOrderId: ticketDetails.sourceOrderId,
    source: ticketDetails.source,
    ticketOrderId: ticketDetails.ticketOrderId,
  })

  const [saving, setSaving] = useState(false)

  const [selectedShowId, setSelectedShowId] = useState(showId)
  const [selectedRestaurantId, setSelectedRestaurantId] = useState(restaurantId)

  const [ticketImageURL, setTicketImageURL] = useState(ticketDetails.ticketImageURL)
  const [algorithmResults, setAlgorithmResults] = useState(order.algorithmMeta)

  var originalUpdateHistory = order.updateHistory ? JSON.parse(order.updateHistory.replace(/\n/g, " ")) : []
  const [updateHistory, setUpdateHistory] = useState(originalUpdateHistory)
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)

  const handleUpdateRestaurantDetails = (key, value) => {
    var newRestaurantDetailsState = {...restaurantReservationDetailsState}
    newRestaurantDetailsState[key] = value

    setRestaurantReservationDetailsState(newRestaurantDetailsState)
  }

  const handleUpdateTicketDetails = _.debounce((key, value) => {
    var newTicketDetailsState = {...ticketDetailsState}
    var newUpdateHistory = [...updateHistory]
    var updateHistoryEntry = {}

    updateHistoryEntry.user = currentUser.firstName
    updateHistoryEntry.date = moment().format('YYYY-MM-DD HH:mm A')
    updateHistoryEntry.key = key
    updateHistoryEntry.newValue = value.toString()
    updateHistoryEntry.oldValue = ticketDetailsState[key] ? ticketDetailsState[key].toString() : ''

    newUpdateHistory.push(updateHistoryEntry)
    newTicketDetailsState[key] = value

    setTicketDetailsState(newTicketDetailsState)
    setUpdateHistory(newUpdateHistory)
  }, 500)

  const getAnnotations = (order, show, excludedShowIds, showsAssignedToUserIds) => {
    var showTime = getShowTime(order)

    var isShowPartnerOnDay = getIsShowPartnerOnDay(show, showTime, moment(order.requestedShowDate))

    var isExcludedShow = show.id in excludedShowIds
    var hasOrWillSeeShow = show.id in showsAssignedToUserIds

    var hasPartnerTicketsAvailableOnDay = !(blackoutDates && blackoutDates[show.id])

    return {
      isShowPartner: isShowPartnerOnDay,
      isExcludedShow,
      hasOrWillSeeShow,
      hasPartnerTicketsAvailableOnDay
    }
  }

  const getIsExcludingTooMuchOfOneType = (allShows, excludedShows) => {
    var numberOfActivePlays = 0
    var numberOfActiveMusicals = 0

    var numberOfExcludedPlays = 0
    var numberOfExcludedMusicals = 0

    allShows.forEach(show => {
      show.isMusical ? numberOfActiveMusicals++ : numberOfActivePlays++
    })

    excludedShows.forEach(show => {
      show.isMusical ? numberOfExcludedMusicals++ : numberOfExcludedPlays++
    })

    var isExcludingTooManyPlays = (numberOfActivePlays - numberOfExcludedPlays) <= EXCLUSION_THRESHOLD
    var isExcludingTooManyMusicals = (numberOfActiveMusicals - numberOfExcludedMusicals) <= EXCLUSION_THRESHOLD

    return isExcludingTooManyPlays || isExcludingTooManyMusicals
  }

  const getIsShowPartnerOnDay = (show, showTime, showDate) => {
    var isShowPartnerOnDay = false

    if (show.showPartnerDetails.isShowPartner) {
      var dayOfWeek = showDate.format('dddd').toLowerCase()

      var dayTimeKey = dayOfWeek + showTime

      if (show.showPartnerDetails[dayTimeKey]) {
        isShowPartnerOnDay = true
      }
    }

    return isShowPartnerOnDay
  }

  const getMatchData = (order, showsSeenBackCompat, excludedShowIds,
                        isExcludingTooMuchOfOneType, showsAssignedToUserIds, show) => {

    var showIsSpecialShow = SPECIAL_SHOW_IDS.indexOf(show.id) > -1

    var doesSatisfyAllPreference = order.showPreference === SHOW_PREFERENCE.ALL
    var doesSatisfyBothPreference = order.showPreference === SHOW_PREFERENCE.BOTH
    var doesSatisfyMusicalPreference = order.showPreference === SHOW_PREFERENCE.MUSICAL && show.isMusical && !showIsSpecialShow
    var doesSatisfyMusicalAndSpecialPreference = order.showPreference === SHOW_PREFERENCE.MUSICAL_AND_SPECIAL && show.isMusical
    var doesSatisfyPlayPreference = order.showPreference === SHOW_PREFERENCE.PLAY && !show.isMusical && !showIsSpecialShow
    var doesSatisfyPlayAndSpecialPreference = order.showPreference === SHOW_PREFERENCE.PLAY_AND_SPECIAL && !show.isMusical

    var doesSatisfyPreference = doesSatisfyAllPreference ||
                                doesSatisfyBothPreference ||
                                doesSatisfyMusicalPreference ||
                                doesSatisfyMusicalAndSpecialPreference ||
                                doesSatisfyPlayPreference ||
                                doesSatisfyPlayAndSpecialPreference

    var doesSatisfyChildFriendly = !order.isRequestingChildFriendly ||show.isChildFriendly

    var isExcludedShow = show.id in excludedShowIds
    var hasOrWillSeeShow = show.id in showsAssignedToUserIds
    var hasSeenShowBRV1 = showsSeenBackCompat[show.id]

    var matchResults = []
    var isClientVersionValid = (order.clientVersion && order.clientVersion.split('.').length === 3)
    var isStrictlyMusicalsOnlyOrder = false

    if (isClientVersionValid) {
      var clientVersionParts = order.clientVersion.replace('a','').split('.').map(Number)
      var musicalsOnlyCutoffClientVersionParts = CLIENT_VERSION_FOR_MUSICAL_ONLY.split('.').map(Number)

      if (clientVersionParts[0] === musicalsOnlyCutoffClientVersionParts[0]) {
        if (clientVersionParts[1] === musicalsOnlyCutoffClientVersionParts[1]) {
          if (clientVersionParts[2] >= musicalsOnlyCutoffClientVersionParts[2]) {
            isStrictlyMusicalsOnlyOrder = true
          }
        } else if (clientVersionParts[1] > musicalsOnlyCutoffClientVersionParts[1]) {
          isStrictlyMusicalsOnlyOrder = true
        }
      } else if (clientVersionParts[0] > musicalsOnlyCutoffClientVersionParts[0]) {
        isStrictlyMusicalsOnlyOrder = true
      }
    }

    if (showIsSpecialShow && ['All', 'MusicalAndSpecial', 'PlayAndSpecial'].indexOf(order.showPreference) === -1 ) {
      matchResults.push({ result: 'no', reason: 'User did not opt in for Broadway favorites' })
    } if (isExcludedShow) {
      matchResults.push({ result: 'no', reason: 'This show has been excluded' })
    } if (hasSeenShowBRV1) {
      matchResults.push({ result: 'no', reason: 'This has seen or has excluded the show in BRV1' })
    } if (hasOrWillSeeShow) {
      matchResults.push({ result: 'no', reason:'The customer has/will see this show'})
    } if (!doesSatisfyChildFriendly) {
      matchResults.push({ result: 'no', reason: 'The show is not child friendly' })
    } if (!doesSatisfyMusicalPreference && order.showPreference === SHOW_PREFERENCE.MUSICAL &&
          isStrictlyMusicalsOnlyOrder) {
      matchResults.push({ result: 'no', reason: 'The customer selected "Musical Only"' })
    } if (!doesSatisfyMusicalAndSpecialPreference && order.showPreference === SHOW_PREFERENCE.MUSICAL_AND_SPECIAL &&
        isStrictlyMusicalsOnlyOrder) {
      matchResults.push({ result: 'no', reason: 'The customer selected "Musical and Special Only"' })
    } if (!doesSatisfyPlayPreference && order.showPreference === SHOW_PREFERENCE.PLAY &&
          isStrictlyMusicalsOnlyOrder) {
      matchResults.push({ result: 'no', reason: 'The customer selected "Play Only"' })
    } if (!doesSatisfyPlayAndSpecialPreference && order.showPreference === SHOW_PREFERENCE.PLAY_AND_SPECIAL &&
          isStrictlyMusicalsOnlyOrder) {
      matchResults.push({ result: 'no', reason: 'The customer selected "Play and Special Only"' })
    } if (!doesSatisfyPreference) {
      matchResults.push({ result: 'no', reason: "This show does not satisfy the user's preference" })
    }

    var result = 'yes'
    if (matchResults.some(match => match.result === 'maybe')) { result = 'maybe' }
    if (matchResults.some(match => match.result === 'no')) { result = 'no' }
    if (matchResults.some(match => match.result === 'unavailable')) { result = 'unavailable' }

    return {
      overallResult: result,
      show,
      matchResults
    }
  }

  const getShowRankingsForOrder = (order, allShows, showsSeenBackCompat, showsAssignedToUserIds) => {
    var filteredShows = allShows.filter(show => moment(show.runDateStart) <= moment(order.requestedShowDate) || SPECIAL_SHOW_IDS.indexOf(show.id) > -1 )
                                .filter(show => !show.runDateEnd || moment(show.runDateEnd) >= moment(order.requestedShowDate))
                                .filter(show => show.location.name === order.location.name)
                                .filter(show => show.dateAdded === null || moment(show.dateAdded) < moment(order.orderPlacedAt))

    var excludedShowIds = {}
    order.excludedShows.items.forEach(show => { excludedShowIds[show.id] = true })

    var isExcludingTooMuchOfOneType = getIsExcludingTooMuchOfOneType(filteredShows, order.excludedShows.items)

    var matches = filteredShows.map(show => {
      return getMatchData(order, showsSeenBackCompat, excludedShowIds, isExcludingTooMuchOfOneType, showsAssignedToUserIds, show)
    })

    var showIdToAnnotations = {}
    filteredShows.forEach(show => {
      showIdToAnnotations[show.id] = getAnnotations(order, show, excludedShowIds, showsAssignedToUserIds)
    })

    var showRankings = {
      yes: [],
      maybe: [],
      no: [],
      unavailable: [],
    }

    matches.forEach(match => {
      showRankings[match.overallResult].push({
        overallRank: match.overallResult,
        show: match.show,
        matchResults: match.matchResults,
        annotations: showIdToAnnotations[match.show.id]
      })
    })

    return showRankings
  }

  const handleClickClearButton = async () => {
    setSaving(true)

    try {
      if (selectedShowId) {
        var ticketDetailsResult = await props.client.mutate({
          mutation: CLEAR_TICKET_DETAILS_MUTATION,
          variables: {
            id: order.ticketDetails.id,
            showId: selectedShowId
          }
        })
      }

      if (order.restaurantReservationDetails && selectedRestaurantId) {
        var restaurantResult = await props.client.mutate({
          mutation: RESTAURANT_RESERVATION_DETAILS_CLEAR_MUTATION,
          variables: {
            id: order.restaurantReservationDetails.id,
            restaurantId: selectedRestaurantId,
          }
        })
      }

      var newTicketDetails = {
        listPrice: '',
        notes: '',
        mappedBy: null,
        purchasedBy: null,
        purchaseDate: null,
        purchasePrice: '',
        seats: '',
        section: '',
        showTime: '',
        ticketOrderId: '',
        sourceOrderId: '',
        source: '',
      }

      setTicketDetailsState(newTicketDetails)
      setSelectedShowId(null)

      var orderType = 'spin'
      var spreadsheetResult = await BRClient.updateOrderOnSpreadsheet(order.id, orderType, order.requestedShowDate, order.requestedShowTime, updateHistory, null)

      if (spreadsheetResult.status !== 200) {
        alert('Order not updated on spreadsheet, try again.')
      }

    } catch(err) {
      alert('Error clearing details. Please refresh and try again!')
      console.log(err)
    }

    setSaving(false)
  }

  const handleClickSaveButton = async () => {
    setSaving(true)

    try {
      var { listPrice, notes, mappedBy, purchasedBy, purchaseDate, purchasePrice, seats,
            section, showTime, ticketOrderId, sourceOrderId, source } = ticketDetailsState

      purchaseDate = purchaseDate ? moment(purchaseDate).format('YYYY-MM-DD') : null

      var showConnection = selectedShowId ? `show: { reconnect: { id: "${selectedShowId}" } }` : ''

      var ticketDetailsMutation = gql`
        mutation TicketDetailsUpdateMutation($id: ID!, $listPrice: String, $mappedBy: String,  $notes: String,
                                             $purchasedBy: String, $purchaseDate: Date, $purchasePrice: Float, $seats: String,
                                             $section: String, $showTime: String, $ticketImageURL: String,
                                             $ticketOrderId: String, $sourceOrderId: String, $source: String, $updateHistory: String) {
          ticketDetailUpdate(data:{
            id: $id
            listPrice: $listPrice
            mappedBy: $mappedBy
            notes: $notes
            purchasedBy: $purchasedBy
            purchaseDate: $purchaseDate
            purchasePrice: $purchasePrice
            seats: $seats
            section: $section
            showTime: $showTime
            sourceOrderId: $sourceOrderId
            source: $source

            order: {
              update: { updateHistory: $updateHistory }
            }

            ticketImageURL: $ticketImageURL
            ticketOrderId: $ticketOrderId

            ${showConnection}

          }) {
            id
          }
        }
      `

      var ticketDetailsResult = await props.client.mutate({
        mutation: ticketDetailsMutation,
        variables: {
          id: order.ticketDetails.id,
          listPrice,
          notes,
          mappedBy,
          purchasedBy,
          purchaseDate,
          purchasePrice: parseFloat(purchasePrice, 10),
          seats,
          section,
          showTime,
          sourceOrderId,
          source,
          ticketImageURL,
          ticketOrderId,
          updateHistory: JSON.stringify(updateHistory)
        }
      })

      if (order.restaurantReservationDetails && selectedRestaurantId) {
        var restaurantResult = await props.client.mutate({
          mutation: RESTAURANT_RESERVATION_DETAILS_UPDATE_MUTATION,
          variables: {
            id: order.restaurantReservationDetails.id,
            madeBy: restaurantReservationDetailsState.madeBy,
            restaurantId: selectedRestaurantId,
            time: restaurantReservationDetailsState.time,
          }
        })
      }

      var orderType = 'spin'
      var spreadsheetResult = await BRClient.updateOrderOnSpreadsheet(order.id, orderType, order.requestedShowDate, order.requestedShowTime, updateHistory, null)

      if (spreadsheetResult.status !== 200) {
        alert('Order not updated on spreadsheet, try again.')
      }

    } catch(err) {
      alert('Error saving details. Please refresh and try again!')
      console.log(err)
    }

    setSaving(false)
  }

  const runMatchingAlgorithm = async () => {
    setSaving(true)

    try {
      const result = await BRClient.runMatchingAlgorithmOnOrder(order.id)

      if (result.status !== 200) {
        alert('Matching algorithm failed, try again.')
      } else {
        const matchingAlgorithmQuery = await props.client.query({
          query: MATCHING_ALGORITHM_QUERY,
          variables: {
            id: order.id
          }
        })

        setAlgorithmResults(matchingAlgorithmQuery.data.order.algorithmMeta)
      }
    } catch(err) {
      alert('Matching algorithm failed, try again.')
      console.log(err)
    }

    setSaving(false)
  }

  var isNewCustomer = true

  var showsAssignedToUserIds = {}
  order.user.orders.items.filter(userOrder => userOrder.isValidOrder && userOrder.id !== order.id).forEach(previousOrder => {
    var show = previousOrder.ticketDetails.show

    if (show && show.id) {
      showsAssignedToUserIds[show.id] = true

      const showDateStr = moment(order.requestedShowDate).format('YYYY-MM-DD')
      var isPreviousOrder = previousOrder.requestedShowDate < showDateStr
      if (isPreviousOrder) { isNewCustomer = false }
    }
  })

  var isBRV1Order = (order) => (order.orderPlacedAt <= '2018-03-28T14:30:24.000Z')

  var brv1Orders = order.user.orders.items.filter(isBRV1Order).sort((a, b) => {
    if (a.orderPlacedAt < b.orderPlacedAt) { return -1 }
    else if (a.orderPlacedAt === b.orderPlacedAt) { return 0 }
    else { return 1 }
  })

  const madeMultipleBRV1Orders = (1 < brv1Orders.length)
  const lastBRV1Order = brv1Orders[brv1Orders.length-1]
  const isLastBRV1Order = lastBRV1Order && (lastBRV1Order.id === order.id)

  const isImpactedByLostExclusions = (madeMultipleBRV1Orders && !isLastBRV1Order)

  var exclusionsReferencingInactiveShows = []

  /* Backwards compatibility for seen shows */
  var showsSeenBackCompat = {}
  if (isBRV1Order(order)) {
    order.user.showsSeen.forEach(show => {
      showsSeenBackCompat[show.id] = true
    })
  } else {
    var requestedShowDate = moment(order.requestedShowDate)
    order.excludedShows.items.forEach(show => {
      if ((moment(show.runDateStart) <= moment(requestedShowDate)) && (moment(show.runDateEnd) >= moment(requestedShowDate))) {
        exclusionsReferencingInactiveShows.push(show)
      }
    })
  }

  const usedTestCode = order.paymentDetails.giftCardCode && order.paymentDetails.giftCardCode.code === 'ROKUDOBE'
  const isPremiumOrder = order.isRequestingPremium
  const showRankings = getShowRankingsForOrder(order, shows, showsSeenBackCompat, showsAssignedToUserIds)

  const orderCanSeeLateNight = order.isOptingIntoExtendedHours &&
                               (moment(order.requestedShowDate).format('dddd') === 'Saturday' ||
                               moment(order.requestedShowDate).format('dddd') === 'Sunday')

  const matchingAlgorithmMeta = algorithmResults ? JSON.parse(algorithmResults) : null
  const recommendedShowId = matchingAlgorithmMeta ? matchingAlgorithmMeta.assignedShow : null
  const recommendedShow = matchingAlgorithmMeta ? matchingAlgorithmMeta[recommendedShowId] : null
  const algorithmRunDate = matchingAlgorithmMeta ? matchingAlgorithmMeta.runTime : null

  const cardTitle = (
    <span>
      <a href={'/user/' + order.user.id}>{order.user.firstName} {order.user.lastName}</a>
      {isPremiumOrder ? ' - PREMIUM ORDER' : ''} {usedTestCode ? ' - TEST ORDER' : ''}
    </span>
  )

  const cardSubTitle = `${moment(order.requestedShowDate).format('MMM DD, YYYY')} - ${order.requestedShowTime}`

  var spinType = []
  if (order.paymentDetails.additionalExclusionFee > 0 && !order.isRequestingFamilyFriendlyExclusions) {
    spinType.push('Additional Exclusions')
  } if (order.isRequestingFamilyFriendlyExclusions) {
    spinType.push('Family-Friendly with Additional Exclusions')
  } if (order.isRequestingChildFriendly && !order.isRequestingFamilyFriendlyExclusions) {
    spinType.push('Family-Friendly')
  } if (order.isRequestingPremium) {
    spinType.push('Premium')
  } if (order.isOptingIntoExtendedHours) {
    spinType.push('Late Night')
  } if (!order.isRequestingChildFriendly && !order.isRequestingPremium) {
    spinType.push('Standard')
  }

  if (order.restaurantReservationDetails) {
    var restaurantsBeenToList = order.user.orders.items.filter(userOrder => userOrder.isValidOrder && userOrder.id !== order.id)
                                                       .map(order => {
      if (order.restaurantReservationDetails && order.restaurantReservationDetails.restaurant) {
        return (order.restaurantReservationDetails.restaurant.id)
      }
    })

    var preferencesObj = JSON.parse(props.order.restaurantReservationDetails.preferences)
    var preferencesStr = Object.values(preferencesObj).join('/')
  }

  useEffect(() => {
    setHasUnsavedChanges(true)
  }, [ticketDetailsState])

  return (
    <Grid item xs={12} sm={6} md={12} key={order.id}>
      <Card className={usedTestCode ? 'test-order' : ''}>
        <CardHeader title={cardTitle}
                    subheader={cardSubTitle}
                    avatar={
                      <Avatar sx={{ bgcolor: order.ticketDetails.seats && order.ticketDetails.purchasePrice && order.ticketDetails.showTime
                                    ? green[500] : red[500] }} aria-label="ticket">
                        {order.user.firstName.charAt(0)}{order.user.lastName.charAt(0)}
                      </Avatar>
                    }
                    action={<OrderMenu client={props.client} currentUser={props.currentUser} order={order} setUpdating={setUpdating} />} />

        <CardContent>
          <Divider orientation="horizontal" flexItem />
          <Stack spacing={4} justifyContent="flex-start" alignItems="flex-start" className="order-details-section">
            <TableContainer className="order-details">
              <Typography variant="h5" className="section-title" component="div">
                Order Details
              </Typography>

              <Table aria-label="table" className="ticket-assignments-table">
                <TableBody>
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell component="th" scope="row">Order ID</TableCell>
                      <TableCell component="th" scope="row">
                        <a href={`/order?id=${order.id}`}>{order.id.substr(order.id.length-10)}</a>
                      </TableCell>
                    </TableRow>

                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell align="left">Spin Type</TableCell>
                      <TableCell align="left" className="assignments-table-data"><strong>{spinType.join(', ')}</strong></TableCell>
                    </TableRow>

                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell align="left" className="assignments-table-title">Order Date</TableCell>
                      <TableCell align="left" className="assignments-table-data">
                        {order.requestedShowDate}, {order.requestedShowTime}
                      </TableCell>
                    </TableRow>

                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell align="left">User Email</TableCell>
                      <TableCell align="left" className="assignments-table-data">{order.user.email}</TableCell>
                    </TableRow>

                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell align="left">User Phone</TableCell>
                      <TableCell align="left" className="assignments-table-data">{order.user.mobilePhone}</TableCell>
                    </TableRow>

                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell align="left">Tickets</TableCell>
                      <TableCell align="left" className="assignments-table-data">{order.numberOfTickets}</TableCell>
                    </TableRow>

                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell align="left">Show Preference</TableCell>
                      <TableCell align="left" className="assignments-table-data">{order.showPreference}</TableCell>
                    </TableRow>

                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell align="left">Retailer</TableCell>
                      <TableCell align="left" className="assignments-table-data">{order.retailer.name}</TableCell>
                    </TableRow>

                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell align="left">Ticket Protection?</TableCell>
                      <TableCell align="left" className="assignments-table-data">
                        {order.isRequestingTicketFlex && <CheckIcon />}
                      </TableCell>
                    </TableRow>

                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell align="left">Is New User?</TableCell>
                      <TableCell align="left" className="assignments-table-data">
                        {isNewCustomer && <CheckIcon />}
                      </TableCell>
                    </TableRow>
                </TableBody>
              </Table>

              {!saving &&
                <div className="tickets-buttons hidemobile stack">
                  <Button variant="contained" className="button" onClick={handleClickSaveButton}>
                    Save Changes
                  </Button>

                  <Button variant="contained" className="button" onClick={handleClickClearButton}>
                    Clear Changes
                  </Button>

                  <Button variant="contained" className="button" onClick={() => { runMatchingAlgorithm() }}>
                    Run Matching Algorithm
                  </Button>
                </div>
              }

              {saving &&
                <div className="tickets-buttons hidemobile">
                  <CircularProgress />
                </div>
              }

            </TableContainer>
            <Divider orientation="vertical" flexItem />

            <Stack justifyContent="flex-start" alignItems="flex-start" className="show-selection-section">
              <div>
                <Typography variant="h5" className="section-title" component="div">
                  Show Selection
                </Typography>

                {recommendedShow &&
                  <div className="recommended-show">
                    Recommended Show: <b>{recommendedShow.name}</b> ({(recommendedShow.probability * 100).toFixed(2)}%
                    Probability), Run At: {algorithmRunDate}
                  </div>
                }

                <div>
                  {isBRV1Order(order) && ' –BRV1 ORDER– '}
                  {isImpactedByLostExclusions && ' –WRONG EXCLUSIONS– '}
                  {(0 < exclusionsReferencingInactiveShows.length) && ' (Inactive exclusions) '}

                  {exclusionsReferencingInactiveShows.map((show, idx) => (
                    <span>
                      -{show.shortName}-
                    </span>
                  ))}
                </div>

                {!saving &&
                  <ShowSelectionList algorithmMeta={matchingAlgorithmMeta}
                                     showRankings={showRankings}
                                     selectedShowId={selectedShowId}
                                     onChange={(showId) => { setSelectedShowId(showId) }} />
                }

                {saving &&
                  <CircularProgress />
                }
              </div>


              <Typography variant="h5" className="section-title" component="div">
                Ticket Details
              </Typography>

              {!saving &&
                <Stack direction="row" flexWrap="wrap" className="ticket-assignments-inputs">
                  <div className="ticket-assignments-input-wrapper">
                    <TextField label="Purchased By"
                               defaultValue={ticketDetailsState.purchasedBy}
                               onChange={(event) => handleUpdateTicketDetails('purchasedBy', event.target.value)} />
                  </div>

                  <div className="ticket-assignments-input-wrapper">
                    <LocalizationProvider adapterLocale={LOCALE} dateAdapter={AdapterDateFns}>

                      <div className="hidemobile">
                          <DesktopDatePicker label="Purchase Date"
                                             inputFormat="MM/dd/yyyy"
                                             value={ticketDetailsState.purchaseDate}
                                             onChange={(newValue) => handleUpdateTicketDetails('purchaseDate', newValue)}
                                             clearable={true}
                                             renderInput={(params) => <TextField {...params} />} />
                      </div>

                      <div className="showmobile">
                        <MobileDatePicker label="Purchase date"
                                          inputFormat="MM/dd/yyyy"
                                          value={ticketDetailsState.purchaseDate}
                                          onChange={(newValue) => handleUpdateTicketDetails('purchaseDate', newValue)}
                                          renderInput={(params) => <TextField {...params} />} />
                      </div>
                    </LocalizationProvider>
                  </div>

                  <div className="ticket-assignments-input-wrapper">
                    <TextField label="Purchase Price"
                               defaultValue={ticketDetailsState.purchasePrice}
                               onChange={(event) => handleUpdateTicketDetails('purchasePrice', event.target.value)} />
                  </div>

                  <div className="ticket-assignments-input-wrapper dropdown-wrapper">
                    {order.requestedShowTime === 'Evening' &&
                      <FormControl>
                        <InputLabel>Show Time</InputLabel>
                        <Select value={ticketDetailsState.showTime}
                                label="Show Time"
                                onChange={(event) => handleUpdateTicketDetails('showTime', event.target.value)}>

                          <MenuItem value={'6:30PM'}>6:30PM</MenuItem>
                          <MenuItem value={'7PM'}>7PM</MenuItem>
                          <MenuItem value={'7:30PM'}>7:30PM</MenuItem>
                          <MenuItem value={'8PM'}>8PM</MenuItem>
                          {orderCanSeeLateNight &&
                            <MenuItem value={'10PM'}>10PM</MenuItem>
                          }
                        </Select>
                      </FormControl>
                    }

                    {order.requestedShowTime === 'Matinee' &&
                      <FormControl>
                        <InputLabel>Show Time</InputLabel>
                        <Select value={ticketDetailsState.showTime}
                                label="Show Time"
                                onChange={(event) => handleUpdateTicketDetails('showTime', event.target.value)}>

                          <MenuItem value={'1PM'}>1PM</MenuItem>
                          <MenuItem value={'1:30PM'}>1:30PM</MenuItem>
                          <MenuItem value={'2PM'}>2PM</MenuItem>
                          <MenuItem value={'2:30PM'}>2:30PM</MenuItem>
                          <MenuItem value={'3PM'}>3PM</MenuItem>
                          <MenuItem value={'3:30PM'}>3:30PM</MenuItem>
                          <MenuItem value={'4PM'}>4PM</MenuItem>
                        </Select>
                      </FormControl>
                    }
                  </div>

                  <div className="ticket-assignments-input-wrapper">
                    <TextField label="Seats"
                               defaultValue={ticketDetailsState.seats}
                               onChange={(event) => handleUpdateTicketDetails('seats', event.target.value)} />
                  </div>

                  <div className="ticket-assignments-input-wrapper">
                    <TextField label="Section"
                               defaultValue={ticketDetailsState.section}
                               onChange={(event) => handleUpdateTicketDetails('section', event.target.value)} />
                  </div>

                  <div className="ticket-assignments-input-wrapper">
                    <TextField label="Mapped by"
                               defaultValue={ticketDetailsState.mappedBy}
                               onChange={(event) => handleUpdateTicketDetails('mappedBy', event.target.value)} />
                  </div>

                  <div className="ticket-assignments-input-wrapper">
                    <FormControl>
                      <InputLabel>Source</InputLabel>
                      <Select value={ticketDetailsState.source}
                              label="Source"
                              onChange={(event) => handleUpdateTicketDetails('source', event.target.value)}>

                        <MenuItem value={'TTG Whitelabel'}>TTG Whitelabel</MenuItem>
                        <MenuItem value={'TTG API'}>TTG API</MenuItem>
                        <MenuItem value={'Online'}>Online</MenuItem>
                        <MenuItem value={'Box Office'}>Box Office</MenuItem>
                      </Select>
                    </FormControl>
                  </div>

                  <div className="ticket-assignments-input-wrapper">
                    <TextField label="Source Order ID"
                               defaultValue={ticketDetailsState.sourceOrderId}
                               onChange={(event) => handleUpdateTicketDetails('sourceOrderId', event.target.value)} />
                  </div>

                  <div className="ticket-assignments-input-wrapper">
                    <TextField label="Notes"
                               className="notes-input"
                               defaultValue={ticketDetailsState.notes}
                               multiline
                               maxRows={10}
                               onChange={(event) => handleUpdateTicketDetails('notes', event.target.value)} />
                  </div>
                </Stack>
              }

              {saving &&
                <CircularProgress />
              }

              {order.restaurantReservationDetails &&
                <div>
                  <Typography variant="h5" className="section-title" component="div">
                    Restaurant Details
                  </Typography>

                  <div>
                    <p>
                      Preferences: {preferencesStr}
                    </p>
                  </div>

                  <RestaurantSelectionList selectedRestaurantId={selectedRestaurantId}
                                           restaurantsBeenToList={restaurantsBeenToList}
                                           restaurants={restaurants}
                                           onChange={(restaurantId) => { setSelectedRestaurantId(restaurantId) }} />

                  <Stack spacing={2} direction="row" flexWrap="wrap" className="restaurant-inputs">
                    <TextField defaultValue={restaurantReservationDetailsState.madeBy}
                               label="Reservation Made By"
                               onChange={(e) => { handleUpdateRestaurantDetails('madeBy', e.target.value) }}/>

                    <TextField defaultValue={restaurantReservationDetailsState.time}
                               label="Time"
                               onChange={(e) => { handleUpdateRestaurantDetails('time', e.target.value) }}/>
                  </Stack>
                </div>
              }

              {!saving &&
                <div className="tickets-buttons showmobile stack">
                  <Button variant="contained" className="button" onClick={handleClickSaveButton}>
                    Save Changes
                  </Button>

                  <Button variant="contained" className="button" onClick={handleClickClearButton}>
                    Clear Changes
                  </Button>

                  <Button variant="contained" className="button" onClick={() => { runMatchingAlgorithm() }}>
                    Run Matching Algorithm
                  </Button>
                </div>
              }

              {saving &&
                <div className="tickets-buttons showmobile">
                  <CircularProgress />
                </div>
              }

            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Grid>
  )
}

function TicketAssignments(props) {
  const queryParams = queryString.parse(props.location.search)

  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(1)
  const [skip, setSkip] = useState(0)

  const [orders, setOrders] = useState([])
  const [loading, setLoading] = useState(true)
  const [updating, setUpdating] = useState(false)

  const [shows, setShows] = useState([])
  const [restaurants, setRestaurants] = useState([])
  const [blackoutDates, setBlackoutDates] = useState([])

  const [sortBy, setSortBy] = useState('name')
  const [sortDirection, setSortDirection] = useState('asc')
  const [notes, setNotes] = useState('')

  const [search, setSearch] = useState('')
  const [location, setLocation] = useState('Broadway')
  const [retailer, setRetailer] = useState('All')
  const [showTime, setShowTime] = useState('Both')
  const [showOnlyUnfulfilledOrders, setShowOnlyUnfulfilledOrders] = useState(false)

  const startDate = queryParams.startDate ? moment(queryParams.startDate).format('YYYY-MM-DDTHH:MM') : moment(TODAY).format('YYYY-MM-DDTHH:MM')
  const endDate = queryParams.endDate ? moment(queryParams.endDate).format('YYYY-MM-DDTHH:MM') : moment(TODAY).format('YYYY-MM-DDTHH:MM')

  const [showDateRange, setShowDateRange] = useState([startDate, endDate])

  const history = useHistory()

  const getItemsToRender = async () => {
    console.log('render items')
    loadOrders()
  }

  const handleChangeDates = (start, end) => {
    setLoading(true)
    setOrders([])
    setPage(1)
    setSkip(0)

    var startDate = moment(start).format('YYYY-MM-DD')
    var endDate = moment(end).format('YYYY-MM-DD')

    history.push(`/ticket-assignments?startDate=${startDate}&endDate=${endDate}`)
  }

  const handleChangePage = (value) => {
    setPage(value)
    setLoading(true)

    const skipValue = (value - 1) * ORDERS_TO_VIEW
    setSkip(skipValue)
    loadOrders()
  }

  const handleDecrementDates = () => {
    var newStartDate = moment(startDate).subtract(1, 'days')
    var newEndDate = moment(endDate).subtract(1, 'days')

    setShowDateRange([newStartDate, newEndDate])
    handleChangeDates(newStartDate, newEndDate)
  }

  const handleIncrementDates = () => {
    var newStartDate = moment(startDate).add(1, 'days')
    var newEndDate = moment(endDate).add(1, 'days')

    setShowDateRange([newStartDate, newEndDate])
    handleChangeDates(newStartDate, newEndDate)
  }

  const handleSearch = () => {
    setLoading(true)
  }

  const handleUpdateSearch = (value) => {
    setSearch(value)
  }

  const loadBlackoutDates = async () => {
    var startDate = moment(showDateRange[0]).format('YYYY-MM-DD')
    var endDate = moment(showDateRange[1]).format('YYYY-MM-DD')

    try {
      var query = {
        query: SHOW_BLACKOUT_DATES_LIST_QUERY,
        variables: {
          startOfPeriod: startDate,
          endOfPeriod: endDate,
        }
      }
      var result = await props.client.query(query)

      var dateToShowToBlackoutObj = {}
      result.data.showBlackoutDatesList.items.forEach(blackoutDate => {
        if (!dateToShowToBlackoutObj[blackoutDate.date]) {
          dateToShowToBlackoutObj[blackoutDate.date] = {}
        }

        if (!dateToShowToBlackoutObj[blackoutDate.date][blackoutDate.show.id]) {
          dateToShowToBlackoutObj[blackoutDate.date][blackoutDate.show.id] = blackoutDate
        }
      })

      setBlackoutDates(dateToShowToBlackoutObj)
    } catch (err) {
      console.log(`Unable to get data for blackout dates: ${err.message}`)
    }
  }

  const loadNotes = async () => {
    try {
      const loadNotesQuery = await props.client.query({
        query: CONFIG_JSON_QUERY,
        variables: {
          id: ADMIN_NOTES_ID
        }
      })

      setNotes(loadNotesQuery.data.configJson.value)

    } catch (err) {
      console.log(`Unable to get data for config: ${err.message}`)
    }
  }

  const loadOrders = async () => {
    console.log('load orders')

    const sort = (sortBy !== 'name')
               ? `sort: { ${sortBy} : ${sortDirection.toUpperCase()} }`
               : `sort: { user: { lastName: ${sortDirection.toUpperCase()} }}`

    const showTimeQuery = showTime === 'Both' ? `` : `{ requestedShowTime: { equals: "${showTime}" } },`
    const retailerQuery = retailer === 'All' ? `` : `{ retailer: { name: { equals: "${retailer}" }}},`

    const searchQuery = !search
        ? `{ requestedShowDate: { gte: "${moment(startDate).format('YYYY-MM-DD')}" } },
           { requestedShowDate: { lte: "${moment(endDate).format('YYYY-MM-DD')}" } },`
        : `{ OR: [
             { id: { contains: "${search}" }}
             { user: { email: { contains: "${search}" }}}
           ]},`

    const filterQuery = showOnlyUnfulfilledOrders
                        ? `{OR: [
                            { ticketDetails: { seats: { is_empty: true }}},
                            { ticketDetails: { purchasePrice: { is_empty: true }}},
                            { ticketDetails: { show: { name: { is_empty: true }}}},
                          ]}
                          `
                        : ``

    var ordersListQuery = gql`
      query OrdersListQuery($skip: Int, $first: Int, $location: String) {
        ordersList(
          ${sort},
          first: $first,
          skip: $skip,
          filter: {
            isForOffBroadway: { equals: false },
            isValidOrder: { equals: true },
            AND: [
              ${filterQuery}
              ${showTimeQuery}
              ${retailerQuery}
              
              ${searchQuery}

              { location: { name: { equals: $location }}},
              { user: { email: { not_contains: "tracythuynh" } }},
              { user: { isAdmin: { equals: false } }},
              { user: { email: { not_contains: "@broadwayroulette.com" } }},
            ]
        }) {
          count
          items {
            id
            algorithmMeta
            clientVersion
            isOptingIntoExtendedHours
            isRequestingChildFriendly
            isRequestingAdditionalExclusions
            isRequestingFamilyFriendlyExclusions
            isRequestingPremium
            isRequestingTicketFlex
            notes
            numberOfTickets
            orderPlacedAt
            requestedShowDate
            requestedShowTime
            showPreference

            location {
              id
              currency
              name
            }

            retailer {
              id
              name
            }

            user {
              id
              firstName
              lastName
              email
              mobilePhone
              notes

              orders {
                items {
                  id
                  isValidOrder
                  requestedShowDate

                  restaurantReservationDetails {
                    id

                    restaurant {
                      id
                      name
                    }
                  }

                  ticketDetails {
                    id
                    show {
                      id
                    }
                  }
                }
              }

              showsSeen {
                items {
                  id
                  shortName
                }
              }
            }

            excludedShows {
              items {
                id
                shortName
              }
            }

            orderStatus {
              id
              receivedConfirmationAt
            }

            paymentDetails {
              id
              additionalExclusionFee
              creditAmount
              giftCardAmount
              price

              giftCardCode {
                id
                code
              }
            }

            restaurantReservationDetails {
              id
              madeBy
              preferences
              time

              restaurant {
                id
              }
            }

            ticketDetails {
              id
              listPrice
              notes
              mappedBy
              purchasedBy
              purchaseDate
              purchasePrice
              seats
              section
              showTime
              sourceOrderId
              source

              show {
                id
                name
              }

              ticketImageURL
              ticketOrderId
            }
          }
        }
      }
    `

    const result = await props.client.query({
      query: ordersListQuery,
      variables: {
        skip: skip,
        first: ORDERS_TO_VIEW,
        location: location
      }
    })

    const orderQuery = result.data.ordersList && result.data.ordersList.items
    const numOrders = result.data.ordersList && result.data.ordersList.count
    const count = Math.ceil(numOrders / ORDERS_TO_VIEW)

    if (orderQuery) {
      setPageCount(count)
      setOrders(orderQuery)
    }
  }

  const loadRestaurants = async () => {
    try {
      var restaurantsQuery = await props.client.query({ query: RESTAURANTS_LIST_QUERY })

      setRestaurants(restaurantsQuery.data.restaurantsList.items)
    } catch (err) {
      console.log(`Unable to get data for restaurants: ${err.message}`)
    }
  }

  const loadShows = async () => {
    var startDate = moment(showDateRange[0]).format('YYYY-MM-DD')
    var endDate = moment(showDateRange[1]).format('YYYY-MM-DD')

    try {
      var showsQuery = await props.client.query({
        query: SHOWS_LIST_QUERY,
        variables: {
          runDateEnd: startDate,
          runDateStart: endDate
        }
      })

      setShows(showsQuery.data.showsList.items)

    } catch (err) {
      console.log(`Unable to get show data: ${err.message}`)
    }
  }

  useEffect(() => {
    getItemsToRender()
  }, [showOnlyUnfulfilledOrders, location, retailer, showTime, sortBy, sortDirection])

  useEffect(() => {
    console.log('is loading: ' + loading)

    if (loading) {
      loadOrders()
      loadShows()
      loadNotes()
      loadRestaurants()
      loadBlackoutDates()
    }
  }, [loading])

  useEffect(() => {
    setLoading(false)
  }, [orders])

  return (
    <div className="ticket-assignments">
      <Stack spacing={2} direction="row" justifyContent="center" alignItems="center">
        <Typography sx={{ flex: '1 1 100%' }} variant="h3" className="page-title" component="div">
          Ticket Assignments
        </Typography>

        <NotesPopover client={props.client}
                      notes={notes}
                      setNotes={setNotes} />

        <FilterPopover getItemsToRender={getItemsToRender}
                       location={location}
                       setLocation={setLocation}
                       retailer={retailer}
                       setRetailer={setRetailer}
                       setShowOnlyUnfulfilledOrders={setShowOnlyUnfulfilledOrders}
                       setShowTime={setShowTime}
                       showOnlyUnfulfilledOrders={showOnlyUnfulfilledOrders}
                       showTime={showTime} />

        <SortPopover setSortBy={setSortBy}
                     setSortDirection={setSortDirection}
                     sortBy={sortBy}
                     sortDirection={sortDirection} />
      </Stack>

      <div className="filter-section">
        <Stack spacing={2} direction="row" flexWrap="wrap" justifyContent="flexStart" alignItems="center" className="date-picker-stack">
          <Stack spacing={2} direction="row">
            <Button variant="contained" onClick={handleDecrementDates}> {'<'} </Button>
            <Button variant="contained" onClick={handleIncrementDates}> {'>'} </Button>
          </Stack>

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateRangePicker startText="Start Date"
                             endText="End Date"
                             value={showDateRange}
                             onChange={(newValue) => { setShowDateRange(newValue) }}
                             renderInput={(startProps, endProps) => (
                               <Stack spacing={2} direction="row" justifyContent="center" alignItems="center" className="date-picker-stack">
                                 <TextField {...startProps} className="show-date-picker" />
                                 <TextField {...endProps} className="show-date-picker" />
                               </Stack>
                             )} />
          </LocalizationProvider>

          <Button variant="contained" onClick={() => { handleChangeDates(showDateRange[0], showDateRange[1]) }}>Go</Button>
        </Stack>

        <Stack spacing={2} direction="row" className="search-stack">
          <TextField label="Search by email or order id"
                     className="search"
                     defaultValue=''
                     onChange={(event) => handleUpdateSearch(event.target.value)} />

          <Button variant="contained" onClick={handleSearch}>Search</Button>
        </Stack>
      </div>

      {!orders || loading || updating &&
        <CircularProgress />
      }

      {!loading &&
        <Pagination className="pagination-top" count={pageCount} page={page} onChange={(e, n) => { handleChangePage(n) }} />
      }

      <Grid container spacing={4} justifyContent="center">
        {!loading && !updating && orders && orders.map((order, idx) => (
          <TicketAssignmentsCard blackoutDates={blackoutDates}
                                 currentUser={props.currentUser}
                                 client={props.client}
                                 key={order.id}
                                 order={order}
                                 restaurants={restaurants}
                                 shows={shows}
                                 setUpdating={setUpdating}/>
        ))}
      </Grid>

      {!loading &&
        <Pagination count={pageCount} page={page} onChange={(e, n) => { handleChangePage(n) }} />
      }

    </div>
  )
}

const CONFIG_JSON_QUERY = gql`
  query ConfigJsonsQuery($id: ID!) {
    configJson(id: $id) {
      id
      key
      value
    }
  }
`

const CONFIG_JSON_UPDATE_MUTATION = gql`
  mutation ConfigJsonUpdateMutation($id: ID!, $value: JSON!) {
    configJsonUpdate(data: {
      id: $id,
      value: $value
    }) {
      id
    }
  }
`

const MATCHING_ALGORITHM_QUERY = gql`
  query MatchingAlgorithmQuery($id: ID!) {
    order(id: $id) {
      algorithmMeta
    }
  }
`

const RESTAURANTS_LIST_QUERY = gql`
  query RestaurantsListQuery {
    restaurantsList(sort: { name : ASC },
    filter: {
      location: { name: { equals: "Broadway" }},
      isActive: { equals: true }
    }) {
      items {
        id
        name
      }
    }
  }
`

const SHOWS_LIST_QUERY = gql`
  query ShowsListQuery($runDateStart: Date, $runDateEnd: Date) {
    showsList(
      sort: { name: ASC },
      filter: {
        isForOffBroadway: { equals: false }
        runDateStart: { lte: $runDateStart }
        OR: [
          { runDateEnd: { gte: $runDateEnd }},
          { runDateEnd: { is_empty: true }},
        ]
      }
    ) {
      items {
        id
        dateAdded
        shortName
        name
        isChildFriendly
        isMusical
        runDateStart
        runDateEnd

        location {
          id
          name
        }

        showPartnerDetails {
          isShowPartner
          mondayMatinee
          mondayEvening
          tuesdayMatinee
          tuesdayEvening
          wednesdayMatinee
          wednesdayEvening
          thursdayMatinee
          thursdayEvening
          fridayMatinee
          fridayEvening
          saturdayMatinee
          saturdayEvening
          sundayMatinee
          sundayEvening
        }
      }
    }
  }
`

const SHOW_BLACKOUT_DATES_LIST_QUERY = gql`
  query ShowBlackoutDatesListQuery($startOfPeriod: Date!, $endOfPeriod: Date!) {
    showBlackoutDatesList(
      sort: { date : ASC },
      filter: {
        AND: [
          {
            date: { gte: $startOfPeriod }
          },
          {
            date: { lte: $endOfPeriod }
          }
        ]
      }
    ) {
      items {
        id
        date

        show {
          id
        }
      }
    }
  }
`

const RESTAURANT_RESERVATION_DETAILS_CLEAR_MUTATION = gql`
  mutation RestaurantReservationDetailsUpdateMutation($id: ID!, $restaurantId: ID) {
    restaurantReservationDetailUpdate(data: {
      id: $id
      madeBy: null
      time: null

      restaurant: {
        disconnect: { id: $restaurantId }
      }
    }) {
      id
    }
  }
`

const RESTAURANT_RESERVATION_DETAILS_UPDATE_MUTATION = gql`
  mutation RestaurantReservationDetailsUpdateMutation($id: ID!, $madeBy: String, $restaurantId: ID, $time: String) {
    restaurantReservationDetailUpdate(data: {
      id: $id
      madeBy: $madeBy
      time: $time

      restaurant: {
        reconnect: { id: $restaurantId }
      }
    }) {
      id
    }
  }
`

const CLEAR_TICKET_DETAILS_MUTATION = gql`
  mutation clearTicketDetails($id: ID!, $showId: ID) {
    ticketDetailUpdate(data:{
      id: $id
      listPrice: null
      notes: null
      mappedBy: null
      purchasedBy: null
      purchaseDate: null
      purchasePrice: null
      seats: null
      section: null
      showTime: null
      sourceOrderId: null
      source: null

      show: {
        disconnect: { id: $showId }
      }

      ticketImageURL: null
      ticketOrderId: null
    }) {
      id
    }
  }
`

export default withApollo(TicketAssignments)
