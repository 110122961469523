import React, { Component } from 'react'
import { gql } from 'apollo-boost'
import { graphql, withApollo } from 'react-apollo'
import moment from 'moment-timezone'
import { withRouter } from 'react-router'
import _ from 'underscore'

import Button from '@material-ui/core/Button'
import FontAwesome from 'react-fontawesome'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'

import { ENTRIES_PER_PAGE } from '../constants'
import { getBeginningOfTimeStrYYYYMMDD, getParamsFromUrl, getTodayStrYYYYMMDD } from '../utils'


class GiftCardSummary extends Component {

  render() {
    const purchaseCode = this.props.purchaseCode
    const giftCard = purchaseCode.giftCardOrder

    const objId = giftCard ? (<a href={'/gift-card/' + giftCard.id}>
                     {giftCard.id.substr(giftCard.id.length-4)}
                   </a>) : (<a href={'/purchase-code/' + purchaseCode.id}>
                     admin
                   </a>)

    const dateTimePlaced = giftCard ? moment(giftCard.orderPlacedAt).format('MM-DD-YYYY h:mm:ss a') : ''
    const purchaserEmail = giftCard ? giftCard.purchaserEmail : ''
    const recipientEmail = giftCard ? giftCard.recipientEmail : ''
    const deliveryDate = (giftCard && giftCard.deliveryDate !== 'null') ? giftCard.deliveryDate : ''
    const remainingAmount = `${purchaseCode.currency === 'GBP' ? '£' : '$' }${ purchaseCode.remainingLifetimeAmountInUsd } /
                             ${purchaseCode.currency === 'GBP' ? '£' : '$' }${ purchaseCode.initialLifetimeAmountInUsd }`

    return (
      <TableRow>
        <TableCell>{ objId }</TableCell>
        <TableCell>{ dateTimePlaced }</TableCell>
        <TableCell>{ purchaseCode.code }</TableCell>
        <TableCell>{ purchaserEmail }</TableCell>
        <TableCell>{ recipientEmail }</TableCell>
        <TableCell>{ deliveryDate }</TableCell>
        <TableCell>{ purchaseCode.isExpired && <FontAwesome name="check"/>}</TableCell>
        <TableCell style={{ width: '150px' }}>{ remainingAmount }</TableCell>
        <TableCell>{ purchaseCode.currency }</TableCell>
      </TableRow>
    )
  }
}

class GiftCardList extends Component {

  state = {
    searchedItems: null,
    searchText: ''
  }

  _executeSearch = async () => {
    var searchText = this.state.searchText

    const result = await this.props.client.query({
      query: GIFT_CARD_PURCHASE_CODES_LIST_QUERY,
      variables: { searchText }
    })

    console.log(result)

    const giftCardCodes = result.data.purchaseCodesList.items
    this.setState({ searchedItems: giftCardCodes })
  }

  _getItemsToRender = () => {
    return this.props.giftCardPurchaseCodesListQuery.purchaseCodesList.items
  }

  _lazyUpdateGiftCardCodes = _.debounce(this._updateGiftCardCodes, 500)

  _nextPage = () => {
    const page = parseInt(this.props.match.params.page, 10)
    const nextPage = page + 1

    const wantsOrdersForToday = this.props.location.pathname.includes('today')
    const todayPart = wantsOrdersForToday ? '-today' : ''

    this.props.history.push(`/gift-cards${todayPart}/${nextPage}`)
  }

  _previousPage = () => {
    const page = parseInt(this.props.match.params.page, 10)
    if (page > 1) {
      const nextPage = page - 1

      const wantsOrdersForToday = this.props.location.pathname.includes('today')
      const todayPart = wantsOrdersForToday ? '-today' : ''

      this.props.history.push(`/gift-cards${todayPart}/${nextPage}`)
    }
  }

  _updateGiftCardCodes() {
    var isSearching = !!this.state.searchText

    if (isSearching) {
      this._executeSearch()
    } else {
      this.setState({ searchedItems: null })
    }

    this.setState({ isSearchDirty: false })
  }

  _updateSearchText = (text) => {
    this.setState({ searchText: text, isSearchDirty: true })
  }

  componentDidUpdate() {
    if (this.state.isSearchDirty) {
      this._lazyUpdateGiftCardCodes()
    }
  }

  render() {
    if (this.props.giftCardPurchaseCodesListQuery && this.props.giftCardPurchaseCodesListQuery.loading) {
      return <div>Loading</div>
    }

    if (this.props.giftCardPurchaseCodesListQuery && this.props.giftCardPurchaseCodesListQuery.error) {
      return <div>Error</div>
    }

    const itemsToRender = this.state.searchedItems || this._getItemsToRender()

    var islastPage = this.props.giftCardPurchaseCodesListQuery.purchaseCodesList.count - this.props.giftCardPurchaseCodesListQuery.variables.skip < ENTRIES_PER_PAGE
    const page = parseInt(this.props.match.params.page, 10)
    var itemOffset = (page - 1) * ENTRIES_PER_PAGE

    return (
      <div className="list-page">
        <div>
          <h1 style={{ display: 'inline-block' }}>Gift Cards</h1>
        </div>

        <Paper>
          <TextField value= {this.state.searchText || ""}
                     label="Search for code"
                     onChange={(e) => { this._updateSearchText(e.target.value) }}
                     style={{ width: '50%', margin: '10px', paddingTop:"10px" }} />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Date Ordered</TableCell>
                <TableCell>Code</TableCell>
                <TableCell>Gifter</TableCell>
                <TableCell>Recipient</TableCell>
                <TableCell>Delivery Date</TableCell>
                <TableCell>Expired</TableCell>
                <TableCell style={{ width: '150px' }}>Remaining</TableCell>
                <TableCell>Currency</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {itemsToRender.map((purchaseCode, index) => (
                <GiftCardSummary key={purchaseCode.id}
                                 purchaseCode={purchaseCode}
                                 index={itemOffset + index}/>
              ))}
            </TableBody>
          </Table>
        </Paper>
        {!this.state.searchText &&
          <div>
            <Button variant="contained"
                    disabled={this.props.match.params.page <= 1}
                    style={{ margin: '10px 10px 15px 0'}}
                    onClick={() => { this._previousPage() }}>
            Previous
            </Button>
            <Button variant="contained"
                    disabled={islastPage}
                    style={{ margin: '10px 10px 15px 0'}}
                    onClick={() => { this._nextPage() }}>
            Next
            </Button>
          </div>
        }
      </div>
    )
  }
}


const GIFT_CARD_PURCHASE_CODES_LIST_QUERY = gql`
  query GiftCardPurchaseCodesListQuery($first: Int, $skip: Int, $searchText: String) {
    purchaseCodesList(
      first: $first,
      skip: $skip,
      filter: {
        OR: [
          { giftCardOrder: { purchaserEmail: { contains: $searchText }}},
          { giftCardOrder: { recipientEmail: { contains: $searchText }}},
          { code: {contains: $searchText }}
        ],
        purchaseCodeType: { equals: "GiftCard" }
      }
      sort: { createdAt: DESC }
    ) {
      count

      items {
        id
        code
        currency
        initialLifetimeAmountInUsd
        isExpired
        remainingLifetimeAmountInUsd

        giftCardOrder {
          id
          deliveryDate
          orderPlacedAt
          purchaserEmail
          recipientEmail
        }
      }
    }
  }
`

export default withRouter(withApollo(graphql(GIFT_CARD_PURCHASE_CODES_LIST_QUERY, {
  name: 'giftCardPurchaseCodesListQuery',
  options: (ownProps) => {
    const wantsOrdersForToday = ownProps.location.pathname.includes('today')
    var date = wantsOrdersForToday ? getTodayStrYYYYMMDD() : getBeginningOfTimeStrYYYYMMDD()

    return {
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
      variables: { ...getParamsFromUrl(ownProps), date }
    }
  }
})(GiftCardList)))
