import { gql } from 'apollo-boost'


export const USER_ID_REQUEST_COUNT = 5
export const SHOW_ID_REQUEST_COUNT = 5

export const USER_QUERY = gql`
  query {
    user {
      id
    }
  }
`

export const TICKET_PICKUPS_LIST_QUERY = gql`
  query TicketPickupsListQuery {
    ticketPickupsList {
      items {
        id
      }
    }
  }
`

export const THEATERS_LIST_QUERY = gql`
  query TheatersListQuery {
    theatersList {
      items {
        id
        name
        isForOffBroadway
      }
    }
  }
`

export const VENUES_LIST_QUERY = gql`
  query VenuesListQuery {
    venuesList {
      items {
        id
        name
      }
    }
  }
`

// TODO: Add `id_contains: $searchText` when Apollo starts to support it
const SHOW_ID_PAIRS_LIST_QUERY = gql`
  query ShowIdPairsListQuery($searchText: String!) {
    showsList(
      first: ${SHOW_ID_REQUEST_COUNT},
      filter: {
        name: { contains: $searchText }
      }
    ) {
      items {
        id
        shortName
      }
    }
  }
`

// TODO: Add `id_contains: $searchText` when Apollo starts to support it
const USER_ID_PAIRS_LIST_QUERY = gql`
  query UserIdPairsListQuery($searchText: String!) {
    usersList(
      first: ${USER_ID_REQUEST_COUNT},
      filter: {
        OR: [{
          firstName: { starts_with: $searchText }
        }, {
          lastName: { starts_with: $searchText }
        }]
      }
    ) {
      items {
        id
        firstName
        lastName
      }
    }
  }
`

export const CREDIT_CREATE_MUTATION = gql`
  mutation CreditCreateMutation($currency: String!, $initialAmount: Float!, $remainingAmount: Float!, $orderId: ID!, $userId: ID!) {
    purchaseCreditCreate(data: {
      currency: $currency,
      initialAmount: $initialAmount,
      isExpired: false,
      remainingAmount: $remainingAmount,
      order: { connect: { id: $orderId }}
      user: { connect: { id: $userId }}
    }) {
      id
      initialAmount
      remainingAmount
      currency
    }
  }
`

const getNewCachedIdPairs = function(cachedIdPairs, queriedIdPairs, getName) {
  var newCachedIdPairs = cachedIdPairs.slice()

  var existingIds = {}
  cachedIdPairs.forEach((obj) => { existingIds[obj.id] = true })

  queriedIdPairs.forEach((obj) => {
    if (obj.id in existingIds) { return }

    newCachedIdPairs.push({
      name: getName(obj),
      id: obj.id
    })
  })

  return newCachedIdPairs
}

const loadIdPairs = async function(client, cachedIdPairs, searchText, query, resultName, getName) {
  var newCachedIdPairs = cachedIdPairs

  try {
    // TODO: Handle errors when loading?
    var queriedIdPairs = await client.query({
      query,
      variables: {
        searchText
      }
    })

    queriedIdPairs = queriedIdPairs.data[resultName].items

    newCachedIdPairs = getNewCachedIdPairs(cachedIdPairs, queriedIdPairs, getName)
  } catch (err) {
    // TODO: Do anything else?
    console.log(`Error loading id pairs: ${err.message}`)
  }

  return newCachedIdPairs
}

export const userToString = function(user) {
  return user.firstName + ' ' + user.lastName + ' (' + user.id.substr(user.id.length-4) + ')'
}

const loadShowIdPairs = async function(client, cachedIdPairs, searchText) {
  return await loadIdPairs(client, cachedIdPairs, searchText, SHOW_ID_PAIRS_LIST_QUERY, 'showsList', (obj) => obj.shortName)
}

const loadUserIdPairs = async function(client, cachedIdPairs, searchText) {
  return await loadIdPairs(client, cachedIdPairs, searchText, USER_ID_PAIRS_LIST_QUERY, 'usersList', userToString)
}

const Queries = {
  loadShowIdPairs,
  loadUserIdPairs
}

export default Queries
