import React, { useState, useEffect } from 'react'
import AWS from 'aws-sdk'
import { Link } from 'react-router-dom'
import moment from 'moment-timezone'
import { gql } from 'apollo-boost'
import { graphql, withApollo } from 'react-apollo'

import AdapterDateFns from '@mui/lab/AdapterDateFns'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'
import Divider from '@mui/material/Divider'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FormControl from '@mui/material/FormControl'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import MenuItem from '@mui/material/MenuItem'
import MobileDatePicker from '@mui/lab/MobileDatePicker'
import InputLabel from '@mui/material/InputLabel'
import Paper from '@mui/material/Paper'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import TextField from '@mui/material/TextField'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { visuallyHidden } from '@mui/utils'

import { AWS_ACCESS_KEY_ID, AWS_ACCESS_KEY, LOCALE } from '../constants'
import BRClient from '../broadwayRouletteClient'

import './Reports.css'


AWS.config.update({
  accessKeyId: AWS_ACCESS_KEY_ID,
  secretAccessKey: AWS_ACCESS_KEY,
  region: 'us-west-2'
})

const S3 = new AWS.S3()
const BUCKET_NAME = 'broadwayroulette-reports'
const BUCKET_URL = 'https://broadwayroulette-reports.s3-us-west-2.amazonaws.com/'

const reportCopy = {
  'AverageCostPerTicket': 'Average cost per ticket, orders filtered by purchase date entered in admin',
  'DaysBetweenGiftCardOrderAndUse': 'Days Between Gift Card Order and Use, orders filtered by date order was placed',
  'DaysBetweenOrderAndShow': 'Days Between Order and Show, orders filtered by date order was placed',
  'FinanceReport': 'Breakdown of orders and their total price redeemed',
  'EventFinanceReport': 'Breakdown of event orders and their total price redeemed',
  'GiftCardOrders': 'Gift card orders, orders filtered by date order was placed',
  'OrdersByDay': 'Orders by day, orders filtered by date order was placed',
  'OrdersByDayOfWeek': 'Orders by day of week, orders filtered by date order was placed',
  'OrdersDumpByOrderDate': 'Orders dump by order show date, orders filtered by date order was placed',
  'OrdersDumpByShowDate': 'Orders dump by order show date, orders filtered by requested show date',
  'EventOrdersDumpByEventDate': 'Event orders dump by order event date, orders filtered by requested date',
  'EventOrdersDumpByOrderDate': 'Event orders dump by order event date, orders filtered by date order was placed',
  'OrdersDumpByShowDate': 'Event orders dump by order event date, orders filtered by date order was placed',
  'TicketsPurchasedPerShow': 'Tickets purchased per show, orders filtered by purchase date entered in admin',
  'RegistrationsByDayOfWeek': 'User registration by day of week, user filtered by user sign up date',
  'RequestedShowDateByDayOfWeek': 'Requested show date by day of week, orders filtered by requested show date',
  'RestaurantReservationsDumpByOrderDate': 'Restaurant reservations dump by order date, orders filtered by date order was placed',
  'Users': 'Users, filtered by user sign up date'
}

const ordersReportKeyToName = {
  'OrdersByDay': 'Orders: By Day Placed',
  'OrdersByDayOfWeek': 'Orders: By Day of Week Placed',
  'OrdersDumpByOrderDate': 'Orders: Dump by Order Placed Date',
  'OrdersDumpByShowDate': 'Orders: Dump by Order Show Date',
  'RequestedShowDateByDayOfWeek': 'Orders: Show Date by Day of Week',
  'DaysBetweenOrderAndShow': 'Orders: Days Between Order and Show',
  'AverageCostPerTicket': 'Orders: Average Cost Per Ticket',
  'FinanceReport': 'Orders Finance: Total amount redeemed',
  'TicketsPurchasedPerShow': 'Orders: Tickets Purchased Per Show',
}

const eventOrdersReportKeyToName = {
  'EventOrdersDumpByOrderDate': 'Event Orders: Dump by Order Placed Date',
  'EventOrdersDumpByEventDate': 'Event Orders: Dump by Order Show Date',
  'AverageCostPerTicket': 'Event Orders: Average Cost Per Ticket',
  'EventFinanceReport': 'Event Orders Finance: Total amount redeemed',
}

const giftCardReportKeyToName = {
  'GiftCardOrders': 'Gift Card Orders: By Date Placed',
  'DaysBetweenGiftCardOrderAndUse': 'Gift Card Orders: Days Between Order and Use',
}

const usersReportKeyToName = {
  'Users': 'Users: By Registration Date',
  'RegistrationsByDayOfWeek': 'Users: Registration by Day of Week',
}

const restaurantReportKeyToName = {
  'RestaurantReservationsDumpByOrderDate': 'Restaurant Reservations: Dump by Order Placed Date',
}

const headCells = [
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'dateRange',
    label: 'Date Range',
  },
  {
    id: 'runDate',
    label: 'Run Date',
  },
]

function createData(reports) {
  var formattedData = reports && reports.map(report => {

    const reportKey = report.Key
    const reportParts = report.Key.split('_')
    const reportName = reportParts[0]
    const runDate = moment(reportParts[1], 'YYYY-MM-DD:HH:mm:ss').format('MM-DD-YYYY h:mm:ss a')
    const dateRange = moment(reportParts[2]).format('MM-DD-YYYY') + ' to ' + moment(reportParts[3]).format('MM-DD-YYYY')
    const reportNameUrl = BUCKET_URL + reportKey

    return {
      name: reportName,
      url: reportNameUrl,
      dateRange: dateRange,
      runDate: runDate
    }
  })

  return formattedData
}

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

const EnhancedTableHead = (props) => {
  const { order, orderBy, rowCount, onRequestSort } = props

  const createSortHandler = (property) => (theater) => {
    onRequestSort(theater, property)
  }

  return (
    <TableHead>
      <TableRow>

        {headCells.map((headCell) => (
          <TableCell key={headCell.id}
                     align='left'
                     padding={'normal'}
                     sortDirection={orderBy === headCell.id ? order : false}>

            <TableSortLabel active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>

          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}


function ReportsList(props) {
  const { isLoading } = props

  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('runDate')

  const [rows, setRows] = useState([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const [loadingSearch, setLoadingSearch] = useState(false)
  const [error, setError] = useState(false)

  const handleChangePage = (theater, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10))
    setPage(0)
  }

  const handleRequestSort = (theater, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const getItemsToRender = async () => {
    var reports = []

    var params = {
      Bucket: BUCKET_NAME,
      Delimiter: '/',
      MaxKeys: 50,
      Prefix: ''
    }

    await S3.listObjects(params, (err, data) => {
      if (err) {
        console.log(err)
        setError(true)
        alert('Error fetching reports: ' + err)

        return
      }

      const items = createData(data.Contents)
      setRows(items)
    })
  }

  useEffect(() => {
    getItemsToRender()
  }, [isLoading])

  const emptyRows = page > 0 && rows ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0

  return (
    <Box sx={{ width: '100%' }}>
      <div className='data'>

        {!rows && !error &&
          <CircularProgress />
        }

        {error &&
          <h2>Error</h2>
        }

        {rows &&
          <div>
            <TableContainer>
              <Table sx={{ minWidth: 750 }}
                     aria-labelledby="table-title"
                     size='medium'>

                <EnhancedTableHead order={order}
                                   orderBy={orderBy}
                                   onRequestSort={handleRequestSort}
                                   rowCount={rows.length}/>
                <TableBody>

                  {rows.slice().sort(getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const name = (<a href={row.url}>{row.name}</a>)

                      return (
                        <TableRow hover
                                  role="checkbox"
                                  tabIndex={-1}
                                  key={row.id}>

                          <TableCell component="th"
                                     scope="row"
                                     padding="none">
                            {name}
                          </TableCell>
                          <TableCell align="left">{row.dateRange}</TableCell>
                          <TableCell align="left">{row.runDate}</TableCell>
                        </TableRow>
                      )
                    })}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }} >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}

                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination rowsPerPageOptions={[5, 10, 25, 50, 100]}
                             component="div"
                             count={rows.length}
                             rowsPerPage={rowsPerPage}
                             page={page}
                             onPageChange={handleChangePage}
                             showFirstButton={true}
                             showLastButton={true}
                             onRowsPerPageChange={handleChangeRowsPerPage}/>
          </div>
        }
      </div>
    </Box>
  )
}

const Reports = (props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [locations, setLocations] = useState([])

  const [reportKey, setReportKey] = useState('OrdersDumpByOrderDate')
  const [selectedReportType, setSelectedReportType] = useState(ordersReportKeyToName)

  const [startDate, setStartDate] = useState(moment().startOf('year').format('YYYY-MM-DDTHH:MM'))
  const [endDate, setEndDate] = useState(moment().endOf('year').format('YYYY-MM-DDTHH:MM'))
  const [location, setLocation] = useState('Broadway')

  const handleChangeReportKey = (newKey) => {
    setReportKey(newKey)
  }

  const handleRunReport = async () => {
    setIsLoading(true)

    try {
      console.log('start date: ' + startDate)
      console.log('end date: ' + endDate)

      var response = await BRClient.getReport(reportKey, startDate, endDate, location, moment().valueOf())

      if (response.status === 301) {
        alert('Report complete')
        setIsLoading(false)
      } else {
        throw(response.status)
      }

    } catch(err) {
      console.log(err)
      alert('Unable to run report: ' + err)
    }
  }

  useEffect(() => {
    if (props.locationsListQuery.locationsList && props.locationsListQuery.locationsList.items) {
      setLocations(props.locationsListQuery.locationsList.items)
    }
  }, [props.locationsListQuery])

  return (
    <div className="reports">
      <h1>Reports</h1>

        <Accordion defaultExpanded={true}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header">
            <Typography className="accordion-title">Run Report</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Divider />

            <Stack direction="column" width="100%" justifyContent="center" alignItems="center">
              <div className="padding">
                <FormControl>
                  <InputLabel>Location</InputLabel>
                  <Select value={location}
                          label="Location"
                          onChange={(event) => setLocation(event.target.value)}>

                      {locations && locations.map((locationMap, idx) => (
                        <MenuItem key={idx} value={locationMap.name}> {locationMap.name} </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>

              <div className="padding">
                <FormControl>
                  <InputLabel>Report type</InputLabel>
                  <Select value={selectedReportType}
                          label="Report type"
                          onChange={(event) => setSelectedReportType(event.target.value)}>

                      <MenuItem key={'Orders'} value={ordersReportKeyToName}> Orders </MenuItem>
                      <MenuItem key={'Event Orders'} value={eventOrdersReportKeyToName}> Event Orders </MenuItem>
                      <MenuItem key={'Users'} value={usersReportKeyToName}> Users </MenuItem>
                      <MenuItem key={'Gift Cards'} value={giftCardReportKeyToName}> Gift Cards </MenuItem>
                      <MenuItem key={'Restaurant'} value={restaurantReportKeyToName}> Restaurant </MenuItem>
                  </Select>
                </FormControl>
              </div>

              <div className="padding">
                <FormControl>
                  <InputLabel>Report To Run</InputLabel>
                  <Select value={reportKey}
                          label="Report To Run"
                          onChange={(e,k,p) => { handleChangeReportKey(e.target.value) }} >

                    {
                      (() => {
                        return Object.keys(selectedReportType).map((key, i) => {
                          var reportName = selectedReportType[key]
                          return <MenuItem key={i} value={key}>{reportName}</MenuItem>
                        })
                      })()
                    }
                  </Select>
                </FormControl>
              </div>

              <LocalizationProvider adapterLocale={LOCALE} dateAdapter={AdapterDateFns}>
                <Stack spacing={2} direction="column" className="hidemobile stack" alignItems="center">
                    <DesktopDatePicker label="Start Date"
                                       inputFormat="MM/dd/yyyy"
                                       value={startDate}
                                       onChange={(newValue) => setStartDate(newValue)}
                                       clearable={true}
                                       renderInput={(params) => <TextField {...params} />} />

                    <DesktopDatePicker label="End Date"
                                       inputFormat="MM/dd/yyyy"
                                       value={endDate}
                                       onChange={(newValue) => setEndDate(newValue)}
                                       clearable={true}
                                       renderInput={(params) => <TextField {...params} />} />
                </Stack>

                <Stack spacing={2} direction="column" className="showmobile stack" alignItems="center">
                  <MobileDatePicker label="Start date"
                                    inputFormat="MM/dd/yyyy"
                                    value={startDate}
                                    onChange={(newValue) => setStartDate(newValue)}
                                    renderInput={(params) => <TextField {...params} />} />

                  <MobileDatePicker label="End date"
                                    inputFormat="MM/dd/yyyy"
                                    value={endDate}
                                    onChange={(newValue) => setEndDate(newValue)}
                                    renderInput={(params) => <TextField {...params} />} />
                </Stack>
              </LocalizationProvider>

              {isLoading &&
                <CircularProgress size={60} thickness={7} />
              }

              {!isLoading &&
                <Stack direction="column" alignItems="center">
                  <h3>{reportCopy[reportKey]}, from {moment(startDate).format('MM/DD/YYYY')} to {moment(endDate).format('MM/DD/YYYY')}</h3>

                  <Button variant="contained" className="button" onClick={() => { handleRunReport() }}>
                    Run Report
                  </Button>
                </Stack>
              }
            </Stack>

          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header">
            <Typography className="accordion-title">Reports List</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Divider />

            <ReportsList isLoading={isLoading} />

          </AccordionDetails>
        </Accordion>

    </div>
  )
}

const LOCATIONS_LIST_QUERY = gql`
  query LocationsListQuery {
    locationsList(sort: { name: ASC }) {
      items {
        id
        name
      }
    }
  }
`

export default withApollo(graphql(LOCATIONS_LIST_QUERY, {
  name: 'locationsListQuery',
  options: (ownProps) => {

    return {
      notifyOnNetworkStatusChange: true
    }
  }
})(Reports))
