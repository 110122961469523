import React, { Component } from 'react'
import { gql } from 'apollo-boost'
import { graphql, withApollo } from 'react-apollo'
import { withRouter } from 'react-router'

import Fab from '@material-ui/core/Fab'
import Icon from '@material-ui/core/Icon'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'


class RestaurantSummary extends Component {

  render() {
    const restaurant = this.props.restaurant

    const name = (<a href={'/restaurant/' + restaurant.id}>{restaurant.name}</a>)

    return (
      <TableRow>
        <TableCell>{restaurant.id}</TableCell>
        <TableCell>{name}</TableCell>
        <TableCell>{restaurant.location.name}</TableCell>
      </TableRow>
    )
  }
}

class RestaurantList extends Component {

  state = {}

  _getItemsToRender = () => {
    var restaurants = this.props.restaurantsListQuery.restaurantsList.items

    return restaurants
  }

  async _handleClickNew() {
    try {

      const result = await this.props.client.mutate({
        mutation: RESTAURANT_CREATE_MUTATION
      })

      this.props.history.push(`/restaurant/${result.data.restaurantCreate.id}`)
    } catch(err) {
      alert(`Unable to create restaurant: ${err.message}`)
    }
  }

  render() {
    if (this.props.restaurantsListQuery && this.props.restaurantsListQuery.loading) {
      return <div>Loading</div>
    }

    if (this.props.restaurantsListQuery && this.props.restaurantsListQuery.error) {
      return <div>Error</div>
    }

    const itemsToRender = this._getItemsToRender()

    return (
      <div>
        <div>
          <h1 style={{ display: 'inline-block' }}>Restaurants</h1>
          <Fab style={{ marginLeft: '10px' }}
               onClick={() => { this._handleClickNew() }}>
            <Icon className="fa fa-plus-circle" />
          </Fab>
        </div>

        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Location</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {itemsToRender.map((obj, index) => (
                <RestaurantSummary key={obj.id} restaurant={obj}/>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </div>
    )
  }
}

const RESTAURANTS_LIST_QUERY = gql`
  query {
    restaurantsList(sort: { name : ASC }, filter: { isActive: { equals: true }}) {
      items {
        id
        name

        location {
          id
          name
        }
      }
    }
  }
`

const RESTAURANT_CREATE_MUTATION = gql`
  mutation {
    restaurantCreate(data: {
      name: "Fill me out"

      location: {
        connect: { name: "Broadway" }
      }
    }) {
      id
    }
  }
`

export default withRouter(withApollo(graphql(RESTAURANTS_LIST_QUERY, {
  name: 'restaurantsListQuery',
  options: () => {
    return {
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    }
  }
})(RestaurantList)))
