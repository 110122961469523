import React, { Component } from 'react'
import { gql } from 'apollo-boost'
import { graphql, withApollo } from 'react-apollo'

import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import Switch from '@material-ui/core/Switch'
import TextField from '@material-ui/core/TextField'

import BRClient from '../broadwayRouletteClient'


class GiftCardOrderDetails extends Component {

  state = {
    isEditingLocked: true,
    isSendingGiftCardEmail: false,
    isSendingGiftCardReceiptEmail: false
  }

  _clearGiftCardOrder() {
    if (this.state.purchaseCode) { this.setState({ giftCardOrder: null, purchaseCode: null }) }
  }

  _handleChangeLock(isInputChecked) {
    this.setState({ isEditingLocked: isInputChecked })

    if (isInputChecked) {
      this._updateGiftCardOrderFields()
      this._updatePurchaseCodeFields()
    }
  }

  _handleChangeDeliveryDate(val) { this._updateGiftCardOrder('deliveryDate', val)}
  _handleChangeIsExpired(val) { this._updatePurchaseCode('isExpired', val) }
  _handleChangeRemainingValue (val) { this._updatePurchaseCode('remainingLifetimeAmountInUsd', val) }
  _handleChangeCurrency (val) { this._updatePurchaseCode('currency', val) }

  _refetchData() {
    this.props.giftCardOrderQuery.refetch()
  }

  _sendGiftCardEmail() {
    this.setState({ isSendingGiftCardEmail: true })

    BRClient.resendGiftCardEmail(this.state.giftCardOrder.id)
    .then(() => {
      setTimeout(() => {
        this.setState({ isSendingGiftCardEmail: false })
      }, 500)
    })
  }

  _sendGiftCardReceiptEmail() {
    this.setState({ isSendingGiftCardReceiptEmail: true })

    BRClient.resendGiftCardReceiptEmail(this.state.giftCardOrder.id)
    .then(() => {
      setTimeout(() => {
        this.setState({ isSendingGiftCardReceiptEmail: false })
      }, 500)
    })
  }

  _setGiftCardOrder() {
    var giftCardOrderQuery = { ...this.props.giftCardOrderQuery }

    this.setState({
      giftCardOrder: giftCardOrderQuery.giftCardOrder,
      purchaseCode: giftCardOrderQuery.giftCardOrder.purchaseCode
    })
  }

  _updateGiftCardOrder(key, value) {
    if (!this.state.giftCardOrder) { return }

    var state = { giftCardOrder: { ...this.state.giftCardOrder } }
    state.giftCardOrder[key] = value

    this.setState(state)
  }

  _updateGiftCardOrderFields = async function() {
    if (!this.state.giftCardOrder) { return }

    const giftCard = this.state.giftCardOrder
    const deliveryDate = giftCard.deliveryDate || null

    try {
      const id = this.props.match.params.id

      await this.props.client.mutate({
        mutation: GIFT_CARD_ORDER_UPDATE_MUTATION,
        variables: {
          id: id,
          deliveryDate: deliveryDate
        }
      })
    } catch (err) {
      alert(`Unable to update gift card order: ${err.message}`)
    }

    this._refetchData()
  }

  _updatePurchaseCode(key, value) {
    if (!this.state.giftCardOrder) { return }

    var state = { purchaseCode: { ...this.state.purchaseCode } }
    state.purchaseCode[key] = value

    this.setState(state)
  }

  _updatePurchaseCodeFields = async function() {
    if (!this.state.purchaseCode) { return }

    const purchaseCode = this.state.purchaseCode
    const remainingLifetimeAmountInUsd = Number.parseFloat(purchaseCode.remainingLifetimeAmountInUsd)

    try {
      await this.props.client.mutate({
        mutation: PURCHASE_CODE_UPDATE_MUTATION,
        variables: {
          id: purchaseCode.id,
          isExpired:purchaseCode.isExpired,
          remainingLifetimeAmountInUsd: remainingLifetimeAmountInUsd,
          currency: purchaseCode.currency
        }
      })
    } catch (err) {
      alert(`Unable to update purchase code associated with gift card order: ${err.message}`)
    }

    this._refetchData()
  }

  componentDidUpdate() {
    const query = this.props.giftCardOrderQuery

    if (query && query.loading) { this._clearGiftCardOrder() }
    else if (!this.state.giftCardOrder) { this._setGiftCardOrder() }
  }

  render() {
    if (this.props.giftCardOrderQuery && this.props.giftCardOrderQuery.loading) {
      return <div>Loading</div>
    }

    if (this.props.giftCardOrderQuery && this.props.giftCardOrderQuery.error) {
      return <div>Error</div>
    }

    if (!this.state.giftCardOrder) {
      return <div>Loading</div>
    }

    const giftCard = this.state.giftCardOrder
    const purchaseCode = this.state.purchaseCode
    const amountPaid = giftCard.purchasePrice - giftCard.discountAmount

    const paperStyle = { height: '90%', width: '100%', textAlign: 'center', display: 'inline-block' }

    var flatButtonStyle = { height: '100%' }
    if (this.state.isEditingLocked) {
      flatButtonStyle.visibility = 'hidden'
    }

    return (
      <div>
        <h1>Gift Card Details</h1>

        <Paper style={paperStyle}>

          <div className='flex mt2 items-start' style={{ display: 'inline-block', textAlign: 'left' }}>
            <div>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.isEditingLocked}
                    onChange={(e, b) => { this._handleChangeLock(b) }}
                  />
                }
                label={"Editing " + (this.state.isEditingLocked ? "Locked" : "Unlocked")}
              />
            </div>

            <div className='ml1'>
              <p><b>Code: </b>{giftCard.purchaseCode.code}</p>
              <p><b>Purchased On: </b>{giftCard.orderPlacedAt}</p>
              <p><b>To: </b>{giftCard.recipientEmail}</p>
              <p><b>From: </b>{giftCard.purchaserEmail}</p>
              <p><b>Amount Paid: </b>{purchaseCode.currency === 'GBP' ? '£' : '$' }{amountPaid}</p>
              <p><b>Initial Value: </b>{purchaseCode.currency === 'GBP' ? '£' : '$' }{purchaseCode.initialLifetimeAmountInUsd}</p>
              <br/>
              
              <span>
                <div>
                  <FormControlLabel
                    control={<Checkbox checked={purchaseCode.isExpired}
                                       onChange={(e,b) => { this._handleChangeIsExpired(b) }}
                                       disabled={this.state.isEditingLocked} />}
                    label="Is Expired"
                  />
                </div>

                <div className="input-wrapper">
                  <TextField value={ purchaseCode.remainingLifetimeAmountInUsd || 0 }
                             label="Remaining Value"
                             disabled={this.state.isEditingLocked}
                             onChange={(e) => { this._handleChangeRemainingValue(e.target.value) }}
                  />
                </div>

                <div className="input-wrapper">
                  <TextField value={ giftCard.deliveryDate || undefined }
                             label="Delivery Date"
                             disabled={this.state.isEditingLocked}
                             onChange={(e) => { this._handleChangeDeliveryDate(e.target.value) }}
                  />
                </div>

                <div className="input-wrapper">
                  <FormControl>
                    <InputLabel style={{marginRight: '10px', width:"400px"}}>Currency</InputLabel>
                    <Select value={purchaseCode.currency}
                            label="Currency"
                            onChange={(e,k,p) => { this._handleChangeCurrency(k.props.value) }}
                            disabled={this.state.isEditingLocked}>

                      <MenuItem key="USD" value="USD"> USD </MenuItem>
                      <MenuItem key="GBP" value="GBP"> GBP </MenuItem>

                    </Select>
                  </FormControl>
                </div>
              </span>

              <div>
                {!this.state.isSendingGiftCardEmail &&
                  <Button variant="contained"
                          style={{marginBottom: '15px'}}
                          onClick={() => { this._sendGiftCardEmail() }}>
                    Send Gift Card to Recipient
                  </Button>
                }
                {this.state.isSendingGiftCardEmail &&
                <CircularProgress size={60} thickness={7} />
                }
              </div>
              <div>
                {!this.state.isSendingGiftCardReceiptEmail &&
                  <Button variant="contained"
                          style={{marginBottom: '15px'}}
                          onClick={() => { this._sendGiftCardReceiptEmail() }}>
                    Send Gift Card Reciept
                  </Button>
                }
                {this.state.isSendingGiftCardReceiptEmail &&
                  <CircularProgress size={60} thickness={7} />
                }
              </div>
            </div>
          </div>
        </Paper>
      </div>
    )
  }
}

const GIFT_CARD_ORDER_QUERY = gql`
  query GiftCardOrderQuery($id: ID!) {
    giftCardOrder(id: $id) {
      id
      orderPlacedAt
      deliveryDate
      discountAmount
      purchasePrice
      purchaserEmail
      purchaserNote
      recipientEmail

      purchaseCode {
        id
        code
        currency
        initialLifetimeAmountInUsd
        isExpired
        remainingLifetimeAmountInUsd
      }
    }
  }
`

const GIFT_CARD_ORDER_UPDATE_MUTATION = gql`
  mutation GiftCardOrderUpdateMutation($id: ID!, $deliveryDate: String) {
    giftCardOrderUpdate(data: {
      id: $id,
      deliveryDate: $deliveryDate
    }) {
      id
    }
  }
`

const PURCHASE_CODE_UPDATE_MUTATION = gql`
  mutation PurchaseCodeUpdateMutation($id: ID!, $isExpired: Boolean!, $remainingLifetimeAmountInUsd: Float, $currency: String) {
    purchaseCodeUpdate(data: {
      id: $id,
      isExpired: $isExpired,
      remainingLifetimeAmountInUsd: $remainingLifetimeAmountInUsd,
      currency: $currency
    }) {
      id
    }
  }
`

export default withApollo(graphql(GIFT_CARD_ORDER_QUERY, {
  name: 'giftCardOrderQuery',
  options: (ownProps) => {
    const id = ownProps.match.params.id

    return {
      variables: { id },
      notifyOnNetworkStatusChange: true
    }
  }
})(GiftCardOrderDetails))
