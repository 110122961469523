import React, { Component } from 'react'
import { gql } from 'apollo-boost'
import { graphql, withApollo } from 'react-apollo'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import Switch from '@material-ui/core/Switch'
import TextField from '@material-ui/core/TextField'


class RestaurantDetails extends Component {

  state = {
    isEditingLocked: true
  }

  _clearRestaurantCopy() {
    if (this.state.restaurant) { this.setState({ restaurant: null }) }
  }

  _handleChangeLock(isInputChecked) {
    this.setState({ isEditingLocked: isInputChecked })

    if (isInputChecked) {
      this._updateRestaurantFields()
    }
  }

  _handleChangeAddress(val) { this._updateRestaurant('address', val) }
  _handleChangeName(val) { this._updateRestaurant('name', val) }
  _handleChangePhoneNumber(val) { this._updateRestaurant('phoneNumber', val) }
  _handleChangeWebsite(val) { this._updateRestaurant('website', val) }
  _handleChangeLocation(val) { this._updateRestaurant('location', val) }

  _loadLocations = async () => {
    try {
      var locationsQuery = await this.props.client.query({ query: LOCATIONS_LIST_QUERY })
      this.setState({ locationsQuery })

      console.log(locationsQuery)

    } catch (err) {
      console.log('Unable to get locations data')
    }
  }

  _refetchData() {
    this.props.restaurantQuery.refetch()
  }

  _setRestaurantCopy() {
    var restaurant = this.props.restaurantQuery.restaurant
    restaurant.location = this.props.restaurantQuery.restaurant.location.name

    this.setState({ restaurant })
  }

  _updateRestaurant(key, value) {
    if (!this.state.restaurant) { return }

    var state = { restaurant: { ...this.state.restaurant } }
    state.restaurant[key] = value
    this.setState(state)
  }

  _updateRestaurantFields = async function() {
    if (!this.state.restaurant) { return }

    var restaurant = this.state.restaurant

    try {
      await this.props.client.mutate({
        mutation: RESTAURANT_UPDATE_MUTATION,
        variables: {
          id: this.props.match.params.id,
          address: restaurant.address,
          name: restaurant.name,
          phoneNumber: restaurant.phoneNumber,
          website: restaurant.website,
          location: restaurant.location
        }
      })

    } catch (err) {
      alert(`Error while updating restaurant: ${err.message}`)
    }

    this._refetchData()
  }

  componentDidUpdate() {
    const query = this.props.restaurantQuery
    if (query && query.loading) { this._clearRestaurantCopy() }
    else if (!this.state.restaurant) {
      this._setRestaurantCopy()
      this._loadLocations()
    }
  }

  render() {
    if (this.props.restaurantQuery && this.props.restaurantQuery.loading) {
      return <div>Loading</div>
    }

    if (this.props.restaurantQuery && this.props.restaurantQuery.error) {
      return <div>Error</div>
    }

    if (!this.state.restaurant) {
      return <div>Loading</div>
    }

    const restaurant = this.state.restaurant
    const paperStyle = { height: '90%', width: '100%', textAlign: 'center', display: 'inline-block' }

    return (
      <div>
        <h1>Restaurant Details</h1>

        <Paper style={paperStyle}>

          <div className='flex mt2 items-start' style={{ display: 'inline-block', textAlign: 'left' }}>
            <div>
              {/* TODO: Fix console warning that happens on toggle */}
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.isEditingLocked}
                    onChange={(e, b) => { this._handleChangeLock(b) }}
                  />
                }
                label={"Editing " + (this.state.isEditingLocked ? "Locked" : "Unlocked")}
              />
            </div>

            <div className='ml1' style={{ width: '265px' }}>
              <div className="padding">
                <TextField defaultValue={restaurant.name || ''}
                           label="Name"
                           style={{paddingTop:"10px"}}
                           onChange={(e) => { this._handleChangeName(e.target.value) }}
                           disabled={this.state.isEditingLocked}/>
              </div>

              <div className="padding">
                <FormControl>
                  <InputLabel style={{marginRight: '10px', width:"400px"}}>Location</InputLabel>
                  <Select value={restaurant.location}
                          label="Location"
                          onChange={(e,k,p) => { this._handleChangeLocation(k.props.value) }}
                          disabled={this.state.isEditingLocked}>

                    {this.state.locationsQuery && this.state.locationsQuery.data.locationsList.items.map((location, idx) => (
                      <MenuItem key={idx} value={location.name}> {location.name} </MenuItem>
                    ))}

                  </Select>
                </FormControl>
              </div>

              <div className="padding">
                <TextField defaultValue={restaurant.address || ''}
                           label="Address"
                           style={{paddingTop:"10px"}}
                           onChange={(e) => { this._handleChangeAddress(e.target.value) }}
                           disabled={this.state.isEditingLocked}/>
              </div>

              <div className="padding">
                <TextField defaultValue={restaurant.phoneNumber || ''}
                           label="Phone Number"
                           style={{paddingTop:"10px"}}
                           onChange={(e) => { this._handleChangePhoneNumber(e.target.value) }}
                           disabled={this.state.isEditingLocked}/>
              </div>
            </div>
          </div>
        </Paper>
      </div>
    )
  }
}

const RESTAURANT_QUERY = gql`
  query RestaurantQuery($id: ID) {
    restaurant(id: $id) {
      id
      address
      name
      phoneNumber

      location {
        id
        name
      }
    }
  }
`

const RESTAURANT_UPDATE_MUTATION = gql`
  mutation RestaurantUpdateMutation($id: ID!, $address: String, $name: String!, $phoneNumber: String, $location: String) {
    restaurantUpdate(data: {
      id: $id,
      address: $address,
      name: $name,
      phoneNumber: $phoneNumber

      location: {
        connect: { name: $location }
      }
    }) {
      id
    }
  }
`

const LOCATIONS_LIST_QUERY = gql`
  query LocationsListQuery {
    locationsList(sort: { name: ASC }) {
      items {
        id
        name
      }
    }
  }
`

export default withApollo(graphql(RESTAURANT_QUERY, {
  name: 'restaurantQuery',
  options: (ownProps) => {
    const id = ownProps.match.params.id

    return {
      variables: { id },
      notifyOnNetworkStatusChange: true
    }
  }
})(RestaurantDetails))
