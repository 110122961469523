// TODO: Find a way to share this code between admin portal and main site
import 'whatwg-fetch'
import { BR_API_KEY } from './constants'

const BR_API_URL = 'https://beta-api.broadwayroulette.com'
const ADMIN_SERVICE_PREFIX = '/admin'
const TICKETS_SERVICE_PREFIX = '/tickets'

const ENDPOINT = {
  BUY_TICKETS: '/buyTickets',
  GET_PERFORMANCES_AVAILABILITY: '/getPerformancesAvailability',
  GET_PREMIUM_TICKETS: '/getPremiumTickets',
  GET_REPORT: '/getReport',
  GET_SHOW_BASICS: '/getShowBasics',
  GET_SHOW_DETAILS: '/getShowDetails',
  RESEND_GIFT_CARD_EMAIL: '/resendGiftCardEmail',
  RESEND_GIFT_CARD_RECEIPT: '/resendGiftCardReceiptEmail',
  RESEND_MIXER_INVITE_CONFIRMATION: '/resendMixerInviteEmail',
  RESEND_MIXER_ORDER_CONFIRMATION: '/resendMixerOrderConfirmationEmail',
  RESEND_ORDER_CONFIRMATION: '/resendOrderConfirmationEmail',
  RESEND_EVENT_TICKET_DETAILS: '/resendEventTicketDetailsEmail',
  RESEND_TICKET_DETAILS: '/resendTicketDetailsEmail',
  RUN_ALGORITHM_BY_DAY: '/runMatchingAlgorithmByDay',
  RUN_ALGORITHM_ON_ORDER: '/runMatchingAlgorithm',
  SELECT_TICKETS: '/selectTickets',
  SEND_REJECTION_EMAIL: '/sendMixerOrderRejectionEmail',
  UPDATE_ORDER_ON_SPREADSHEET: '/updateOrderOnSpreadsheet'
}

const buyTickets = (productId, showCode, showDate, showTime, quantity, price, lastName, firstName, referenceNumber,
                    notes, area, lowSeatNum, highSeatNum, row, session) => {
  var REQUEST_URL = BR_API_URL + TICKETS_SERVICE_PREFIX + ENDPOINT.BUY_TICKETS

  return fetch(REQUEST_URL, {
    method: 'POST',
    body: JSON.stringify({
      productId,
      showCode,
      showDate,
      showTime,
      quantity,
      price,
      lastName,
      firstName,
      referenceNumber,
      notes,
      area,
      lowSeatNum,
      highSeatNum,
      row,
      session
    }),
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': BR_API_KEY
    },
    credentials: 'omit'
  })
}

const getPerformancesAvailability = (showCode, showDate, bestSeats) => {
  var REQUEST_URL = BR_API_URL + TICKETS_SERVICE_PREFIX + ENDPOINT.GET_PERFORMANCES_AVAILABILITY

  return fetch(REQUEST_URL, {
    method: 'POST',
    body: JSON.stringify({
      showCode,
      showDate,
      bestSeats
    }),
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': BR_API_KEY
    },
    credentials: 'omit'
  })
}

const getPremiumTickets = (showCode, showDate) => {
  var REQUEST_URL = BR_API_URL + TICKETS_SERVICE_PREFIX + ENDPOINT.GET_PREMIUM_TICKETS

  return fetch(REQUEST_URL, {
    method: 'POST',
    body: JSON.stringify({
      showCode,
      showDate
    }),
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': BR_API_KEY
    },
    credentials: 'omit'
  })
}

const getReport = (reportName, startDate, endDate, location, timestamp) => {
  var REQUEST_URL = BR_API_URL + ADMIN_SERVICE_PREFIX + ENDPOINT.GET_REPORT +
                   `?reportName=${reportName}&startDate=${startDate}&endDate=${endDate}&location=${location}&timestamp=${timestamp}`

  return fetch(REQUEST_URL, {
    method: 'GET',
    headers: { 'x-api-key': BR_API_KEY },
    credentials: 'omit'
  })
}

const getShowBasics = () => {
  var REQUEST_URL = BR_API_URL + TICKETS_SERVICE_PREFIX + ENDPOINT.GET_SHOW_BASICS

  return fetch(REQUEST_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': BR_API_KEY
    },
    credentials: 'omit'
  })
}

const getShowDetails = (showCode) => {
  var REQUEST_URL = BR_API_URL + TICKETS_SERVICE_PREFIX + ENDPOINT.GET_SHOW_DETAILS

  return fetch(REQUEST_URL, {
    method: 'POST',
    body: JSON.stringify({
      showCode
    }),
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': BR_API_KEY
    },
    credentials: 'omit'
  })
}

const resendGiftCardEmail = (giftCardOrderId) => {
  var REQUEST_URL = BR_API_URL + ADMIN_SERVICE_PREFIX + ENDPOINT.RESEND_GIFT_CARD_EMAIL

  return fetch(REQUEST_URL, {
    method: 'POST',
    body: JSON.stringify({
      giftCardOrderId
    }),
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': BR_API_KEY
    },
    credentials: 'omit'
  })
}

const resendGiftCardReceiptEmail = (giftCardOrderId) => {
  var REQUEST_URL = BR_API_URL + ADMIN_SERVICE_PREFIX + ENDPOINT.RESEND_GIFT_CARD_RECEIPT

  return fetch(REQUEST_URL, {
    method: 'POST',
    body: JSON.stringify({
      giftCardOrderId
    }),
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': BR_API_KEY
    },
    credentials: 'omit'
  })
}

const resendMixerInviteEmail = (orderId) => {
  var REQUEST_URL = BR_API_URL + ADMIN_SERVICE_PREFIX + ENDPOINT.RESEND_MIXER_INVITE_CONFIRMATION

  return fetch(REQUEST_URL, {
    method: 'POST',
    body: JSON.stringify({
      orderId
    }),
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': BR_API_KEY
    },
    credentials: 'omit'
  })
}

const resendMixerOrderEmail = (orderId) => {
  var REQUEST_URL = BR_API_URL + ADMIN_SERVICE_PREFIX + ENDPOINT.RESEND_MIXER_ORDER_CONFIRMATION

  return fetch(REQUEST_URL, {
    method: 'POST',
    body: JSON.stringify({
      orderId
    }),
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': BR_API_KEY
    },
    credentials: 'omit'
  })
}

const resendOrderEmail = (orderId, orderType) => {
  var REQUEST_URL = BR_API_URL + ADMIN_SERVICE_PREFIX + ENDPOINT.RESEND_ORDER_CONFIRMATION

  return fetch(REQUEST_URL, {
    method: 'POST',
    body: JSON.stringify({
      orderId,
      orderType
    }),
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': BR_API_KEY
    },
    credentials: 'omit'
  })
}

const resendEventTicketDetailsEmail = (orderId) => {
  var REQUEST_URL = BR_API_URL + ADMIN_SERVICE_PREFIX + ENDPOINT.RESEND_EVENT_TICKET_DETAILS

  return fetch(REQUEST_URL, {
    method: 'POST',
    body: JSON.stringify({
      orderId
    }),
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': BR_API_KEY
    },
    credentials: 'omit'
  })
}

const resendTicketDetailsEmail = (orderId) => {
  var REQUEST_URL = BR_API_URL + ADMIN_SERVICE_PREFIX + ENDPOINT.RESEND_TICKET_DETAILS

  return fetch(REQUEST_URL, {
    method: 'POST',
    body: JSON.stringify({
      orderId
    }),
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': BR_API_KEY
    },
    credentials: 'omit'
  })
}

const runMatchingAlgorithmByDay = (startDate, endDate, location) => {
  var REQUEST_URL = BR_API_URL + ADMIN_SERVICE_PREFIX + ENDPOINT.RUN_ALGORITHM_BY_DAY +
                    `?startDate=${startDate}&endDate=${endDate}&location=${location}`

  return fetch(REQUEST_URL, {
    method: 'POST',
    headers: { 'x-api-key': BR_API_KEY },
    credentials: 'omit'
  })
}

const runMatchingAlgorithmOnOrder = (orderId) => {
  var REQUEST_URL = BR_API_URL + ADMIN_SERVICE_PREFIX + ENDPOINT.RUN_ALGORITHM_ON_ORDER

  return fetch(REQUEST_URL, {
    method: 'POST',
    body: JSON.stringify({
      orderId: orderId
    }),
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': BR_API_KEY
    },
    credentials: 'omit'
  })
}

const selectTickets = (productId, showCode, showDate, showTime, quantity) => {
  var REQUEST_URL = BR_API_URL + TICKETS_SERVICE_PREFIX + ENDPOINT.SELECT_TICKETS

  return fetch(REQUEST_URL, {
    method: 'POST',
    body: JSON.stringify({
      productId,
      showCode,
      showDate,
      showTime,
      quantity
    }),
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': BR_API_KEY
    },
    credentials: 'omit'
  })
}

const sendMixerOrderRejectionEmail = (orderId) => {
  var REQUEST_URL = BR_API_URL + ADMIN_SERVICE_PREFIX + ENDPOINT.SEND_REJECTION_EMAIL

  return fetch(REQUEST_URL, {
    method: 'POST',
    body: JSON.stringify({
      orderId
    }),
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': BR_API_KEY
    },
    credentials: 'omit'
  })
}

const updateOrderOnSpreadsheet = (orderId, orderType, previousShowDate, previousShowTime, changes, credit) => {
  var REQUEST_URL = BR_API_URL + ADMIN_SERVICE_PREFIX + ENDPOINT.UPDATE_ORDER_ON_SPREADSHEET

  return fetch(REQUEST_URL, {
    method: 'POST',
    body: JSON.stringify({
      orderId,
      orderType,
      previousShowDate,
      previousShowTime,
      changes,
      credit
    }),
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': BR_API_KEY
    },
    credentials: 'omit'
  })
}


const BRClient = {
  buyTickets,
  getPerformancesAvailability,
  getPremiumTickets,
  getReport,
  getShowBasics,
  getShowDetails,
  resendGiftCardEmail,
  resendGiftCardReceiptEmail,
  resendMixerInviteEmail,
  resendMixerOrderEmail,
  resendOrderEmail,
  resendEventTicketDetailsEmail,
  resendTicketDetailsEmail,
  runMatchingAlgorithmByDay,
  runMatchingAlgorithmOnOrder,
  selectTickets,
  sendMixerOrderRejectionEmail,
  updateOrderOnSpreadsheet
}

export default BRClient
