import React, { useState, useEffect } from 'react'
import { gql } from 'apollo-boost'
import { graphql, withApollo } from 'react-apollo'
import moment from 'moment-timezone'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import { withRouter } from 'react-router'

import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Checkbox from '@mui/material/Checkbox'
import Divider from '@mui/material/Divider'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormLabel from '@mui/material/FormLabel'
import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'

import BRClient from '../broadwayRouletteClient'
import { getDaysUntil, getLocalDateStr } from '../utils'
import Queries, { userToString } from '../queries'


const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body1,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
}))


const OrderBox = (props) => {
  const { order, currentUser } = props

  const orderStatus = order.orderStatus
  const paymentDetails = order.paymentDetails

  const preferences = order.restaurantReservationDetails ? JSON.parse(order.restaurantReservationDetails.preferences) : null

  const orderRestaurantPreferenceBudget = (preferences && preferences.budget) || 'Affordable'
  const orderRestaurantPreferencePriority = (preferences && preferences.priority) || 'Cute Decor'
  const orderRestaurantPreferenceTime = (preferences && preferences.when) || 'Pre-Event'

  const daysUntil = getDaysUntil(moment().startOf('day'), moment(order.requestedDate).startOf('day'))
  const orderPlacedAt = getLocalDateStr(order.orderPlacedAt)
  const spinEmailAt = getLocalDateStr(orderStatus.sentSpinEmailAt)
  const ticketsEmailAt = getLocalDateStr(orderStatus.sentTicketsEmailAt)
  const ticketsTextAt = getLocalDateStr(orderStatus.sentTicketsTextAt)
  const receivedConfirmationAt = getLocalDateStr(orderStatus.receivedConfirmationAt)

  const [events, setEvents] = useState([])
  const [locations, setLocations] = useState([])
  const [saving, setSaving] = useState(false)

  const [orderState, setOrderState] = useState({
    excludedEvents: order.excludedEvents.items,
    isRequestingTicketFlex: order.isRequestingTicketFlex,
    isRequestingAdditionalExclusions: order.isRequestingAdditionalExclusions,
    isValidOrder: order.isValidOrder,
    location: order.location.name,
    notes: order.notes,
    numberOfTickets: order.numberOfTickets,
    requestedDate: order.requestedDate,
    requestedTime: order.requestedTime,
    eventPreference: order.eventPreference,
    userEmail: order.user.email,
  })

  const [paymentDetailsState, setPaymentDetailsState] = useState({
    additionalExclusionFee: paymentDetails.additionalExclusionFee,
    discountAmount: paymentDetails.discountAmount,
    parkingFee: paymentDetails.parkingFee,
    giftCardAmount: paymentDetails.giftCardAmount,
    restaurantFee: paymentDetails.restaurantFee,
    orderFee: paymentDetails.orderFee,
    serviceFee: paymentDetails.serviceFee,
    ticketFlexFee: paymentDetails.ticketFlexFee,
    totalPaid: paymentDetails.price
  })

  const [restaurantState, setRestaurantState] = useState({
    restaurantPreferenceBudget: orderRestaurantPreferenceBudget,
    restaurantPreferencePriority: orderRestaurantPreferencePriority,
    restaurantPreferenceTime: orderRestaurantPreferenceTime
  })

  const [previousEventDate, setPreviousEventDate] = useState(order.requestedDate)
  const [previousEventTime, setPreviousEventTime] = useState(order.requestedTime)

  const [eventRestaurantOptions, setEventRestaurantOptions] = useState(order.restaurantReservationDetails)

  var originalUpdateHistory = order.updateHistory ? JSON.parse(order.updateHistory.replace(/\n/g, " ")) : []
  const [updateHistory, setUpdateHistory] = useState(originalUpdateHistory)

  const [isSendingConfirmationEmail, setIsSendingConfirmationEmail] = useState(false)
  const [isSendingTicketDetailsEmail, setIsSendingTicketDetailsEmail] = useState(false)

  const [excludedEventsString, setExcludedEventsString] = useState('')

  var ticketsRedeemed = order.paymentDetails.orderFee / 47
  var pointsRedeemed = 0

  order.pointsRedemptionDetails && order.pointsRedemptionDetails.items.map(points => {
    pointsRedeemed += points.pointsRedeemed
  })

  var timeTillEventEl
  if (daysUntil < 0) {
    timeTillEventEl = `This event was ${-1 * daysUntil} day(s) ago`
  } else if (daysUntil === 0) {
    timeTillEventEl = `This event is today!`
  } else {
    timeTillEventEl = `${daysUntil} day(s) until the event`
  }

  const handleAddRestaurantReservation = async () => {
    try {
      var restaurantPreferences = {
        budget: "Affordable",
        priority: "Cute Decor",
        when: "Pre-Event"
      }

      var restaurantPreferencesString = JSON.stringify(restaurantPreferences)

      await props.client.mutate({
        mutation: RESTAURANT_RESERVATION_DETAILS_CREATE_MUTATION,
        variables: {
          orderId: order.id,
          restaurantPreferences: restaurantPreferencesString
        }
      })

      alert('Restaurant reservation made')
      setEventRestaurantOptions(true)
    } catch (err) {
      alert('Unable to create restaurant reservation')
      console.log(err)
    }
  }

  const handleClickEvent = (event) => {
    var eventId = event.id
    var newOrderState = {...orderState}
    var newExcludedEvents = [...orderState.excludedEvents]

    var isInExcludedEvents = newExcludedEvents.filter(excludedEvent => excludedEvent.id === eventId).length > 0
    if (isInExcludedEvents) {
      newExcludedEvents = newExcludedEvents.filter(excludedEvent => excludedEvent.id !== eventId)
    } else {
      newExcludedEvents.push(event)
    }

    handleUpdateOrderState('excludedEvents', newExcludedEvents)
  }

  const handleSaveChanges = async () => {
    try {
      setSaving(true)
      console.log(updateHistory)

      var { userEmail, location, numberOfTickets, requestedDate, requestedTime, eventPreference,
            isRequestingTicketFlex, isRequestingAdditionalExclusions, isValidOrder, notes } = orderState

      var { totalPaid, additionalExclusionFee, parkingFee, restaurantFee, serviceFee, ticketFlexFee,
            discountAmount, giftCardAmount, orderFee } = paymentDetailsState

      var excludedEventsReconnection = ''
      orderState.excludedEvents.forEach(event => {
        excludedEventsReconnection += `{id: "${ event.id }"},`
      })

      var restaurantReservationMutation = ""
      if (order.restaurantReservationDetails && eventRestaurantOptions) {
        var restaurantPreferences = {
          budget: restaurantState.restaurantPreferenceBudget,
          priority: restaurantState.restaurantPreferencePriority,
          when: restaurantState.restaurantPreferenceTime
        }

        var restaurantPreferencesString = JSON.stringify(restaurantPreferences)

        restaurantReservationMutation = `restaurantReservationDetails: {
                                           update: {
                                             preferences: "${restaurantPreferencesString.replace(/"/g, '\\"')}"
                                           }
                                         }`
                                       }

      var UpdateEventOrderMutation = gql`
        mutation UpdateEventOrderMutation($orderId: ID!, $userEmail: String, $numberOfTickets: Int, $requestedDate: Date,
                                     $requestedTime: String, $eventPreference: String, $location: String,
                                     $isRequestingTicketFlex: Boolean, $isRequestingAdditionalExclusions: Boolean,
                                     $isValidOrder: Boolean, $notes: String, $updateHistory: String) {
          eventOrderUpdate(data: {
            id: $orderId,
            numberOfTickets: $numberOfTickets,
            requestedDate: $requestedDate,
            requestedTime: $requestedTime,
            eventPreference: $eventPreference,
            isRequestingTicketFlex: $isRequestingTicketFlex,
            isRequestingAdditionalExclusions: $isRequestingAdditionalExclusions,
            isValidOrder: $isValidOrder,
            notes: $notes,
            updateHistory: $updateHistory

            location: {
              connect: { name: $location }
            }

            ${restaurantReservationMutation}

            excludedEvents: {
              reconnect: [
                ${excludedEventsReconnection}
              ]
            }
            user: {
              reconnect: { email: $userEmail }
            }
          }) {
            id
          }
        }
      `

      var result = await props.client.mutate({
        mutation: UpdateEventOrderMutation,
        variables: {
          orderId: order.id,
          userEmail,
          location,
          numberOfTickets: parseInt(numberOfTickets, 10),
          requestedDate,
          requestedTime,
          eventPreference,
          isRequestingTicketFlex,
          isRequestingAdditionalExclusions,
          isValidOrder,
          notes,
          updateHistory: JSON.stringify(updateHistory)
        }
      })

      await props.client.mutate({
        mutation: PAYMENT_DETAILS_UPDATE_MUTATION,
        variables: {
          paymentDetailsId: order.paymentDetails.id,
          price: parseFloat(totalPaid, 10),
          additionalExclusionFee: parseFloat(additionalExclusionFee, 10),
          parkingFee: parseFloat(parkingFee, 10),
          restaurantFee: parseFloat(restaurantFee, 10),
          orderFee: parseFloat(orderFee, 10),
          serviceFee: parseFloat(serviceFee, 10),
          ticketFlexFee: parseFloat(ticketFlexFee, 10),
          discountAmount: parseFloat(discountAmount, 10),
          giftCardAmount: parseFloat(giftCardAmount, 10),
        }
      })

      try {
        var orderType = 'event spin'
        var result = await BRClient.updateOrderOnSpreadsheet(order.id, orderType, previousEventDate, previousEventTime)
        alert('Order successfully updated')
      } catch (err) {
        alert('Order details saved but not updated on spreadsheet, please try again.')
      }

      setSaving(false)

    } catch (err) {
      setSaving(false)
      alert(`Did not save, error: ${err}`)
      console.log(err)
      console.log(err.message)
      console.log(err.extraInfo)
      console.log(err.graphQLErrors)
      console.log(err.networkError)
    }
  }

  const handleUpdateUserEmail = (email) => {
    setTimeout(() => {
      handleUpdateOrderState('userEmail', email)
    }, 1000)
  }

  const handleUpdateOrderState = (key, value) => {
    var newOrderState = {...orderState}
    var newUpdateHistory = [...updateHistory.filter(history => history.key !== key)]

    var updateHistoryEntry = {}

    updateHistoryEntry.user = currentUser.firstName
    updateHistoryEntry.date = moment().format('YYYY-MM-DD HH:mm A')
    updateHistoryEntry.key = key
    updateHistoryEntry.newValue = key === 'excludedEvents' ? eventsArrayToString(value) : value.toString()

    if (key === 'userEmail') {
      updateHistoryEntry.oldValue = order.user.email
    } else if (key === 'location') {
      updateHistoryEntry.oldValue = order.location.name
    } else if (key === 'excludedEvents') {
      updateHistoryEntry.oldValue = eventsArrayToString(order.excludedEvents.items)
    } else if (key === 'notes') {
      updateHistoryEntry.oldValue = order.notes ? order.notes : ''
    } else {
      updateHistoryEntry.oldValue = order[key].toString()
    }

    newUpdateHistory.push(updateHistoryEntry)
    newOrderState[key] = value

    setOrderState(newOrderState)
    setUpdateHistory(newUpdateHistory)
  }

  const handleUpdatePaymentDetailsState = (key, value) => {
    var newPaymentDetailsState = {...paymentDetailsState}
    var newUpdateHistory = [...updateHistory.filter(history => history.key !== key)]

    var updateHistoryEntry = {}
    updateHistoryEntry.date = moment().format('YYYY-MM-DD HH:mm A')
    updateHistoryEntry.key = key
    updateHistoryEntry.newValue = value.toString()
    updateHistoryEntry.oldValue = order.paymentDetails[key].toString()

    newUpdateHistory.push(updateHistoryEntry)
    newPaymentDetailsState[key] = parseFloat(value, 10)

    setPaymentDetailsState(newPaymentDetailsState)
    setUpdateHistory(newUpdateHistory)
  }

  const handleUpdateRestaurantState = (key, value) => {
    var newRestaurantState = {...restaurantState}

    newRestaurantState[key] = value
    setRestaurantState(newRestaurantState)
  }

  const loadLocations = async () => {
    try {
      var locationsQuery = await props.client.query({ query: LOCATIONS_LIST_QUERY })
      setLocations(locationsQuery.data.locationsList.items)

    } catch (err) {
      console.log('Unable to get locations data')
    }
  }

  const loadEvents = async () => {
    try {
      var result = await props.client.query({ query: EVENTS_LIST_QUERY })

      var requestedDate = order.requestedDate
      var events = result.data.eventsList.items
      var filteredEvents = events.filter(event => moment(event.startDate).format('YYYY-MM-DD') <= moment(requestedDate).format('YYYY-MM-DD'))
                               .filter(event => !event.endDate || moment(event.endDate).format('YYYY-MM-DD') >= moment(requestedDate).format('YYYY-MM-DD'))
                               .filter(event => event.location.name === order.location.name)

      setEvents(filteredEvents)
    } catch (err) {
      console.log(err)
      console.log('Unable to get data for events')
    }
  }

  const eventsArrayToString = (array) => {
    var string = ''

    array.map((event, idx) => {
      var segment = idx < array.length - 1 ? `${event.shortName}, ` : `${event.shortName}`
      string = string.concat(segment)
    })

    return string
  }

  const sendConfirmationEmail = async () => {
    try {
    setIsSendingConfirmationEmail(true)

    var response = await BRClient.resendOrderEmail(order.id, 'event spin')
    setIsSendingConfirmationEmail(false)
    } catch(err) {
      console.log(err)
      alert('Failed to resend order confirmation')
    }
  }

  const sendTicketDetailsEmail = async () => {
    try {
    setIsSendingTicketDetailsEmail(true)

    var response = await BRClient.resendEventTicketDetailsEmail(order.id)
    setIsSendingTicketDetailsEmail(false)
    } catch(err) {
      console.log(err)
      alert('Failed to resend ticket details')
    }
  }

  useEffect(() => {
    loadEvents()
    loadLocations()
  }, [])

  useEffect(() => {
    var string = eventsArrayToString(orderState.excludedEvents)

    setExcludedEventsString(string)
  }, [orderState])

  return (
    <Item className="order-tile">
      <Typography sx={{ flex: '1 1 100%' }} variant="h5" className="order-tile-subtitle" component="div">
        {order.user.firstName} {order.user.lastName} - {moment(order.requestedDate).format('MM/DD/YYYY')} @ {order.requestedTime}
      </Typography>

      <Typography sx={{ flex: '1 1 100%' }} variant="h6" className="order-tile-subtitle" component="div">
        <a href={`event-assignments?startDate=${order.requestedDate}&endDate=${order.requestedDate}`}>
          {order.ticketDetails.event ? order.ticketDetails.event.name : 'No Event Assigned'}
        </a>
      </Typography>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header">
          <Typography className="accordion-title">Event Order Details</Typography>
          <Divider />
        </AccordionSummary>
        <AccordionDetails>
          <Stack direction="column" alignItems="stretch" justifyContent="flex-start">
            <Divider />

            <div className="padding first-entry">
              <TextField label="ID"
                         defaultValue={order.id}
                         disabled={true} />
            </div>

            <div className="padding">
              <TextField label="User Email"
                         defaultValue={orderState.userEmail}
                         onChange={(e) => { handleUpdateUserEmail(e.target.value) }} />
            </div>

            <div className="padding">
              <TextField label="Number of Tickets"
                         defaultValue={orderState.numberOfTickets}
                         onChange={(e) => { handleUpdateOrderState('numberOfTickets', e.target.value) }} />
            </div>

            <div className="padding">
              <TextField label="Event Date"
                         type="date"
                         defaultValue={moment(orderState.requestedDate).format("YYYY-MM-DD")}
                         onChange={(e) => { handleUpdateOrderState('requestedDate', e.target.value) }} />
            </div>

            <div className="padding">
              <FormControl>
                <InputLabel>Location</InputLabel>
                <Select value={orderState.location}
                        label="Location"
                        onChange={(event) => handleUpdateOrderState('location', event.target.value)}>

                    {locations && locations.map((locationMap, idx) => (
                      <MenuItem key={idx} value={locationMap.name}> {locationMap.name} </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>

            <div className="padding">
              <FormControl>
                <InputLabel>Time</InputLabel>
                <Select value={orderState.requestedTime}
                        label="Time"
                        onChange={(event) => handleUpdateOrderState('requestedTime', event.target.value)}>
                  <MenuItem value={'Afternoon'}>Afternoon</MenuItem>
                  <MenuItem value={'Evening'}>Evening</MenuItem>
                  <MenuItem value={'AllDay'}>All-Day</MenuItem>
                </Select>
              </FormControl>
            </div>

            {!order.isForOffBroadway &&
              <div className="padding">
                <FormControl>
                  <InputLabel>Preference</InputLabel>
                  <Select label="Preference"
                          value={orderState.eventPreference}
                          onChange={(event) => handleUpdateOrderState('eventPreference', event.target.value)}>
                    <MenuItem value={'Arts'}>Arts</MenuItem>
                    <MenuItem value={'Attractions'}>Attractions</MenuItem>
                    <MenuItem value={'Premium'}>Premium</MenuItem>
                  </Select>
                </FormControl>

                <div>
                  <FormControlLabel
                    control={
                      <Checkbox checked={orderState.isRequestingTicketFlex}
                                onChange={(e) => { handleUpdateOrderState('isRequestingTicketFlex', e.target.checked) }} />
                    }
                    label="Requesting Ticket Protection" />
                </div>

                <div>
                  <FormControlLabel
                    control={
                      <Checkbox checked={orderState.isRequestingAdditionalExclusions}
                                onChange={(e) => { handleUpdateOrderState('isRequestingAdditionalExclusions', e.target.checked) }} />
                    }
                    label="Requesting Additional Exclusions" />
                </div>
              </div>
            }

            <div className="padding">
              <TextField defaultValue={orderState.notes}
                         label="Notes"
                         multiline
                         maxRows={10}
                         onChange={(e) => { handleUpdateOrderState('notes', e.target.value) }} />
            </div>

            <div className="padding">
              <FormControlLabel
                control={
                  <Switch checked={orderState.isValidOrder}
                          style={{color: 'red'}}
                           onChange={(e) => { handleUpdateOrderState('isValidOrder', e.target.checked) }} />
                }
                label={`Is Valid Order? ${orderState.isValidOrder}`}
                style={{ color: 'red' }}
              />
            </div>
          </Stack>

        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header">
          <Typography className="accordion-title">Excluded Events</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Divider />

          <div className="padding">
            <strong>Excluded events:</strong> {excludedEventsString}
          </div>

          <Stack spacing={2} direction="column" alignItems="center" justifyContent="center" className="padding">
            {events && events.map(event => {
              var style = {}

              if (orderState && orderState.excludedEvents && orderState.excludedEvents.filter(excludedEvent => excludedEvent.id === event.id).length > 0) {
                style = {textDecoration: 'line-through'}
              }

              return (<span key={event.id}>
                        <p onClick={() => handleClickEvent(event)} style={style} className="excluded-events-list">
                          {event.shortName}
                        </p>
                      </span>)
            })}
          </Stack>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header">
          <Typography className="accordion-title">Payment Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Divider />

          <Stack direction="column" alignItems="center" justifyContent="center">

            <div className="input-wrapper">
              <TextField defaultValue={paymentDetailsState.totalPaid}
                         label="Total Paid (no $)"
                         onChange={(e) => { handleUpdatePaymentDetailsState('totalPaid', e.target.value) }} />
            </div>

            <div className="input-wrapper">
              <TextField defaultValue={paymentDetailsState.additionalExclusionFee}
                         label="Total Extra Exclusion Fee (no $)"
                         onChange={(e) => { handleUpdatePaymentDetailsState('additionalExclusionFee', e.target.value) }} />
            </div>

            <div className="input-wrapper">
              <TextField defaultValue={paymentDetailsState.parkingFee}
                         label="Parking Fee"
                         onChange={(e) => { handleUpdatePaymentDetailsState('parkingFee', e.target.value) }} />
            </div>

            <div className="input-wrapper">
              <TextField defaultValue={paymentDetailsState.serviceFee}
                         label="Total Service Fee (no $)"
                         onChange={(e) => { handleUpdatePaymentDetailsState('serviceFee', e.target.value) }} />
            </div>

            <div className="input-wrapper">
              <TextField defaultValue={paymentDetailsState.ticketFlexFee}
                         label="Total Ticket Protection Fee (no $)"
                         onChange={(e) => { handleUpdatePaymentDetailsState('ticketFlexFee', e.target.value) }} />
            </div>

            <div className="input-wrapper">
              <TextField defaultValue={paymentDetailsState.restaurantFee}
                         label="Restaurant Fee"
                         onChange={(e) => { handleUpdatePaymentDetailsState('restaurantFee', e.target.value) }} />
            </div>

            <div className="input-wrapper">
              <TextField defaultValue={paymentDetailsState.orderFee}
                         label="Order Fee"
                         onChange={(e) => { handleUpdatePaymentDetailsState('orderFee', e.target.value) }} />
            </div>

            <div className="input-wrapper">
              <p>Discount Code: {paymentDetails.promoCode && paymentDetails.promoCode.code}</p>
              <TextField defaultValue={paymentDetailsState.discountAmount}
                         label="Promo Discount (no $)"
                         onChange={(e) => { handleUpdatePaymentDetailsState('discountAmount', e.target.value) }} />
            </div>

            <div className="input-wrapper">
              <p>Gift Card Code: {paymentDetails.giftCardCode && paymentDetails.giftCardCode.code}</p>
              <TextField defaultValue={paymentDetailsState.giftCardAmount}
                         label="Gift Card Amount"
                         onChange={(e) => { handleUpdatePaymentDetailsState('giftCardAmount', e.target.value) }} />
            </div>
          </Stack>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header">
          <Typography className="accordion-title">Restaurant Reservation</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Divider />

          <div className="padding first-entry">
            {(!order.restaurantReservationDetails && !eventRestaurantOptions) &&
              <div className="top-padding">
                <Button variant="contained"
                        onClick={() => { handleAddRestaurantReservation() }}>
                  + Add Restaurant Reservation
                </Button>
              </div>
            }

            {eventRestaurantOptions &&
              <div>
                <div className="top-padding">
                  <FormControl>
                    <FormLabel id="radio-buttons-group-label">Event Time</FormLabel>
                    <RadioGroup aria-labelledby="time-radio-buttons-group-label"
                                name="radio-buttons-group"
                                defaultValue={restaurantState.restaurantPreferenceTime}
                                onChange={(event) => { handleUpdateRestaurantState('restaurantPreferenceTime', event.target.value) }}>
                      <FormControlLabel value="Pre-Event" control={<Radio />} label="Pre-Event" />
                      <FormControlLabel value="Post-Event" control={<Radio />} label="Post-Event" />
                    </RadioGroup>
                  </FormControl>
                </div>

                <div className="top-padding">
                  <FormControl>
                    <FormLabel id="radio-buttons-group-label">Budget</FormLabel>
                    <RadioGroup aria-labelledby="time-radio-buttons-group-label"
                                name="radio-buttons-group"
                                defaultValue={restaurantState.restaurantPreferenceBudget}
                                onChange={(event) => { handleUpdateRestaurantState('restaurantPreferenceBudget', event.target.value) }}>

                      <FormControlLabel value="Affordable"
                                        control={<Radio />} label="Looking to keep it super affordable" />
                      <FormControlLabel value="Reasonable Price"
                                        control={<Radio />} label="Reasonable, but I get it's Times Square" />
                      <FormControlLabel value="Night Out"
                                        control={<Radio />} label="This is my big night out - don't worry about it!" />
                    </RadioGroup>
                  </FormControl>
                </div>

                <div className="top-padding">
                  <FormControl>
                    <FormLabel id="radio-buttons-group-label">Priority</FormLabel>
                    <RadioGroup aria-labelledby="time-radio-buttons-group-label"
                                name="radio-buttons-group"
                                defaultValue={restaurantState.restaurantPreferencePriority}
                                onChange={(event) => { handleUpdateRestaurantState('restaurantPreferencePriority', event.target.value) }}>

                      <FormControlLabel value="Cute Decor"
                                        control={<Radio />} label="Cute Decor" />
                      <FormControlLabel value="Food Options"
                                        control={<Radio />} label="Range of food options" />
                      <FormControlLabel value="Night Out"
                                        control={<Radio />} label="Proximity to theater" />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
            }
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header">
          <Typography className="accordion-title">Order Status</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Divider />

          <div className="padding first-entry">
            <div>
              {timeTillEventEl}

              <div>
                <p>Order Number of Tickets: {order.numberOfTickets}</p>

                <p>Order placed: {orderPlacedAt}</p>
                <p>Spin email sent at: {spinEmailAt}</p>
                <p>Tickets email sent at: {ticketsEmailAt}</p>
                <p>Tickets text sent at: {ticketsTextAt}</p>
                <p>Received confirmation at: {receivedConfirmationAt}</p>
              </div>
            </div>

            <div className="top-padding">
              {!isSendingConfirmationEmail &&
                <Button variant="contained"
                        style={{marginBottom: '15px'}}
                        onClick={() => { sendConfirmationEmail() }}>
                  Send Confirmation Email
                </Button>
              }

              {isSendingConfirmationEmail &&
                <CircularProgress size={60} thickness={7} />
              }
            </div>

            <div>
              {!isSendingTicketDetailsEmail &&
                <Button variant="contained"
                        style={{marginBottom: '15px'}}
                        onClick={() => { sendTicketDetailsEmail() }}>
                  Send Ticket Details Email
                </Button>
              }

              {isSendingTicketDetailsEmail &&
                <CircularProgress size={60} thickness={7} />
              }
            </div>
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header">
          <Typography className="accordion-title">Order Update History</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Divider />

          <div className="padding first-entry">
            <TableContainer>
              <Table sx={{ minWidth: 750 }}
                     aria-labelledby="table-title"
                     size='medium'>

                <TableHead>
                  <TableRow>
                    <TableCell align='left'>User</TableCell>
                    <TableCell align='left'>Date</TableCell>
                    <TableCell align='left'>Updated field</TableCell>
                    <TableCell align='left'>Old value</TableCell>
                    <TableCell align='left'>New value</TableCell>
                  </TableRow>
                </TableHead>

                {updateHistory && updateHistory.map((update, idx) => (
                  <TableBody key={idx}>
                    <TableRow>
                      <TableCell align="left">{update.user}</TableCell>
                      <TableCell align="left">{update.date}</TableCell>
                      <TableCell align="left">{update.key}</TableCell>
                      <TableCell align="left">{update.oldValue}</TableCell>
                      <TableCell align="left">{update.newValue}</TableCell>
                    </TableRow>
                  </TableBody>
                ))}
              </Table>
            </TableContainer>

          </div>
        </AccordionDetails>
      </Accordion>

      {!saving &&
        <div className="save-button">
          <Button variant="contained" onClick={handleSaveChanges}>
            Save changes
          </Button>
        </div>
      }

      {saving &&
        <CircularProgress />
      }

    </Item>
  )
}

function OrderDetails(props) {
  const [loading, setLoading] = useState(true)
  const [updating, setUpdating] = useState(false)
  const [error, setError] = useState(false)

  const [orders, setOrders] = useState([])

  const history = useHistory()

  const ids = queryString.parse(props.location.search).id

  const allUserEmails = orders.map((order, index) => {
    return `${order.user.email};`
  })

  const uniqueUserEmails = [...new Set(allUserEmails)]

  const getItemsToRender = (ids) => {
    new Promise(async (resolve, reject) => {
      try {
        var data = []

        if (typeof ids !== 'string') {
          var requests = ids.map(async (id, idx) => {
            const result = await props.client.query({
              query: EVENT_ORDER_QUERY,
              variables: { id }
            })

            console.log(result.data.eventOrder)

            data.push(result.data.eventOrder)
          })

          var promiseMaps = await Promise.all(requests)

        } else {
          const result = await props.client.query({
            query: EVENT_ORDER_QUERY,
            variables: { id: ids }
          })

          data.push(result.data.eventOrder)
        }


        setLoading(false)
        console.log(data)
        setOrders(data)
        resolve()

      } catch(err) {
        console.log(err)
        reject()
        setError(true)
      }
    })
  }

  const handleCancelOrders = async () => {
    setUpdating(true)

    try {
      await orders.map(async (order) => {
        var orderId = order.id

        var UpdateEventOrderMutation = gql`
          mutation UpdateEventOrderMutation($orderId: ID!) {
            eventOrderUpdate(data: {
              id: $orderId,
              isValidOrder: false,
            }) {
              id
              requestedDate
              requestedTime
            }
          }
        `

        var result = await props.client.mutate({
          mutation: UpdateEventOrderMutation,
          variables: {
            orderId
          }
        })
      })

      var cancelledOrder = result.data.eventOrderUpdate
      var orderType = 'event spin'

      var result = await BRClient.updateOrderOnSpreadsheet(orderId, orderType, cancelledOrder.requestedDate, cancelledOrder.requestedTime)

      if (result.status !== 200) {
        alert('Order not updated on spreadsheet, try again.')
      }

      alert(`Successfully cancelled ${orders.length} order(s)`)

    } catch(err) {
      alert('Unable to cancel orders')
      console.log(err)
    }

    setUpdating(false)
  }

  const handleCreateCredits = async (uniqueUserEmails) => {
    setUpdating(true)

    try {
      uniqueUserEmails.map(async (userEmail, idx) => {
        var formattedUserEmail = userEmail.replace(';', '')
        var userOrders = orders.filter(order => order.user.email === formattedUserEmail)

        var userCreditTotal = 0
        var user = userOrders[0].user

        var credits = user.purchaseCredits.items.filter(credit => !credit.isExpired && credit.remainingAmount > 0)
        var activeCredit = credits.length > 0 ? credits[0] : null

        userOrders.map((userOrder, idx) => {
          var creditToAdd = userOrder.paymentDetails.price + userOrder.paymentDetails.giftCardAmount
          userCreditTotal += creditToAdd
        })

        var newRemaining = activeCredit ? activeCredit.remainingAmount + userCreditTotal : userCreditTotal
        var newInitial = activeCredit ? activeCredit.initialAmount + userCreditTotal : userCreditTotal

        if (activeCredit) {
          await props.client.mutate({
            mutation: CREDIT_UPDATE_MUTATION,
            variables: {
              id: activeCredit.id,
              remainingAmount: newRemaining,
              initialAmount: newInitial
            }
          })

        } else {
          await props.client.mutate({
            mutation: CREDIT_CREATE_MUTATION,
            variables: {
              userId: user.id,
              remainingAmount: newRemaining,
              initialAmount: newInitial
            }
          })
        }
      })

      alert(`Successfully created credits for ${orders.length} order(s)`)

      setTimeout(() => {
        getItemsToRender(ids)
      }, 500)

    } catch(err) {
      alert('Unable to create credits')
      console.log(err)
    }

    setUpdating(false)
  }

  useEffect(async () => {
    if (loading) {
      await getItemsToRender(ids)
    }
  }, [ids, loading])

  return (
    <Box sx={{ width: '100%' }}>
      <div className='data'>
        <Typography sx={{ flex: '1 1 100%' }} variant="h3" className="page-title" component="div">
          Order Details
        </Typography>

        {loading &&
          <CircularProgress />
        }

        {!orders && error &&
          <h2>Error</h2>
        }

        {orders && !loading &&
          <div>
            {!updating &&
              <Stack className="buttons-stack">
                <div>
                  <Button variant="contained"
                          className="cancel-button button"
                          onClick={() => { handleCancelOrders() }}>
                    Cancel {orders.length} order(s)
                  </Button>
                </div>

                <div>
                  <Button variant="contained"
                          className="credit-button button"
                          onClick={() => { handleCreateCredits(uniqueUserEmails) }}>
                    Create credit for {uniqueUserEmails.length} users(s)
                  </Button>
                </div>

                <div>
                  <a target="_blank" href={`mailto:?bcc=${uniqueUserEmails}&subject=Important%20Message%20About%20Your%20Event%20Tonight`}>
                    <Button variant="contained" className="email-button button">Email {uniqueUserEmails.length} user(s)</Button>
                  </a>
                </div>
              </Stack>
            }

            {updating &&
              <CircularProgress />
            }

          <Grid container spacing={2} justifyContent="center">
            {orders.map((boxData, idx) => {
              return (
                <Grid item xs={12} md={6} lg={6} key={boxData.id}>
                  <OrderBox order={boxData} client={props.client} currentUser={props.currentUser} key={idx} />
                </Grid>
              )
            })}
          </Grid>
          </div>
        }
      </div>
    </Box>
  )
}

const CREDIT_CREATE_MUTATION = gql`
  mutation CreditCreateMutation($userId: ID!, $initialAmount: Float!, $remainingAmount: Float!) {
    purchaseCreditCreate(data: {
      initialAmount: $initialAmount,
      remainingAmount: $remainingAmount,
      currency: "USD",
      isExpired: false,
      user: { connect: { id: $userId }}
    }) {
      id
    }
  }
`

const CREDIT_UPDATE_MUTATION = gql`
  mutation CreditUpdateMutation($id: ID!, $initialAmount: Float!, $remainingAmount: Float!) {
    purchaseCreditUpdate(data: {
      id: $id
      initialAmount: $initialAmount,
      remainingAmount: $remainingAmount,
      isExpired: false
    }) {
      id
    }
  }
`

const LOCATIONS_LIST_QUERY = gql`
  query LocationsListQuery {
    locationsList(sort: { name: ASC }) {
      items {
        id
        name
      }
    }
  }
`

const EVENTS_LIST_QUERY = gql`
  query EventsListQuery {
    eventsList(sort: { name: ASC }) {
      items {
        id

        location {
          name
        }

        startDate
        endDate
        shortName
      }
    }
  }
`

const EVENT_ORDER_QUERY = gql`
  query EventOrderQuery($id: ID) {
    eventOrder(id: $id) {
      id
      createdAt
      isRequestingAdditionalExclusions
      isRequestingTicketFlex
      isValidOrder
      numberOfTickets
      orderPlacedAt
      requestedDate
      requestedTime
      eventPreference
      updateHistory

      excludedEvents {
        items {
          id
          shortName
        }
      }

      location {
        id
        name
      }

      orderStatus {
        id
        receivedConfirmationAt
        sentSpinEmailAt
        sentTicketsEmailAt
        sentTicketsTextAt
      }

      paymentDetails {
        id
        price
        additionalExclusionFee
        discountAmount
        parkingFee
        restaurantFee
        serviceFee
        ticketFlexFee
        giftCardAmount
        orderFee

        promoCode {
          id
          code
        }

        giftCardCode {
          id
          code
        }
      }

      restaurantReservationDetails {
        id
        preferences

        restaurant {
          name
        }
      }

      ticketDetails {
        event {
          name
        }
      }

      user {
        id
        email
        firstName
        lastName

        purchaseCredits {
          items {
            id
            isExpired
            initialAmount
            remainingAmount
          }
        }
      }
    }
  }
`

const RESTAURANT_RESERVATION_DETAILS_CREATE_MUTATION = gql`
  mutation RestaurantReservationDetailsCreateMutation($orderId: ID!, $restaurantPreferences: String!) {
    restaurantReservationDetailCreate(data: {
      preferences: $restaurantPreferences
      eventOrder: {
        connect: {
          id: $orderId
        }
      }
    }) {
      id
    }
  }
`

const PAYMENT_DETAILS_UPDATE_MUTATION  = gql`
  mutation PaymentDetailsUpdateMutation($paymentDetailsId: ID!, $price: Float!, $additionalExclusionFee: Float,
                                        $parkingFee: Float, $restaurantFee: Float, $serviceFee: Float,
                                        $ticketFlexFee: Float, $discountAmount: Float!, $giftCardAmount: Float,
                                        $orderFee: Float) {
    eventPaymentDetailUpdate(data: {
      id: $paymentDetailsId,
      price: $price,
      additionalExclusionFee: $additionalExclusionFee,
      discountAmount: $discountAmount,
      parkingFee: $parkingFee,
      restaurantFee: $restaurantFee,
      orderFee: $orderFee,
      serviceFee: $serviceFee,
      ticketFlexFee: $ticketFlexFee,
      giftCardAmount: $giftCardAmount,
    }) {
      id
    }
  }
`

export default withApollo(graphql(EVENT_ORDER_QUERY, {
  name: 'eventEventOrderQuery',
  options: (ownProps) => {
    const id = queryString.parse(ownProps.location.search).id[0]

    return {
      variables: { id },
      notifyOnNetworkStatusChange: true
    }
  }
})(OrderDetails))
