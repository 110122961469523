import React, { useState, useEffect } from 'react'
import { gql } from 'apollo-boost'
import { graphql, withApollo } from 'react-apollo'
import moment from 'moment-timezone'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import { withRouter } from 'react-router'

import AdapterDateFns from '@mui/lab/AdapterDateFns'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Checkbox from '@mui/material/Checkbox'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import { DataGrid } from '@mui/x-data-grid'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'
import Divider from '@mui/material/Divider'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import InputLabel from '@mui/material/InputLabel'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import MenuItem from '@mui/material/MenuItem'
import MobileDatePicker from '@mui/lab/MobileDatePicker'
import Paper from '@mui/material/Paper'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'

import BRClient from '../broadwayRouletteClient'

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body1,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
}))

const creditColumns = [
  { field: 'id', headerName: 'ID', width: 250 },
  { field: 'initialAmount', headerName: 'Initial Amount', width: 150 },
  { field: 'remainingAmount', headerName: 'Remaining Amount', width: 150 },
  { field: 'currency', headerName: 'Currency' },
  { field: 'isExpired', headerName: 'Is Expired?' },
]

const mixerColumns = [
  { field: 'id', headerName: 'ID', width: 250 },
  { field: 'showDate', headerName: 'Show Date' },
  { field: 'showTime', headerName: 'Show Time' },
  { field: 'mixerType', headerName: 'Type' },
  { field: 'paid', headerName: 'Paid' },
  { field: 'rating', headerName: 'Rating' },
  { field: 'isValidOrder', headerName: 'Is Valid Order?', width: 150  },
]

const orderColumns = [
  { field: 'id', headerName: 'ID', width: 250 },
  { field: 'orderPlacedAt', headerName: 'Order Placed At', width: 200 },
  { field: 'requestedShowDate', headerName: 'Show Date' },
  { field: 'requestedShowTime', headerName: 'Show Time' },
  { field: 'numberOfTickets', headerName: 'Tickets' },
  { field: 'showName', headerName: 'Show Name', width: 200 },
  { field: 'revealedTickets', headerName: 'Revealed?' },
  { field: 'rating', headerName: 'Rating' },
  { field: 'location', headerName: 'Location' },
  { field: 'isValidOrder', headerName: 'Is Valid Order?', width: 150 },
]

const subscriptionColumns = [
  { field: 'id', headerName: 'ID', width: 250 },
  { field: 'paid', headerName: 'Paid' },
  { field: 'subscriptionPackage', headerName: 'Subscription Package', width: 200},
  { field: 'startDate', headerName: 'Start Date' },
  { field: 'endDate', headerName: 'End Date' },
  { field: 'renewalDate', headerName: 'Renewal Date', width: 125 },
  { field: 'ticketsPerPeriod', headerName: 'Tickets Per Period', width: 150 },
  { field: 'points', headerName: 'Points' },
  { field: 'isActive', headerName: 'Is Active?' },
]

const UserData = (props) => {
  const { user } = props
  const history = useHistory()

  const [requiredFieldBlankError, setRequiredFieldBlankError] = useState(false)
  const [saving, setSaving] = useState(false)
  const [isCreatingCredit, setIsCreatingCredit] = useState(false)
  const [selectionModel, setSelectionModel] = React.useState([])

  const [userState, setUserState] = useState({
     email: user.email,
     firstName: user.firstName,
     isAdmin: user.isAdmin,
     isBanned: user.isBanned,
     lastName: user.lastName,
     mobilePhone: user.mobilePhone,
     notes: user.notes,
  })

  const cancelOrders = (orders) => {
    try {
      orders.map(async orderId => {

        var result = await props.client.mutate({
          mutation: CANCEL_ORDER_MUTATION,
          variables: {
            id: orderId
          }
        })

        var cancelledOrder = result.data.orderUpdate
        var result = await BRClient.updateOrderOnSpreadsheet(orderId, cancelledOrder.requestedShowDate, cancelledOrder.requestedShowTime)

        if (result.status !== 200) {
          alert('Order not updated on spreadsheet, try again.')
        }

        window.location.reload()
      })
    } catch(err) {
      setIsCreatingCredit(false)
      console.log(`Unable to cancel orders: ${err.message}`)
    }
  }

  const createCredit = async () => {
    setIsCreatingCredit(true)
    var credits = user.purchaseCredits.items.filter(credit => !credit.isExpired && credit.remainingAmount > 0)
    var activeCredit = credits.length > 0 ? credits[0] : null

    var creditToAdd = getCreditTotal()

    var newRemaining = activeCredit ? activeCredit.remainingAmount + creditToAdd : creditToAdd
    var newInitial = activeCredit ? activeCredit.initialAmount + creditToAdd : creditToAdd

    try {
      if (activeCredit) {
        await props.client.mutate({
          mutation: CREDIT_UPDATE_MUTATION,
          variables: {
            id: activeCredit.id,
            remainingAmount: newRemaining,
            initialAmount: newInitial
          }
        })

      } else {
        await props.client.mutate({
          mutation: CREDIT_CREATE_MUTATION,
          variables: {
            userId: user.id,
            remainingAmount: newRemaining,
            initialAmount: newInitial
          }
        })
      }

      await cancelOrders(selectionModel)

    } catch(err) {
      setIsCreatingCredit(false)
      console.log(`Unable to create credit: ${err.message}`)
    }
  }

  const getCreditTotal = () => {
    var total = 0

    selectionModel.map(selectedOrder => {
      var order = user.orders.items.find(x => x.id === selectedOrder)
      return total += order.paymentDetails.price + order.paymentDetails.giftCardAmount
    })

    return total
  }

  const handleOpenOrders = (event) => {
    var url = '?'

    selectionModel.map((id, idx) => {
      if (idx < selectionModel.length - 1) {
        var urlSegment = `id=${id}&`
        url = url.concat(urlSegment)
      } else {
        var urlSegment = `id=${id}`
        url = url.concat(urlSegment)
      }
    })

    history.push(`/order${url}`)
  }

  const handleSaveChanges = async () => {
    try {
      setSaving(true)

      var { firstName, lastName, email, isAdmin, isBanned, mobilePhone, notes } = userState

      if (!firstName || !lastName || !email) {
        setRequiredFieldBlankError(true)
        alert('Please fill in required fields')

        setSaving(false)
        return
      }

      var result = await props.client.mutate({
        mutation: USER_UPDATE_MUTATION,
        variables: {
          id: user.id,
          firstName: firstName,
          lastName: lastName,
          mobilePhone: mobilePhone,
          isInvalidMobilePhone: false,
          isAdmin: isAdmin,
          isBanned: isBanned,
          notes: notes
        }
      })

      alert('User successfully updated')
      setSaving(false)

    } catch (err) {
      setSaving(false)
      alert(`Did not save, error: ${err}`)
    }
  }

  const handleUpdateUserState = (key, value) => {
    var newUserState = {...userState}

    newUserState[key] = value
    setUserState(newUserState)
  }

  return (
    <Item className="order-tile">
      <Typography sx={{ flex: '1 1 100%' }} variant="h5" className="order-tile-subtitle" component="div">
        {user.email}
      </Typography>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header">
          <Typography className="accordion-title">User Details</Typography>
          <Divider />
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <Divider />

            <Stack justifyContent="space-around" direction="row" flexWrap="wrap">

              <Stack direction="column">
                <div className="padding">
                  <TextField label="First Name"
                             defaultValue={userState.firstName}
                             helperText="*required field"
                             error={requiredFieldBlankError && !userState.firstName}
                             onChange={(e) => { handleUpdateUserState('firstName', e.target.value) }} />
                </div>

                <div className="padding">
                  <TextField label="Last Name"
                             defaultValue={userState.lastName}
                             helperText="*required field"
                             error={requiredFieldBlankError && !userState.lastName}
                             onChange={(e) => { handleUpdateUserState('lastName', e.target.value) }} />
                </div>

                <div className="padding">
                  <TextField label="Email"
                             defaultValue={userState.email}
                             helperText="*required field"
                             error={requiredFieldBlankError && !userState.email}
                             onChange={(e) => { handleUpdateUserState('email', e.target.value) }} />
                </div>

                <div className="padding">
                  <TextField label="Phone Number"
                             defaultValue={userState.mobilePhone}
                             onChange={(e) => { handleUpdateUserState('mobilePhone', e.target.value) }} />
                </div>

                <div className="padding">
                  <TextField label="Notes"
                             defaultValue={userState.purchaserNote}
                             multiline
                             maxRows={10}
                             onChange={(e) => { handleUpdateUserState('purchaserNote', e.target.value) }} />
                </div>

                <Stack justifyContent="space-around" direction="row" flexWrap="wrap">
                  <FormControlLabel
                    control={
                      <Checkbox checked={userState.isAdmin}
                                onChange={(e) => { handleUpdateUserState('isAdmin', e.target.checked) }} />
                    }
                    label="Is active" />

                  <FormControlLabel
                    control={
                      <Checkbox checked={userState.isBanned}
                                onChange={(e) => { handleUpdateUserState('isBanned', e.target.checked) }} />
                    }
                    label="Is banned" />
                </Stack>
              </Stack>

            </Stack>
          </div>

        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header">
          <Typography className="accordion-title">Order History</Typography>
          <Divider />
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <Divider />

            <Stack justifyContent="flex-start" spacing={2} direction="row" flexWrap="wrap">
              <Button variant="contained" onClick={handleOpenOrders}>
                View Selected Orders
              </Button>

              {isCreatingCredit &&
                <CircularProgress />
              }
            </Stack>

            <div style={{ height: 400, width: '100%' }}>
              <DataGrid
                rows={createOrderData(user.orders.items)}
                columns={orderColumns}
                height="100%"
                pageSize={5}
                rowsPerPageOptions={[5]}
                checkboxSelection
                onSelectionModelChange={(newSelectionModel) => {
                  setSelectionModel(newSelectionModel)
                }}
              />
            </div>
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header">
          <Typography className="accordion-title">Subscription History</Typography>
          <Divider />
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <Divider />

            <div style={{ height: 400, width: '100%' }}>
              <DataGrid
                rows={createSubscriptionData(user.monthlySubscriptions.items)}
                columns={subscriptionColumns}
                height="100%"
                pageSize={5}
                rowsPerPageOptions={[5]}
                onCellClick={(cell) => {
                  history.push(`/subscription/${cell.id}`)
                }}
              />
            </div>
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header">
          <Typography className="accordion-title">Credit History</Typography>
          <Divider />
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <Divider />

            <div style={{ height: 400, width: '100%' }}>
              <DataGrid
                rows={createCreditData(user.purchaseCredits.items)}
                columns={creditColumns}
                height="100%"
                pageSize={5}
                rowsPerPageOptions={[5]}
                onCellClick={(cell) => {
                  history.push(`/credit/${cell.id}`)
                }}
              />
            </div>
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header">
          <Typography className="accordion-title">Mixer Order History</Typography>
          <Divider />
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <Divider />

            <div style={{ height: 400, width: '100%' }}>
              <DataGrid
                rows={createMixerData(user.mixerOrders.items)}
                columns={mixerColumns}
                height="100%"
                pageSize={5}
                rowsPerPageOptions={[5]}
              />
            </div>
          </div>
        </AccordionDetails>
      </Accordion>

      {!saving &&
        <div className="save-button">
          <Button variant="contained" onClick={handleSaveChanges}>
            Save changes
          </Button>
        </div>
      }

      {saving &&
        <CircularProgress />
      }

    </Item>
  )
}

function createCreditData(credits) {
  var formattedCredits = credits.map(credit => {
    return {
      id: credit.id,
      initialAmount: credit.initialAmount,
      remainingAmount: credit.remainingAmount,
      currency: credit.currency,
      isExpired: credit.isExpired ? '✔️' : '❌'
    }
  })

  return formattedCredits
}

function createMixerData(orders) {
  var formattedOrders = orders.map(order => {
    return {
      id: order.id,
      showDate: order.mixerSession.date,
      showTime: order.mixerSession.time,
      mixerType: order.mixerType,
      paid: order.paid,
      rating: order.mixerOrderSurvey ? order.mixerOrderSurvey.rating : '',
      isValidOrder: order.isValidOrder ? '✔️' : '❌'
    }
  })

  return formattedOrders
}

function createOrderData(orders) {
  var formattedOrders = orders.map(order => {
    return {
      id: order.id,
      orderPlacedAt: moment(order.orderPlacedAt).format('YYYY-MM-DD HH:MM:SS'),
      requestedShowDate: order.requestedShowDate,
      requestedShowTime: order.requestedShowTime,
      numberOfTickets: order.numberOfTickets,
      showName: order.ticketDetails.show ? order.ticketDetails.show.name : 'Coming Soon',
      revealedTickets: order.orderStatus.sentTicketsTextAt ? '✔️' : '❌',
      rating: order.orderSurvey ? order.orderSurvey.rating : '',
      location: order.location.name,
      isValidOrder: order.isValidOrder ? '✔️' : '❌'
    }
  })

  return formattedOrders
}

function createSubscriptionData(subscriptions) {
  var formattedSubscriptions = subscriptions.map(subscription => {
    return {
      id: subscription.id,
      paid: `$${subscription.orderDetails.purchasePrice}`,
      subscriptionPackage: subscription.orderDetails.subscriptionPackage,
      startDate: subscription.startDate,
      endDate: subscription.endDate,
      renewalDate: subscription.renewalDate,
      ticketsPerPeriod: `${subscription.remainingTicketsForPeriod} / ${subscription.numberOfTicketsPerPeriod}`,
      points: `${subscription.currentPoints} / ${subscription.totalPoints}`,
      isActive: subscription.isActive ? '✔️' : '❌'
    }
  })

  return formattedSubscriptions
}

function UserDetails(props) {
  const [loading, setLoading] = useState(true)
  const [updating, setUpdating] = useState(false)
  const [error, setError] = useState(false)

  const [user, setUser] = useState([])

  const getUser = () => {
    try {
      var userQuery = props.userQuery.user

      if (userQuery) {
        setUser(userQuery)
        setLoading(false)
      }

    } catch(err) {
      console.log(err)
      setError(true)
    }
  }

  useEffect(async () => {
    if (loading && !props.userQuery.loading) {
      await getUser()
    }
  }, [props.userQuery, loading])

  return (
    <Box sx={{ width: '100%' }}>
      <div className='data'>

        <Typography sx={{ flex: '1 1 100%' }} variant="h3" className="page-title" component="div">
          User Details
        </Typography>

        {loading &&
          <CircularProgress />
        }

        {error &&
          <h2>Error</h2>
        }

        {user && !loading &&
          <UserData user={user} client={props.client} userQuery={props.userQuery} getUser={getUser} />
        }

      </div>
    </Box>
  )
}

const USER_QUERY = gql`
  query UserQuery($id: ID) {
    user(id: $id) {
      id
      createdAt
      dateRegistered
      email
      firstName
      isAdmin
      isBanned
      lastName
      mobilePhone
      isInvalidMobilePhone
      notes

      mixerOrders {
        items {
          id
          createdAt
          isValidOrder
          mixerType
          paid

          mixerSession {
            id
            date
            time
          }

          mixerSurvey {
            id
            rating
          }
        }
      }

      mixerSubscriptions {
        items {
          id
          isActive
          endDate
          purchasePrice
          renewalDate
          startDate
        }
      }

      orders {
        items {
          id
          isForOffBroadway
          isValidOrder
          numberOfTickets
          orderPlacedAt
          requestedShowDate
          requestedShowTime

          location {
            id
            name
          }

          orderStatus {
            id
            sentTicketsTextAt
          }

          orderSurvey {
            rating
          }

          paymentDetails {
            id
            price
            discountAmount
            giftCardAmount
          }

          ticketDetails {
            id
            show {
              id
              name
              shortName
            }
          }
        }
      }

      monthlySubscriptions {
        items {
          id
          startDate
          endDate
          renewalDate
          isActive
          currentPoints
          totalPoints
          remainingTicketsForPeriod
          numberOfTicketsPerPeriod
          timesRenewed

          orderDetails {
            id
            purchasePrice
            subscriptionPackage
          }
        }
      }

      purchaseCredits {
        items {
          id
          createdAt
          currency
          isExpired
          remainingAmount
          initialAmount
        }
      }

      talent {
        id
        defaultPay

        mixersCanPerform {
          items {
            id
          }
        }

        mixersAvailable {
          items {
            id
            date
          }
        }

        paymentAccount
      }
    }
  }
`

const CREDIT_CREATE_MUTATION = gql`
  mutation CreditCreateMutation($userId: ID!, $initialAmount: Float!, $remainingAmount: Float!) {
    purchaseCreditCreate(data: {
      initialAmount: $initialAmount,
      remainingAmount: $remainingAmount,
      currency: "USD",
      isExpired: false,
      user: { connect: { id: $userId }}
    }) {
      id
    }
  }
`

const CREDIT_UPDATE_MUTATION = gql`
  mutation CreditUpdateMutation($id: ID!, $initialAmount: Float!, $remainingAmount: Float!) {
    purchaseCreditUpdate(data: {
      id: $id
      initialAmount: $initialAmount,
      remainingAmount: $remainingAmount,
      isExpired: false
    }) {
      id
    }
  }
`

const CANCEL_ORDER_MUTATION = gql`
  mutation CancelOrderMutation($id: ID!) {
    orderUpdate(data: {
      id: $id,
      isValidOrder: false
    }) {
      id
      requestedShowDate
      requestedShowTime
    }
  }
`

const USER_UPDATE_MUTATION = gql`
  mutation UserUpdateQuery($id: ID!, $firstName: String, $lastName: String, $mobilePhone: String,
                           $isInvalidMobilePhone: Boolean, $isAdmin: Boolean, $isBanned: Boolean, $notes:String) {
    userUpdate(data: {
      id: $id
      firstName: $firstName
      lastName: $lastName
      mobilePhone: $mobilePhone
      isInvalidMobilePhone: $isInvalidMobilePhone
      isAdmin: $isAdmin
      isBanned: $isBanned
      notes: $notes
    }) {
      id
      email
    }
  }
`

export default withApollo(graphql(USER_QUERY, {
  name: 'userQuery',
  options: (ownProps) => {
    const id = ownProps.match.params.id

    return {
      variables: { id },
      notifyOnNetworkStatusChange: true
    }
  }
})(UserDetails))
