import React, { useState, useEffect } from 'react'
import { gql } from 'apollo-boost'
import { graphql, withApollo } from 'react-apollo'
import moment from 'moment-timezone'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import { withRouter } from 'react-router'

import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { alpha } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import CheckIcon from '@mui/icons-material/Check'
import CircularProgress from '@mui/material/CircularProgress'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'
import FilterListIcon from '@mui/icons-material/FilterList'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import IconButton from '@mui/material/IconButton'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import MobileDatePicker from '@mui/lab/MobileDatePicker'
import Popover from '@mui/material/Popover'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import TextField from '@mui/material/TextField'
import Toolbar from '@mui/material/Toolbar'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { visuallyHidden } from '@mui/utils'

import {
  getBeginningOfTimeStrYYYYMMDD,
  getTenYearsFromNowYYYYMMDD,
  getParamsFromUrl
} from '../utils'


const headCells = [
  {
    id: 'id',
    label: 'Id',
  },
  {
    id: 'customer',
    label: 'User',
  },
  {
    id: 'purchasedBy',
    label: 'Purchased By',
  },
  {
    id: 'subscriptionPackage',
    label: 'Subscription Package',
  },
  {
    id: 'startDate',
    label: 'Start Date',
  },
  {
    id: 'endDate',
    label: 'End Date',
  },
  {
    id: 'purchasePrice',
    label: 'Purchase Price',
  },
  {
    id: 'remainingTicketsForPeriod',
    label: 'Tickets For Period',
  },
  {
    id: 'currentPoints',
    label: 'Current Points',
  },
  {
    id: 'isActive',
    label: 'Is Active?',
  },
]

function createData(subscriptions) {
  var formattedData = subscriptions && subscriptions.map(subscription => {

    return {
      id: subscription.id,
      user: subscription.user,
      customer: subscription.user.email.toLowerCase(),
      orderPlacedAt: subscription.createdAt,
      purchasedBy: subscription.orderDetails && subscription.orderDetails.purchaserEmail,
      subscriptionPackage: subscription.orderDetails && subscription.orderDetails.subscriptionPackage,
      purchasePrice: subscription.orderDetails && subscription.orderDetails.purchasePrice,
      startDate: subscription.startDate,
      endDate: subscription.endDate,
      remainingTicketsForPeriod: `${subscription.remainingTicketsForPeriod} / ${subscription.numberOfTicketsPerPeriod}`,
      currentPoints: `${subscription.currentPoints} / ${subscription.totalPoints}`,
      isActive: subscription.isActive ? '✔️' : '❌'
    }
  })

  return formattedData
}

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

const FilterPopover = (props) => {
  const {
    startDate,
    endDate,
    executeSearch,
    searchText,
    setStartDate,
    setEndDate,
    setLocation,
    setSearchText
  } = props.data

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClickButton = (event) => {
    executeSearch()
    handleClose()
  }

  const open = Boolean(anchorEl)
  const id = open ? 'popover' : undefined

  return (
    <div>

      <Tooltip title="Filter list">
        <IconButton aria-describedby={id} onClick={handleClick}>
          <FilterListIcon />
        </IconButton>
      </Tooltip>

      <Popover id={id}
               open={open}
               anchorEl={anchorEl}
               onClose={handleClose}
               anchorOrigin={{
                 vertical: 'bottom',
                 horizontal: 'left',
               }}>

        <div className="filters">
          <h3>Filters</h3>
          <Stack spacing={3} direction="column">
            <div>
              <LocalizationProvider dateAdapter={AdapterDateFns}>

                <Stack spacing={2} direction="column" className="hidemobile stack">
                    <DesktopDatePicker label="Start Date"
                                       inputFormat="MM/dd/yyyy"
                                       value={startDate}
                                       onChange={(newValue) => setStartDate(newValue)}
                                       clearable={true}
                                       renderInput={(params) => <TextField {...params} />} />

                    <DesktopDatePicker label="End Date"
                                       inputFormat="MM/dd/yyyy"
                                       value={endDate}
                                       onChange={(newValue) => setEndDate(newValue)}
                                       clearable={true}
                                       renderInput={(params) => <TextField {...params} />} />
                </Stack>

                <Stack spacing={2} direction="column" className="showmobile stack">
                  <MobileDatePicker label="Start date"
                                    inputFormat="MM/dd/yyyy"
                                    value={startDate}
                                    onChange={(newValue) => setStartDate(newValue)}
                                    renderInput={(params) => <TextField {...params} />} />

                  <MobileDatePicker label="End date"
                                    inputFormat="MM/dd/yyyy"
                                    value={endDate}
                                    onChange={(newValue) => setEndDate(newValue)}
                                    renderInput={(params) => <TextField {...params} />} />
                </Stack>
              </LocalizationProvider>
            </div>

            <div>
              <TextField id="outlined-basic"
                         label="User Email"
                         variant="outlined"
                         onChange={(event) => setSearchText(event.target.value)} />
            </div>

            <Button variant="contained" onClick={handleClickButton}>Search</Button>
          </Stack>
        </div>

      </Popover>
    </div>
  )
}

const EnhancedTableHead = (props) => {
  const { order, orderBy, rowCount, onRequestSort } = props

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>

        {headCells.map((headCell) => (
          <TableCell key={headCell.id}
                     align='left'
                     padding={'normal'}
                     sortDirection={orderBy === headCell.id ? order : false}>

            <TableSortLabel active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>

          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const EnhancedTableToolbar = (props) => {
  const { history } = props

  return (
    <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }}>
      <Typography sx={{ flex: '1 1 100%' }} variant="h3" className="page-title" component="div">
        Subscription
      </Typography>

      <FilterPopover data={props}/>
    </Toolbar>
  )
}

function SubscriptionList(props) {
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('orderPlacedAt')

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const [endDate, setEndDate] = useState(null)
  const [startDate, setStartDate] = useState(null)
  const [searchText, setSearchText] = useState('')

  const [loadingSearch, setLoadingSearch] = useState(false)
  const [error, setError] = useState(false)
  const [searchedItems, setSearchedItems] = useState(null)

  const history = useHistory()

  const executeSearch = async () => {
    try {
      setLoadingSearch(true)

      var endOfPeriod = endDate ? moment(endDate).format('YYYY-MM-DD') : getTenYearsFromNowYYYYMMDD()
      var startOfPeriod = startDate ? moment(startDate).format('YYYY-MM-DD') : getBeginningOfTimeStrYYYYMMDD()

      var filterSubscriptionQuery = gql`
        query monthlySubscriptionsListQuery($searchText: String, $startOfPeriod: Date, $endOfPeriod: Date) {
          monthlySubscriptionsList (
            first: 1000,
            sort: { createdAt : DESC },
            filter: {
              AND: [
                { startDate: { gte: $startOfPeriod }},
                { endDate: { lte: $endOfPeriod }},
                { user: { email: { contains: $searchText }}},
              ]
            }
          ) {
            items {
              id
              startDate
              endDate
              isActive
              timesRenewed
              currentPoints
              totalPoints
              remainingTicketsForPeriod
              numberOfTicketsPerPeriod

              orderDetails {
                id
                deliveryDate
                purchasePrice
                purchaserEmail
                subscriptionPackage
              }

              user {
                id
                firstName
                lastName
                email
              }
            }
          }
        }
      `

      var result = await props.client.query({
        query: filterSubscriptionQuery,
        variables: {
          searchText,
          endOfPeriod,
          startOfPeriod,
        }
      })

      const subscriptions = result.data.monthlySubscriptionsList.items
      setSearchedItems(subscriptions)

      setSearchText('')
      setEndDate(null)
      setStartDate(null)
      setLoadingSearch(false)

    } catch(err) {
      setError(true)
      setLoadingSearch(false)
      console.log(err)
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const getItemsToRender = () => {
    return (searchedItems || props.monthlySubscriptionsListQuery.monthlySubscriptionsList && props.monthlySubscriptionsListQuery.monthlySubscriptionsList.items)
  }

  const items = getItemsToRender()
  const rows = createData(items)

  const emptyRows = page > 0 && rows ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0

  return (
    <Box sx={{ width: '100%' }}>
      <div className='data'>
        <EnhancedTableToolbar endDate={endDate}
                              executeSearch={executeSearch}
                              history={history}
                              searchText={searchText}
                              startDate={startDate}
                              setEndDate={setEndDate}
                              setSearchText={setSearchText}
                              setStartDate={setStartDate} />

        {(loadingSearch || !rows && props.monthlySubscriptionsListQuery && props.monthlySubscriptionsListQuery.loading) &&
          <CircularProgress />
        }

        {(!rows && props.monthlySubscriptionsListQuery && props.monthlySubscriptionsListQuery.error) || error &&
          <h2>Error</h2>
        }

        {rows && !loadingSearch &&
          <div>
            <TableContainer>
              <Table sx={{ minWidth: 750 }}
                     aria-labelledby="table-title"
                     size='medium'>

                <EnhancedTableHead order={order}
                                   orderBy={orderBy}
                                   onRequestSort={handleRequestSort}
                                   rowCount={rows.length}/>
                <TableBody>

                  {rows.slice().sort(getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`

                      const id = (<a href={'/subscription/' + row.id}>{row.id.substr(row.id.length-6)}</a>)
                      const user = (<a href={'/user/' + row.user.id}>{row.user.email}</a>)

                      return (
                        <TableRow hover
                                  role="checkbox"
                                  tabIndex={-1}
                                  key={row.id}>

                          <TableCell component="th"
                                     id={labelId}
                                     scope="row"
                                     padding="none">
                            {id}
                          </TableCell>
                          <TableCell align="left">{user}</TableCell>
                          <TableCell align="left">{row.purchasedBy}</TableCell>
                          <TableCell align="left">{row.subscriptionPackage}</TableCell>
                          <TableCell align="left">{row.startDate && moment(row.startDate).format('MM-DD-YYYY')}</TableCell>
                          <TableCell align="left">{row.endDate && moment(row.endDate).format('MM-DD-YYYY')}</TableCell>
                          <TableCell align="left">${row.purchasePrice}</TableCell>
                          <TableCell align="left">{row.remainingTicketsForPeriod}</TableCell>
                          <TableCell align="left">{row.currentPoints}</TableCell>
                          <TableCell align="left">{row.isActive}</TableCell>
                        </TableRow>
                      )
                    })}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }} >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}

                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination rowsPerPageOptions={[5, 10, 25, 50, 100]}
                             component="div"
                             count={rows.length}
                             rowsPerPage={rowsPerPage}
                             page={page}
                             onPageChange={handleChangePage}
                             showFirstButton={true}
                             showLastButton={true}
                             onRowsPerPageChange={handleChangeRowsPerPage}/>
          </div>
        }
      </div>
    </Box>
  )
}

const MONTHLY_SUBSCRIPTIONS_LIST_QUERY = gql`
  query MonthlySubscriptionsListQuery($first: Int, $skip: Int) {
    monthlySubscriptionsList(
      first: $first,
      skip: $skip,
      sort: { createdAt : DESC }
    ) {
      count

      items {
        id
        startDate
        endDate
        isActive
        timesRenewed
        currentPoints
        totalPoints
        remainingTicketsForPeriod
        numberOfTicketsPerPeriod

        orderDetails {
          id
          deliveryDate
          purchasePrice
          purchaserEmail
          subscriptionPackage
        }

        user {
          id
          firstName
          lastName
          email
        }
      }
    }
  }
`

export default withRouter(withApollo(graphql(MONTHLY_SUBSCRIPTIONS_LIST_QUERY, {
  name: 'monthlySubscriptionsListQuery',
  options: (ownProps) => {
    return {
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
      variables: { ...getParamsFromUrl(ownProps) }
    }
  }
})(SubscriptionList)))
