export const brBlack = '#271c21'
export const brRed = '#d50032'

const brGreen = '#00D523'
const brDarkGreen = '#008014'
const brSelectedGreen = '#00400c'

const brYellow = '#D5CA00'
const brDarkYellow = '#807901'
const brSelectedYellow = '#3f3d01'

const brRedLight = '#ff2a5d'
const brDarkRed = brRed
const brSelectedRed = '#ff6b8e'

export const CHIP_COLORS = {
  yes: {
    unselected: {
      avatarColor: 'black',
      avatarBackground: brDarkGreen,
      background: brGreen
    },
    selected: {
      avatarColor: 'white',
      avatarBackground: brSelectedGreen,
      background: brDarkGreen
    }
  },
  maybe: {
    unselected: {
      avatarColor: 'black',
      avatarBackground: brDarkYellow,
      background: brYellow
    },
    selected: {
      avatarColor: 'white',
      avatarBackground: brSelectedYellow,
      background: brDarkYellow
    }
  },
  no: {
    unselected: {
      avatarColor: 'black',
      avatarBackground: brDarkRed,
      background: brRedLight
    },
    selected: {
      avatarColor: 'white',
      avatarBackground: brSelectedRed,
      background: brDarkRed
    }
  }
}