import React, { useState, useEffect } from 'react'
import { gql } from 'apollo-boost'
import { graphql, withApollo } from 'react-apollo'
import moment from 'moment-timezone'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import { withRouter } from 'react-router'

import AdapterDateFns from '@mui/lab/AdapterDateFns'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Checkbox from '@mui/material/Checkbox'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'
import Divider from '@mui/material/Divider'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import FormLabel from '@mui/material/FormLabel'
import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import MenuItem from '@mui/material/MenuItem'
import MobileDatePicker from '@mui/lab/MobileDatePicker'
import Paper from '@mui/material/Paper'
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'

import Queries, { THEATERS_LIST_QUERY } from '../queries'


const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body1,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
}))

const ShowData = (props) => {
  const { show } = props

  const [locations, setLocations] = useState([])
  const [theaters, setTheaters] = useState([])
  const [ticketPickups, setTicketPickups] = useState([])

  const [requiredFieldBlankError, setRequiredFieldBlankError] = useState(false)
  const [saving, setSaving] = useState(false)

  const showPartnerDetails = show.showPartnerDetails
  const ticketPrices = show.ticketPrices

  const regularWeekday = show.ticketPrices ? ticketPrices.purchasePrice.regular.weekday : 0
  const regularWeekend = show.ticketPrices ? ticketPrices.purchasePrice.regular.weekend : 0

  const familyWeekday = show.ticketPrices ? ticketPrices.purchasePrice.family.weekday : 0
  const familyWeekend = show.ticketPrices ? ticketPrices.purchasePrice.family.weekend : 0

  const premiumWeekday = show.ticketPrices ? ticketPrices.purchasePrice.premium.weekday : 0
  const premiumWeekend = show.ticketPrices ? ticketPrices.purchasePrice.premium.weekend : 0

  const [showState, setShowState] = useState({
    blackoutDates: show.showBlackoutDates.items,
    dateAdded: show.dateAdded ? moment(show.dateAdded).format('YYYY-MM-DDTHH:MM') : null,
    description: show.description,
    duration: show.duration,
    isChildFriendly: show.isChildFriendly,
    isMusical: show.isMusical,
    location: show.location.name,
    name: show.name,
    recommendedAge: show.recommendedAge,
    runDateEnd: show.runDateEnd ? moment(show.runDateEnd).format('YYYY-MM-DDTHH:MM') : null,
    runDateStart: show.runDateStart ? moment(show.runDateStart).format('YYYY-MM-DDTHH:MM') : null,
    shortName: show.shortName,
    slug: show.slug,
    theater: show.theater ? show.theater.id: null,
    ticketPickup: show.ticketPickup ? show.ticketPickup.id : null,
    ticketPickupMatinee: show.ticketPickupMatinee ? show.ticketPickupMatinee.id : null,
  })

  const [showPartnerState, setShowPartnerState] = useState({
    isShowPartner: showPartnerDetails.isShowPartner,
    mondayMatinee: showPartnerDetails.mondayMatinee,
    mondayEvening: showPartnerDetails.mondayEvening,
    tuesdayMatinee: showPartnerDetails.tuesdayMatinee,
    tuesdayEvening: showPartnerDetails.tuesdayEvening,
    wednesdayMatinee: showPartnerDetails.wednesdayMatinee,
    wednesdayEvening: showPartnerDetails.wednesdayEvening,
    thursdayMatinee: showPartnerDetails.thursdayMatinee,
    thursdayEvening: showPartnerDetails.thursdayEvening,
    fridayMatinee: showPartnerDetails.fridayMatinee,
    fridayEvening: showPartnerDetails.fridayEvening,
    saturdayMatinee: showPartnerDetails.saturdayMatinee,
    saturdayEvening: showPartnerDetails.saturdayEvening,
    sundayMatinee: showPartnerDetails.sundayMatinee,
    sundayEvening: showPartnerDetails.sundayEvening
  })

  const handleSaveChanges = async () => {
    try {
      setSaving(true)

      var { blackoutDates, dateAdded, description, duration, isChildFriendly, isMusical, location, name,
            recommendedAge, runDateEnd, runDateStart, shortName, slug, theater, ticketPickup,
            ticketPickupMatinee, ticketPrices } = showState

      var { isShowPartner, mondayMatinee, mondayEvening, tuesdayMatinee, tuesdayEvening, wednesdayMatinee,
            wednesdayEvening, thursdayMatinee, thursdayEvening, fridayMatinee, fridayEvening, saturdayMatinee,
            saturdayEvening, sundayMatinee, sundayEvening } = showPartnerState

      if (!ticketPickup || !ticketPickupMatinee || !name || !shortName || !slug || !location) {
        setRequiredFieldBlankError(true)
        alert('Please fill in required fields')

        setSaving(false)
        return
      }

      var result = await props.client.mutate({
        mutation: SHOW_UPDATE_MUTATION,
        variables: {
          id: show.id,
          dateAdded: moment(dateAdded).format('YYYY-MM-DD'),
          description,
          duration,
          isChildFriendly,
          isMusical,
          location,
          name,
          recommendedAge,
          runDateEnd: moment(runDateEnd).format('YYYY-MM-DD'),
          runDateStart: moment(runDateStart).format('YYYY-MM-DD'),
          shortName,
          slug,
          theaterId: theater,
          ticketPickupId: ticketPickup,
          ticketPrices,
          ticketPickupMatineeId: ticketPickupMatinee
        }
      })

      await props.client.mutate({
        mutation: SHOW_PARTNER_DETAILS_UPDATE_MUTATION,
        variables: {
          id: showPartnerDetails.id,
          isShowPartner,
          mondayMatinee,
          mondayEvening,
          tuesdayMatinee,
          tuesdayEvening,
          wednesdayMatinee,
          wednesdayEvening,
          thursdayMatinee,
          thursdayEvening,
          fridayMatinee,
          fridayEvening,
          saturdayMatinee,
          saturdayEvening,
          sundayMatinee,
          sundayEvening
        }
      })

      alert('Show successfully updated')
      setSaving(false)

    } catch (err) {
      setSaving(false)
      alert(`Did not save, error: ${err}`)
    }
  }
  
  const handleUpdateCOGSField = (state, newValue) => {
    newValue = parseInt(newValue, 10)

    var newFamilyWeekday = showState.ticketPrices ? showState.ticketPrices.purchasePrice.family.weekday : 0
    var newFamilyWeekend = showState.ticketPrices ? showState.ticketPrices.purchasePrice.family.weekend : 0

    var newPremiumWeekday = showState.ticketPrices ? showState.ticketPrices.purchasePrice.premium.weekday : 0
    var newPremiumWeekend = showState.ticketPrices ? showState.ticketPrices.purchasePrice.premium.weekend : 0

    var newRegularWeekday = showState.ticketPrices ? showState.ticketPrices.purchasePrice.regular.weekday : 0
    var newRegularWeekend = showState.ticketPrices ? showState.ticketPrices.purchasePrice.regular.weekend : 0

    switch(state) {
      case "family.weekday":
        newFamilyWeekday = newValue
        break;
      case "family.weekend":
        newFamilyWeekend = newValue
        break;
      case "premium.weekday":
        newPremiumWeekday = newValue
        break;
      case "premium.weekend":
        newPremiumWeekend = newValue
        break;
      case "regular.weekday":
        newRegularWeekday = newValue
        break;
      case "regular.weekend":
        newRegularWeekend = newValue
        break;
      default:
        console.log(state + " is invalid")
    }

    var data = {
      purchasePrice: {
        family: {
          weekday: newFamilyWeekday,
          weekend: newFamilyWeekend
        },
        premium: {
          weekday: newPremiumWeekday,
          weekend: newPremiumWeekend
        },
        regular: {
          weekday: newRegularWeekday,
          weekend: newRegularWeekend
        },
      }
    }

    handleUpdateShowState('ticketPrices', data)
  }

  const handleUpdateShowState = (key, value) => {
    var newShowState = {...showState}

    newShowState[key] = value
    setShowState(newShowState)
  }

  const handleUpdateShowPartnerState = (key, value) => {
    var newShowPartnerState = {...showPartnerState}

    newShowPartnerState[key] = value
    setShowPartnerState(newShowPartnerState)
  }

  const loadTheaters = async () => {
    try {
      var theatersQuery = await props.client.query({ query: THEATERS_LIST_QUERY })
      setTheaters(theatersQuery.data.theatersList.items)

    } catch (err) {
      console.log('Unable to get theater data')
    }
  }

  const loadTicketPickups = async () => {
    try {
      var ticketPickupsQuery = await props.client.query({ query: TICKET_PICKUPS_LIST_QUERY })
      setTicketPickups(ticketPickupsQuery.data.ticketPickupsList.items)

    } catch (err) {
      console.log('Unable to get ticket pickup data')
    }
  }

  const loadLocations = async () => {
    try {
      var locationsQuery = await props.client.query({ query: LOCATIONS_LIST_QUERY })
      setLocations(locationsQuery.data.locationsList.items)

    } catch (err) {
      console.log('Unable to get locations data')
    }
  }

  useEffect(() => {
    loadLocations()
    loadTicketPickups()
    loadTheaters()
  }, [])

  return (
    <Item className="order-tile">
      <Typography sx={{ flex: '1 1 100%' }} variant="h5" className="order-tile-subtitle" component="div">
        {show.name}
      </Typography>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header">
          <Typography className="accordion-title">Basic Details</Typography>
          <Divider />
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <Divider />

            <Stack justifyContent="space-around" direction="row" flexWrap="wrap">

              <Stack direction="column">
                <h3>Details</h3>

                <div className="padding first-entry">
                  <TextField label="ID"
                             defaultValue={show.id}
                             disabled={true} />
                </div>

                <div className="padding">
                  <TextField label="Name"
                             helperText="*required field"
                             error={requiredFieldBlankError && !showState.name}
                             defaultValue={showState.name}
                             onChange={(e) => { handleUpdateShowState('name', e.target.value) }} />
                </div>

                <div className="padding">
                  <TextField label="Short Name"
                             helperText="*required field"
                             error={requiredFieldBlankError && !showState.shortName}
                             defaultValue={showState.shortName}
                             onChange={(e) => { handleUpdateShowState('shortName', e.target.value) }} />
                </div>

                <div className="padding">
                  <TextField label="Slug"
                             helperText="*required field"
                             error={requiredFieldBlankError && !showState.slug}
                             defaultValue={showState.slug}
                             onChange={(e) => { handleUpdateShowState('slug', e.target.value) }} />
                </div>

                <div className="padding">
                  <FormControlLabel
                    control={
                      <Checkbox checked={showState.isChildFriendly}
                                onChange={(e) => { handleUpdateShowState('isChildFriendly', e.target.checked) }} />
                    }
                    label="Is child friendly" />

                  <FormControlLabel
                    control={
                      <Checkbox checked={showState.isMusical}
                                onChange={(e) => { handleUpdateShowState('isMusical', e.target.checked) }} />
                    }
                    label="Is musical" />
                </div>
              </Stack>

              <Stack direction="column">
                <h3>Copy</h3>

                <div className="padding">
                  <TextField label="Duration"
                             defaultValue={showState.duration}
                             onChange={(e) => { handleUpdateShowState('duration', e.target.value) }} />
                </div>

                <div className="padding">
                  <TextField label="Recommended Age"
                             defaultValue={showState.recommendedAge}
                             onChange={(e) => { handleUpdateShowState('recommendedAge', e.target.value) }} />
                </div>

                <div className="padding">
                  <TextField label="Description"
                             defaultValue={showState.description}
                             multiline
                             maxRows={10}
                             onChange={(e) => { handleUpdateShowState('description', e.target.value) }} />
                </div>
              </Stack>

              <Stack direction="column">
                <h3>Location & Pickup</h3>

                <div className="padding flex-basis-0">
                  <FormControl error={requiredFieldBlankError && !showState.location}>
                    <InputLabel>Location</InputLabel>
                    <Select value={showState.location}
                            label="Location"
                            onChange={(event) => handleUpdateShowState('location', event.target.value)}>

                        {locations && locations.map((locationMap, idx) => (
                          <MenuItem key={idx} value={locationMap.name}> {locationMap.name} </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>*required field</FormHelperText>
                  </FormControl>
                </div>

                <div className="padding">
                  <FormControl error={requiredFieldBlankError && !showState.theater}>
                    <InputLabel>Theater</InputLabel>
                    <Select value={showState.theater}
                            label="Theater"
                            onChange={(event) => handleUpdateShowState('theater', event.target.value)}>

                        {theaters && theaters.map((theatersMap, idx) => (
                          <MenuItem key={idx} value={theatersMap.id}> {theatersMap.name} </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>*required field</FormHelperText>
                  </FormControl>
                </div>

                <div className="padding">
                  <FormControl error={requiredFieldBlankError && !showState.ticketPickup}>
                    <InputLabel>Ticket Pickup</InputLabel>
                    <Select value={showState.ticketPickup}
                            label="Ticket Pickup"
                            onChange={(event) => handleUpdateShowState('ticketPickup', event.target.value)}>

                        {ticketPickups && ticketPickups.map((ticketPickupMap, idx) => (
                          <MenuItem key={idx} value={ticketPickupMap.id}> {ticketPickupMap.name} </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>*required field</FormHelperText>
                  </FormControl>
                </div>

                <div className="padding">
                  <FormControl error={requiredFieldBlankError && !showState.ticketPickupMatinee}>
                    <InputLabel>Matinee Ticket Pickup</InputLabel>
                    <Select value={showState.ticketPickupMatinee}
                            label="Matinee Ticket Pickup"
                            onChange={(event) => handleUpdateShowState('ticketPickupMatinee', event.target.value)}>

                        {ticketPickups && ticketPickups.map((ticketPickupMap, idx) => (
                          <MenuItem key={idx} value={ticketPickupMap.id}> {ticketPickupMap.name} </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>*required field</FormHelperText>
                  </FormControl>
                </div>
              </Stack>

            </Stack>
          </div>

        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header">
          <Typography className="accordion-title">Show Partner Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Divider />

          <Stack justifyContent="center" direction="row" flexWrap="wrap">
            <FormControlLabel
              control={
                <Switch
                  checked={showPartnerState.isShowPartner}
                  onChange={(e, b) => { this._handleChangeIsShowPartner(b) }}
                />
              }
              label={"Is a Show Partner"}
            />
          </Stack>

          {showPartnerState.isShowPartner &&
            <Stack justifyContent="space-around" direction="row" flexWrap="wrap">

              <Stack direction="column">
                <h3>Matinee</h3>

                <FormControlLabel
                  control={
                    <Switch
                      checked={showPartnerState.mondayMatinee}
                      onChange={(e, b) => { handleUpdateShowPartnerState('mondayMatinee', b) }}
                    />
                  }
                  label={"Monday Matinee"}
                />

                <FormControlLabel
                  control={
                    <Switch
                      checked={showPartnerState.tuesdayMatinee}
                      onChange={(e, b) => { handleUpdateShowPartnerState('tuesdayMatinee', b) }}
                    />
                  }
                  label={"Tuesday Matinee"}
                />

                <FormControlLabel
                  control={
                    <Switch
                      checked={showPartnerState.wednesdayMatinee}
                      onChange={(e, b) => { handleUpdateShowPartnerState('wednesdayMatinee', b) }}
                    />
                  }
                  label={"Wednesday Matinee"}
                />

                <FormControlLabel
                  control={
                    <Switch
                      checked={showPartnerState.thursdayMatinee}
                      onChange={(e, b) => { handleUpdateShowPartnerState('thursdayMatinee', b) }}
                    />
                  }
                  label={"Thursday Matinee"}
                />

                <FormControlLabel
                  control={
                    <Switch
                      checked={showPartnerState.fridayMatinee}
                      onChange={(e, b) => { handleUpdateShowPartnerState('fridayMatinee', b) }}
                    />
                  }
                  label={"Friday Matinee"}
                />

                <FormControlLabel
                  control={
                    <Switch
                      checked={showPartnerState.saturdayMatinee}
                      onChange={(e, b) => { handleUpdateShowPartnerState('saturdayMatinee', b) }}
                    />
                  }
                  label={"Saturday Matinee"}
                />

                <FormControlLabel
                  control={
                    <Switch
                      checked={showPartnerState.sundayMatinee}
                      onChange={(e, b) => { handleUpdateShowPartnerState('sundayMatinee', b) }}
                    />
                  }
                  label={"Sunday Matinee"}
                />
              </Stack>

              <Stack direction="column">
                <h3>Evening</h3>

                <FormControlLabel
                  control={
                    <Switch
                      checked={showPartnerState.mondayEvening}
                      onChange={(e, b) => { handleUpdateShowPartnerState('mondayEvening', b) }}
                    />
                  }
                  label={"Monday Evening"}
                />

                <FormControlLabel
                  control={
                    <Switch
                      checked={showPartnerState.tuesdayEvening}
                      onChange={(e, b) => { handleUpdateShowPartnerState('tuesdayEvening', b) }}
                    />
                  }
                  label={"Tuesday Evening"}
                />

                <FormControlLabel
                  control={
                    <Switch
                      checked={showPartnerState.wednesdayEvening}
                      onChange={(e, b) => { handleUpdateShowPartnerState('wednesdayEvening', b) }}
                    />
                  }
                  label={"Wednesday Evening"}
                />

                <FormControlLabel
                  control={
                    <Switch
                      checked={showPartnerState.thursdayEvening}
                      onChange={(e, b) => { handleUpdateShowPartnerState('thursdayEvening', b) }}
                    />
                  }
                  label={"Thursday Evening"}
                />

                <FormControlLabel
                  control={
                    <Switch
                      checked={showPartnerState.fridayEvening}
                      onChange={(e, b) => { handleUpdateShowPartnerState('fridayEvening', b) }}
                    />
                  }
                  label={"Friday Evening"}
                />

                <FormControlLabel
                  control={
                    <Switch
                      checked={showPartnerState.saturdayEvening}
                      onChange={(e, b) => { handleUpdateShowPartnerState('saturdayEvening', b) }}
                    />
                  }
                  label={"Saturday Evening"}
                />

                <FormControlLabel
                  control={
                    <Switch
                      checked={showPartnerState.sundayEvening}
                      onChange={(e, b) => { handleUpdateShowPartnerState('sundayEvening', b) }}
                    />
                  }
                  label={"Sunday Evening"}
                />
              </Stack>
            </Stack>
          }


        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header">
          <Typography className="accordion-title">Dates</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Divider />

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack spacing={2} direction="row" className="hidemobile stack padding" justifyContent="space-around" flexWrap="wrap">

              <div className="flex-basis-0">
                <DesktopDatePicker label="Date Added"
                                   inputFormat="MM/dd/yyyy"
                                   value={showState.dateAdded}
                                   onChange={(newValue) => handleUpdateShowState('dateAdded', newValue)}
                                   clearable={true}
                                   renderInput={(params) => <TextField {...params} />} />
                <p className="small-text">
                  *Orders placed on or before this date will not have this show as a ticket assignments option
                </p>
              </div>

              <DesktopDatePicker label="Show Start Date"
                                 inputFormat="MM/dd/yyyy"
                                 value={showState.runDateStart}
                                 onChange={(newValue) => handleUpdateShowState('runDateStart', newValue)}
                                 clearable={true}
                                 renderInput={(params) => <TextField {...params} />} />

              <DesktopDatePicker label="Show End Date"
                                 inputFormat="MM/dd/yyyy"
                                 value={showState.runDateEnd}
                                 onChange={(newValue) => handleUpdateShowState('runDateEnd', newValue)}
                                 clearable={true}
                                 renderInput={(params) => <TextField {...params} />} />
            </Stack>

            <Stack spacing={2} direction="column" className="showmobile stack padding" justifyContent="center">
              <div className="flex-basis-0">
                <MobileDatePicker label="Date Added"
                                   inputFormat="MM/dd/yyyy"
                                   value={showState.dateAdded}
                                   onChange={(newValue) => handleUpdateShowState('dateAdded', newValue)}
                                   clearable={true}
                                   renderInput={(params) => <TextField {...params} />} />
               <p>*Orders placed on or before this date will not have this show as a ticket assignments option</p>
              </div>

              <MobileDatePicker label="Show Start Date"
                                 inputFormat="MM/dd/yyyy"
                                 value={showState.runDateStart}
                                 onChange={(newValue) => handleUpdateShowState('runDateStart', newValue)}
                                 clearable={true}
                                 renderInput={(params) => <TextField {...params} />} />

              <MobileDatePicker label="Show End Date"
                                 inputFormat="MM/dd/yyyy"
                                 value={showState.runDateEnd}
                                 onChange={(newValue) => handleUpdateShowState('runDateEnd', newValue)}
                                 clearable={true}
                                 renderInput={(params) => <TextField {...params} />} />
            </Stack>
          </LocalizationProvider>

        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header">
          <Typography className="accordion-title">Ticket Prices for COGS in Algorithm</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Divider />

          <Stack justifyContent="space-around" direction="row" flexWrap="wrap">
            <Stack direction="column">
              <h3>Weekday</h3>
              <div className="padding">
                <TextField defaultValue={regularWeekday}
                           label="Regular Ticket - Weekday"
                           onChange={(e, val) => { handleUpdateCOGSField("regular.weekday", e.target.value) }} />
              </div>

              <div className="padding">
                <TextField defaultValue={familyWeekday}
                           label="Family Ticket - Weekday"
                           onChange={(e, val) => { handleUpdateCOGSField("family.weekday", e.target.value) }} />
              </div>

              <div className="padding">
                <TextField defaultValue={premiumWeekday}
                           label="Premium Ticket - Weekday"
                           onChange={(e, val) => { handleUpdateCOGSField("premium.weekday", e.target.value) }} />
              </div>
            </Stack>

            <Stack justifyContent="space-around" direction="column" flexWrap="wrap">
              <h3>Weekend</h3>
              <div className="padding">
                <TextField defaultValue={regularWeekend}
                           label="Regular Ticket - Weekend"
                           onChange={(e, val) => { handleUpdateCOGSField("regular.weekend", e.target.value) }} />
              </div>

              <div className="padding">
                <TextField defaultValue={familyWeekend}
                           label="Family Ticket - Weekend"
                           onChange={(e, val) => { handleUpdateCOGSField("family.weekend", e.target.value) }} />
              </div>

              <div className="padding">
                <TextField defaultValue={premiumWeekend}
                           label="Premium Ticket - Weekend"
                           onChange={(e, val) => { handleUpdateCOGSField("premium.weekend", e.target.value) }} />
              </div>
            </Stack>
          </Stack>

        </AccordionDetails>
      </Accordion>

      {!saving &&
        <div className="save-button">
          <Button variant="contained" onClick={handleSaveChanges}>
            Save changes
          </Button>
        </div>
      }

      {saving &&
        <CircularProgress />
      }

    </Item>
  )
}

function ShowDetails(props) {
  const [loading, setLoading] = useState(true)
  const [updating, setUpdating] = useState(false)
  const [error, setError] = useState(false)

  const [show, setShow] = useState([])

  const getShowData = () => {
    try {
      var show = props.showQuery.show

      if (show) {
        setShow(show)
        setLoading(false)
      }

    } catch(err) {
      console.log(err)
      setError(true)
    }
  }

  useEffect(async () => {
    if (loading) {
      await getShowData()
    }
  }, [props.showQuery, loading])

  return (
    <Box sx={{ width: '100%' }}>
      <div className='data'>

        <Typography sx={{ flex: '1 1 100%' }} variant="h3" className="page-title" component="div">
          Show Details
        </Typography>

        {loading &&
          <CircularProgress />
        }

        {!show && error &&
          <h2>Error</h2>
        }

        {show && !loading &&
          <ShowData show={show} client={props.client} />
        }

      </div>
    </Box>
  )
}

const SHOW_QUERY = gql`
  query ShowQuery($id: ID) {
    show(id: $id) {
      id
      createdAt
      dateAdded
      isChildFriendly
      isMusical
      description
      duration
      name
      recommendedAge
      runDateStart
      runDateEnd
      shortName
      slug
      ticketPrices

      location {
        id
        name
      }

      theater {
        id
        name
      }

      ticketPickup {
        id
        name
      }

      ticketPickupMatinee {
        id
        name
      }

      showBlackoutDates {
        items {
          date
        }
      }

      showPartnerDetails {
        id
        isShowPartner
        mondayMatinee
        mondayEvening
        tuesdayMatinee
        tuesdayEvening
        wednesdayMatinee
        wednesdayEvening
        thursdayMatinee
        thursdayEvening
        fridayMatinee
        fridayEvening
        saturdayMatinee
        saturdayEvening
        sundayMatinee
        sundayEvening
      }
    }
  }
`

const SHOW_UPDATE_MUTATION = gql`
  mutation ShowUpdateMutation($id: ID!, $isChildFriendly: Boolean, $isMusical: Boolean, $dateAdded: Date, $description: String,
                              $duration: String, $name: String, $recommendedAge: String, $shortName: String,
                              $location: String, $theaterId: ID!, $ticketPickupId: ID!, $ticketPickupMatineeId: ID,
                              $slug: String, $runDateEnd: Date, $runDateStart: Date, $ticketPrices: JSON) {
    showUpdate(data: {
      id: $id,
      isChildFriendly: $isChildFriendly,
      isMusical: $isMusical,
      dateAdded: $dateAdded,
      description: $description,
      duration: $duration,
      name: $name,
      recommendedAge: $recommendedAge,
      runDateEnd: $runDateEnd,
      runDateStart: $runDateStart,
      shortName: $shortName,
      slug: $slug,
      ticketPrices: $ticketPrices,

      location: {
        connect: { name: $location }
      }

      theater: {
        connect: { id: $theaterId }
      },

      ticketPickup: {
        connect: { id: $ticketPickupId }
      },

      ticketPickupMatinee: {
        connect: { id: $ticketPickupMatineeId }
      }
    }) {
      id
      ticketPrices
    }
  }
`

const LOCATIONS_LIST_QUERY = gql`
  query LocationsListQuery {
    locationsList(sort: { name: ASC }) {
      items {
        id
        name
      }
    }
  }
`

const SHOW_PARTNER_DETAILS_UPDATE_MUTATION = gql`
  mutation ShowPartnerDetailsUpdateMutation($id: ID!, $isShowPartner: Boolean!,
                              $mondayMatinee: Boolean!, $mondayEvening: Boolean!,
                              $tuesdayMatinee: Boolean!, $tuesdayEvening: Boolean!,
                              $wednesdayMatinee: Boolean!, $wednesdayEvening: Boolean!,
                              $thursdayMatinee: Boolean!, $thursdayEvening: Boolean!,
                              $fridayMatinee: Boolean!, $fridayEvening: Boolean!,
                              $saturdayMatinee: Boolean!, $saturdayEvening: Boolean!,
                              $sundayMatinee: Boolean!, $sundayEvening: Boolean!) {
    showPartnerDetailUpdate(data: {
      id: $id
      isShowPartner: $isShowPartner,
      mondayMatinee: $mondayMatinee,
      mondayEvening: $mondayEvening,
      tuesdayMatinee: $tuesdayMatinee,
      tuesdayEvening: $tuesdayEvening,
      wednesdayMatinee: $wednesdayMatinee,
      wednesdayEvening: $wednesdayEvening,
      thursdayMatinee: $thursdayMatinee,
      thursdayEvening: $thursdayEvening,
      fridayMatinee: $fridayMatinee,
      fridayEvening: $fridayEvening,
      saturdayMatinee: $saturdayMatinee,
      saturdayEvening: $saturdayEvening,
      sundayMatinee: $sundayMatinee,
      sundayEvening: $sundayEvening
    }) {
      id
    }
  }
`

const SHOW_BLACKOUT_DATES_LIST_QUERY = gql`
  query ShowBlackOutDatesListQuery($showId: ID!, $startOfPeriod: Date!, $endOfPeriod: Date!) {
    showBlackoutDatesList(
      sort: { date : ASC},
      filter: { 
        show: { id: { equals: $showId }},
        AND: [{
                date: { gte: $startOfPeriod }
              },
              {
                date: { lte: $endOfPeriod }
              }]
      }
    ) {
      items {
        id
        date
      }
    }
  }
`

const SHOW_BLACKOUT_DATE_CREATE_MUTATION = gql`
  mutation ShowBlackoutDateCreateMutation($showId: ID!, $date: String!) {
    createShowBlackoutDate(showId: $showId, date: $date)
    {
      id
      date
    }
  }
`

const SHOW_BLACKOUT_DATE_DELETE_MUTATION = gql`
  mutation ShowBlackoutDateDeleteMutation($id: ID!) {
    showBlackoutDateDelete(id: $id)
    {
      id
    }
  }
`

const TICKET_PICKUPS_LIST_QUERY = gql`
  query TicketPickupsListQuery {
    ticketPickupsList {
      items {
        id
        name
      }
    }
  }
`

export default withApollo(graphql(SHOW_QUERY, {
  name: 'showQuery',
  options: (ownProps) => {
    const id = ownProps.match.params.id

    return {
      variables: { id },
      notifyOnNetworkStatusChange: true
    }
  }
})(ShowDetails))
