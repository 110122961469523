import React, { useState, useEffect } from 'react'
import { gql } from 'apollo-boost'
import { graphql, withApollo } from 'react-apollo'
import moment from 'moment-timezone'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import { withRouter } from 'react-router'

import AdapterDateFns from '@mui/lab/AdapterDateFns'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { alpha } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import CheckIcon from '@mui/icons-material/Check'
import CircularProgress from '@mui/material/CircularProgress'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'
import FilterListIcon from '@mui/icons-material/FilterList'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import IconButton from '@mui/material/IconButton'
import InputLabel from '@mui/material/InputLabel'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import MenuItem from '@mui/material/MenuItem'
import MobileDatePicker from '@mui/lab/MobileDatePicker'
import Paper from '@mui/material/Paper'
import Popover from '@mui/material/Popover'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import TextField from '@mui/material/TextField'
import Toolbar from '@mui/material/Toolbar'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { visuallyHidden } from '@mui/utils'

import {
  getBeginningOfTimeStrYYYYMMDD,
  getTenYearsFromNowYYYYMMDD,
  getParamsFromUrl,
  getTodayStrYYYYMMDD
} from '../utils'

import { brRed } from '../colors'
import { ALLOWABLE_DAYS_AHEAD_TO_PLACE_ORDER } from '../constants'


const FILTER_ADMINS = `
  {AND: [{
    user: {
      email: { not_equals: "tracy@broadwayroulette.com" }
    }
  }, {
    user: {
      email: { not_contains: "tracythuynh" }
    }
  }, {
    user: {
      email: { not_equals: "orders@broadwayroulette.com" }
    }
  }, {
    user: {
      email: { not_equals: "devteam@broadwayroulette.com" }
    }
  }]}
`

const headCells = [
  {
    id: 'id',
    label: 'Id',
  },
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'address',
    label: 'Address',
  },
  {
    id: 'shows',
    label: 'Shows',
  },
  {
    id: 'location',
    label: 'Location',
  },
]

const getDateRange = (ownProps) => {
  const queryParams = queryString.parse(ownProps.location.search)

  var startOfPeriod = queryParams.startDate
    ? moment(queryParams.startDate).format('YYYY-MM-DD')
    : getBeginningOfTimeStrYYYYMMDD

  var endOfPeriod = queryParams.endDate
    ? moment(queryParams.endDate).format('YYYY-MM-DD')
    : getTenYearsFromNowYYYYMMDD

  return [startOfPeriod, endOfPeriod]
}

function createData(theaters) {
  var formattedData = theaters && theaters.map(theater => {
    const shows = theater.shows && theater.shows.items

    return {
      id: theater.id,
      name: theater.name,
      address: theater.address,
      shows: shows,
      location: theater.location.name
    }
  })

  return formattedData
}

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

const FilterPopover = (props) => {
  const {
    executeSearch,
    location,
    searchText,
    setLocation,
    setSearchText,
    setTheaterName
  } = props.data

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (theater) => {
    setAnchorEl(theater.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClickButton = (theater) => {
    executeSearch()
    handleClose()
  }

  const open = Boolean(anchorEl)
  const id = open ? 'popover' : undefined

  return (
    <div>

      <Tooltip title="Filter list">
        <IconButton aria-describedby={id} onClick={handleClick}>
          <FilterListIcon />
        </IconButton>
      </Tooltip>

      <Popover id={id}
               open={open}
               anchorEl={anchorEl}
               onClose={handleClose}
               anchorOrigin={{
                 vertical: 'bottom',
                 horizontal: 'left',
               }}>

        <div className="filters">
          <h3>Filters</h3>
          <Stack spacing={3} direction="column">

            <div>
              <TextField id="outlined-basic"
                         label="Theater"
                         variant="outlined"
                         onChange={(theater) => setSearchText(theater.target.value)} />
            </div>

            <div>
              <Stack spacing={2} direction="row">

                <FormControl fullWidth>
                  <InputLabel id="select-label">Location</InputLabel>
                  <Select labelId="select-label"
                          id="select"
                          value={location}
                          label="Location"
                          onChange={(theater) => setLocation(theater.target.value)}>
                    <MenuItem value="Broadway">Broadway</MenuItem>
                    <MenuItem value="West End">West End</MenuItem>
                  </Select>
                </FormControl>

              </Stack>
            </div>

            <Button variant="contained" onClick={handleClickButton}>Search</Button>
          </Stack>
        </div>

      </Popover>
    </div>
  )
}

const EnhancedTableHead = (props) => {
  const { order, orderBy, rowCount, onRequestSort } = props

  const createSortHandler = (property) => (theater) => {
    onRequestSort(theater, property)
  }

  return (
    <TableHead>
      <TableRow>

        {headCells.map((headCell) => (
          <TableCell key={headCell.id}
                     align='left'
                     padding={'normal'}
                     sortDirection={orderBy === headCell.id ? order : false}>

            <TableSortLabel active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>

          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const EnhancedTableToolbar = (props) => {
  const { history } = props

  const handleClickNew = async () => {
    try {
      const result = await props.client.mutate({
        mutation: THEATER_CREATE_MUTATION
      })

      history.push(`/theater/${result.data.theaterCreate.id}`)
    } catch(err) {
      alert(`Unable to create theater: ${err.message}`)
    }
  }

  return (
    <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 }}}>
      <Typography sx={{ flex: '1 1 100%' }} variant="h3" className="page-title" component="div">
        Theaters <AddCircleIcon fontSize="large" onClick={() => { handleClickNew() }} />
      </Typography>

      <FilterPopover data={props}/>
    </Toolbar>
  )
}

function TheaterList(props) {
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('name')

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const [location, setLocation] = useState('Broadway')
  const [searchText, setSearchText] = useState('')

  const [loadingSearch, setLoadingSearch] = useState(false)
  const [error, setError] = useState(false)
  const [searchedItems, setSearchedItems] = useState(null)

  const history = useHistory()

  const executeSearch = async () => {
    try {
      setLoadingSearch(true)

      var filterTheaterQuery = gql`
        query TheatersListQuery($searchText: String, $location: String) {
          theatersList (
            first: 1000,
            sort: { createdAt : DESC },
            filter: {
              AND: [
                { location: { name: { equals: $location }}}

                {OR: [
                  { name: { contains: $searchText }},
                  { address: { contains: $searchText }},
                ]},
              ]
            }
          ) {
            items {
              id
              address
              name
              slug

              location {
                id
                name
              }
            }
          }
        }
      `

      var result = await props.client.query({
        query: filterTheaterQuery,
        variables: {
          searchText,
          location,
        }
      })

      const theaters = result.data.theatersList.items
      setSearchedItems(theaters)

      setSearchText('')
      setLocation('Broadway')
      setEndDate(null)
      setStartDate(null)
      setLoadingSearch(false)

    } catch(err) {
      setError(true)
      setLoadingSearch(false)
      console.log(err)
    }
  }

  const handleChangePage = (theater, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (theater) => {
    setRowsPerPage(parseInt(theater.target.value, 10))
    setPage(0)
  }

  const handleRequestSort = (theater, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const getItemsToRender = () => {
    return (searchedItems || props.theatersListQuery.theatersList && props.theatersListQuery.theatersList.items)
  }

  const items = getItemsToRender()
  const rows = createData(items)

  const emptyRows = page > 0 && rows ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0

  return (
    <Box sx={{ width: '100%' }}>
      <div className='data'>
        <EnhancedTableToolbar client={props.client}
                              executeSearch={executeSearch}
                              history={history}
                              location={location}
                              searchText={searchText}
                              setLocation={setLocation}
                              setSearchText={setSearchText} />

        {(loadingSearch || !rows && props.theatersListQuery && props.theatersListQuery.loading) &&
          <CircularProgress />
        }

        {(!rows && props.theatersListQuery && props.theatersListQuery.error) || error &&
          <h2>Error</h2>
        }

        {rows && !loadingSearch &&
          <div>
            <TableContainer>
              <Table sx={{ minWidth: 750 }}
                     aria-labelledby="table-title"
                     size='medium'>

                <EnhancedTableHead order={order}
                                   orderBy={orderBy}
                                   onRequestSort={handleRequestSort}
                                   rowCount={rows.length}/>
                <TableBody>

                  {rows.slice().sort(getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`

                      const id = (<a href={'/theater/' + row.id}>{row.id}</a>)
                      const theaterName = (<a href={'/theater/' + row.id}>{row.name}</a>)
                      const shows = row.shows && row.shows.map((show, idx) => {
                        return (
                          <a href={'/show/' + show.id}>
                            {idx === row.shows.length -1 ? show.shortName : show.shortName + ", "}
                          </a>
                        )
                      })

                      return (
                        <TableRow hover
                                  role="checkbox"
                                  tabIndex={-1}
                                  key={row.id}>

                          <TableCell component="th"
                                     id={labelId}
                                     scope="row"
                                     padding="none">
                            {id}
                          </TableCell>
                          <TableCell align="left">{theaterName}</TableCell>
                          <TableCell align="left">{row.address}</TableCell>
                          <TableCell align="left">{shows}</TableCell>
                          <TableCell align="left">{row.location.name}</TableCell>
                        </TableRow>
                      )
                    })}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }} >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}

                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination rowsPerPageOptions={[5, 10, 25, 50, 100]}
                             component="div"
                             count={rows.length}
                             rowsPerPage={rowsPerPage}
                             page={page}
                             onPageChange={handleChangePage}
                             showFirstButton={true}
                             showLastButton={true}
                             onRowsPerPageChange={handleChangeRowsPerPage}/>
          </div>
        }
      </div>
    </Box>
  )
}

const THEATERS_LIST_QUERY = gql`
  query TheatersListQuery {
    theatersList(
      sort: { name: ASC },
      filter: {
        isForOffBroadway: { equals: false }
      }
    ) {
      items {
        id
        createdAt
        name
        address

        location {
          id
          name
        }

        shows {
          items {
            id
            shortName
          }
        }
      }
    }
  }
`

const THEATER_CREATE_MUTATION = gql`
  mutation TheaterCreateMutation {
    theaterCreate(data: {
      name: "Fill me out",
      address: "Fill me out",

      location: {
        connect: { name: "Broadway" }
      }
    })
    {
      id
    }
  }
`

export default withRouter(withApollo(graphql(THEATERS_LIST_QUERY, { 
  name: 'theatersListQuery',
  options: () => {
    return {
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    }
  }
})(TheaterList)))