import moment from 'moment-timezone'

import { ENTRIES_PER_PAGE } from './constants'

export function getDateStrMMDDYYYY(date) {
  return moment(date).format('MM-DD-YYYY')
}

export function getDateStrYYYYMMDD(date) {
  return moment(date).format('YYYY-MM-DD')
}

export function getDateTimeStr(date) {
  return moment(date).format('MM-DD-YYYY h:mm:ss a')
}

export function getDaysUntil(startDate, endDate) {
  return endDate.diff(startDate, 'days')
}

export function getTodayStrMMDDYYYY() {
  return moment().format('MM-DD-YYYY')
}

export function getTodayStrYYYYMMDD() {
  return moment().format('YYYY-MM-DD')
}

export function getBeginningOfTimeStrYYYYMMDD() {
  return moment('1970-01-01').format('YYYY-MM-DD')
}

export function getTenYearsFromNowYYYYMMDD() {
  return moment().add(10, 'years').format('YYYY-MM-DD')
}

export function getLocalDateStr(date) {
  if (!date) { return '' }
  return moment(date).format('MM-DD-YYYY hh:mm a')
}

export function getParamsFromUrl(ownProps) {
  const page = parseInt(ownProps.match.params.page, 10)
  const skip = (page - 1) * ENTRIES_PER_PAGE
  const first = ENTRIES_PER_PAGE
  const orderBy = "createdAt_DESC"

  return { first, skip, orderBy }
}

