import React, { useState, useEffect } from 'react'
import { gql } from 'apollo-boost'
import { graphql, withApollo } from 'react-apollo'
import moment from 'moment-timezone'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import { withRouter } from 'react-router'

import AdapterDateFns from '@mui/lab/AdapterDateFns'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { alpha } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import CheckIcon from '@mui/icons-material/Check'
import CircularProgress from '@mui/material/CircularProgress'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'
import FilterListIcon from '@mui/icons-material/FilterList'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import IconButton from '@mui/material/IconButton'
import InputLabel from '@mui/material/InputLabel'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import MenuItem from '@mui/material/MenuItem'
import MobileDatePicker from '@mui/lab/MobileDatePicker'
import Paper from '@mui/material/Paper'
import Popover from '@mui/material/Popover'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import TextField from '@mui/material/TextField'
import Toolbar from '@mui/material/Toolbar'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { visuallyHidden } from '@mui/utils'

import {
  getBeginningOfTimeStrYYYYMMDD,
  getTenYearsFromNowYYYYMMDD,
  getParamsFromUrl,
  getTodayStrYYYYMMDD
} from '../utils'

import { brRed } from '../colors'
import { ALLOWABLE_DAYS_AHEAD_TO_PLACE_ORDER } from '../constants'


const FILTER_ADMINS = `
  {AND: [{
    user: {
      email: { not_equals: "tracy@broadwayroulette.com" }
    }
  }, {
    user: {
      email: { not_contains: "tracythuynh" }
    }
  }, {
    user: {
      email: { not_equals: "orders@broadwayroulette.com" }
    }
  }, {
    user: {
      email: { not_equals: "devteam@broadwayroulette.com" }
    }
  }]}
`

const headCells = [
  {
    id: 'id',
    label: 'Id',
  },
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'category',
    label: 'Category',
  },
  {
    id: 'type',
    label: 'Type',
  },
  {
    id: 'venueName',
    label: 'Venue',
  },
  {
    id: 'startDate',
    label: 'Start Date',
  },
  {
    id: 'endDate',
    label: 'End Date',
  },
  {
    id: 'recommendedAge',
    label: 'Recommended Age',
  },
  {
    id: 'location',
    label: 'Location',
  },
]

const getDateRange = (ownProps) => {
  const queryParams = queryString.parse(ownProps.location.search)

  var startOfPeriod = queryParams.startDate
    ? moment(queryParams.startDate).format('YYYY-MM-DD')
    : getBeginningOfTimeStrYYYYMMDD

  return [startOfPeriod, endOfPeriod]
}

function createData(events) {
  var formattedData = events && events.map(event => {
    const venue = event.venue ? event.venue.name : 'None assigned'

    return {
      id: event.id,
      name: event.name,
      category: event.eventCategory,
      type: event.eventType,
      venueName: event.venue.name,
      venue: event.venue,
      startDate: event.startDate,
      endDate: event.endDate,
      recommendedAge: event.recommendedAge,
      location: event.location.name
    }
  })

  return formattedData
}

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

const FilterPopover = (props) => {
  const {
    startDate,
    endDate,
    executeSearch,
    isSearchingForFamilyFriendly,
    location,
    searchText,
    setStartDate,
    setEndDate,
    setLocation,
    setIsSearchingForFamilyFriendly,
    setSearchText,
    setEventName
  } = props.data

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClickButton = (event) => {
    executeSearch()
    handleClose()
  }

  const open = Boolean(anchorEl)
  const id = open ? 'popover' : undefined

  return (
    <div>

      <Tooltip title="Filter list">
        <IconButton aria-describedby={id} onClick={handleClick}>
          <FilterListIcon />
        </IconButton>
      </Tooltip>

      <Popover id={id}
               open={open}
               anchorEl={anchorEl}
               onClose={handleClose}
               anchorOrigin={{
                 vertical: 'bottom',
                 horizontal: 'left',
               }}>

        <div className="filters">
          <h3>Filters</h3>
          <Stack spacing={3} direction="column">
            <div>
              <LocalizationProvider dateAdapter={AdapterDateFns}>

                <Stack spacing={2} direction="column" className="hidemobile stack">
                    <DesktopDatePicker label="Start Date"
                                       inputFormat="MM/dd/yyyy"
                                       value={startDate}
                                       onChange={(newValue) => setStartDate(newValue)}
                                       clearable={true}
                                       renderInput={(params) => <TextField {...params} />} />

                    <DesktopDatePicker label="End Date"
                                       inputFormat="MM/dd/yyyy"
                                       value={endDate}
                                       onChange={(newValue) => setEndDate(newValue)}
                                       clearable={true}
                                       renderInput={(params) => <TextField {...params} />} />
                </Stack>

                <Stack spacing={2} direction="column" className="showmobile stack">
                  <MobileDatePicker label="Start date"
                                    inputFormat="MM/dd/yyyy"
                                    value={startDate}
                                    onChange={(newValue) => setStartDate(newValue)}
                                    renderInput={(params) => <TextField {...params} />} />

                  <MobileDatePicker label="End date"
                                    inputFormat="MM/dd/yyyy"
                                    value={endDate}
                                    onChange={(newValue) => setEndDate(newValue)}
                                    renderInput={(params) => <TextField {...params} />} />
                </Stack>
              </LocalizationProvider>
            </div>

            <div>
              <TextField id="outlined-basic"
                         label="Event or Venue"
                         variant="outlined"
                         onChange={(event) => setSearchText(event.target.value)} />
            </div>

            <div>
              <Stack spacing={2} direction="column">
                <FormControlLabel
                  control={<Checkbox checked={isSearchingForFamilyFriendly}
                                     className="search-checkbox"
                                     onChange={(event) => setIsSearchingForFamilyFriendly(event.target.checked)} />}
                  label="Show Only Family Friendly" />
              </Stack>
            </div>

            <div>
              <Stack spacing={2} direction="row">

                <FormControl fullWidth>
                  <InputLabel id="select-label">Location</InputLabel>
                  <Select labelId="select-label"
                          id="select"
                          value={location}
                          label="Location"
                          onChange={(event) => setLocation(event.target.value)}>
                    <MenuItem value="Broadway">Broadway</MenuItem>
                    <MenuItem value="West End">West End</MenuItem>
                    <MenuItem value="Los Angeles">Los Angeles</MenuItem>
                  </Select>
                </FormControl>

              </Stack>
            </div>

            <Button variant="contained" onClick={handleClickButton}>Search</Button>
          </Stack>
        </div>

      </Popover>
    </div>
  )
}

const EnhancedTableHead = (props) => {
  const { order, orderBy, rowCount, onRequestSort } = props

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>

        {headCells.map((headCell) => (
          <TableCell key={headCell.id}
                     align='left'
                     padding={'normal'}
                     sortDirection={orderBy === headCell.id ? order : false}>

            <TableSortLabel active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>

          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const EnhancedTableToolbar = (props) => {
  const { history } = props

  const handleClickNew = async () => {
    try {
      const result = await props.client.mutate({
        mutation: EVENT_CREATE_MUTATION
      })

      history.push(`/event/${result.data.eventCreate.id}`)
    } catch(err) {
      alert(`Unable to create event: ${err.message}`)
    }
  }

  return (
    <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 }}}>
      <Typography sx={{ flex: '1 1 100%' }} variant="h3" className="page-title" component="div">
        Events <AddCircleIcon fontSize="large" onClick={() => { handleClickNew() }} />
      </Typography>

      <FilterPopover data={props}/>
    </Toolbar>
  )
}

function EventList(props) {
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('orderPlacedAt')

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const [location, setLocation] = useState('Los Angeles')
  const [endDate, setEndDate] = useState(null)
  const [startDate, setStartDate] = useState(null)
  const [searchText, setSearchText] = useState('')

  const [loadingSearch, setLoadingSearch] = useState(false)
  const [error, setError] = useState(false)
  const [searchedItems, setSearchedItems] = useState(null)

  const [isSearchingForFamilyFriendly, setIsSearchingForFamilyFriendly] = useState(false)

  const history = useHistory()

  const executeSearch = async () => {
    try {
      setLoadingSearch(true)

      var endOfPeriod = endDate ? moment(endDate).format('YYYY-MM-DD') : getTenYearsFromNowYYYYMMDD()
      var startOfPeriod = startDate ? moment(startDate).format('YYYY-MM-DD') : getBeginningOfTimeStrYYYYMMDD()
      var familyOnlyFilter = isSearchingForFamilyFriendly ? `{ isChildFriendly: { equals: true }}` : ''

      var filterEventQuery = gql`
        query EventsListQuery($searchText: String, $location: String, $startOfPeriod: Date, $endOfPeriod: Date) {
          eventsList (
            first: 1000,
            sort: { createdAt : DESC },
            filter: {
              AND: [
                { startDate: { gte: $startOfPeriod }},
                { endDate: { lte: $endOfPeriod }},
                { location: { name: { equals: $location }}}

                ${familyOnlyFilter}

                {OR: [
                  { venue: { name: { contains: $searchText }} },
                  { name: { contains: $searchText }},
                  { shortName: { contains: $searchText }}
                ]},
              ]
            }
          ) {
            items {
              id
              createdAt
              isChildFriendly
              name
              recommendedAge
              eventCategory
              eventType
              endDate
              startDate
              shortName

              location {
                id
                name
              }

              venue {
                id
                name
              }
            }
          }
        }
      `

      var result = await props.client.query({
        query: filterEventQuery,
        variables: {
          searchText,
          endOfPeriod,
          startOfPeriod,
          location,
        }
      })

      const events = result.data.eventsList.items
      setSearchedItems(events)

      setSearchText('')
      setLocation('Broadway')
      setEndDate(null)
      setStartDate(null)
      setLoadingSearch(false)

    } catch(err) {
      setError(true)
      setLoadingSearch(false)
      console.log(err)
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const getItemsToRender = () => {
    return (searchedItems || props.eventsListQuery.eventsList && props.eventsListQuery.eventsList.items)
  }

  const items = getItemsToRender()
  const rows = createData(items)

  const emptyRows = page > 0 && rows ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0

  return (
    <Box sx={{ width: '100%' }}>
      <div className='data'>
        <EnhancedTableToolbar client={props.client}
                              endDate={endDate}
                              executeSearch={executeSearch}
                              history={history}
                              isSearchingForFamilyFriendly={isSearchingForFamilyFriendly}
                              location={location}
                              searchText={searchText}
                              startDate={startDate}
                              setEndDate={setEndDate}
                              setLocation={setLocation}
                              setIsSearchingForFamilyFriendly={setIsSearchingForFamilyFriendly}
                              setSearchText={setSearchText}
                              setStartDate={setStartDate} />

        {(loadingSearch || !rows && props.eventsListQuery && props.eventsListQuery.loading) &&
          <CircularProgress />
        }

        {(!rows && props.eventsListQuery && props.eventsListQuery.error) || error &&
          <h2>Error</h2>
        }

        {rows && !loadingSearch &&
          <div>
            <TableContainer>
              <Table sx={{ minWidth: 750 }}
                     aria-labelledby="table-title"
                     size='medium'>

                <EnhancedTableHead order={order}
                                   orderBy={orderBy}
                                   onRequestSort={handleRequestSort}
                                   rowCount={rows.length}/>
                <TableBody>

                  {rows.slice().sort(getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`

                      const id = (<a href={'/event/' + row.id}>{row.id}</a>)
                      const eventName = (<a href={'/event/' + row.id}>{row.name}</a>)
                      const venueName = row.venue
                                          ? (<a href={'/venue/' + row.venue.id}>{row.venueName}</a>)
                                          : row.venueName

                      return (
                        <TableRow hover
                                  role="checkbox"
                                  tabIndex={-1}
                                  key={row.id}>

                          <TableCell component="th"
                                     id={labelId}
                                     scope="row"
                                     padding="none">
                            {id}
                          </TableCell>
                          <TableCell align="left">{eventName}</TableCell>
                          <TableCell align="left">{row.category}</TableCell>
                          <TableCell align="left">{row.type}</TableCell>
                          <TableCell align="left">{venueName}</TableCell>
                          <TableCell align="left">{row.startDate}</TableCell>
                          <TableCell align="left">{row.endDate}</TableCell>
                          <TableCell align="left">{row.recommendedAge}</TableCell>
                          <TableCell align="left">{row.location}</TableCell>
                        </TableRow>
                      )
                    })}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }} >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}

                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination rowsPerPageOptions={[5, 10, 25, 50, 100]}
                             component="div"
                             count={rows.length}
                             rowsPerPage={rowsPerPage}
                             page={page}
                             onPageChange={handleChangePage}
                             showFirstButton={true}
                             showLastButton={true}
                             onRowsPerPageChange={handleChangeRowsPerPage}/>
          </div>
        }
      </div>
    </Box>
  )
}

const EVENTS_LIST_QUERY = gql`
  query EventsListQuery($location: String, $startDate: Date) {
    eventsList(
      sort: { name : ASC }
      filter: {
        startDate: { lte: $startDate },
        location: { name: { equals: $location }}
      }
    ) {
      items {
        id
        createdAt
        isChildFriendly
        name
        recommendedAge
        endDate
        eventType
        eventCategory
        startDate
        shortName

        location {
          id
          name
        }

        venue {
          id
          name
        }
      }
    }
  }
`

const EVENT_CREATE_MUTATION = gql`
  mutation EventCreateMutation {
    eventCreate(data: {
      name: "Fill me out"
      shortName: "Fill me out"
      slug: "Fill me out"
      isChildFriendly: false
      eventType: "Other"

      location: {
        connect: { name: "Los Angeles" }
      }

      schedule: {
        create: { }
      }
    }) {
      id
    }
  }
`

export default withRouter(withApollo(graphql(EVENTS_LIST_QUERY, {
  name: 'eventsListQuery',
  options: (ownProps) => {
    var today = moment().format('YYYY-MM-DD')
    var runDateCutoff = moment().add(ALLOWABLE_DAYS_AHEAD_TO_PLACE_ORDER, 'days').format('YYYY-MM-DD')

    return {
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
      variables: {
        startDate: runDateCutoff,
        location: "Los Angeles"
      }
    }
  }
})(EventList)))
