import React, { Component } from 'react'
import { gql } from 'apollo-boost'
import { graphql } from 'react-apollo'
import { withRouter } from 'react-router'
import { Switch, Route, Redirect } from 'react-router-dom'

import {
  grey100, grey300, grey400, grey500,
  white, fullBlack,
} from 'material-ui/styles/colors'

import { ThemeProvider, createTheme } from '@mui/material/styles'

import Color from 'color'

import Header from './Header'
import LoginAuth0 from './LoginAuth0'

import OrderList from './OrderList'
import EventOrderList from './EventOrderList'
import ShowList from './ShowList'
import EventList from './EventList'
import VenueList from './VenueList'
import TheaterList from './TheaterList'
import UserList from './UserList'
import PurchaseCodeList from './PurchaseCodeList'
import GiftCardList from './GiftCardList'
import ReferralList from './ReferralList'
import PickupList from './PickupList'
import RestaurantList from './RestaurantList'

import OrderDetails from './OrderDetails'
import EventOrderDetails from './EventOrderDetails'
import ShowDetails from './ShowDetails'
import EventDetails from './EventDetails'
import VenueDetails from './VenueDetails'
import TheaterDetails from './TheaterDetails'
import UserDetails from './UserDetails'
import PurchaseCodeDetails from './PurchaseCodeDetails'
import CreditDetails from './CreditDetails'
import GiftCardDetails from './GiftCardDetails'
import PickupDetails from './PickupDetails'
import RestaurantDetails from './RestaurantDetails'
import RestaurantReservationsList from './RestaurantReservationsList'
import TicketAssignments from './TicketAssignments'
import EventTicketAssignments from './EventTicketAssignments'

import AlgorithmConfiguration from './AlgorithmConfiguration'
import Configuration from './Configuration'
import DisabledDates from './DisabledDates'
import DisabledDateDetails from './DisabledDateDetails'
import Reports from './Reports'
import SubscriptionDetails from './SubscriptionDetails'
import SubscriptionList from './SubscriptionList'

import { GRAPHQL_AUTH_CLIENT_ID, GRAPHQL_AUTH_DOMAIN } from '../constants'
import { brBlack, brRed } from '../colors'

import './App.css'

class App extends Component {

  _isLoggedIn = () => {
    var user = this.props.data.user
    return user && user.isAdmin
  }

  render() {
    var middleOfPage = { display: 'flex', justifyContent: 'center',
                         alignItems: 'center', height: '100vh' }

    if (this.props.data.loading) {
      return <div style={middleOfPage}>
               Loading
             </div>
    }

    if (this._isLoggedIn()) {
      return this.renderLoggedIn()
    } else {
      return this.renderLoggedOut(middleOfPage)
    }
  }

  renderLoggedOut(middleOfPage) {
    var centeredContent = { display: 'block', textAlign: 'center',
                            marginBottom: '10px' }

    if (window.location.pathname !== '/') {
      this.props.history.push('/')
    }

    return (
      <div style={{ ...middleOfPage, backgroundColor: brBlack }}>
        <div>
          <div style={{ ...centeredContent, color: 'white' }}>
            Log in to the Admin Portal
          </div>
          <div style={centeredContent}>
            <LoginAuth0 clientId={GRAPHQL_AUTH_CLIENT_ID} domain={GRAPHQL_AUTH_DOMAIN}/>
          </div>
        </div>
      </div>
    )
  }

  renderLoggedIn() {
    const logoBlack = brBlack
    const logoRed = brRed

    const theme = createTheme({
      palette: {
        primary: {
          main: brRed,
          light: '#ff525c',
          dark: '#9b000d'
        },

        secondary: {
          main: brBlack,
          light: '#4f4348',
          dark: '#000000'
        },

        white: {
          main: '#ffffff'
        }
      },
    })

    const user = this.props.data.user
    const homepage = user.email === 'jcarterspires@gmail.com' ? '/reports' : 'ticket-assignments'

    return (
      <ThemeProvider theme={theme}>

        <div className="header">
          <Header user={user}>
            <div className='pv3' style={{ padding: '0' }}>
            </div>
          </Header>

          <div className='app-content'>
            <div className='ph3 pv1 w95 center'>
              <Switch>
                <Route exact path='/' render={() => <Redirect to={homepage} />} />
                <Route exact path='/user/:id' component={UserDetails} currentUser={user} />
                <Route exact path='/users' render={() => <Redirect to='/users/1' />} />
                <Route exact path='/users/:page' component={UserList}/>

                <Route exact path='/order' render={(props) => <OrderDetails {...props} currentUser={user} />} />
                <Route exact path='/orders' render={() => <Redirect to='/orders/1' />} />
                <Route exact path='/orders/:page' component={OrderList}/>

                <Route exact path='/event-order' render={(props) => <EventOrderDetails {...props} currentUser={user} />} />
                <Route exact path='/event-orders' render={() => <Redirect to='/event-orders/1' />} />
                <Route exact path='/event-orders/:page' component={EventOrderList}/>

                <Route exact path='/restaurant/:id' component={RestaurantDetails}/>
                <Route exact path='/restaurants' component={RestaurantList}/>

                <Route exact path='/restaurant-reservations' render={() => <Redirect to='/restaurant-reservations/1' />} />
                <Route exact path='/restaurant-reservations/:page' component={RestaurantReservationsList}/>

                <Route exact path='/show/:id' component={ShowDetails}/>
                <Route exact path='/shows' component={ShowList}/>

                <Route exact path='/event/:id' component={EventDetails}/>
                <Route exact path='/events' component={EventList}/>

                <Route exact path='/theater/:id' component={TheaterDetails}/>
                <Route exact path='/theaters' component={TheaterList}/>

                <Route exact path='/venue/:id' component={VenueDetails}/>
                <Route exact path='/venues' component={VenueList}/>

                <Route exact path='/purchase-code/:id' component={PurchaseCodeDetails}/>
                <Route exact path='/purchase-codes' render={() => <Redirect to='/purchase-codes/1' />} />
                <Route exact path='/purchase-codes/:page' component={PurchaseCodeList}/>

                <Route exact path='/gift-card/:id' component={GiftCardDetails}/>
                <Route exact path='/gift-cards' render={() => <Redirect to='/gift-cards/1' />} />
                <Route exact path='/gift-cards/:page' component={GiftCardList}/>

                <Route exact path='/credit/:id' component={CreditDetails}/>

                <Route exact path='/referrals' render={() => <Redirect to='/referrals/1' />} />
                <Route exact path='/referrals/:page' component={ReferralList}/>

                <Route exact path='/pickup/:id' component={PickupDetails}/>
                <Route exact path='/pickups' component={PickupList}/>

                <Route exact path='/ticket-assignments' render={(props) => <TicketAssignments {...props} currentUser={user} />} />
                <Route exact path='/event-assignments' render={(props) => <EventTicketAssignments {...props} currentUser={user} />} />

                <Route exact path='/subscriptions/:page' component={SubscriptionList} />} />
                <Route exact path='/subscriptions' render={() => <Redirect to='/subscriptions/1' />} />
                <Route exact path='/subscription/:id' component={SubscriptionDetails} />} />

                <Route exact path='/config' component={Configuration}/>
                <Route exact path='/algorithm' component={AlgorithmConfiguration}/>
                <Route exact path='/reports' render={(props) => <Reports {...props} currentUser={user} />} />

                <Route exact path='/disabled-date/:id' component={DisabledDateDetails}/>
                <Route exact path='/disabled-dates' render={() => <Redirect to='/disabled-dates/1' />} />
                <Route exact path='/disabled-dates/:page' component={DisabledDates}/>

              </Switch>
            </div>
          </div>
        </div>

      </ThemeProvider>
    )
  }
}

const USER_QUERY = gql`
  query {
    user {
      id
      email
      isAdmin
      firstName
      roles {
        items {
          name
        }
      }
    }
  }
`

export default graphql(USER_QUERY, {
  options: {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true
  }
})(withRouter(App))
