import React, { useState, useEffect } from 'react'
import AWS from 'aws-sdk'
import Dropzone from 'react-dropzone'
import { gql } from 'apollo-boost'
import { graphql, withApollo } from 'react-apollo'
import moment from 'moment-timezone'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import { withRouter } from 'react-router'
import S3 from 'aws-s3'

import AdapterDateFns from '@mui/lab/AdapterDateFns'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Checkbox from '@mui/material/Checkbox'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'
import Divider from '@mui/material/Divider'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import FormLabel from '@mui/material/FormLabel'
import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import MenuItem from '@mui/material/MenuItem'
import MobileDatePicker from '@mui/lab/MobileDatePicker'
import Paper from '@mui/material/Paper'
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'

import {
  AWS_ACCESS_KEY_ID,
  AWS_ACCESS_KEY,
  AWS_MEDIA_BUCKET_URL
} from '../constants'

import Queries, { VENUES_LIST_QUERY } from '../queries'

const options = {
  accessKeyId: AWS_ACCESS_KEY_ID,
  secretAccessKey: AWS_ACCESS_KEY,
}

const s3 = new AWS.S3(options)

const MAX_FILE_SIZE = 15000000

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body1,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
}))

const EventData = (props) => {
  const { event } = props

  const [locations, setLocations] = useState([])
  const [venues, setVenues] = useState([])
  const [ticketPickups, setTicketPickups] = useState([])

  const [mediaLinks, setMediaLinks] = useState(event.media)

  const [requiredFieldBlankError, setRequiredFieldBlankError] = useState(false)
  const [saving, setSaving] = useState(false)

  const schedule = event.schedule
  const ticketPrices = event.ticketPrices

  const [eventState, setEventState] = useState({
    dateAdded: event.dateAdded ? moment(event.dateAdded).format('YYYY-MM-DDTHH:MM') : null,
    description: event.description,
    eventType: event.eventType,
    eventCategory: event.eventCategory,
    isChildFriendly: event.isChildFriendly,
    location: event.location.name,
    name: event.name,
    recommendedAge: event.recommendedAge,
    endDate: event.endDate ? moment(event.endDate).format('YYYY-MM-DDTHH:MM') : null,
    startDate: event.startDate ? moment(event.startDate).format('YYYY-MM-DDTHH:MM') : null,
    shortName: event.shortName,
    slug: event.slug,
    venue: event.venue ? event.venue.id: null,
    ticketPickup: event.ticketPickup ? event.ticketPickup.id : null,
  })

  var isAvailableDaily = schedule ? schedule.isAvailableEveryDayOfWeek : false
  const [isAvailableEveryDayOfWeek, setIsAvailableEveryDayOfWeek] = useState(isAvailableDaily)

  const [scheduleState, setScheduleState] = useState({
    monday: schedule ? schedule.daysOfWeekAvailable['monday'] : false,
    tuesday: schedule ? schedule.daysOfWeekAvailable['tuesday'] : false,
    wednesday: schedule ? schedule.daysOfWeekAvailable['wednesday'] : false,
    thursday: schedule ? schedule.daysOfWeekAvailable['thursday'] : false,
    friday: schedule ? schedule.daysOfWeekAvailable['friday'] : false,
    saturday: schedule ? schedule.daysOfWeekAvailable['saturday'] : false,
    sunday: schedule ? schedule.daysOfWeekAvailable['sunday'] : false,
  })

  const handleSaveChanges = async () => {
    try {
      setSaving(true)

      var { dateAdded, description, duration, eventCategory, eventType, isChildFriendly, location, name,
            recommendedAge, endDate, startDate, shortName, slug, venue, ticketPickup } = eventState

      if (!ticketPickup || !name || !shortName || !location || !eventCategory) {
        setRequiredFieldBlankError(true)
        alert('Please fill in required fields')

        setSaving(false)
        return
      }

      var formattedEndDate = !endDate ? null : moment(endDate).format('YYYY-MM-DD')
      var formattedStartDate = !startDate ? null : moment(startDate).format('YYYY-MM-DD')

      var variables = {
        id: event.id,
        dateAdded: moment(dateAdded).format('YYYY-MM-DD'),
        daysOfWeekAvailable: scheduleState,
        description,
        eventCategory,
        eventType,
        isAvailableEveryDayOfWeek,
        isChildFriendly,
        location,
        name,
        recommendedAge,
        endDate: formattedEndDate,
        startDate: formattedStartDate,
        shortName,
        slug,
        venueId: venue,
        ticketPickupId: ticketPickup,
        media: mediaLinks
      }

      var result = await props.client.mutate({
        mutation: EVENT_UPDATE_MUTATION,
        variables
      })

      alert('Event successfully updated')
      setSaving(false)

    } catch (err) {
      setSaving(false)
      alert(`Did not save, error: ${err}`)
    }
  }

  const handleUpdateEventState = (key, value) => {
    var newEventState = {...eventState}

    newEventState[key] = value
    setEventState(newEventState)
  }

  const handleUpdateSchedule = (key, value) => {
    var newSchedule = {...scheduleState}

    newSchedule[key] = value
    setScheduleState(newSchedule)
  }

  const loadVenues = async () => {
    try {
      var venuesQuery = await props.client.query({ query: VENUES_LIST_QUERY })
      setVenues(venuesQuery.data.venuesList.items)

    } catch (err) {
      console.log('Unable to get venue data')
    }
  }

  const loadTicketPickups = async () => {
    try {
      var ticketPickupsQuery = await props.client.query({ query: TICKET_PICKUPS_LIST_QUERY })
      setTicketPickups(ticketPickupsQuery.data.ticketPickupsList.items)

    } catch (err) {
      console.log('Unable to get ticket pickup data')
    }
  }

  const loadLocations = async () => {
    try {
      var locationsQuery = await props.client.query({ query: LOCATIONS_LIST_QUERY })
      setLocations(locationsQuery.data.locationsList.items)

    } catch (err) {
      console.log('Unable to get locations data')
    }
  }

  const uploadEventMedia = async (file) => {
    try {
      var fileName = `${moment().valueOf()}`

      var location = 'la'
      var fileDir = 'images'

      if (file.size > MAX_FILE_SIZE) {
        alert('File size is too big. Please attach files under 15 MB.')
      } else {
        const config = {
          bucketName: 'goldstarroulette-media',
          dirName: `${location}/${fileDir}/${event.id}`,
          region: 'us-east-1',
          accessKeyId: AWS_ACCESS_KEY_ID,
          secretAccessKey: AWS_ACCESS_KEY,
          s3Url: AWS_MEDIA_BUCKET_URL
        }

        const S3Client = new S3(config)
        const data = await S3Client.uploadFile(file, fileName)

        var newMediaLinks = [...mediaLinks]
        newMediaLinks.push(data.location)

        setMediaLinks(newMediaLinks)
      }
    } catch(err) {
      console.log(err)
      alert('Failed to attach upload to order')
    }
  }

  useEffect(() => {
    loadLocations()
    loadTicketPickups()
    loadVenues()
  }, [])

  return (
    <Item className="order-tile">
      <Typography sx={{ flex: '1 1 100%' }} variant="h5" className="order-tile-subtitle" component="div">
        {event.name}
      </Typography>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header">
          <Typography className="accordion-title">Basic Details</Typography>
          <Divider />
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <Divider />

            <Stack justifyContent="space-around" direction="row" flexWrap="wrap">

              <Stack direction="column">
                <h3>Details</h3>

                <div className="padding first-entry">
                  <TextField label="ID"
                             defaultValue={event.id}
                             disabled={true} />
                </div>

                <div className="padding">
                  <TextField label="Name"
                             helperText="*required field"
                             error={requiredFieldBlankError && !eventState.name}
                             defaultValue={eventState.name}
                             onChange={(e) => { handleUpdateEventState('name', e.target.value) }} />
                </div>

                <div className="padding">
                  <TextField label="Short Name"
                             helperText="*required field"
                             error={requiredFieldBlankError && !eventState.shortName}
                             defaultValue={eventState.shortName}
                             onChange={(e) => { handleUpdateEventState('shortName', e.target.value) }} />
                </div>

                <div className="padding">
                  <TextField label="Slug"
                             helperText="*required field"
                             defaultValue={eventState.slug}
                             onChange={(e) => { handleUpdateEventState('slug', e.target.value) }} />
                </div>

                <div className="padding">
                  <FormControlLabel
                    control={
                      <Checkbox checked={eventState.isChildFriendly}
                                onChange={(e) => { handleUpdateEventState('isChildFriendly', e.target.checked) }} />
                    }
                    label="Is child friendly" />

                </div>
              </Stack>

              <Stack direction="column">
                <h3>Copy</h3>

                <div className="padding">
                  <TextField label="Recommended Age"
                             defaultValue={eventState.recommendedAge}
                             onChange={(e) => { handleUpdateEventState('recommendedAge', e.target.value) }} />
                </div>

                <div className="padding">
                  <TextField label="Description"
                             defaultValue={eventState.description}
                             multiline
                             maxRows={10}
                             onChange={(e) => { handleUpdateEventState('description', e.target.value) }} />
                    <FormHelperText>{eventState.description ? eventState.description.length : 0} characters</FormHelperText>
                </div>
              </Stack>

              <Stack direction="column">
                <h3>Location & Pickup</h3>

                <div className="padding flex-basis-0">
                  <FormControl error={requiredFieldBlankError && !eventState.location}>
                    <InputLabel>Location</InputLabel>
                    <Select value={eventState.location}
                            label="Location"
                            onChange={(event) => handleUpdateEventState('location', event.target.value)}>

                        {locations && locations.map((locationMap, idx) => (
                          <MenuItem key={idx} value={locationMap.name}> {locationMap.name} </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>*required field</FormHelperText>
                  </FormControl>
                </div>

                <div className="padding">
                  <FormControl error={requiredFieldBlankError && !eventState.venue}>
                    <InputLabel>Venue</InputLabel>
                    <Select value={eventState.venue}
                            label="Venue"
                            onChange={(event) => handleUpdateEventState('venue', event.target.value)}>

                        {venues && venues.map((venuesMap, idx) => (
                          <MenuItem key={idx} value={venuesMap.id}> {venuesMap.name} </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>*required field</FormHelperText>
                  </FormControl>
                </div>

                <div className="padding">
                  <FormControl error={requiredFieldBlankError && !eventState.ticketPickup}>
                    <InputLabel>Ticket Pickup</InputLabel>
                    <Select value={eventState.ticketPickup}
                            label="Ticket Pickup"
                            onChange={(event) => handleUpdateEventState('ticketPickup', event.target.value)}>

                        {ticketPickups && ticketPickups.map((ticketPickupMap, idx) => (
                          <MenuItem key={idx} value={ticketPickupMap.id}> {ticketPickupMap.name} </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>*required field</FormHelperText>
                  </FormControl>
                </div>

                <div className="padding">
                  <FormControl>
                    <InputLabel>Event Type</InputLabel>
                    <Select value={eventState.eventType}
                            label="Event Type"
                            onChange={(event) => handleUpdateEventState('eventType', event.target.value)}>

                      <MenuItem key={'Activity'} value={'Activity'}> Activity </MenuItem>
                      <MenuItem key={'Comedy'} value={'Comedy'}> Comedy </MenuItem>
                      <MenuItem key={'Museum'} value={'Museum'}> Museum </MenuItem>
                      <MenuItem key={'Music'} value={'Music'}> Music </MenuItem>
                      <MenuItem key={'Sports'} value={'Sports'}> Sports </MenuItem>
                      <MenuItem key={'Theatre'} value={'Theatre'}> Theatre </MenuItem>
                      <MenuItem key={'Other'} value={'Other'}> Other </MenuItem>

                    </Select>
                  </FormControl>
                </div>

                <div className="padding">
                  <FormControl error={requiredFieldBlankError && !eventState.eventCategory}>
                    <InputLabel>Event Category</InputLabel>
                    <Select value={eventState.eventCategory}
                            label="Event Category"
                            onChange={(event) => handleUpdateEventState('eventCategory', event.target.value)}>

                      <MenuItem key={'Attractions'} value={'Attractions'}> Museums & Attractions </MenuItem>
                      <MenuItem key={'Arts'} value={'Arts'}> Theatre & Arts </MenuItem>
                      <MenuItem key={'Premium'} value={'Premium'}> Premium </MenuItem>

                    </Select>
                    <FormHelperText>*required field</FormHelperText>
                  </FormControl>
                </div>
              </Stack>

            </Stack>
          </div>

        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header">
          <Typography className="accordion-title">Image Upload</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Divider />

          <div className="dropzone">
            <Dropzone onDrop={acceptedFiles => uploadEventMedia(acceptedFiles[0])}>
              {({getRootProps, getInputProps}) => (
                <section>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />

                    <div>
                      <p>Drag and drop here, or click to select file</p>
                    </div>

                  </div>
                </section>
              )}
            </Dropzone>

            {mediaLinks.length > 0 && mediaLinks.map((link) => {
              return (<img src={link} alt="event image" width="200px" className="event-media" />)
            })}
          </div>

        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header">
          <Typography className="accordion-title">Schedule</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Divider />

          <Stack justifyContent="center" direction="row" flexWrap="wrap">
            <FormControlLabel
              control={
                <Switch
                  checked={isAvailableEveryDayOfWeek}
                  onChange={(e, b) => { setIsAvailableEveryDayOfWeek(b) }}
                />
              }
              label={"Is Available Every Day?"}
            />
          </Stack>

          {!isAvailableEveryDayOfWeek &&
            <Stack justifyContent="space-around" direction="row" flexWrap="wrap">

              <Stack direction="column">
                <h3>Day's Available</h3>

                <FormControlLabel
                  control={
                    <Switch
                      checked={scheduleState.monday}
                      onChange={(e, b) => { handleUpdateSchedule('monday', b) }}
                    />
                  }
                  label={"Monday"}
                />

                <FormControlLabel
                  control={
                    <Switch
                      checked={scheduleState.tuesday}
                      onChange={(e, b) => { handleUpdateSchedule('tuesday', b) }}
                    />
                  }
                  label={"Tuesday"}
                />

                <FormControlLabel
                  control={
                    <Switch
                      checked={scheduleState.wednesday}
                      onChange={(e, b) => { handleUpdateSchedule('wednesday', b) }}
                    />
                  }
                  label={"Wednesday"}
                />

                <FormControlLabel
                  control={
                    <Switch
                      checked={scheduleState.thursday}
                      onChange={(e, b) => { handleUpdateSchedule('thursday', b) }}
                    />
                  }
                  label={"Thursday"}
                />

                <FormControlLabel
                  control={
                    <Switch
                      checked={scheduleState.friday}
                      onChange={(e, b) => { handleUpdateSchedule('friday', b) }}
                    />
                  }
                  label={"Friday"}
                />

                <FormControlLabel
                  control={
                    <Switch
                      checked={scheduleState.saturday}
                      onChange={(e, b) => { handleUpdateSchedule('saturday', b) }}
                    />
                  }
                  label={"Saturday"}
                />

                <FormControlLabel
                  control={
                    <Switch
                      checked={scheduleState.sunday}
                      onChange={(e, b) => { handleUpdateSchedule('sunday', b) }}
                    />
                  }
                  label={"Sunday"}
                />
              </Stack>

            </Stack>
          }

        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header">
          <Typography className="accordion-title">Dates</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Divider />

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack spacing={2} direction="row" className="hidemobile stack padding" justifyContent="space-around" flexWrap="wrap">

              <div className="flex-basis-0">
                <DesktopDatePicker label="Date Added"
                                   inputFormat="MM/dd/yyyy"
                                   value={eventState.dateAdded}
                                   onChange={(newValue) => handleUpdateEventState('dateAdded', newValue)}
                                   clearable={true}
                                   renderInput={(params) => <TextField {...params} />} />
                <p className="small-text">
                  *Orders placed on or before this date will not have this event as a ticket assignments option
                </p>
              </div>

              <DesktopDatePicker label="Event Start Date"
                                 inputFormat="MM/dd/yyyy"
                                 value={eventState.startDate}
                                 onChange={(newValue) => handleUpdateEventState('startDate', newValue)}
                                 clearable={true}
                                 renderInput={(params) => <TextField {...params} />} />

              <DesktopDatePicker label="Event End Date"
                                 inputFormat="MM/dd/yyyy"
                                 value={eventState.endDate}
                                 onChange={(newValue) => handleUpdateEventState('endDate', newValue)}
                                 clearable={true}
                                 renderInput={(params) => <TextField {...params} />} />
            </Stack>

            <Stack spacing={2} direction="column" className="showmobile stack padding" justifyContent="center">
              <div className="flex-basis-0">
                <MobileDatePicker label="Date Added"
                                   inputFormat="MM/dd/yyyy"
                                   value={eventState.dateAdded}
                                   onChange={(newValue) => handleUpdateEventState('dateAdded', newValue)}
                                   clearable={true}
                                   renderInput={(params) => <TextField {...params} />} />
               <p>*Orders placed on or before this date will not have this event as a ticket assignments option</p>
              </div>

              <MobileDatePicker label="Event Start Date"
                                 inputFormat="MM/dd/yyyy"
                                 value={eventState.startDate}
                                 onChange={(newValue) => handleUpdateEventState('startDate', newValue)}
                                 clearable={true}
                                 renderInput={(params) => <TextField {...params} />} />

              <MobileDatePicker label="Event End Date"
                                 inputFormat="MM/dd/yyyy"
                                 value={eventState.endDate}
                                 onChange={(newValue) => handleUpdateEventState('endDate', newValue)}
                                 clearable={true}
                                 renderInput={(params) => <TextField {...params} />} />
            </Stack>
          </LocalizationProvider>

        </AccordionDetails>
      </Accordion>

      {!saving &&
        <div className="save-button">
          <Button variant="contained" onClick={handleSaveChanges}>
            Save changes
          </Button>
        </div>
      }

      {saving &&
        <CircularProgress />
      }

    </Item>
  )
}

function EventDetails(props) {
  const [loading, setLoading] = useState(true)
  const [updating, setUpdating] = useState(false)
  const [error, setError] = useState(false)

  const [event, setEvent] = useState([])

  const getEventData = () => {
    try {
      var event = props.eventQuery.event

      if (event) {
        setEvent(event)
        setLoading(false)
      }

    } catch(err) {
      console.log(err)
      setError(true)
    }
  }

  useEffect(async () => {
    if (loading) {
      await getEventData()
    }
  }, [props.eventQuery, loading])

  return (
    <Box sx={{ width: '100%' }}>
      <div className='data'>

        <Typography sx={{ flex: '1 1 100%' }} variant="h3" className="page-title" component="div">
          Event Details
        </Typography>

        {loading &&
          <CircularProgress />
        }

        {!event && error &&
          <h2>Error</h2>
        }

        {event && !loading &&
          <EventData event={event} client={props.client} />
        }

      </div>
    </Box>
  )
}

const EVENT_QUERY = gql`
  query EventQuery($id: ID) {
    event(id: $id) {
      id
      createdAt
      dateAdded
      isChildFriendly
      description
      eventCategory
      eventType
      media
      name
      recommendedAge
      startDate
      endDate
      shortName
      slug

      location {
        id
        name
      }

      schedule {
        id
        isAvailableEveryDayOfWeek
        daysOfWeekAvailable
      }

      venue {
        id
        name
      }

      ticketPickup {
        id
        name
      }
    }
  }
`

const EVENT_UPDATE_MUTATION = gql`
  mutation EventUpdateMutation($id: ID!, $isChildFriendly: Boolean, $dateAdded: Date, $eventCategory: String,
                              $eventType: String, $daysOfWeekAvailable: JSON, $description: String,
                              $isAvailableEveryDayOfWeek: Boolean, $name: String, $recommendedAge: String,
                              $shortName: String, $location: String, $venueId: ID!, $ticketPickupId: ID!,
                              $slug: String, $endDate: Date, $startDate: Date, $media: JSON) {
    eventUpdate(data: {
      id: $id,
      isChildFriendly: $isChildFriendly,
      dateAdded: $dateAdded,
      description: $description,
      eventCategory: $eventCategory,
      eventType: $eventType,
      name: $name,
      recommendedAge: $recommendedAge,
      endDate: $endDate,
      media: $media,
      startDate: $startDate,
      shortName: $shortName,
      slug: $slug,

      schedule: {
        update: {
          isAvailableEveryDayOfWeek: $isAvailableEveryDayOfWeek,
          daysOfWeekAvailable: $daysOfWeekAvailable,
        }
      }

      location: {
        connect: { name: $location }
      }

      venue: {
        connect: { id: $venueId }
      },

      ticketPickup: {
        connect: { id: $ticketPickupId }
      },
    }) {
      id
      ticketPrices
    }
  }
`

const LOCATIONS_LIST_QUERY = gql`
  query LocationsListQuery {
    locationsList(sort: { name: ASC }) {
      items {
        id
        name
      }
    }
  }
`

const TICKET_PICKUPS_LIST_QUERY = gql`
  query TicketPickupsListQuery {
    ticketPickupsList {
      items {
        id
        name
      }
    }
  }
`

export default withApollo(graphql(EVENT_QUERY, {
  name: 'eventQuery',
  options: (ownProps) => {
    const id = ownProps.match.params.id

    return {
      variables: { id },
      notifyOnNetworkStatusChange: true
    }
  }
})(EventDetails))
