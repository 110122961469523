import React, { useState, useEffect } from 'react'
import { gql } from 'apollo-boost'
import { graphql, withApollo } from 'react-apollo'
import moment from 'moment-timezone'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import { withRouter } from 'react-router'

import AdapterDateFns from '@mui/lab/AdapterDateFns'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Checkbox from '@mui/material/Checkbox'
import { DataGrid } from '@mui/x-data-grid'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'
import Divider from '@mui/material/Divider'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import InputLabel from '@mui/material/InputLabel'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import MenuItem from '@mui/material/MenuItem'
import MobileDatePicker from '@mui/lab/MobileDatePicker'
import Paper from '@mui/material/Paper'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'


const orderColumns = [
  { field: 'id', headerName: 'ID' },
  { field: 'orderPlacedAt', headerName: 'Order Placed', width: 200 },
  { field: 'requestedShowDate', headerName: 'Show Date' },
  { field: 'requestedShowTime', headerName: 'Show Time' },
  { field: 'numberOfTickets', headerName: 'Tickets' },
  { field: 'showName', headerName: 'Show Name', width: 200 },
  { field: 'pointsRedeemed', headerName: 'Points Redeemed', width: 150 },
  { field: 'ticketsRedeemed', headerName: 'Tickets Redeemed', width: 150 },
  { field: 'isValidOrder', headerName: 'Is Valid Order?', width: 150 },
]

function createOrderData(orders) {
  var formattedOrders = orders.map(order => {
    var ticketsRedeemed = order.paymentDetails.subscriptionDiscountAmount / 47
    var pointsRedeemed = 0

    order.pointsRedemptionDetails && order.pointsRedemptionDetails.items.map(points => {
      pointsRedeemed += points.pointsRedeemed
    })

    return {
      id: order.id,
      orderPlacedAt: moment(order.orderPlacedAt).format('YYYY-MM-DD, hh:mm:ss A'),
      requestedShowDate: order.requestedShowDate,
      requestedShowTime: order.requestedShowTime,
      numberOfTickets: order.numberOfTickets,
      showName: order.ticketDetails && order.ticketDetails.show ? order.ticketDetails.show.name : 'Coming Soon',
      pointsRedeemed: pointsRedeemed,
      ticketsRedeemed: ticketsRedeemed,
      isValidOrder: order.isValidOrder ? '✔️' : '❌'
    }
  })

  return formattedOrders
}

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body1,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
}))

const SubscriptionData = (props) => {
  const { subscription } = props
  const history = useHistory()

  const orderDetails = subscription.orderDetails

  const [locations, setLocations] = useState([])
  const [requiredFieldBlankError, setRequiredFieldBlankError] = useState(false)

  const [saving, setSaving] = useState(false)
  const [selectionModel, setSelectionModel] = React.useState([])

  const [subscriptionState, setSubscriptionState] = useState({
    currentPoints: subscription.currentPoints,
    deliveryDate: orderDetails.deliveryDate,
    endDate: subscription.endDate,
    isActive: subscription.isActive,
    location: subscription.location && subscription.location.name,
    numberOfTicketsPerPeriod: subscription.numberOfTicketsPerPeriod,
    purchaserEmail: orderDetails.purchaserEmail,
    purchaserNote: orderDetails.purchaserNote,
    purchasePrice: orderDetails.purchasePrice,
    remainingTicketsForPeriod: subscription.remainingTicketsForPeriod,
    renewalDate: subscription.renewalDate,
    startDate: subscription.startDate,
    subscriptionPackage: orderDetails.subscriptionPackage,
    timesRenewed: subscription.timesRenewed,
    totalPoints: subscription.totalPoints,
  })

  const handleOpenOrders = (event) => {
    var url = '?'

    selectionModel.map((id, idx) => {
      if (idx < selectionModel.length - 1) {
        var urlSegment = `id=${id}&`
        url = url.concat(urlSegment)
      } else {
        var urlSegment = `id=${id}`
        url = url.concat(urlSegment)
      }
    })

    history.push(`/order${url}`)
  }

  const handleSaveChanges = async () => {
    try {
      setSaving(true)

      var { currentPoints, deliveryDate, endDate, isActive, location, numberOfTicketsPerPeriod, purchaserEmail,
            purchaserNote, purchasePrice, remainingTicketsForPeriod, renewalDate, startDate, subscriptionPackage,
            timesRenewed, totalPoints } = subscriptionState

      if (!numberOfTicketsPerPeriod || !remainingTicketsForPeriod || !renewalDate || !startDate || !endDate || !location) {
        setRequiredFieldBlankError(true)
        alert('Please fill in required fields')

        setSaving(false)
        return
      }

      var result = await props.client.mutate({
        mutation: MONTHLY_SUBSCRIPTION_UPDATE_MUTATION,
        variables: {
          id: subscription.id,
          currentPoints,
          deliveryDate,
          endDate: moment(endDate).format('YYYY-MM-DD'),
          isActive,
          location,
          numberOfTicketsPerPeriod: parseInt(numberOfTicketsPerPeriod),
          purchaserEmail,
          purchaserNote,
          purchasePrice: parseFloat(purchasePrice),
          remainingTicketsForPeriod: parseInt(remainingTicketsForPeriod),
          renewalDate: moment(renewalDate).format('YYYY-MM-DD'),
          startDate: moment(startDate).format('YYYY-MM-DD'),
          subscriptionPackage,
          timesRenewed,
          totalPoints,
          userId: subscription.user.id
        }
      })

      alert('Subscription successfully updated')
      setSaving(false)

    } catch (err) {
      setSaving(false)
      alert(`Did not save, error: ${err}`)
    }
  }

  const handleUpdateSubscriptionState = (key, value) => {
    var newSubscriptionState = {...subscriptionState}

    newSubscriptionState[key] = value
    setSubscriptionState(newSubscriptionState)
  }

  const loadLocations = async () => {
    try {
      var locationsQuery = await props.client.query({ query: LOCATIONS_LIST_QUERY })
      setLocations(locationsQuery.data.locationsList.items)

    } catch (err) {
      console.log(err)
      console.log('Unable to get locations data')
    }
  }

  useEffect(() => {
    loadLocations()
  }, [])

  return (
    <Item className="order-tile">
      <Typography sx={{ flex: '1 1 100%' }} variant="h5" className="order-tile-subtitle" component="div">
        {subscription.user.email} Subscription
      </Typography>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header">
          <Typography className="accordion-title">Order Details</Typography>
          <Divider />
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <Divider />

            <Stack justifyContent="space-around" direction="row" flexWrap="wrap">

              <Stack direction="column">

                <div className="padding">
                  <TextField label="Delivery Date"
                             defaultValue={subscriptionState.deliveryDate}
                             onChange={(e) => { handleUpdateSubscriptionState('deliveryDate', e.target.value) }} />
                </div>

                <div className="padding">
                  <TextField label="Purchaser Email"
                             defaultValue={subscriptionState.purchaserEmail}
                             onChange={(e) => { handleUpdateSubscriptionState('purchaserEmail', e.target.value) }} />
                </div>

                <div className="padding">
                  <TextField label="Purchase Price"
                             defaultValue={subscriptionState.purchasePrice}
                             onChange={(e) => { handleUpdateSubscriptionState('purchasePrice', e.target.value) }} />
                </div>

                <div className="padding">
                  <TextField label="Purchaser Note"
                             defaultValue={subscriptionState.purchaserNote}
                             onChange={(e) => { handleUpdateSubscriptionState('purchaserNote', e.target.value) }} />
                </div>

                <div className="padding">
                  <FormControl>
                    <InputLabel>Subscription Package</InputLabel>
                    <Select value={subscriptionState.subscriptionPackage}
                            label="Subscription Package"
                            onChange={(event) => handleUpdateSubscriptionState('subscriptionPackage', event.target.value)}>

                      <MenuItem key="3-month" value="3-month"> 3 Months </MenuItem>
                      <MenuItem key="6-month" value="6-month"> 6 Months </MenuItem>
                      <MenuItem key="12-month" value="12-month"> 12 Months </MenuItem>
                      <MenuItem key="monthlyRenewing" value="monthlyRenewing"> Monthly Renewing </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </Stack>

            </Stack>
          </div>

        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header">
          <Typography className="accordion-title">Subscription Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Divider />

          <Stack alignItems="flex-start" justifyContent="space-around" direction="row" flexWrap="wrap">

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Stack spacing={2} direction="column" className="hidemobile stack" justifyContent="space-around">
                <h3>Dates</h3>

                <FormControl error={requiredFieldBlankError && !subscriptionState.startDate}>
                  <DesktopDatePicker label="Start Date"
                                     inputFormat="MM/dd/yyyy"
                                     value={moment(subscriptionState.startDate).format('MM-DD-YYYY')}
                                     onChange={(newValue) => handleUpdateSubscriptionState('startDate', newValue)}
                                     clearable={true}
                                     renderInput={(params) => <TextField {...params} />} />
                  <FormHelperText>*required field</FormHelperText>
                </FormControl>

                <FormControl error={requiredFieldBlankError && !subscriptionState.startDate}>
                  <DesktopDatePicker label="End Date"
                                     inputFormat="MM/dd/yyyy"
                                     value={moment(subscriptionState.endDate).format('MM-DD-YYYY')}
                                     onChange={(newValue) => handleUpdateSubscriptionState('endDate', newValue)}
                                     clearable={true}
                                     renderInput={(params) => <TextField {...params} />} />
                  <FormHelperText>*required field</FormHelperText>
                </FormControl>

                <FormControl error={requiredFieldBlankError && !subscriptionState.startDate}>
                  <DesktopDatePicker label="Renewal Date"
                                     inputFormat="MM/dd/yyyy"
                                     value={moment(subscriptionState.renewalDate).format('MM-DD-YYYY')}
                                     onChange={(newValue) => handleUpdateSubscriptionState('renewalDate', newValue)}
                                     clearable={true}
                                     renderInput={(params) => <TextField {...params} />} />
                  <FormHelperText>*required field, must be changed if moving start date</FormHelperText>
                </FormControl>
              </Stack>

              <Stack spacing={2} direction="column" className="showmobile stack" justifyContent="center">
                <h3>Dates</h3>

                <FormControl error={requiredFieldBlankError && !subscriptionState.startDate}>
                  <MobileDatePicker label="Start Date"
                                    inputFormat="MM/dd/yyyy"
                                    value={moment(subscriptionState.startDate).format('MM-DD-YYYY')}
                                    onChange={(newValue) => handleUpdateSubscriptionState('startDate', newValue)}
                                    clearable={true}
                                    renderInput={(params) => <TextField {...params} />} />
                  <FormHelperText>*required field</FormHelperText>
                </FormControl>

                <FormControl error={requiredFieldBlankError && !subscriptionState.startDate}>
                  <MobileDatePicker label="End Date"
                                    inputFormat="MM/dd/yyyy"
                                    value={moment(subscriptionState.endDate).format('MM-DD-YYYY')}
                                    onChange={(newValue) => handleUpdateSubscriptionState('endDate', newValue)}
                                    clearable={true}
                                    renderInput={(params) => <TextField {...params} />} />
                  <FormHelperText>*required field</FormHelperText>
                </FormControl>

                <FormControl error={requiredFieldBlankError && !subscriptionState.startDate}>
                  <MobileDatePicker label="Renewal Date"
                                    inputFormat="MM/dd/yyyy"
                                    value={moment(subscriptionState.renewalDate).format('MM-DD-YYYY')}
                                    onChange={(newValue) => handleUpdateSubscriptionState('renewalDate', newValue)}
                                    clearable={true}
                                    renderInput={(params) => <TextField {...params} />} />
                  <FormHelperText>*required field, must be changed if moving start date</FormHelperText>
                </FormControl>
              </Stack>
            </LocalizationProvider>

            <Stack direction="column">
              <h3>Tickets & Points</h3>

              <div className="padding">
                <TextField label="Remaining Tickets for Period"
                           defaultValue={subscriptionState.remainingTicketsForPeriod}
                           helperText="*required field"
                           error={requiredFieldBlankError && !subscriptionState.remainingTicketsForPeriod}
                           onChange={(e) => { handleUpdateSubscriptionState('remainingTicketsForPeriod', e.target.value) }} />
              </div>

              <div className="padding">
                <TextField label="Number Of Tickets Per Period"
                           defaultValue={subscriptionState.numberOfTicketsPerPeriod}
                           helperText="*required field"
                           error={requiredFieldBlankError && !subscriptionState.numberOfTicketsPerPeriod}
                           onChange={(e) => { handleUpdateSubscriptionState('numberOfTicketsPerPeriod', e.target.value) }} />
              </div>

              <div className="padding">
                <TextField label="Current Points"
                           defaultValue={subscriptionState.currentPoints}
                           onChange={(e) => { handleUpdateSubscriptionState('currentPoints', e.target.value) }} />
              </div>

              <div className="padding">
                <TextField label="Total Points"
                           defaultValue={subscriptionState.totalPoints}
                           onChange={(e) => { handleUpdateSubscriptionState('totalPoints', e.target.value) }} />
              </div>
            </Stack>

            <Stack direction="column">
              <h3>Misc</h3>

              <div className="padding">
                <TextField label="Times Renewed"
                           defaultValue={subscriptionState.timesRenewed}
                           onChange={(e) => { handleUpdateSubscriptionState('timesRenewed', e.target.value) }} />
              </div>

              <div className="padding flex-basis-0">
                <FormControl error={requiredFieldBlankError && !subscriptionState.location}>
                  <InputLabel>Location</InputLabel>
                  <Select value={subscriptionState.location}
                          label="Location"
                          onChange={(event) => handleUpdateSubscriptionState('location', event.target.value)}>

                      {locations && locations.map((locationMap, idx) => (
                        <MenuItem key={idx} value={locationMap.name}> {locationMap.name} </MenuItem>
                      ))}
                  </Select>
                  <FormHelperText>*required field</FormHelperText>
                </FormControl>
              </div>

              <FormControlLabel
                control={
                  <Checkbox checked={subscriptionState.isActive}
                            onChange={(e) => { handleUpdateSubscriptionState('isActive', e.target.checked) }} />
                }
                label="Is active" />

            </Stack>
          </Stack>

        </AccordionDetails>
      </Accordion>


      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header">
          <Typography className="accordion-title">Order History</Typography>
          <Divider />
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <Divider />

            <Stack justifyContent="flex-start" spacing={2} direction="row" flexWrap="wrap">
              <Button variant="contained" onClick={handleOpenOrders}>
                View Selected Orders
              </Button>
            </Stack>

            <div style={{ height: 400, width: '100%' }}>
              <DataGrid
                rows={createOrderData(subscription.orders.items)}
                columns={orderColumns}
                height="100%"
                pageSize={5}
                rowsPerPageOptions={[5]}
                checkboxSelection
                onSelectionModelChange={(newSelectionModel) => {
                  setSelectionModel(newSelectionModel)
                }}
              />
            </div>
          </div>
        </AccordionDetails>
      </Accordion>

      {!saving &&
        <div className="save-button">
          <Button variant="contained" onClick={handleSaveChanges}>
            Save changes
          </Button>
        </div>
      }

      {saving &&
        <CircularProgress />
      }

    </Item>
  )
}

function SubscriptionDetails(props) {
  const [loading, setLoading] = useState(true)
  const [updating, setUpdating] = useState(false)
  const [error, setError] = useState(false)

  const [subscription, setSubscription] = useState([])

  const getSubscriptionData = () => {
    try {
      var monthlySubscription = props.monthlySubscriptionQuery.monthlySubscription

      if (subscription) {
        setSubscription(monthlySubscription)
        setLoading(false)
      }

    } catch(err) {
      console.log(err)
      setError(true)
    }
  }

  useEffect(async () => {
    if (loading && !props.monthlySubscriptionQuery.loading) {
      await getSubscriptionData()
    }
  }, [props.monthlySubscriptionQuery, loading])

  return (
    <Box sx={{ width: '100%' }}>
      <div className='data'>

        <Typography sx={{ flex: '1 1 100%' }} variant="h3" className="page-title" component="div">
          Subscription Details
        </Typography>

        {loading &&
          <CircularProgress />
        }

        {error &&
          <h2>Error</h2>
        }

        {subscription && !loading &&
          <SubscriptionData subscription={subscription} client={props.client} />
        }

      </div>
    </Box>
  )
}

const LOCATIONS_LIST_QUERY = gql`
  query LocationsListQuery {
    locationsList(sort: { name: ASC }) {
      items {
        id
        name
      }
    }
  }
`

const MONTHLY_SUBSCRIPTION_QUERY = gql`
  query monthlySubscriptionQuery($id: ID!) {
    monthlySubscription(id: $id) {
      id
      createdAt
      currentPoints
      isActive
      endDate
      renewalDate
      remainingTicketsForPeriod
      numberOfTicketsPerPeriod

      location {
        id
        name
      }

      orders {
        items {
          id
          orderPlacedAt
          numberOfTickets
          requestedShowDate
          requestedShowTime
          isValidOrder

          location {
            id
            name
          }

          orderStatus {
            id
            sentTicketsEmailAt
          }

          orderSurvey {
            id
            rating
          }

          paymentDetails {
            id
            subscriptionDiscountAmount
          }

          pointsRedemptionDetails {
            items {
              id
              pointsRedeemed
            }
          }

          ticketDetails {
            id

            show {
              id
              name
            }
          }
        }
      }

      orderDetails {
        id
        deliveryDate
        purchaserEmail
        purchasePrice
        subscriptionPackage
      }

      startDate
      timesRenewed
      totalPoints

      user {
        id
        firstName
        lastName
        email
      }
    }
  }
`

const MONTHLY_SUBSCRIPTION_UPDATE_MUTATION = gql`
  mutation MonthlySubscriptionUpdateMutation($id: ID!, $currentPoints: Float, $deliveryDate: String, $endDate: Date!,
                                             $isActive: Boolean!, $location: String!, $numberOfTicketsPerPeriod: Int!,
                                             $purchaserEmail: String, $purchaserNote: String, $purchasePrice: Float,
                                             $remainingTicketsForPeriod: Int!, $renewalDate: Date!, $startDate: Date!,
                                             $subscriptionPackage: String, $timesRenewed: Int, $totalPoints: Float,
                                             $userId: ID!) {
    monthlySubscriptionUpdate(data: {
      id: $id
      currentPoints: $currentPoints
      endDate: $endDate
      isActive: $isActive
      numberOfTicketsPerPeriod: $numberOfTicketsPerPeriod
      remainingTicketsForPeriod: $remainingTicketsForPeriod
      renewalDate: $renewalDate
      startDate: $startDate
      timesRenewed: $timesRenewed
      totalPoints: $totalPoints

      orderDetails: { update: {
        deliveryDate: $deliveryDate
        purchaserEmail: $purchaserEmail
        purchasePrice: $purchasePrice
        purchaserNote: $purchaserNote
        subscriptionPackage: $subscriptionPackage
      }}

      user: {
        reconnect: { id: $userId }
      }

      location: {
        reconnect: { name: $location }
      }
    }) {
      id
    }
  }
`

export default withApollo(graphql(MONTHLY_SUBSCRIPTION_QUERY, {
  name: 'monthlySubscriptionQuery',
  options: (ownProps) => {
    const id = ownProps.match.params.id

    return {
      variables: { id },
      notifyOnNetworkStatusChange: true
    }
  }
})(SubscriptionDetails))
