import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import App from './components/App'
import './styles/index.css'
import 'font-awesome/css/font-awesome.css'

import ApolloClient from 'apollo-boost'
import { ApolloProvider } from '@apollo/react-hooks'

import { GRAPHQL_API_URL, GRAPHQL_AUTH_TOKEN_LOCAL_STORAGE } from './constants'

const client = new ApolloClient({
  uri: GRAPHQL_API_URL,
  
  request: (operation) => {
    const token = localStorage.getItem(GRAPHQL_AUTH_TOKEN_LOCAL_STORAGE)

    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : ''
      }
    })
  }
})

ReactDOM.render(
  <BrowserRouter>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </BrowserRouter>
  , document.getElementById('root')
)
