import React, { useState, useEffect } from 'react'
import { gql } from 'apollo-boost'
import { graphql, withApollo } from 'react-apollo'
import moment from 'moment-timezone'
import { useHistory } from 'react-router-dom'

import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { alpha } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import CheckIcon from '@mui/icons-material/Check'
import CircularProgress from '@mui/material/CircularProgress'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'
import FilterListIcon from '@mui/icons-material/FilterList'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import IconButton from '@mui/material/IconButton'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import MobileDatePicker from '@mui/lab/MobileDatePicker'
import Popover from '@mui/material/Popover'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import TextField from '@mui/material/TextField'
import Toolbar from '@mui/material/Toolbar'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { visuallyHidden } from '@mui/utils'

import {
  getBeginningOfTimeStrYYYYMMDD,
  getTenYearsFromNowYYYYMMDD,
  getParamsFromUrl
} from '../utils'


const headCells = [
  {
    id: 'lastName',
    label: 'User',
  },
  {
    id: 'email',
    label: 'Email',
  },
  {
    id: 'mobilePhone',
    label: 'Phone Number',
  },
  {
    id: 'birthday',
    label: 'Birthday',
  },
]

function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  )
}

function createData(users) {
  var formattedData = users && users.map(user => {
    const fullName = `${user.firstName} ${user.lastName}`
    const birthday = user.birthday === 'null' || !user.birthday ? '' : moment(user.birthday).format('YYYY-MM-DD')

    return {
      id: user.id,
      email: user.email.toLowerCase(),
      lastName: toTitleCase(user.lastName),
      fullName: toTitleCase(fullName),
      mobilePhone: user.mobilePhone,
      birthday: birthday
    }
  })

  return formattedData
}

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

const FilterPopover = (props) => {
  const {
    adminFlag,
    bannedFlag,
    endDate,
    executeSearch,
    searchText,
    setAdminFlag,
    setBannedFlag,
    setEndDate,
    setSearchText,
    setStartDate,
    setTalentFlag,
    startDate,
    talentFlag
  } = props.data

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClickButton = (event) => {
    executeSearch()
    handleClose()
  }

  const open = Boolean(anchorEl)
  const id = open ? 'popover' : undefined

  return (
    <div>

      <Tooltip title="Filter list">
        <IconButton aria-describedby={id} onClick={handleClick}>
          <FilterListIcon />
        </IconButton>
      </Tooltip>

      <Popover id={id}
               open={open}
               anchorEl={anchorEl}
               onClose={handleClose}
               anchorOrigin={{
                 vertical: 'bottom',
                 horizontal: 'left',
               }}>

        <div className="filters">
          <h3>Filters</h3>
          <Stack spacing={3} direction="column">
            <div>
              <LocalizationProvider dateAdapter={AdapterDateFns}>

                <Stack spacing={2} direction="column" className="hidemobile stack">
                    <DesktopDatePicker label="Registered Start Date"
                                       inputFormat="MM/dd/yyyy"
                                       value={startDate}
                                       onChange={(newValue) => setStartDate(newValue)}
                                       clearable={true}
                                       renderInput={(params) => <TextField {...params} />} />

                    <DesktopDatePicker label="Registered End Date"
                                       inputFormat="MM/dd/yyyy"
                                       value={endDate}
                                       onChange={(newValue) => setEndDate(newValue)}
                                       clearable={true}
                                       renderInput={(params) => <TextField {...params} />} />
                </Stack>

                <Stack spacing={2} direction="column" className="showmobile stack">
                  <MobileDatePicker label="Registered Start Date"
                                    inputFormat="MM/dd/yyyy"
                                    value={startDate}
                                    onChange={(newValue) => setStartDate(newValue)}
                                    renderInput={(params) => <TextField {...params} />} />

                  <MobileDatePicker label="Registered End Date"
                                    inputFormat="MM/dd/yyyy"
                                    value={endDate}
                                    onChange={(newValue) => setEndDate(newValue)}
                                    renderInput={(params) => <TextField {...params} />} />
                </Stack>
              </LocalizationProvider>
            </div>

            <div>
              <TextField id="outlined-basic"
                         label="User"
                         variant="outlined"
                         onChange={(event) => setSearchText(event.target.value)} />
            </div>

            <Stack spacing={2} direction="column">
              <FormControlLabel
                control={<Checkbox checked={adminFlag}
                                   className="search-checkbox admin-flag-checkbox"
                                   onChange={(event) => setAdminFlag(event.target.checked)} />}
                label="Show Only Admins" />

              <FormControlLabel
                control={<Checkbox checked={bannedFlag}
                                   className="search-checkbox"
                                   onChange={(event) => setBannedFlag(event.target.checked)} />}
                label="Show Only Banned" />

              <FormControlLabel
                control={<Checkbox checked={talentFlag}
                                   className="search-checkbox"
                                   onChange={(event) => setTalentFlag(event.target.checked)} />}
                label="Show Only Talent" />
            </Stack>

            <Button variant="contained" onClick={handleClickButton}>Search</Button>
          </Stack>
        </div>

      </Popover>
    </div>
  )
}

const EnhancedTableHead = (props) => {
  const { order, orderBy, numSelected, rowCount, onRequestSort } = props

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>

        {headCells.map((headCell) => (
          <TableCell key={headCell.id}
                     align='left'
                     padding={'normal'}
                     sortDirection={orderBy === headCell.id ? order : false}>

            <TableSortLabel active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>

          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const EnhancedTableToolbar = (props) => {
  const { history, numSelected, selected } = props

  return (
    <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 }, ...(numSelected > 0 && {
             bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
      }),
    }}>
      <Typography sx={{ flex: '1 1 100%' }} variant="h3" className="page-title" component="div">
        Users
      </Typography>

      <FilterPopover data={props} />
    </Toolbar>
  )
}

function UserList(props) {
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('orderPlacedAt')

  const [selected, setSelected] = useState([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(100)

  const [endDate, setEndDate] = useState(null)
  const [startDate, setStartDate] = useState(null)
  const [searchText, setSearchText] = useState('')

  const [adminFlag, setAdminFlag] = useState(false)
  const [bannedFlag, setBannedFlag] = useState(false)
  const [talentFlag, setTalentFlag] = useState(false)

  const [loadingSearch, setLoadingSearch] = useState(false)
  const [error, setError] = useState(false)
  const [searchedItems, setSearchedItems] = useState(null)

  const history = useHistory()

  const executeSearch = async () => {
    try {
      setLoadingSearch(true)

      var endOfPeriod = endDate
                        ? moment(endDate).format('YYYY-MM-DDThh:mm:ssZ')
                        : moment(getTenYearsFromNowYYYYMMDD()).format('YYYY-MM-DDThh:mm:ssZ')
      var startOfPeriod = startDate
                          ? moment(startDate).format('YYYY-MM-DDThh:mm:ssZ')
                          : moment(getBeginningOfTimeStrYYYYMMDD()).format('YYYY-MM-DDThh:mm:ssZ')

      const result = await props.client.query({
        query: USERS_LIST_QUERY,
        variables: { searchText, isAdmin: adminFlag, isBanned: bannedFlag, endOfPeriod, startOfPeriod }
      })

      var usersList = talentFlag ? result.data.usersList.items.filter(user => user.talent) : result.data.usersList.items

      const users = result.data.usersList.items
      setSearchedItems(users)

      setSearchText('')
      setPage(0)
      setEndDate(null)
      setStartDate(null)
      setLoadingSearch(false)

    } catch(err) {
      setError(true)
      setLoadingSearch(false)
      console.log(err)
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const getItemsToRender = () => {
    return (searchedItems || props.usersListQuery.usersList && props.usersListQuery.usersList.items)
  }

  useEffect(() => {
    history.push(`/users/${page + 1}${props.location.search}`)
  }, [page])

  useEffect(() => {
    var pageNumber = props.location.pathname.slice(7)
    setPage(pageNumber - 1)
  }, [])

  const isSelected = (name) => selected.indexOf(name) !== -1
  const items = getItemsToRender()
  const rows = createData(items)

  const emptyRows = page > 0 && rows ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0

  return (
    <Box sx={{ width: '100%' }}>
      <div className='data'>
        <EnhancedTableToolbar adminFlag={adminFlag}
                              bannedFlag={bannedFlag}
                              endDate={endDate}
                              executeSearch={executeSearch}
                              history={history}
                              searchText={searchText}
                              selected={selected}
                              startDate={startDate}
                              numSelected={selected.length}
                              setAdminFlag={setAdminFlag}
                              setBannedFlag={setBannedFlag}
                              setEndDate={setEndDate}
                              setSearchText={setSearchText}
                              setStartDate={setStartDate}
                              setTalentFlag={setTalentFlag} />

        {(loadingSearch || !rows && props.usersListQuery && props.usersListQuery.loading) &&
          <CircularProgress />
        }

        {(!rows && props.usersListQuery && props.usersListQuery.error) || error &&
          <h2>Error</h2>
        }

        {rows && !loadingSearch &&
          <div>
            <TableContainer>
              <Table sx={{ minWidth: 750 }}
                     aria-labelledby="table-title"
                     size='medium'>

                <EnhancedTableHead order={order}
                                   orderBy={orderBy}
                                   onRequestSort={handleRequestSort}
                                   rowCount={rows.length}/>
                <TableBody>

                  {rows.slice().sort(getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`

                      const user = (<a href={'/user/' + row.id}>{row.fullName}</a>)

                      return (
                        <TableRow hover
                                  role="checkbox"
                                  tabIndex={-1}
                                  key={row.id}>

                          <TableCell component="th"
                                     id={labelId}
                                     scope="row"
                                     padding="none">
                            {user}
                          </TableCell>
                          <TableCell align="left">{row.email}</TableCell>
                          <TableCell align="left">{row.mobilePhone}</TableCell>
                          <TableCell align="left">{row.birthday}</TableCell>
                        </TableRow>
                      )
                    })}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }} >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}

                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination rowsPerPageOptions={[5, 10, 25, 50, 100]}
                             component="div"
                             count={rows.length}
                             rowsPerPage={rowsPerPage}
                             page={page}
                             onPageChange={handleChangePage}
                             showFirstButton={true}
                             showLastButton={true}
                             onRowsPerPageChange={handleChangeRowsPerPage}/>
          </div>
        }
      </div>
    </Box>
  )
}

export const USERS_LIST_QUERY = gql`
  query UsersListQuery($searchText: String, $skip: Int, $isAdmin: Boolean, $isBanned: Boolean,
                       $endOfPeriod: DateTime, $startOfPeriod: DateTime) {
    usersList (
      first: 1000,
      skip: $skip,
      sort: { dateRegistered : DESC },
      filter: {
        isAdmin: { equals: $isAdmin },
        isBanned: { equals: $isBanned }
        AND: [
          { dateRegistered: { gte: $startOfPeriod }},
          { dateRegistered: { lte: $endOfPeriod }},

          {OR: [{
            email: { contains: $searchText }
          }, {
            firstName: { contains: $searchText }
          }, {
            lastName: { contains: $searchText }
          }, {
            mobilePhone: { contains: $searchText }
          }]}
        ]
      }
    ) {
      count

      items {
        id
        birthday
        createdAt
        isAdmin
        dateRegistered
        email
        firstName
        lastName
        mobilePhone
        zipCode

        showsSeen {
          items {
            id
            name
          }
        }

        talent {
          id
          paymentAccount
        }

        orders {
          items {
            id
          }
        }
      }
    }
  }
`

const USER_CREATE_MUTATION = gql`
  mutation UserCreateMutation {
    userCreate(data: {
      email: "placeholder@broadwayroulette.com"
      firstName: "Fill"
      lastName: "Out"
      mobilePhone: "+1 000-000-0000"
      roles: {
        connect: { name: "User" }
      }
    }) {
      id
    }
  }
`

export default withApollo(graphql(USERS_LIST_QUERY, {
  name: 'usersListQuery',
  options: (ownProps) => {
    return {
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
      variables: getParamsFromUrl(ownProps)
    }
  }
})(UserList))
