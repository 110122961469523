import React, { Component } from 'react'
import { gql } from 'apollo-boost'
import { graphql, withApollo } from 'react-apollo'
import { withRouter } from 'react-router'

import Button from '@material-ui/core/Button'
import FontAwesome from 'react-fontawesome'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import { ENTRIES_PER_PAGE } from '../constants'
import { getBeginningOfTimeStrYYYYMMDD, getParamsFromUrl, getTodayStrYYYYMMDD } from '../utils'


class PurchaseCodeSummary extends Component {

  render() {
    const code = this.props.purchaseCode

    var referredEmail = ''
    var referrerEmail = ''
    var isReward = false

    if (code.referral) {
     referredEmail = code.referral.referredEmail
     referrerEmail = code.referral.referer.email
    }

    if (code.referralReward) {
      referredEmail = code.referralReward.referral.referredEmail
      referrerEmail = code.referralReward.referral.referer.email
      isReward = true
    }

    return (
      <TableRow>
        <TableCell>{code.code}</TableCell>
        <TableCell>{ referredEmail }</TableCell>
        <TableCell>{ referrerEmail }</TableCell>
        <TableCell>{ isReward && <FontAwesome name="check"/>}</TableCell>
        <TableCell>{code.isExpired && <FontAwesome name="check"/>}</TableCell>
      </TableRow>
    )
  }
}

class ReferralList extends Component {

  state = { }

  _getItemsToRender = () => {
    return this.props.purchaseCodesListQuery.purchaseCodesList.items
  }

  _nextPage = () => {
    const page = parseInt(this.props.match.params.page, 10)
    const nextPage = page + 1

    const wantsOrdersForToday = this.props.location.pathname.includes('today')
    const todayPart = wantsOrdersForToday ? '-today' : ''

    this.props.history.push(`/referrals${todayPart}/${nextPage}`)
  }

  _previousPage = () => {
    const page = parseInt(this.props.match.params.page, 10)
    if (page > 1) {
      const nextPage = page - 1

      const wantsOrdersForToday = this.props.location.pathname.includes('today')
      const todayPart = wantsOrdersForToday ? '-today' : ''

      this.props.history.push(`/referrals${todayPart}/${nextPage}`)
    }
  }

  render() {
    if (this.props.purchaseCodesListQuery && this.props.purchaseCodesListQuery.loading) {
      return <div>Loading</div>
    }

    if (this.props.purchaseCodesListQuery && this.props.purchaseCodesListQuery.error) {
      return <div>Error</div>
    }

    const itemsToRender = this._getItemsToRender()

    var islastPage = this.props.purchaseCodesListQuery.purchaseCodesList.count - this.props.purchaseCodesListQuery.variables.skip < ENTRIES_PER_PAGE
    const page = parseInt(this.props.match.params.page, 10)
    var itemOffset = (page - 1) * ENTRIES_PER_PAGE

    return (
      <div>
        <div>
          <h1 style={{ display: 'inline-block' }}>Referrals</h1>
        </div>

        <Paper >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Code</TableCell>
                <TableCell>Referred</TableCell>
                <TableCell>Referrer</TableCell>
                <TableCell>Reward</TableCell>
                <TableCell>Used</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {itemsToRender.map((purchaseCode, index) => (
                <PurchaseCodeSummary key={purchaseCode.id}
                                     purchaseCode={purchaseCode}
                                     index={itemOffset + index}/>
              ))}
            </TableBody>
          </Table>
        </Paper>
        <div>
          <Button variant="contained"
                  disabled={this.props.match.params.page <= 1}
                  style={{ margin: '10px 10px 15px 0'}}
                  onClick={() => { this._previousPage() }}>
          Previous
          </Button>
          <Button variant="contained"
                  disabled={islastPage}
                  style={{ margin: '10px 10px 15px 0'}}
                  onClick={() => { this._nextPage() }}>
          Next
          </Button>
        </div>
      </div>
    )
  }
}

const PURCHASE_CODES_LIST_QUERY = gql`
  query PurchaseCodesListQuery($first: Int, $skip: Int) {
    purchaseCodesList(
      first: $first,
      skip: $skip,
      sort: { updatedAt : DESC },
      filter: {
        OR: [
          {
            purchaseCodeType: { equals: "Referral" }
          },
          {
              purchaseCodeType: { equals: "ReferralReward" }
          }
        ]
      }
    ) {
      items {
        id
        code
        isExpired
        purchaseCodeType

        referral {
          referredEmail
          referralReward {
            id
          }
          referer {
            email
          }
        }

        referralReward {
          referral {
            referredEmail
            referer {
              email
            }
          }
        }
      }
    }
  }
`

export default withRouter(withApollo(graphql(PURCHASE_CODES_LIST_QUERY, {
  name: 'purchaseCodesListQuery',
  options: (ownProps) => {
    const wantsOrdersForToday = ownProps.location.pathname.includes('today')
    var date = wantsOrdersForToday ? getTodayStrYYYYMMDD() : getBeginningOfTimeStrYYYYMMDD()

    return {
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
      variables: { ...getParamsFromUrl(ownProps), date }
    }
  }
})(ReferralList)))
