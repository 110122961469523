import React, { useState, useEffect } from 'react'
import { gql } from 'apollo-boost'
import { graphql, withApollo } from 'react-apollo'
import moment from 'moment-timezone'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import { withRouter } from 'react-router'

import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { alpha } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import CheckIcon from '@mui/icons-material/Check'
import CircularProgress from '@mui/material/CircularProgress'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'
import FilterListIcon from '@mui/icons-material/FilterList'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import IconButton from '@mui/material/IconButton'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import MobileDatePicker from '@mui/lab/MobileDatePicker'
import Popover from '@mui/material/Popover'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import TextField from '@mui/material/TextField'
import Toolbar from '@mui/material/Toolbar'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { visuallyHidden } from '@mui/utils'

import {
  getBeginningOfTimeStrYYYYMMDD,
  getTenYearsFromNowYYYYMMDD,
  getParamsFromUrl
} from '../utils'

const ONE_WEEK_AGO = moment().subtract(7,'d').format('YYYY-MM-DD')

const headCells = [
  {
    id: 'orderId',
    label: 'Order',
  },
  {
    id: 'customer',
    label: 'User',
  },
  {
    id: 'requestedShowDate',
    label: 'Show Date',
  },
  {
    id: 'restaurantName',
    label: 'Restaurant',
  },
  {
    id: 'time',
    label: 'Time',
  },
  {
    id: '',
    label: '',
  },
]

function createData(reservations) {
  var formattedData = reservations && reservations.map(reservation => {
    const order = reservation.order

    return {
      orderId: order.id,
      user: order.user,
      customer: order.user.email.toLowerCase(),
      requestedShowDate: order.requestedShowDate,
      restaurant: reservation.restaurant,
      restaurantName: reservation.restaurant ? reservation.restaurant.name : 'Coming soon',
      time: reservation.time
    }
  })

  return formattedData
}

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

const FilterPopover = (props) => {
  const {
    startDate,
    endDate,
    executeSearch,
    searchText,
    setStartDate,
    setEndDate,
    setLocation,
    setSearchText
  } = props.data

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClickButton = (event) => {
    executeSearch()
    handleClose()
  }

  const open = Boolean(anchorEl)
  const id = open ? 'popover' : undefined

  return (
    <div>

      <Tooltip title="Filter list">
        <IconButton aria-describedby={id} onClick={handleClick}>
          <FilterListIcon />
        </IconButton>
      </Tooltip>

      <Popover id={id}
               open={open}
               anchorEl={anchorEl}
               onClose={handleClose}
               anchorOrigin={{
                 vertical: 'bottom',
                 horizontal: 'left',
               }}>

        <div className="filters">
          <h3>Filters</h3>
          <Stack spacing={3} direction="column">
            <div>
              <LocalizationProvider dateAdapter={AdapterDateFns}>

                <Stack spacing={2} direction="column" className="hidemobile stack">
                    <DesktopDatePicker label="Start Date"
                                       inputFormat="MM/dd/yyyy"
                                       value={startDate}
                                       onChange={(newValue) => setStartDate(newValue)}
                                       clearable={true}
                                       renderInput={(params) => <TextField {...params} />} />

                    <DesktopDatePicker label="End Date"
                                       inputFormat="MM/dd/yyyy"
                                       value={endDate}
                                       onChange={(newValue) => setEndDate(newValue)}
                                       clearable={true}
                                       renderInput={(params) => <TextField {...params} />} />
                </Stack>

                <Stack spacing={2} direction="column" className="showmobile stack">
                  <MobileDatePicker label="Start date"
                                    inputFormat="MM/dd/yyyy"
                                    value={startDate}
                                    onChange={(newValue) => setStartDate(newValue)}
                                    renderInput={(params) => <TextField {...params} />} />

                  <MobileDatePicker label="End date"
                                    inputFormat="MM/dd/yyyy"
                                    value={endDate}
                                    onChange={(newValue) => setEndDate(newValue)}
                                    renderInput={(params) => <TextField {...params} />} />
                </Stack>
              </LocalizationProvider>
            </div>

            <div>
              <TextField id="outlined-basic"
                         label="User Email"
                         variant="outlined"
                         onChange={(event) => setSearchText(event.target.value)} />
            </div>

            <Button variant="contained" onClick={handleClickButton}>Search</Button>
          </Stack>
        </div>

      </Popover>
    </div>
  )
}

const EnhancedTableHead = (props) => {
  const { order, orderBy, rowCount, onRequestSort } = props

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>

        {headCells.map((headCell) => (
          <TableCell key={headCell.id}
                     align='left'
                     padding={'normal'}
                     sortDirection={orderBy === headCell.id ? order : false}>

            <TableSortLabel active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>

          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const EnhancedTableToolbar = (props) => {
  const { history, selected } = props

  return (
    <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 }}}>
      <Typography sx={{ flex: '1 1 100%' }} variant="h3" className="page-title" component="div">
        Restaurant Reservations
      </Typography>

      <FilterPopover data={props}/>
    </Toolbar>
  )
}

function RestaurantReservationsList(props) {
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('orderPlacedAt')

  const [selected, setSelected] = useState([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const [endDate, setEndDate] = useState(null)
  const [startDate, setStartDate] = useState(null)
  const [searchText, setSearchText] = useState('')

  const [loadingSearch, setLoadingSearch] = useState(false)
  const [error, setError] = useState(false)
  const [searchedItems, setSearchedItems] = useState(null)

  const history = useHistory()

  const executeSearch = async () => {
    try {
      setLoadingSearch(true)

      var endOfPeriod = endDate ? moment(endDate).format('YYYY-MM-DD') : getTenYearsFromNowYYYYMMDD()
      var startOfPeriod = startDate ? moment(startDate).format('YYYY-MM-DD') : getBeginningOfTimeStrYYYYMMDD()

      var filterReservationsQuery = gql`
        query restaurantReservationDetailsListQuery($searchText: String, $startOfPeriod: Date, $endOfPeriod: Date) {
          restaurantReservationDetailsList (
            first: 1000,
            sort: { createdAt : DESC },
            filter: {
              AND: [
                { order: { requestedShowDate: { gte: $startOfPeriod }}},
                { order: { requestedShowDate: { lte: $endOfPeriod }}},
                { order: { user: { email: { contains: $searchText }}}},
              ]
            }
          ) {
            items {
              id
              time

              order {
                id
                requestedShowDate

                user {
                  id
                  email
                  firstName
                  lastName
                }
              }

              restaurant {
                id
                name
              }
            }
          }
        }
      `

      var result = await props.client.query({
        query: filterReservationsQuery,
        variables: {
          searchText,
          endOfPeriod,
          startOfPeriod,
        }
      })

      const reservations = result.data.restaurantReservationDetailsList.items
      setSearchedItems(reservations)

      setSearchText('')
      setEndDate(null)
      setStartDate(null)
      setLoadingSearch(false)

    } catch(err) {
      setError(true)
      setLoadingSearch(false)
      console.log(err)
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const getItemsToRender = () => {
    return (searchedItems || props.restaurantReservationDetailsListQuery.restaurantReservationDetailsList && props.restaurantReservationDetailsListQuery.restaurantReservationDetailsList.items)
  }

  const items = getItemsToRender()
  const rows = createData(items)

  const emptyRows = page > 0 && rows ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0

  return (
    <Box sx={{ width: '100%' }}>
      <div className='data'>
        <EnhancedTableToolbar endDate={endDate}
                              executeSearch={executeSearch}
                              history={history}
                              searchText={searchText}
                              selected={selected}
                              startDate={startDate}
                              setEndDate={setEndDate}
                              setSearchText={setSearchText}
                              setStartDate={setStartDate} />

        {(loadingSearch || !rows && props.restaurantReservationDetailsListQuery && props.restaurantReservationDetailsListQuery.loading) &&
          <CircularProgress />
        }

        {(!rows && props.restaurantReservationDetailsListQuery && props.restaurantReservationDetailsListQuery.error) || error &&
          <h2>Error</h2>
        }

        {rows && !loadingSearch &&
          <div>
            <TableContainer>
              <Table sx={{ minWidth: 750 }}
                     aria-labelledby="table-title"
                     size='medium'>

                <EnhancedTableHead order={order}
                                   orderBy={orderBy}
                                   onRequestSort={handleRequestSort}
                                   rowCount={rows.length}/>
                <TableBody>

                  {rows.slice().sort(getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`

                      const orderId = (<a href={'/order?id=' + row.orderId}>{row.orderId.substr(row.orderId.length-6)}</a>)
                      const user = (<a href={'/user/' + row.user.id}>{row.customer}</a>)
                      const restaurant = row.restaurant ? (<a href={'/restaurant/' + row.restaurant.id}>{row.restaurantName}</a>) : row.restaurantName

                      const assign = (
                        <a href={`/ticket-assignments?startDate=${row.requestedShowDate}&endDate=${row.requestedShowDate}`}>
                          Modify Reservation
                        </a>
                      )

                      return (
                        <TableRow hover
                                  role="checkbox"
                                  tabIndex={-1}
                                  key={row.id}>

                          <TableCell component="th"
                                     id={labelId}
                                     scope="row"
                                     padding="none">
                            {orderId}
                          </TableCell>
                          <TableCell align="left">{user}</TableCell>
                          <TableCell align="left">{row.requestedShowDate}</TableCell>
                          <TableCell align="left">{restaurant}</TableCell>
                          <TableCell align="left">{row.time}</TableCell>
                          <TableCell align="left">{assign}</TableCell>
                        </TableRow>
                      )
                    })}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }} >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}

                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination rowsPerPageOptions={[5, 10, 25, 50, 100]}
                             component="div"
                             count={rows.length}
                             rowsPerPage={rowsPerPage}
                             page={page}
                             onPageChange={handleChangePage}
                             showFirstButton={true}
                             showLastButton={true}
                             onRowsPerPageChange={handleChangeRowsPerPage}/>
          </div>
        }
      </div>
    </Box>
  )
}

const RESTAURANT_RESERVATIONS_DETAILS_LIST_QUERY = gql`
  query RestaurantReservationsDetailsListQuery($first: Int, $skip: Int) {
    restaurantReservationDetailsList(
      first: $first,
      skip: $skip,
      filter: {
        AND: [
          { order: { requestedShowDate: { gte: "${ONE_WEEK_AGO}" }}}
          { order: { location: { name: { equals: "Broadway" }}}}
        ]
      },
      sort: { order: { requestedShowDate: ASC }}
    ) {
      count

      items {
        id
        time
        
        order {
          id
          requestedShowDate

          user {
            id
            email
            firstName
            lastName
          }
        }
        
        restaurant {
          id
          name
        }
      }  
    }
  }
`

export default withRouter(withApollo(graphql(RESTAURANT_RESERVATIONS_DETAILS_LIST_QUERY, {
  name: 'restaurantReservationDetailsListQuery',
  options: (ownProps) => {
    const wantsOrdersForToday = ownProps.location.pathname.includes('today')
    var date = wantsOrdersForToday ? getTodayStrYYYYMMDD() : getBeginningOfTimeStrYYYYMMDD()

    return {
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
      variables: { ...getParamsFromUrl(ownProps), date }
    }
  }
})(RestaurantReservationsList)))
