import React, { useState, useEffect } from 'react'
import AWS from 'aws-sdk'
import Dropzone from 'react-dropzone'
import { gql } from 'apollo-boost'
import { graphql, withApollo } from 'react-apollo'
import { useHistory } from 'react-router-dom'
import moment from 'moment-timezone'
import queryString from 'query-string'
import S3 from 'aws-s3'

import AdapterDateFns from '@mui/lab/AdapterDateFns'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardMedia from '@mui/material/CardMedia'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import CheckIcon from '@mui/icons-material/Check'
import Chip from '@mui/material/Chip'
import CircularProgress from '@mui/material/CircularProgress'
import Collapse from '@mui/material/Collapse'
import DateRangePicker from '@mui/lab/DateRangePicker'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'
import Divider from '@mui/material/Divider'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FavoriteIcon from '@mui/icons-material/Favorite'
import FilterListIcon from '@mui/icons-material/FilterList'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import InputLabel from '@mui/material/InputLabel'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import MenuItem from '@mui/material/MenuItem'
import MobileDatePicker from '@mui/lab/MobileDatePicker'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { green, red } from '@mui/material/colors'
import Popover from '@mui/material/Popover'
import ShareIcon from '@mui/icons-material/Share'
import Select from '@mui/material/Select'
import SortIcon from '@mui/icons-material/Sort'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'

import { CHIP_COLORS } from '../colors'

import BRClient from '../broadwayRouletteClient'

import {
  AWS_ACCESS_KEY_ID,
  AWS_ACCESS_KEY,
  AWS_GOLDSTAR_TICKETS_BUCKET_URL,
  LOCALE,
  TODAY
} from '../constants'

import './TicketAssignments.css'

const options = {
  accessKeyId: AWS_ACCESS_KEY_ID,
  secretAccessKey: AWS_ACCESS_KEY,
}

const s3 = new AWS.S3(options)

const MAX_FILE_SIZE = 15000000
const MAX_RANGE_IN_DAYS = 15
const DEFAULT_RANGE_IN_DAYS = 1

const EXCLUSION_THRESHOLD = 2
const BELIEVEABLE_SAVE_TIME_IN_MS = 500
const CLIENT_VERSION_FOR_MUSICAL_ONLY = "2.10.0"

const isProd = process.env.REACT_APP_STAGE === 'PROD' ? true : false

const getDateRange = (ownProps) => {
  const queryParams = queryString.parse(ownProps.location.search)

  var startOfPeriod = queryParams.startDate ? moment(queryParams.startDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
  var endOfPeriod = queryParams.endDate ? moment(queryParams.endDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')

  return [startOfPeriod, endOfPeriod]
}

const getEventSelectionColors = (rank, isSelected) => {
  var selectionKey = isSelected ? 'selected' : 'unselected'

  return CHIP_COLORS[rank][selectionKey]
}

const ExpandMore = styled((props) => {
  const { expand, ...other } = props
  return <IconButton {...other} />
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}))

function RestaurantSelectionList(props) {
  const { onChange, restaurants, restaurantsBeenToList } = props

  const handleTapRestaurant = (restaurantId) => {
    onChange(restaurantId)
  }

  return (
    <div>
      {restaurants.map(restaurant => {
        const restaurantId = restaurant.id
        var restaurantsBeenTo = restaurantsBeenToList && restaurantsBeenToList.filter(restaurant => restaurant === restaurantId)

        var isSelected = restaurantId === props.selectedRestaurantId
        var selectionColors = getEventSelectionColors('yes', isSelected)

        if (restaurantsBeenToList && restaurantsBeenTo.indexOf(restaurantId) > -1 && !isSelected) {
          selectionColors = getEventSelectionColors('no', isSelected)
        }

        return (
          <Chip key={restaurant.id}
                style={{ backgroundColor: selectionColors.background, margin: "4px" }}
                label={restaurant.name}
                onClick={() => handleTapRestaurant(restaurantId)} />
        )
      })}
    </div>
  )
}

function EventSelection(props) {
  const { isSelected, onChange, eventRank } = props

  const handleTapEvent = () => {
    onChange(props.eventRank.event.id)
  }

  var selectionColors = getEventSelectionColors(eventRank.overallRank, isSelected)

  var isExcluded = eventRank.annotations.isExcludedEvent
  var hasOrWillSeeEvent = eventRank.annotations.hasOrWillSeeEvent

  var label = `${eventRank.event.shortName}`
  var iconText = ''

  if (isExcluded) {
    iconText = 'X'
  } else if (hasOrWillSeeEvent) {
    iconText = 'S'
  }

  var annotationIcon = iconText ? (
    <Avatar style={{ backgroundColor: selectionColors.avatarBackground, color: selectionColors.avatarColor, size: 32 }}>
      {iconText}
    </Avatar>
  ) : null

  return (
    <Chip style={{ backgroundColor: selectionColors.background, margin: '4px' }}
          avatar={annotationIcon}
          label={label}
          onClick={() => handleTapEvent()} />
  )
}

function EventSelectionList(props) {
  const { onChange, selectedEventId, eventRankings } = props

  return (
    <div>
        {eventRankings.yes.map(eventRank => {
           var isSelected = eventRank.event.id === selectedEventId

           return <EventSelection key={eventRank.event.id}
                                  eventRank={eventRank}
                                  isSelected={isSelected}
                                  onChange={onChange} />
        })}

        {eventRankings.maybe.map(eventRank => {
           var isSelected = eventRank.event.id === selectedEventId

           return <EventSelection key={eventRank.event.id}
                                  eventRank={eventRank}
                                  isSelected={isSelected}
                                  onChange={onChange} />
        })}

        {eventRankings.no.map(eventRank => {
           var isSelected = eventRank.event.id === selectedEventId

           return <EventSelection key={eventRank.event.id}
                                  eventRank={eventRank}
                                  isSelected={isSelected}
                                  onChange={onChange} />
        })}
      </div>
  )
}

function FilterPopover(props) {
  const {
    getItemsToRender,
    location,
    setLocation,
    setEventDateRange,
    setEventTime,
    setEventPreferenceFilter,
    eventDateRange,
    eventPreferenceFilter,
    eventTime
  } = props

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClickButton = () => {
    getItemsToRender()
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'popover' : undefined

  return (
    <div>
      <Tooltip title="Filter list">
        <IconButton aria-describedby={id} onClick={handleClick}>
          <FilterListIcon />
        </IconButton>
      </Tooltip>

      <Popover id={id}
               open={open}
               anchorEl={anchorEl}
               onClose={handleClose}
               anchorOrigin={{
                 vertical: 'bottom',
                 horizontal: 'left',
               }}>

        <div className="filters">
          <h3>Filters</h3>

          <Stack spacing={3} direction="column" justifyContent="flexStart" alignItems="center" className="filter-section">
            <FormControl>
              <InputLabel id="select-label">Location</InputLabel>
              <Select labelId="select-label"
                      id="select"
                      value={location}
                      label="Location"
                      onChange={(event) => setLocation(event.target.value)}>
                <MenuItem value="Broadway">Broadway</MenuItem>
                <MenuItem value="West End">West End</MenuItem>
                <MenuItem value="Los Angeles">Los Angles</MenuItem>
              </Select>
            </FormControl>

            <FormControl>
              <InputLabel id="select-label">Event Time</InputLabel>
              <Select labelId="select-label"
                      id="select"
                      value={eventTime}
                      label="Location"
                      onChange={(event) => setEventTime(event.target.value)}>
                <MenuItem value="Evening">Evening</MenuItem>
                <MenuItem value="Afternoon">Afternoon</MenuItem>
                <MenuItem value="AllDay">All-Day</MenuItem>
                <MenuItem value="All">All</MenuItem>
              </Select>
            </FormControl>

            <FormControl>
              <InputLabel id="select-label">Category</InputLabel>
              <Select labelId="select-label"
                      id="select"
                      value={eventPreferenceFilter}
                      label="Location"
                      onChange={(event) => setEventPreferenceFilter(event.target.value)}>
                <MenuItem value="Arts">Arts</MenuItem>
                <MenuItem value="Attractions">Attractions</MenuItem>
                <MenuItem value="Premium">Premium</MenuItem>
                <MenuItem value="All">All</MenuItem>
              </Select>
            </FormControl>
          </Stack>
        </div>

      </Popover>
    </div>
  )
}

function SortPopover(props) {
  const { setSortBy, setSortDirection, sortBy, sortDirection } = props

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'popover' : undefined

  return (
    <div>
      <Tooltip title="Filter list">
        <IconButton aria-describedby={id} onClick={handleClick}>
          <SortIcon />
        </IconButton>
      </Tooltip>

      <Popover id={id}
               open={open}
               anchorEl={anchorEl}
               onClose={handleClose}
               anchorOrigin={{
                 vertical: 'bottom',
                 horizontal: 'left',
               }}>

        <div className="filters">
          <h3>Sort</h3>
          <Stack spacing={3} direction="column" justifyContent="flexStart" alignItems="center" className="filter-section">
            <FormControl>
              <InputLabel id="select-label">Sort By</InputLabel>
              <Select labelId="select-label"
                      id="select"
                      value={sortBy}
                      label="Sort By"
                      onChange={(event) => setSortBy(event.target.value)}>
                <MenuItem value="name">Last Name</MenuItem>
                <MenuItem value="requestedDate">Event Date</MenuItem>
                <MenuItem value="orderPlacedAt">Order Placed Date</MenuItem>
              </Select>
            </FormControl>

            <FormControl>
              <InputLabel id="select-label">Sort Direction</InputLabel>
              <Select labelId="select-label"
                      id="select"
                      value={sortDirection}
                      label="Sort Direction"
                      onChange={(event) => setSortDirection(event.target.value)}>
                <MenuItem value="asc">Ascending</MenuItem>
                <MenuItem value="desc">Descending</MenuItem>
              </Select>
            </FormControl>
          </Stack>
        </div>

      </Popover>
    </div>
  )
}

function EventTicketAssignmentsCard(props) {
  const { blackoutDates, order, restaurants, events } = props

  const ticketDetails = order.ticketDetails
  const restaurantReservationDetails = order.restaurantReservationDetails

  const eventId = ticketDetails.event ? ticketDetails.event.id : null

  const restaurantId = restaurantReservationDetails && restaurantReservationDetails.restaurant
                       ? restaurantReservationDetails.restaurant.id : null
  const madeBy = restaurantReservationDetails ? restaurantReservationDetails.madeBy : null
  const time = restaurantReservationDetails ? restaurantReservationDetails.time : null

  const [restaurantReservationDetailsState, setRestaurantReservationDetailsState] = useState({
    madeBy,
    restaurantId,
    time
  })

  const [ticketDetailsState, setTicketDetailsState] = useState({
    checkedBy: ticketDetails.checkedBy,
    listPrice: ticketDetails.listPrice,
    notes: ticketDetails.notes,
    purchasedBy: ticketDetails.purchasedBy,
    purchaseDate: ticketDetails.purchaseDate ? moment(ticketDetails.purchaseDate).format('YYYY-MM-DDTHH:MM') : null,
    purchasePrice: ticketDetails.purchasePrice,
    seats: ticketDetails.seats,
    eventTime: ticketDetails.eventTime,
    sourceOrderId: ticketDetails.sourceOrderId,
    source: ticketDetails.source
  })

  const [saving, setSaving] = useState(false)
  const [expanded, setExpanded] = useState(false)

  const [selectedEventId, setSelectedEventId] = useState(eventId)
  const [selectedRestaurantId, setSelectedRestaurantId] = useState(restaurantId)

  const [ticketImageURL, setTicketImageURL] = useState(ticketDetails.ticketImageURL)
  const [ticketImageAWSLink, setTicketImageAWSLink] = useState(false)

  const handleUpdateRestaurantDetails = (key, value) => {
    var newRestaurantDetailsState = {...restaurantReservationDetailsState}
    newRestaurantDetailsState[key] = value

    setRestaurantReservationDetailsState(newRestaurantDetailsState)
  }

  const handleUpdateTicketDetails = (key, value) => {
    var newTicketDetailsState = {...ticketDetailsState}
    newTicketDetailsState[key] = value

    setTicketDetailsState(newTicketDetailsState)
  }

  const getAnnotations = (order, event, excludedEventIds, eventsAssignedToUserIds) => {
    var isExcludedEvent = event.id in excludedEventIds
    var hasOrWillSeeEvent = event.id in eventsAssignedToUserIds

    var hasPartnerTicketsAvailableOnDay = !(blackoutDates && blackoutDates[event.id])

    return {
      isExcludedEvent,
      hasOrWillSeeEvent,
      hasPartnerTicketsAvailableOnDay
    }
  }

  const getIsExcludingTooMuchOfOneType = (allEvents, excludedEvents) => {
    var numberOfActivePlays = 0
    var numberOfActiveMusicals = 0

    var numberOfExcludedPlays = 0
    var numberOfExcludedMusicals = 0

    allEvents.forEach(event => {
      event.isMusical ? numberOfActiveMusicals++ : numberOfActivePlays++
    })

    excludedEvents.forEach(event => {
      event.isMusical ? numberOfExcludedMusicals++ : numberOfExcludedPlays++
    })

    var isExcludingTooManyPlays = (numberOfActivePlays - numberOfExcludedPlays) <= EXCLUSION_THRESHOLD
    var isExcludingTooManyMusicals = (numberOfActiveMusicals - numberOfExcludedMusicals) <= EXCLUSION_THRESHOLD

    return isExcludingTooManyPlays || isExcludingTooManyMusicals
  }

  const getEventSchedule = (event) => {

    return event
  }

  const getMatchData = (order, excludedEventIds,isExcludingTooMuchOfOneType, eventsAssignedToUserIds, event) => {
    var doesSatisfyPreference = event.eventCategory === order.eventPreference

    var isExcludedEvent = event.id in excludedEventIds
    var hasOrWillSeeEvent = event.id in eventsAssignedToUserIds

    var matchResults = []
    var isClientVersionValid = (order.clientVersion && order.clientVersion.split('.').length === 3)

    if (isExcludedEvent) {
      matchResults.push({ result: 'no', reason: 'This event has been excluded' })
    } if (hasOrWillSeeEvent) {
      matchResults.push({ result: 'no', reason:'The customer has/will see this event'})
    } if (!doesSatisfyPreference) {
      matchResults.push({ result: 'no', reason: 'The customer selected a different category' })
    }

    var result = 'yes'
    if (matchResults.some(match => match.result === 'maybe')) { result = 'maybe' }
    if (matchResults.some(match => match.result === 'no')) { result = 'no' }
    if (matchResults.some(match => match.result === 'unavailable')) { result = 'unavailable' }

    return {
      overallResult: result,
      event,
      matchResults
    }
  }

  const getEventRankingsForOrder = (order, allEvents, eventsAssignedToUserIds) => {
    var filteredEvents = allEvents.filter(event => moment(event.startDate) <= moment(order.requestedDate))
                                .filter(event => !event.endDate || moment(event.endDate) > moment(order.requestedDate))
                                .filter(event => event.location.name === order.location.name)
                                .filter(event => event.dateAdded === null || moment(event.dateAdded) < moment(order.orderPlacedAt))
                                .filter(event => event.eventCategory === order.eventPreference)

    var excludedEventIds = {}
    order.excludedEvents.items.forEach(event => { excludedEventIds[event.id] = true })

    var isExcludingTooMuchOfOneType = getIsExcludingTooMuchOfOneType(filteredEvents, order.excludedEvents.items)

    var matches = filteredEvents.map(event => {
      return getMatchData(order, excludedEventIds, isExcludingTooMuchOfOneType, eventsAssignedToUserIds, event)
    })

    var eventIdToAnnotations = {}
    filteredEvents.forEach(event => {
      eventIdToAnnotations[event.id] = getAnnotations(order, event, excludedEventIds, eventsAssignedToUserIds)
    })

    var eventRankings = {
      yes: [],
      maybe: [],
      no: [],
      unavailable: [],
    }

    matches.forEach(match => {
      eventRankings[match.overallResult].push({
        overallRank: match.overallResult,
        event: match.event,
        matchResults: match.matchResults,
        annotations: eventIdToAnnotations[match.event.id]
      })
    })

    return eventRankings
  }

  const getTicketImage = async () => {
    var key = ticketImageURL ? ticketImageURL.replace(AWS_GOLDSTAR_TICKETS_BUCKET_URL + '/', '') : null

    if (key) {
      var params = {
        Bucket: 'goldstarroulette-tickets',
        Key: key
      }

      await s3.getSignedUrl('getObject', params, (err, data) => {
        if (err) {
          console.log(err)
          return
        } else {
          setTicketImageAWSLink(data)
        }
      })
    }
  }

  const handleClickClearButton = async () => {
    setSaving(true)

    try {
      if (selectedEventId) {
        var ticketDetailsResult = await props.client.mutate({
          mutation: CLEAR_TICKET_DETAILS_MUTATION,
          variables: {
            id: order.ticketDetails.id,
            eventId: selectedEventId
          }
        })
      }

      if (order.restaurantReservationDetails && selectedRestaurantId) {
        var restaurantResult = await props.client.mutate({
          mutation: RESTAURANT_RESERVATION_DETAILS_CLEAR_MUTATION,
          variables: {
            id: order.restaurantReservationDetails.id,
            restaurantId: selectedRestaurantId,
          }
        })
      }

      var orderType = 'event spin'
      var spreadsheetResult = await BRClient.updateOrderOnSpreadsheet(order.id, orderType, order.requestedDate, order.requestedTime)

      if (spreadsheetResult.status !== 200) {
        alert('Order not updated on spreadsheet, try again.')
      }

    } catch(err) {
      alert('Error clearing details. Please refresh and try again!')
      console.log(err)
    }

    setSaving(false)
  }

  const handleClickSaveButton = async () => {
    setSaving(true)

    try {
      const { checkedBy, listPrice, notes, purchasedBy, purchaseDate, purchasePrice, seats,
              eventTime, sourceOrderId, source } = ticketDetailsState

      if (selectedEventId) {
        var ticketDetailsResult = await props.client.mutate({
          mutation: TICKET_DETAILS_UPDATE_MUTATION,
          variables: {
            id: order.ticketDetails.id,
            checkedBy,
            listPrice: parseFloat(listPrice),
            notes,
            purchasedBy,
            purchaseDate: moment(purchaseDate).format('YYYY-MM-DD'),
            purchasePrice: parseFloat(purchasePrice, 10),
            seats,
            eventTime,
            eventId: selectedEventId,
            ticketImageURL,
            sourceOrderId,
            source
          }
        })
      } else {
        alert('Error saving details: no event selected')
      }

      if (order.restaurantReservationDetails && selectedRestaurantId) {
        var restaurantResult = await props.client.mutate({
          mutation: RESTAURANT_RESERVATION_DETAILS_UPDATE_MUTATION,
          variables: {
            id: order.restaurantReservationDetails.id,
            madeBy: restaurantReservationDetailsState.madeBy,
            restaurantId: selectedRestaurantId,
            time: restaurantReservationDetailsState.time,
          }
        })
      }

      var orderType = 'event spin'
      var spreadsheetResult = await BRClient.updateOrderOnSpreadsheet(order.id, orderType, order.requestedDate, order.requestedTime)

      if (spreadsheetResult.status !== 200) {
        alert('Order not updated on spreadsheet, try again.')
      }

    } catch(err) {
      alert('Error saving details. Please refresh and try again!')
      console.log(err)
    }

    setSaving(false)
  }

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const uploadTicketImage = async (file) => {
    try {
      var fileName = `${order.id.substr(order.id.length-6)}-${moment().valueOf()}`

      var location = 'la'
      var fileDir = file.type === 'application/pdf' ? 'pdfs' : 'images'

      if (file.size > MAX_FILE_SIZE) {
        alert('File size is too big. Please attach files under 15 MB.')
      } else {
        setTicketImageURL('')

        const config = {
          bucketName: 'goldstarroulette-tickets',
          dirName: `${location}/${fileDir}`,
          region: 'us-east-1',
          accessKeyId: AWS_ACCESS_KEY_ID,
          secretAccessKey: AWS_ACCESS_KEY,
          s3Url: AWS_GOLDSTAR_TICKETS_BUCKET_URL
        }

        const S3Client = new S3(config)

        const data = await S3Client.uploadFile(file, fileName)
        setTicketImageURL(data.location)
      }
    } catch(err) {
      console.log(err)
      alert('Failed to attach upload to order')
    }
  }

  useEffect(async () => {
    getTicketImage()
  }, [ticketImageURL])

  var isNewCustomer = true

  var eventsAssignedToUserIds = {}
  order.user.eventOrders.items.filter(userOrder => userOrder.isValidOrder && userOrder.id !== order.id).forEach(previousOrder => {
    var event = previousOrder.ticketDetails.event

    if (event && event.id) {
      eventsAssignedToUserIds[event.id] = true

      const eventDateStr = moment(order.requestedDate).format('YYYY-MM-DD')
      var isPreviousOrder = previousOrder.requestedDate < eventDateStr
      if (isPreviousOrder) { isNewCustomer = false }
    }
  })

  var exclusionsReferencingInactiveEvents = []

  var requestedDate = moment(order.requestedDate)
  order.excludedEvents.items.forEach(event => {
    if ((moment(event.startDate) <= moment(requestedDate)) && (moment(event.endDate) >= moment(requestedDate))) {
      exclusionsReferencingInactiveEvents.push(event)
    }
  })

  const usedTestCode = order.paymentDetails.giftCardCode && order.paymentDetails.giftCardCode.code === 'ROKUDOBE'
  const eventRankings = getEventRankingsForOrder(order, events, eventsAssignedToUserIds)

  const cardTitle = (
    <span>
      <a href={'/user/' + order.user.id}>{order.user.firstName} {order.user.lastName}</a>
      {usedTestCode ? ' - TEST ORDER' : ''}
    </span>
  )

  const cardSubTitle = `${moment(order.requestedDate).format('MMM DD, YYYY')} - ${order.requestedTime}`
  const isFileTypePDF = ticketImageURL && ticketImageURL.split('.').pop().toLowerCase() === 'pdf'

  if (order.restaurantReservationDetails) {
    var restaurantsBeenToList = order.user.eventOrders.items.filter(userOrder => userOrder.isValidOrder && userOrder.id !== order.id)
                                                       .map(order => {
      if (order.restaurantReservationDetails && order.restaurantReservationDetails.restaurant) {
        return (order.restaurantReservationDetails.restaurant.id)
      }
    })

    var preferencesObj = JSON.parse(props.order.restaurantReservationDetails.preferences)
    var preferencesStr = Object.values(preferencesObj).join('/')
  }

  return (
    <Grid item xs={12} sm={6} md={12} key={order.id}>
      <Card className={usedTestCode ? 'test-order' : ''}>
        <CardHeader title={cardTitle}
                    subheader={cardSubTitle}
                    avatar={
                      <Avatar sx={{ bgcolor: order.ticketDetails.event && order.ticketDetails.event.name
                                    ? green[500] : red[500] }} aria-label="ticket">
                        {order.user.firstName.charAt(0)}{order.user.lastName.charAt(0)}
                      </Avatar>
                    }
                    action={
                      <IconButton aria-label="settings">
                        <MoreVertIcon />
                      </IconButton>
                    } />

        <CardContent>
          <Divider orientation="horizontal" flexItem />
          <Stack spacing={4} justifyContent="flex-start" alignItems="flex-start" className="order-details-section">
            <TableContainer className="order-details">
              <Typography variant="h5" className="section-title" component="div">
                Order Details
              </Typography>

              <Table aria-label="table" className="ticket-assignments-table">
                <TableBody>
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell component="th" scope="row">Order ID</TableCell>
                      <TableCell component="th" scope="row">
                        <a href={`/event-order?id=${order.id}`}>{order.id.substr(order.id.length-10)}</a>
                      </TableCell>
                    </TableRow>

                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell align="left" className="assignments-table-title">Order Date</TableCell>
                      <TableCell align="left" className="assignments-table-data">
                        {order.requestedDate}, {order.requestedTime}
                      </TableCell>
                    </TableRow>

                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell align="left">User Email</TableCell>
                      <TableCell align="left" className="assignments-table-data">{order.user.email}</TableCell>
                    </TableRow>

                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell align="left">User Phone</TableCell>
                      <TableCell align="left" className="assignments-table-data">{order.user.mobilePhone}</TableCell>
                    </TableRow>

                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell align="left">Tickets</TableCell>
                      <TableCell align="left" className="assignments-table-data">{order.numberOfTickets}</TableCell>
                    </TableRow>

                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell align="left">Event Preference</TableCell>
                      <TableCell align="left" className="assignments-table-data">{order.eventPreference}</TableCell>
                    </TableRow>

                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell align="left">Ticket Protection?</TableCell>
                      <TableCell align="left" className="assignments-table-data">
                        {order.isRequestingTicketFlex && <CheckIcon />}
                      </TableCell>
                    </TableRow>

                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell align="left">Is New User?</TableCell>
                      <TableCell align="left" className="assignments-table-data">
                        {isNewCustomer && <CheckIcon />}
                      </TableCell>
                    </TableRow>
                </TableBody>
              </Table>

              {!saving &&
                <div className="tickets-buttons hidemobile stack">
                  <Button variant="contained" className="button" onClick={handleClickSaveButton}>
                    Save Changes
                  </Button>

                  <Button variant="contained" className="button" onClick={handleClickClearButton}>
                    Clear Changes
                  </Button>
                </div>
              }

              {saving &&
                <div className="tickets-buttons hidemobile">
                  <CircularProgress />
                </div>
              }

            </TableContainer>
            <Divider orientation="vertical" flexItem />

            <Stack justifyContent="flex-start" alignItems="flex-start" className="show-selection-section">
              <div>
                <Typography variant="h5" className="section-title" component="div">
                  Event Selection
                </Typography>

                <EventSelectionList eventRankings={eventRankings}
                                    selectedEventId={selectedEventId}
                                    onChange={(eventId) => { setSelectedEventId(eventId) }} />
              </div>


              <Typography variant="h5" className="section-title" component="div">
                Ticket Details
              </Typography>
              <Stack direction="row" flexWrap="wrap">
                <Stack direction="row" flexWrap="wrap" className="ticket-assignments-inputs">
                  <div className="ticket-assignments-input-wrapper">
                    <TextField label="Purchased By"
                               defaultValue={ticketDetailsState.purchasedBy}
                               onChange={(event) => handleUpdateTicketDetails('purchasedBy', event.target.value)} />
                  </div>

                  <div className="ticket-assignments-input-wrapper">
                    <LocalizationProvider adapterLocale={LOCALE} dateAdapter={AdapterDateFns}>

                      <div className="hidemobile">
                          <DesktopDatePicker label="Purchase Date"
                                             inputFormat="MM/dd/yyyy"
                                             value={ticketDetailsState.purchaseDate}
                                             onChange={(newValue) => handleUpdateTicketDetails('purchaseDate', newValue)}
                                             clearable={true}
                                             renderInput={(params) => <TextField {...params} />} />
                      </div>

                      <div className="showmobile">
                        <MobileDatePicker label="Purchase date"
                                          inputFormat="MM/dd/yyyy"
                                          value={ticketDetailsState.purchaseDate}
                                          onChange={(newValue) => handleUpdateTicketDetails('purchaseDate', newValue)}
                                          renderInput={(params) => <TextField {...params} />} />
                      </div>
                    </LocalizationProvider>
                  </div>

                  <div className="ticket-assignments-input-wrapper">
                    <TextField label="Purchase Price"
                               defaultValue={ticketDetailsState.purchasePrice}
                               onChange={(event) => handleUpdateTicketDetails('purchasePrice', event.target.value)} />
                  </div>

                  <div className="ticket-assignments-input-wrapper">
                    <TextField label="Start time"
                               defaultValue={ticketDetailsState.eventTime}
                               onChange={(event) => handleUpdateTicketDetails('eventTime', event.target.value)} />
                  </div>

                  <div className="ticket-assignments-input-wrapper">
                    <TextField label="Seats"
                               defaultValue={ticketDetailsState.seats}
                               helperText="*will appear in tickets email"
                               onChange={(event) => handleUpdateTicketDetails('seats', event.target.value)} />
                  </div>

                  <div className="ticket-assignments-input-wrapper">
                    <TextField label="List Price"
                               defaultValue={ticketDetailsState.listPrice}
                               onChange={(event) => handleUpdateTicketDetails('listPrice', event.target.value)} />
                  </div>

                  <div className="ticket-assignments-input-wrapper">
                    <TextField label="Checked by"
                               defaultValue={ticketDetailsState.checkedBy}
                               onChange={(event) => handleUpdateTicketDetails('checkedBy', event.target.value)} />
                  </div>

                  <div className="ticket-assignments-input-wrapper">
                    <FormControl>
                      <InputLabel>Source</InputLabel>
                      <Select value={ticketDetailsState.source}
                              label="Source"
                              onChange={(event) => handleUpdateTicketDetails('source', event.target.value)}>

                        <MenuItem value={'TTG Whitelabel'}>TTG Whitelabel</MenuItem>
                        <MenuItem value={'TTG API'}>TTG API</MenuItem>
                        <MenuItem value={'Online'}>Online</MenuItem>
                        <MenuItem value={'Goldstar'}>Goldstar</MenuItem>
                      </Select>
                    </FormControl>
                  </div>

                  <div className="ticket-assignments-input-wrapper">
                    <TextField label="Source Order ID"
                               defaultValue={ticketDetailsState.sourceOrderId}
                               onChange={(event) => handleUpdateTicketDetails('sourceOrderId', event.target.value)} />
                  </div>

                  <div className="ticket-assignments-input-wrapper">
                    <TextField label="Notes"
                               className="notes-input"
                               defaultValue={ticketDetailsState.notes}
                               multiline
                               maxRows={10}
                               onChange={(event) => handleUpdateTicketDetails('notes', event.target.value)} />
                  </div>
                </Stack>

                <div className="dropzone-section ticket-assignments-input-wrapper">
                  <div className="ticket-assignments-input-wrapper dropzone">
                    <Dropzone onDrop={acceptedFiles => uploadTicketImage(acceptedFiles[0])}>
                      {({getRootProps, getInputProps}) => (
                        <section>
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />

                            {!ticketImageURL &&
                              <div>
                                <p>Drop ticket image here, or click to select file</p>
                              </div>
                            }

                            {ticketImageURL && ticketImageURL.split('.').pop().toLowerCase() !== 'pdf' &&
                              <img src={ticketImageAWSLink} alt="ticket" />
                            }
                          </div>
                        </section>
                      )}
                    </Dropzone>

                    {ticketImageAWSLink &&
                      <a href={ticketImageAWSLink} target="_blank" rel="noopener noreferrer">View document</a>
                    }
                  </div>

                  {ticketImageURL &&
                    <div>
                      <h4>Uploaded file type: {ticketImageURL.split('.').pop()}</h4>
                      {isFileTypePDF &&
                        <div>Will send as email attachment</div>
                      }

                      {!isFileTypePDF &&
                       <div>Will send as image insert</div>
                      }
                    </div>
                  }

                  {!ticketImageURL &&
                    <h4>No file uploaded</h4>
                  }

                </div>
              </Stack>

              {order.restaurantReservationDetails &&
                <div>
                  <Typography variant="h5" className="section-title" component="div">
                    Restaurant Details
                  </Typography>

                  <div>
                    <p>
                      Preferences: {preferencesStr}
                    </p>
                  </div>

                  <RestaurantSelectionList selectedRestaurantId={selectedRestaurantId}
                                           restaurantsBeenToList={restaurantsBeenToList}
                                           restaurants={restaurants}
                                           onChange={(restaurantId) => { setSelectedRestaurantId(restaurantId) }} />

                  <Stack spacing={2} direction="row" flexWrap="wrap" className="restaurant-inputs">
                    <TextField defaultValue={restaurantReservationDetailsState.madeBy}
                               label="Reservation Made By"
                               onChange={(e) => { handleUpdateRestaurantDetails('madeBy', e.target.value) }}/>

                    <TextField defaultValue={restaurantReservationDetailsState.time}
                               label="Time"
                               onChange={(e) => { handleUpdateRestaurantDetails('time', e.target.value) }}/>
                  </Stack>
                </div>
              }

              {!saving &&
                <div className="tickets-buttons showmobile stack">
                  <Button variant="contained" className="button" onClick={handleClickSaveButton}>
                    Save Changes
                  </Button>

                  <Button variant="contained" className="button" onClick={handleClickClearButton}>
                    Clear Changes
                  </Button>
                </div>
              }

              {saving &&
                <div className="tickets-buttons showmobile">
                  <CircularProgress />
                </div>
              }

            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Grid>
  )
}

function EventTicketAssignments(props) {
  const queryParams = queryString.parse(props.location.search)

  const [loading, setLoading] = useState(true)
  const [events, setEvents] = useState([])
  const [restaurants, setRestaurants] = useState([])
  const [blackoutDates, setBlackoutDates] = useState([])

  const [sortBy, setSortBy] = useState('requestedDate')
  const [sortDirection, setSortDirection] = useState('desc')

  const [location, setLocation] = useState('Los Angeles')
  const [eventTime, setEventTime] = useState('All')
  const [eventPreferenceFilter, setEventPreferenceFilter] = useState('All')

  const startDate = queryParams.startDate ? moment(queryParams.startDate).format('YYYY-MM-DDTHH:MM') : moment(TODAY).format('YYYY-MM-DDTHH:MM')
  const endDate = queryParams.endDate ? moment(queryParams.endDate).format('YYYY-MM-DDTHH:MM') : moment(TODAY).format('YYYY-MM-DDTHH:MM')

  const [eventDateRange, setEventDateRange] = useState([startDate, endDate])

  const history = useHistory()

  const getItemsToRender = () => {
    var orders = props.eventOrdersListQuery && props.eventOrdersListQuery.eventOrdersList && props.eventOrdersListQuery.eventOrdersList.items

    if (orders) {
      var sortedOrders = orders.sort((a, b) => {
        var aValue = sortBy === 'name' ? a.user.lastName : a[sortBy]
        var bValue = sortBy === 'name' ? b.user.lastName : b[sortBy]

        if (aValue < bValue) {
          return sortDirection === 'desc' ? -1 : 1
        } else if (aValue === bValue) {
          return 0
        } else {
          return sortDirection === 'desc' ? 1 : -1
        }
      })

      var filteredOrders = eventTime === 'All'
                           ? sortedOrders.filter(order => order.location.name === location)
                           : sortedOrders.filter(order => order.location.name === location && order.requestedTime === eventTime)

      orders = eventPreferenceFilter === 'All'
               ? filteredOrders
               : filteredOrders.filter(order => order.eventPreference === eventPreferenceFilter)
    }

    return orders
  }

  const handleChangeDates = () => {
    var startDate = moment(eventDateRange[0]).format('YYYY-MM-DD')
    var endDate = moment(eventDateRange[1]).format('YYYY-MM-DD')

    history.push(`/event-assignments?startDate=${startDate}&endDate=${endDate}`)
  }

  const handleDecrementDates = () => {
    var newStartDate = moment(startDate).subtract(1, 'days')
    var newEndDate = moment(endDate).subtract(1, 'days')

    setEventDateRange([newStartDate, newEndDate])

    var startDateURL = moment(newStartDate).format('YYYY-MM-DD')
    var endDateURL = moment(newEndDate).format('YYYY-MM-DD')

    history.push(`/event-assignments?startDate=${startDateURL}&endDate=${endDateURL}`)
  }

  const handleIncrementDates = () => {
    var newStartDate = moment(startDate).add(1, 'days')
    var newEndDate = moment(endDate).add(1, 'days')

    setEventDateRange([newStartDate, newEndDate])

    var startDateURL = moment(newStartDate).format('YYYY-MM-DD')
    var endDateURL = moment(newEndDate).format('YYYY-MM-DD')

    history.push(`/event-assignments?startDate=${startDateURL}&endDate=${endDateURL}`)
  }

  const loadRestaurants = async () => {
    try {
      var restaurantsQuery = await props.client.query({ query: RESTAURANTS_LIST_QUERY })

      setRestaurants(restaurantsQuery.data.restaurantsList.items)
    } catch (err) {
      console.log(`Unable to get data for restaurants: ${err.message}`)
    }
  }

  const loadEvents = async () => {
    var startDate = moment(eventDateRange[0]).format('YYYY-MM-DD')
    var endDate = moment(eventDateRange[1]).format('YYYY-MM-DD')

    try {
      var eventsQuery = await props.client.query({
        query: EVENTS_LIST_QUERY,
        variables: {
          startDate: startDate
        }
      })

      setEvents(eventsQuery.data.eventsList.items)

    } catch (err) {
      console.log(`Unable to get event data: ${err.message}`)
    }
  }

  var orders = getItemsToRender()

  useEffect(() => {
    if (orders) {
      setLoading(false)
    }
  }, [orders])

  useEffect(() => {
    loadEvents()
    loadRestaurants()
  }, [])

  return (
    <div className="ticket-assignments">
      <Stack spacing={2} direction="row" justifyContent="center" alignItems="center">
        <Typography sx={{ flex: '1 1 100%' }} variant="h3" className="page-title" component="div">
          Event Ticket Assignments
        </Typography>

        <FilterPopover getItemsToRender={getItemsToRender}
                       location={location}
                       setLocation={setLocation}
                       setEventTime={setEventTime}
                       setEventPreferenceFilter={setEventPreferenceFilter}
                       eventPreferenceFilter={eventPreferenceFilter}
                       eventTime={eventTime} />

        <SortPopover setSortBy={setSortBy}
                     setSortDirection={setSortDirection}
                     sortBy={sortBy}
                     sortDirection={sortDirection} />
      </Stack>

      <div className="filter-section">
        <Stack spacing={2} direction="row" flexWrap="wrap" justifyContent="flexStart" alignItems="center" className="date-picker-stack">
          <Stack spacing={2} direction="row">
            <Button variant="contained" onClick={handleDecrementDates}> {'<'} </Button>
            <Button variant="contained" onClick={handleIncrementDates}> {'>'} </Button>
          </Stack>

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateRangePicker startText="Start Date"
                             endText="End Date"
                             value={eventDateRange}
                             onChange={(newValue) => { setEventDateRange(newValue) }}
                             renderInput={(startProps, endProps) => (
                               <Stack spacing={2} direction="row" justifyContent="center" alignItems="center" className="date-picker-stack">
                                 <TextField {...startProps} className="show-date-picker" />
                                 <TextField {...endProps} className="show-date-picker" />
                               </Stack>
                             )} />
          </LocalizationProvider>

          <Button variant="contained" onClick={handleChangeDates}>Go</Button>
        </Stack>
      </div>

      {!orders && loading &&
        <CircularProgress />
      }

      <Grid container spacing={4} justifyContent="center">
        {orders && orders.map((order, idx) => (
          <EventTicketAssignmentsCard blackoutDates={blackoutDates}
                                 client={props.client}
                                 key={order.id}
                                 order={order}
                                 restaurants={restaurants}
                                 events={events} />
        ))}
      </Grid>
    </div>
  )
}

const EVENT_ORDERS_LIST_QUERY = gql`
  query eventOrdersListQuery($startOfPeriod: Date!, $endOfPeriod: Date!) {
    eventOrdersList(
      sort: { requestedDate : ASC },
      filter: {
        isValidOrder: { equals: true },
        AND: [
          { requestedDate: { gte: $startOfPeriod } },
          { requestedDate: { lte: $endOfPeriod } },
          { user: { email: { not_equals: "ali@broadwayroulette.com" } }},
          { user: { email: { not_equals: "tracy@broadwayroulette.com" } }},
          { user: { email: { not_contains: "tracythuynh" } }},
          { user: { email: { not_equals: "orders@broadwayroulette.com" } }},
          { user: { email: { not_equals: "devteam@broadwayroulette.com" } }},
          { user: { email: { not_contains: "ali+" } }},
        ]
    }) {
      items {
        id
        clientVersion
        isRequestingAdditionalExclusions
        isRequestingTicketFlex
        numberOfTickets
        orderPlacedAt
        requestedDate
        requestedTime
        eventPreference

        location {
          id
          name
        }

        user {
          id
          firstName
          lastName
          email
          mobilePhone
          notes

          eventOrders {
            items {
              id
              isValidOrder
              requestedDate

              restaurantReservationDetails {
                id

                restaurant {
                  id
                  name
                }
              }

              ticketDetails {
                id
                event {
                  id
                }
              }
            }
          }
        }

        excludedEvents {
          items {
            id
            shortName
          }
        }

        orderStatus {
          id
          receivedConfirmationAt
        }

        paymentDetails {
          id
          additionalExclusionFee

          giftCardCode {
            id
            code
          }
        }

        restaurantReservationDetails {
          id
          madeBy
          preferences
          time

          restaurant {
            id
          }
        }

        ticketDetails {
          id
          checkedBy
          listPrice
          notes
          sourceOrderId
          source
          purchasedBy
          purchaseDate
          purchasePrice
          seats
          eventTime

          event {
            id
            name
          }

          ticketImageURL
        }
      }
    }
  }
`

const RESTAURANTS_LIST_QUERY = gql`
  query RestaurantsListQuery {
    restaurantsList(sort: { name : ASC }, filter: { location: { name: { equals: "Los Angeles" }}}) {
      items {
        id
        name
      }
    }
  }
`

const EVENTS_LIST_QUERY = gql`
  query EventsListQuery($startDate: Date) {
    eventsList(
      sort: { name: ASC },
      filter: {
        startDate: { lte: $startDate }
      }
    ) {
      items {
        id
        dateAdded
        shortName
        name
        isChildFriendly
        startDate
        endDate
        eventCategory

        location {
          id
          name
        }

        schedule {
          id
          isAvailableEveryDayOfWeek
          daysOfWeekAvailable
        }
      }
    }
  }
`

const CLEAR_RESTAURANT_RESERVATION_DETAILS_MUTATION = gql`
  mutation clearRestaurantReservationDetails($id: ID!, $madeBy: String, $restaurantId: ID, $time: String) {
    restaurantReservationDetailUpdate(data: {
      id: $id
      madeBy: $madeBy
      time: $time
      restaurant: {
        disconnect: { id: $restaurantId }
      }
    }) {
      id
    }
  }
`

const RESTAURANT_RESERVATION_DETAILS_CLEAR_MUTATION = gql`
  mutation RestaurantReservationDetailsUpdateMutation($id: ID!, $restaurantId: ID) {
    restaurantReservationDetailUpdate(data: {
      id: $id
      madeBy: null
      time: null

      restaurant: {
        disconnect: { id: $restaurantId }
      }
    }) {
      id
    }
  }
`

const RESTAURANT_RESERVATION_DETAILS_UPDATE_MUTATION = gql`
  mutation RestaurantReservationDetailsUpdateMutation($id: ID!, $madeBy: String, $restaurantId: ID, $time: String) {
    restaurantReservationDetailUpdate(data: {
      id: $id
      madeBy: $madeBy
      time: $time

      restaurant: {
        reconnect: { id: $restaurantId }
      }
    }) {
      id
    }
  }
`

const CLEAR_TICKET_DETAILS_MUTATION = gql`
  mutation clearTicketDetails($id: ID!, $eventId: ID) {
    eventTicketDetailUpdate(data:{
      id: $id
      checkedBy: null
      listPrice: null
      notes: null
      purchasedBy: null
      purchaseDate: null
      purchasePrice: null
      seats: null
      eventTime: null
      ticketImageURL: null
      sourceOrderId: null
      source: null

      event: {
        disconnect: { id: $eventId }
      }
    }) {
      id
    }
  }
`

const TICKET_DETAILS_UPDATE_MUTATION = gql`
  mutation TicketDetailsUpdateMutation($id: ID!, $checkedBy: String, $listPrice: Float, $notes: String,
                                       $purchasedBy: String, $purchaseDate: Date, $purchasePrice: Float, $seats: String,
                                       $eventId: ID, $eventTime: String, $ticketImageURL: String,
                                       $sourceOrderId: String, $source: String) {
    eventTicketDetailUpdate(data:{
      id: $id
      checkedBy: $checkedBy
      listPrice: $listPrice
      notes: $notes
      purchasedBy: $purchasedBy
      purchaseDate: $purchaseDate
      purchasePrice: $purchasePrice
      seats: $seats
      eventTime: $eventTime
      ticketImageURL: $ticketImageURL
      sourceOrderId: $sourceOrderId
      source: $source

      event: {
        reconnect: { id: $eventId }
      }
    }) {
      id
    }
  }
`

export default withApollo(graphql(EVENT_ORDERS_LIST_QUERY, {
  name: 'eventOrdersListQuery',
  options: (ownProps) => {
    var [startOfPeriod, endOfPeriod] = getDateRange(ownProps)

    return {
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
      variables: {
        startOfPeriod: startOfPeriod,
        endOfPeriod: endOfPeriod
      }
    }
  }
})(EventTicketAssignments))
