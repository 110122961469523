import React, { useState, useEffect } from 'react'
import { gql } from 'apollo-boost'
import { graphql, withApollo } from 'react-apollo'
import moment from 'moment-timezone'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import { withRouter } from 'react-router'
import _ from 'underscore'

import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Checkbox from '@mui/material/Checkbox'
import Divider from '@mui/material/Divider'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormLabel from '@mui/material/FormLabel'
import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'

import BRClient from '../broadwayRouletteClient'
import { getDaysUntil, getLocalDateStr } from '../utils'
import { SPECIAL_SHOW_IDS } from '../constants'
import { CREDIT_CREATE_MUTATION } from '../queries'

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body1,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
}))


const OrderBox = (props) => {
  const { order, currentUser } = props

  const orderStatus = order.orderStatus
  const paymentDetails = order.paymentDetails

  const preferences = order.restaurantReservationDetails ? JSON.parse(order.restaurantReservationDetails.preferences) : null

  const orderRestaurantPreferenceBudget = (preferences && preferences.budget) || 'Affordable'
  const orderRestaurantPreferencePriority = (preferences && preferences.priority) || 'Cute Decor'
  const orderRestaurantPreferenceTime = (preferences && preferences.when) || 'Pre-Show'

  const daysUntil = getDaysUntil(moment().startOf('day'), moment(order.requestedShowDate).startOf('day'))
  const orderPlacedAt = getLocalDateStr(order.orderPlacedAt)
  const spinEmailAt = getLocalDateStr(orderStatus.sentSpinEmailAt)
  const ticketsEmailAt = getLocalDateStr(orderStatus.sentTicketsEmailAt)
  const ticketsTextAt = getLocalDateStr(orderStatus.sentTicketsTextAt)
  const receivedConfirmationAt = getLocalDateStr(orderStatus.receivedConfirmationAt)

  const [shows, setShows] = useState([])
  const [locations, setLocations] = useState([])
  const [retailers, setRetailers] = useState([])
  const [saving, setSaving] = useState(false)

  const [orderState, setOrderState] = useState({
    excludedShows: order.excludedShows.items,
    isRequestingChildFriendly: order.isRequestingChildFriendly,
    isRequestingPremium: order.isRequestingPremium,
    isRequestingTicketFlex: order.isRequestingTicketFlex,
    isOptingIntoExtendedHours: order.isOptingIntoExtendedHours,
    isValidOrder: order.isValidOrder,
    location: order.location.name,
    retailer: order.retailer.name,
    notes: order.notes,
    numberOfTickets: order.numberOfTickets,
    requestedShowDate: order.requestedShowDate,
    requestedShowTime: order.requestedShowTime,
    showPreference: order.showPreference,
    userEmail: order.user.email,
  })

  const [paymentDetailsState, setPaymentDetailsState] = useState({
    additionalExclusionFee: paymentDetails.additionalExclusionFee,
    discountAmount: paymentDetails.discountAmount,
    familyFee: paymentDetails.familyFee,
    giftCardAmount: paymentDetails.giftCardAmount,
    creditAmount: paymentDetails.creditAmount,
    premiumFee: paymentDetails.premiumFee,
    restaurantFee: paymentDetails.restaurantFee,
    subscriptionDiscountAmount: paymentDetails.subscriptionDiscountAmount,
    serviceFee: paymentDetails.serviceFee,
    ticketFlexFee: paymentDetails.ticketFlexFee,
    totalPaid: paymentDetails.price
  })

  const [restaurantState, setRestaurantState] = useState({
    restaurantPreferenceBudget: orderRestaurantPreferenceBudget,
    restaurantPreferencePriority: orderRestaurantPreferencePriority,
    restaurantPreferenceTime: orderRestaurantPreferenceTime
  })

  const [previousShowDate, setPreviousShowDate] = useState(order.requestedShowDate)
  const [previousShowTime, setPreviousShowTime] = useState(order.requestedShowTime)

  const [showRestaurantOptions, setShowRestaurantOptions] = useState(order.restaurantReservationDetails)

  var originalUpdateHistory = order.updateHistory ? JSON.parse(order.updateHistory.replace(/\n/g, " ")) : []
  const [updateHistory, setUpdateHistory] = useState(originalUpdateHistory)

  const [isSendingConfirmationEmail, setIsSendingConfirmationEmail] = useState(false)
  const [isSendingTicketDetailsEmail, setIsSendingTicketDetailsEmail] = useState(false)

  const [excludedShowsString, setExcludedShowsString] = useState('')

  var ticketsRedeemed = order.paymentDetails.subscriptionDiscountAmount / 47
  var pointsRedeemed = 0

  order.pointsRedemptionDetails && order.pointsRedemptionDetails.items.map(points => {
    pointsRedeemed += points.pointsRedeemed
  })

  var timeTillShowEl
  if (daysUntil < 0) {
    timeTillShowEl = `This show was ${-1 * daysUntil} day(s) ago`
  } else if (daysUntil === 0) {
    timeTillShowEl = `This show is today!`
  } else {
    timeTillShowEl = `${daysUntil} day(s) until the show`
  }

  const handleAddRestaurantReservation = async () => {
    try {
      var restaurantPreferences = {
        budget: "Affordable",
        priority: "Cute Decor",
        when: "Pre-Show"
      }

      var restaurantPreferencesString = JSON.stringify(restaurantPreferences)

      await props.client.mutate({
        mutation: RESTAURANT_RESERVATION_DETAILS_CREATE_MUTATION,
        variables: {
          orderId: order.id,
          restaurantPreferences: restaurantPreferencesString
        }
      })

      alert('Restaurant reservation made')
      setShowRestaurantOptions(true)
    } catch (err) {
      alert('Unable to create restaurant reservation')
      console.log(err)
    }
  }

  const handleClickShow = (show) => {
    var showId = show.id
    var newOrderState = {...orderState}
    var newExcludedShows = [...orderState.excludedShows]

    var isInExcludedShows = newExcludedShows.filter(excludedShow => excludedShow.id === showId).length > 0
    if (isInExcludedShows) {
      newExcludedShows = newExcludedShows.filter(excludedShow => excludedShow.id !== showId)
    } else {
      newExcludedShows.push(show)
    }

    handleUpdateOrderState('excludedShows', newExcludedShows)
  }

  const handleSaveChanges = async () => {
    try {
      setSaving(true)

      var { userEmail, location, retailer, numberOfTickets, requestedShowDate, requestedShowTime, showPreference,
            isOptingIntoExtendedHours, isRequestingChildFriendly, isRequestingPremium, isRequestingTicketFlex,
            isValidOrder, notes } = orderState

      var { totalPaid, additionalExclusionFee, familyFee, premiumFee, restaurantFee, serviceFee, ticketFlexFee,
            discountAmount, giftCardAmount, creditAmount, subscriptionDiscountAmount } = paymentDetailsState

      var excludedShowsReconnection = ''
      orderState.excludedShows.forEach(show => {
        excludedShowsReconnection += `{id: "${ show.id }"},`
      })

      var restaurantReservationMutation = ""
      if (order.restaurantReservationDetails && showRestaurantOptions) {
        var restaurantPreferences = {
          budget: restaurantState.restaurantPreferenceBudget,
          priority: restaurantState.restaurantPreferencePriority,
          when: restaurantState.restaurantPreferenceTime
        }

        var restaurantPreferencesString = JSON.stringify(restaurantPreferences)

        restaurantReservationMutation = `restaurantReservationDetails: {
                                           update: {
                                             preferences: "${restaurantPreferencesString.replace(/"/g, '\\"')}"
                                           }
                                         }`
                                       }

      var updateOrderMutation = gql`
        mutation UpdateOrderMutation($orderId: ID!, $userEmail: String, $numberOfTickets: Int, $requestedShowDate: Date,
                                     $requestedShowTime: String, $showPreference: String, $location: String, $retailer: String,
                                     $isOptingIntoExtendedHours: Boolean, $isRequestingChildFriendly: Boolean,
                                     $isRequestingPremium: Boolean, $isRequestingTicketFlex: Boolean, $isValidOrder: Boolean,
                                     $notes: String, $updateHistory: String) {
          orderUpdate(data: {
            id: $orderId,
            numberOfTickets: $numberOfTickets,
            requestedShowDate: $requestedShowDate,
            requestedShowTime: $requestedShowTime,
            showPreference: $showPreference,
            isOptingIntoExtendedHours: $isOptingIntoExtendedHours,
            isRequestingChildFriendly: $isRequestingChildFriendly,
            isRequestingPremium: $isRequestingPremium,
            isRequestingTicketFlex: $isRequestingTicketFlex,
            isValidOrder: $isValidOrder,
            notes: $notes,
            updateHistory: $updateHistory

            location: {
              connect: { name: $location }
            }

            retailer: {
              connect: { name: $retailer }
            }

            ${restaurantReservationMutation}

            excludedShows: {
              reconnect: [
                ${excludedShowsReconnection}
              ]
            }
            user: {
              reconnect: { email: $userEmail }
            }
          }) {
            id
          }
        }
      `

      var result = await props.client.mutate({
        mutation: updateOrderMutation,
        variables: {
          orderId: order.id,
          userEmail,
          location,
          retailer,
          numberOfTickets: parseInt(numberOfTickets, 10),
          requestedShowDate,
          requestedShowTime,
          showPreference,
          isOptingIntoExtendedHours,
          isRequestingChildFriendly,
          isRequestingPremium,
          isRequestingTicketFlex,
          isValidOrder,
          notes,
          updateHistory: JSON.stringify(updateHistory)
        }
      })

      await props.client.mutate({
        mutation: PAYMENT_DETAILS_UPDATE_MUTATION,
        variables: {
          paymentDetailsId: order.paymentDetails.id,
          price: parseFloat(totalPaid, 10),
          additionalExclusionFee: parseFloat(additionalExclusionFee, 10),
          familyFee: parseFloat(familyFee, 10),
          premiumFee: parseFloat(premiumFee, 10),
          restaurantFee: parseFloat(restaurantFee, 10),
          subscriptionDiscountAmount: parseFloat(subscriptionDiscountAmount, 10),
          serviceFee: parseFloat(serviceFee, 10),
          ticketFlexFee: parseFloat(ticketFlexFee, 10),
          discountAmount: parseFloat(discountAmount, 10),
          giftCardAmount: parseFloat(giftCardAmount, 10),
          creditAmount: parseFloat(creditAmount, 10),
        }
      })

      try {
        var orderType = 'spin'
        var result = await BRClient.updateOrderOnSpreadsheet(order.id, orderType, previousShowDate, previousShowTime, updateHistory, null)
        alert('Order successfully updated')
      } catch (err) {
        alert('Order details saved but not updated on spreadsheet, please try again.')
      }

      setSaving(false)

    } catch (err) {
      setSaving(false)
      alert(`Did not save, error: ${err}`)
    }
  }

  const handleUpdateUserEmail = (email) => {
    setTimeout(() => {
      handleUpdateOrderState('userEmail', email)
    }, 1000)
  }

  const handleUpdateOrderState = _.debounce((key, value) => {
    var newOrderState = {...orderState}
    var newUpdateHistory = [...updateHistory]

    var updateHistoryEntry = {}

    updateHistoryEntry.user = currentUser.firstName
    updateHistoryEntry.date = moment().format('YYYY-MM-DD HH:mm A')
    updateHistoryEntry.key = key
    updateHistoryEntry.newValue = key === 'excludedShows' ? showsArrayToString(value) : value.toString()

    if (key === 'userEmail') {
      updateHistoryEntry.oldValue = order.user.email
    } else if (key === 'location') {
      updateHistoryEntry.oldValue = order.location.name
    } else if (key === 'retailer') {
      updateHistoryEntry.oldValue = order.retailer.name
    } else if (key === 'excludedShows') {
      updateHistoryEntry.oldValue = showsArrayToString(order.excludedShows.items)
    } else if (key === 'notes') {
      updateHistoryEntry.oldValue = order.notes ? order.notes : ''
    } else {
      updateHistoryEntry.oldValue = order[key].toString()
    }

    newUpdateHistory.push(updateHistoryEntry)
    newOrderState[key] = value

    setOrderState(newOrderState)
    setUpdateHistory(newUpdateHistory)
  }, 1000)

  const handleUpdatePaymentDetailsState = _.debounce((key, value) => {
    var newPaymentDetailsState = {...paymentDetailsState}
    var newUpdateHistory = [...updateHistory]

    var updateHistoryEntry = {}
    updateHistoryEntry.date = moment().format('YYYY-MM-DD HH:mm A')
    updateHistoryEntry.key = key
    updateHistoryEntry.newValue = value.toString()
    updateHistoryEntry.oldValue = order.paymentDetails[key] && order.paymentDetails[key].toString()

    newUpdateHistory.push(updateHistoryEntry)
    newPaymentDetailsState[key] = parseFloat(value, 10)

    setPaymentDetailsState(newPaymentDetailsState)
    setUpdateHistory(newUpdateHistory)
  }, 1000)

  const handleUpdateRestaurantState = (key, value) => {
    var newRestaurantState = {...restaurantState}

    newRestaurantState[key] = value
    setRestaurantState(newRestaurantState)
  }

  const loadLocations = async () => {
    try {
      var locationsQuery = await props.client.query({ query: LOCATIONS_LIST_QUERY })
      setLocations(locationsQuery.data.locationsList.items)

    } catch (err) {
      console.log('Unable to get locations data')
    }
  }

  const loadRetailers = async () => {
    try {
      var retailersQuery = await props.client.query({ query: RETAILERS_LIST_QUERY })
      setRetailers(retailersQuery.data.retailersList.items)

    } catch (err) {
      console.log('Unable to get retailers data')
    }
  }

  const loadShows = async () => {
    try {
      var result = await props.client.query({ query: SHOWS_LIST_QUERY })

      var requestedShowDate = order.requestedShowDate
      var shows = result.data.showsList.items
      var filteredShows = shows.filter(show => moment(show.runDateStart).format("YYYY-MM-DD") <= moment(requestedShowDate).format("YYYY-MM-DD")
                                               || SPECIAL_SHOW_IDS.indexOf(show.id) > -1 )
                               .filter(show => moment(show.runDateEnd).format("YYYY-MM-DD") >= moment(requestedShowDate).format("YYYY-MM-DD"))
                               .filter(show => show.location.name === order.location.name)

      setShows(filteredShows)
    } catch (err) {
      console.log(err)
      console.log('Unable to get data for shows')
    }
  }

  const sendConfirmationEmail = async () => {
    try {
    setIsSendingConfirmationEmail(true)

    var response = await BRClient.resendOrderEmail(order.id, 'spin')
    setIsSendingConfirmationEmail(false)
    } catch(err) {
      console.log(err)
      alert('Failed to resend order confirmation')
    }
  }

  const sendTicketDetailsEmail = async () => {
    try {
    setIsSendingTicketDetailsEmail(true)

    var response = await BRClient.resendTicketDetailsEmail(order.id)
    setIsSendingTicketDetailsEmail(false)
    } catch(err) {
      console.log(err)
      alert('Failed to resend ticket details')
    }
  }

  const showsArrayToString = (array) => {
    var string = ''

    array.map((show, idx) => {
      var segment = idx < array.length - 1 ? `${show.shortName}, ` : `${show.shortName}`
      string = string.concat(segment)
    })

    return string
  }

  useEffect(() => {
    loadShows()
    loadLocations()
    loadRetailers()
  }, [])

  useEffect(() => {
    var string = showsArrayToString(orderState.excludedShows)

    setExcludedShowsString(string)
  }, [orderState])

  return (
    <Item className="order-tile">
      <Typography sx={{ flex: '1 1 100%' }} variant="h5" className="order-tile-subtitle" component="div">
        {order.user.firstName} {order.user.lastName} - {moment(order.requestedShowDate).format('MM/DD/YYYY')} @ {order.requestedShowTime}
      </Typography>

      <Typography sx={{ flex: '1 1 100%' }} variant="h6" className="order-tile-subtitle" component="div">
        <a href={`ticket-assignments?startDate=${order.requestedShowDate}&endDate=${order.requestedShowDate}`}>
          {order.ticketDetails.show ? order.ticketDetails.show.name : 'No Show Assigned'}
        </a>
      </Typography>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header">
          <Typography className="accordion-title">Order Details</Typography>
          <Divider />
        </AccordionSummary>
        <AccordionDetails>
          <Stack direction="column" alignItems="stretch" justifyContent="flex-start">
            <Divider />

            <div className="padding first-entry">
              <TextField label="ID"
                         defaultValue={order.id}
                         disabled={true} />
            </div>

            <div className="padding">
              <TextField label="User Email"
                         defaultValue={orderState.userEmail}
                         onChange={(e) => { handleUpdateUserEmail(e.target.value) }} />
            </div>

            <div className="padding">
              <TextField label="Number of Tickets"
                         defaultValue={orderState.numberOfTickets}
                         onChange={(e) => { handleUpdateOrderState('numberOfTickets', e.target.value) }} />
            </div>

            <div className="padding">
              <TextField label="Show Date"
                         type="date"
                         defaultValue={moment(orderState.requestedShowDate).format("YYYY-MM-DD")}
                         onChange={(e) => { handleUpdateOrderState('requestedShowDate', e.target.value) }} />
            </div>

            <div className="padding">
              <FormControl>
                <InputLabel>Location</InputLabel>
                <Select value={orderState.location}
                        label="Location"
                        onChange={(event) => handleUpdateOrderState('location', event.target.value)}>

                    {locations && locations.map((locationMap, idx) => (
                      <MenuItem key={idx} value={locationMap.name}> {locationMap.name} </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>

            <div className="padding">
              <FormControl>
                <InputLabel>Retailer</InputLabel>
                <Select value={orderState.retailer}
                        label="Retailer"
                        onChange={(event) => handleUpdateOrderState('retailer', event.target.value)}>

                    {retailers && retailers.map((retailerMap, idx) => (
                      <MenuItem key={idx} value={retailerMap.name}> {retailerMap.name} </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>

            <div className="padding">
              <FormControl>
                <InputLabel>Time</InputLabel>
                <Select value={orderState.requestedShowTime}
                        label="Time"
                        onChange={(event) => handleUpdateOrderState('requestedShowTime', event.target.value)}>
                  <MenuItem value={'Matinee'}>Matinee</MenuItem>
                  <MenuItem value={'Evening'}>Evening</MenuItem>
                </Select>
              </FormControl>
            </div>

            {order.isForOffBroadway &&
              <div className="padding">
                {(order.offBroadwayPreferences && order.offBroadwayPreferences.showPreferenceGoingWith) &&
                  <span>Going with: {order.offBroadwayPreferences.showPreferenceGoingWith}<br/></span>
                }
                {(order.offBroadwayPreferences && order.offBroadwayPreferences.showPreferenceHopingFor) &&
                  <span>Something: {order.offBroadwayPreferences.showPreferenceHopingFor}<br/></span>
                }
                {(order.offBroadwayPreferences && order.offBroadwayPreferences.showPreferenceAdventure) &&
                  <span>Adventure level: {order.offBroadwayPreferences.showPreferenceAdventure}<br/></span>
                }
              </div>
            }

            {!order.isForOffBroadway &&
              <div className="padding">
                <FormControl>
                  <InputLabel>Preference</InputLabel>
                  <Select label="Preference"
                          value={orderState.showPreference}
                          onChange={(event) => handleUpdateOrderState('showPreference', event.target.value)}>
                    <MenuItem value={'All'}>All</MenuItem>
                    <MenuItem value={'Both'}>Both</MenuItem>
                    <MenuItem value={'Musical'}>Musical</MenuItem>
                    <MenuItem value={'MusicalAndSpecial'}>MusicalAndSpecial</MenuItem>
                    <MenuItem value={'Play'}>Play</MenuItem>
                    <MenuItem value={'PlayAndSpecial'}>PlayAndSpecial</MenuItem>
                  </Select>
                </FormControl>

                <div>
                  <FormControlLabel
                    control={
                      <Checkbox checked={orderState.isRequestingChildFriendly}
                                onChange={(e) => { handleUpdateOrderState('isRequestingChildFriendly', e.target.checked) }} />
                    }
                    label="Requesting child friendly" />
                </div>

                <div>
                  <FormControlLabel
                    control={
                      <Checkbox checked={orderState.isRequestingPremium}
                                onChange={(e) => { handleUpdateOrderState('isRequestingPremium', e.target.checked) }} />
                    }
                    label="Requesting premium" />
                </div>

                <div>
                  <FormControlLabel
                    control={
                      <Checkbox checked={orderState.isRequestingTicketFlex}
                                onChange={(e) => { handleUpdateOrderState('isRequestingTicketFlex', e.target.checked) }} />
                    }
                    label="Requesting Ticket Protection" />
                </div>

                <div>
                  <FormControlLabel
                    control={
                      <Checkbox checked={orderState.isOptingIntoExtendedHours}
                                onChange={(e) => { handleUpdateOrderState('isOptingIntoExtendedHours', e.target.checked) }} />
                    }
                    label="Opt Into Late Night" />
                </div>
              </div>
            }

            <div className="padding">
              <TextField defaultValue={orderState.notes}
                         label="Notes"
                         multiline
                         maxRows={10}
                         onChange={(e) => { handleUpdateOrderState('notes', e.target.value) }} />
            </div>

            <div className="padding">
              <FormControlLabel
                control={
                  <Switch checked={orderState.isValidOrder}
                          style={{color: 'red'}}
                           onChange={(e) => { handleUpdateOrderState('isValidOrder', e.target.checked) }} />
                }
                label={`Is Valid Order? ${orderState.isValidOrder}`}
                style={{ color: 'red' }}
              />
            </div>
          </Stack>

        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header">
          <Typography className="accordion-title">Excluded Shows</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Divider />

          <div className="padding">
            <strong>Excluded shows:</strong> {excludedShowsString}
          </div>

          <Stack spacing={2} direction="column" alignItems="center" justifyContent="center" className="padding">
            {shows && shows.map(show => {
              var style = {}

              if (orderState && orderState.excludedShows && orderState.excludedShows.filter(excludedShow => excludedShow.id === show.id).length > 0) {
                style = {textDecoration: 'line-through'}
              }

              return (<span key={show.id}>
                        <p onClick={() => handleClickShow(show)} style={style} className="excluded-shows-list">
                          {show.shortName}
                        </p>
                      </span>)
            })}
          </Stack>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header">
          <Typography className="accordion-title">Payment Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Divider />

          <Stack direction="column" alignItems="center" justifyContent="center">
            {order.subscriptionUsed &&
              <div>
                <div className="input-wrapper">
                  <TextField defaultValue={pointsRedeemed}
                             label="Points redeemed"
                             disabled={true} />
                </div>

                <div className="input-wrapper">
                  <TextField defaultValue={ticketsRedeemed}
                             label="Tickets redeemed"
                             disabled={true} />
                </div>
              </div>
            }

            <div className="input-wrapper">
              <TextField defaultValue={paymentDetailsState.totalPaid}
                         label="Total Paid (no $)"
                         onChange={(e) => { handleUpdatePaymentDetailsState('totalPaid', e.target.value) }} />
            </div>

            <div className="input-wrapper">
              <TextField defaultValue={paymentDetailsState.additionalExclusionFee}
                         label="Total Extra Exclusion Fee (no $)"
                         onChange={(e) => { handleUpdatePaymentDetailsState('additionalExclusionFee', e.target.value) }} />
            </div>

            <div className="input-wrapper">
              <TextField defaultValue={paymentDetailsState.familyFee}
                         label="Total Family Fee (no $)"
                         onChange={(e) => { handleUpdatePaymentDetailsState('familyFee', e.target.value) }} />
            </div>

            <div className="input-wrapper">
              <TextField defaultValue={paymentDetailsState.premiumFee}
                         label="Total Premium Fee (no $)"
                         onChange={(e) => { handleUpdatePaymentDetailsState('premiumFee', e.target.value) }} />
            </div>

            <div className="input-wrapper">
              <TextField defaultValue={paymentDetailsState.serviceFee}
                         label="Total Service Fee (no $)"
                         onChange={(e) => { handleUpdatePaymentDetailsState('serviceFee', e.target.value) }} />
            </div>

            <div className="input-wrapper">
              <TextField defaultValue={paymentDetailsState.ticketFlexFee}
                         label="Total Ticket Protection Fee (no $)"
                         onChange={(e) => { handleUpdatePaymentDetailsState('ticketFlexFee', e.target.value) }} />
            </div>

            <div className="input-wrapper">
              <TextField defaultValue={paymentDetailsState.restaurantFee}
                         label="Restaurant Fee"
                         onChange={(e) => { handleUpdatePaymentDetailsState('restaurantFee', e.target.value) }} />
            </div>

            <div className="input-wrapper">
              <TextField defaultValue={paymentDetailsState.subscriptionDiscountAmount}
                         label="Subscription Discount Amount"
                         onChange={(e) => { handleUpdatePaymentDetailsState('subscriptionDiscountAmount', e.target.value) }} />
            </div>

            <div className="input-wrapper">
              <p>Discount Code: {paymentDetails.promoCode && paymentDetails.promoCode.code}</p>
              <TextField defaultValue={paymentDetailsState.discountAmount}
                         label="Promo Discount (no $)"
                         onChange={(e) => { handleUpdatePaymentDetailsState('discountAmount', e.target.value) }} />
            </div>

            <div className="input-wrapper">
              <p>Gift Card Code: {paymentDetails.giftCardCode && paymentDetails.giftCardCode.code}</p>
              <TextField defaultValue={paymentDetailsState.giftCardAmount}
                         label="Gift Card Amount"
                         onChange={(e) => { handleUpdatePaymentDetailsState('giftCardAmount', e.target.value) }} />
            </div>

            <div className="input-wrapper">
              <TextField defaultValue={paymentDetailsState.creditAmount}
                         label="Credit Amount"
                         onChange={(e) => { handleUpdatePaymentDetailsState('creditAmount', e.target.value) }} />
            </div>

          </Stack>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header">
          <Typography className="accordion-title">Restaurant Reservation</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Divider />

          <div className="padding first-entry">
            {(!order.restaurantReservationDetails && !showRestaurantOptions) &&
              <div className="top-padding">
                <Button variant="contained"
                        onClick={() => { handleAddRestaurantReservation() }}>
                  + Add Restaurant Reservation
                </Button>
              </div>
            }

            {showRestaurantOptions &&
              <div>
                <div className="top-padding">
                  <FormControl>
                    <FormLabel id="radio-buttons-group-label">Show Time</FormLabel>
                    <RadioGroup aria-labelledby="time-radio-buttons-group-label"
                                name="radio-buttons-group"
                                defaultValue={restaurantState.restaurantPreferenceTime}
                                onChange={(event) => { handleUpdateRestaurantState('restaurantPreferenceTime', event.target.value) }}>
                      <FormControlLabel value="Pre-Show" control={<Radio />} label="Pre-Show" />
                      <FormControlLabel value="Post-Show" control={<Radio />} label="Post-Show" />
                    </RadioGroup>
                  </FormControl>
                </div>

                <div className="top-padding">
                  <FormControl>
                    <FormLabel id="radio-buttons-group-label">Budget</FormLabel>
                    <RadioGroup aria-labelledby="time-radio-buttons-group-label"
                                name="radio-buttons-group"
                                defaultValue={restaurantState.restaurantPreferenceBudget}
                                onChange={(event) => { handleUpdateRestaurantState('restaurantPreferenceBudget', event.target.value) }}>

                      <FormControlLabel value="Affordable"
                                        control={<Radio />} label="Looking to keep it super affordable" />
                      <FormControlLabel value="Reasonable Price"
                                        control={<Radio />} label="Reasonable, but I get it's Times Square" />
                      <FormControlLabel value="Night Out"
                                        control={<Radio />} label="This is my big night out - don't worry about it!" />
                    </RadioGroup>
                  </FormControl>
                </div>

                <div className="top-padding">
                  <FormControl>
                    <FormLabel id="radio-buttons-group-label">Priority</FormLabel>
                    <RadioGroup aria-labelledby="time-radio-buttons-group-label"
                                name="radio-buttons-group"
                                defaultValue={restaurantState.restaurantPreferencePriority}
                                onChange={(event) => { handleUpdateRestaurantState('restaurantPreferencePriority', event.target.value) }}>

                      <FormControlLabel value="Cute Decor"
                                        control={<Radio />} label="Cute Decor" />
                      <FormControlLabel value="Food Options"
                                        control={<Radio />} label="Range of food options" />
                      <FormControlLabel value="Night Out"
                                        control={<Radio />} label="Proximity to theater" />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
            }
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header">
          <Typography className="accordion-title">Order Status</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Divider />

          <div className="padding first-entry">
            <div>
              {timeTillShowEl}

              <div>
                <p>Order Number of Tickets: {order.numberOfTickets}</p>

                <p>Order placed: {orderPlacedAt}</p>
                <p>Spin email sent at: {spinEmailAt}</p>
                <p>Tickets email sent at: {ticketsEmailAt}</p>
                <p>Tickets text sent at: {ticketsTextAt}</p>
                <p>Received confirmation at: {receivedConfirmationAt}</p>
              </div>
            </div>

            <div className="top-padding">
              {!isSendingConfirmationEmail &&
                <Button variant="contained"
                        style={{marginBottom: '15px'}}
                        onClick={() => { sendConfirmationEmail() }}>
                  Send Confirmation Email
                </Button>
              }

              {isSendingConfirmationEmail &&
                <CircularProgress size={60} thickness={7} />
              }
            </div>

            <div>
              {!isSendingTicketDetailsEmail &&
                <Button variant="contained"
                        style={{marginBottom: '15px'}}
                        onClick={() => { sendTicketDetailsEmail() }}>
                  Send Ticket Details Email
                </Button>
              }

              {isSendingTicketDetailsEmail &&
                <CircularProgress size={60} thickness={7} />
              }
            </div>
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header">
          <Typography className="accordion-title">Order Update History</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Divider />

          <div className="padding first-entry">
            <TableContainer>
              <Table sx={{ minWidth: 750 }}
                     aria-labelledby="table-title"
                     size='medium'>

                <TableHead>
                  <TableRow>
                    <TableCell align='left'>User</TableCell>
                    <TableCell align='left'>Date</TableCell>
                    <TableCell align='left'>Updated field</TableCell>
                    <TableCell align='left'>Old value</TableCell>
                    <TableCell align='left'>New value</TableCell>
                  </TableRow>
                </TableHead>

                {updateHistory && updateHistory.map((update, idx) => (
                  <TableBody key={idx}>
                    <TableRow>
                      <TableCell align="left">{update.user}</TableCell>
                      <TableCell align="left">{update.date}</TableCell>
                      <TableCell align="left">{update.key}</TableCell>
                      <TableCell align="left">{update.oldValue}</TableCell>
                      <TableCell align="left">{update.newValue}</TableCell>
                    </TableRow>
                  </TableBody>
                ))}
              </Table>
            </TableContainer>

          </div>
        </AccordionDetails>
      </Accordion>

      {!saving &&
        <div className="save-button">
          <Button variant="contained" onClick={handleSaveChanges}>
            Save changes
          </Button>
        </div>
      }

      {saving &&
        <CircularProgress />
      }

    </Item>
  )
}

function OrderDetails(props) {
  const [loading, setLoading] = useState(true)
  const [updating, setUpdating] = useState(false)
  const [error, setError] = useState(false)

  const [orders, setOrders] = useState([])

  const history = useHistory()

  const ids = queryString.parse(props.location.search).id

  const allUserEmails = orders.map((order, index) => {
    return `${order.user.email};`
  })

  const uniqueUserEmails = [...new Set(allUserEmails)]

  const getItemsToRender = (ids) => {
    new Promise(async (resolve, reject) => {
      try {
        var data = []

        if (typeof ids !== 'string') {
          var requests = ids.map(async (id, idx) => {
            const result = await props.client.query({
              query: ORDER_QUERY,
              variables: { id }
            })

            data.push(result.data.order)
          })

          var promiseMaps = await Promise.all(requests)

        } else {
          const result = await props.client.query({
            query: ORDER_QUERY,
            variables: { id: ids }
          })

          data.push(result.data.order)
        }


        setLoading(false)
        setOrders(data)
        resolve()

      } catch(err) {
        console.log(err)
        reject()
        setError(true)
      }
    })
  }

  const handleCancelOrder = async (orderId, credit) => {
    try {
      var updateOrderMutation = gql`
        mutation UpdateOrderMutation($orderId: ID!) {
          orderUpdate(data: {
            id: $orderId,
            isValidOrder: false,
          }) {
            id
            isValidOrder
            requestedShowDate
            requestedShowTime
            updateHistory
          }
        }
      `

      var result = await props.client.mutate({
        mutation: updateOrderMutation,
        variables: {
          orderId
        }
      })

      var cancelledOrder = result.data.orderUpdate
      var orderType = 'spin'

      var cancelledOrderHistory = cancelledOrder.updateHistory ? JSON.parse(cancelledOrder.updateHistory.replace(/\n/g, " ")) : []
      var newUpdateHistory = [...cancelledOrderHistory]

      var updateHistoryEntry = {}
      updateHistoryEntry.date = moment().format('YYYY-MM-DD HH:mm A')
      updateHistoryEntry.key = 'isValidOrder'
      updateHistoryEntry.newValue = false
      updateHistoryEntry.oldValue = cancelledOrder.isValidOrder
      updateHistoryEntry.user = props.currentUser.firstName

      newUpdateHistory.push(updateHistoryEntry)

    } catch(err) {
      alert('Unable to cancel order: ' + orderId)
      console.log(err)
    }

    try {
      var result = await BRClient.updateOrderOnSpreadsheet(orderId, orderType, cancelledOrder.requestedShowDate, cancelledOrder.requestedShowTime, newUpdateHistory, credit)

      if (result.status !== 200) {
        alert('Successfully updated in database but order has not been updated on spreadsheet')
      }
    } catch {
      alert('Successfully updated in database but order has not been updated on spreadsheet')
    }
  }

  const handleCancelOrders = async () => {
    setUpdating(true)

    orders.map(async (order) => {
      var orderId = order.id
      await handleCancelOrder(orderId, null)
    })

    alert(`Successfully cancelled ${orders.length} order(s)`)

    setUpdating(false)
  }

  const handleCreateCredits = async (uniqueUserEmails) => {
    setUpdating(true)

    try {
      uniqueUserEmails.map(async (userEmail, idx) => {
        // format user email properly and get all orders on page belonging to them
        var formattedUserEmail = userEmail.replace(';', '')
        var userOrders = orders.filter(order => order.user.email === formattedUserEmail)

        // create credits for all orders for the user
        userOrders.map(async (userOrder, idx) => {
          var creditToAdd = userOrder.paymentDetails.price + userOrder.paymentDetails.giftCardAmount + userOrder.paymentDetails.creditAmount
          var currency = userOrder.location.currency
          await handleCreateCreditForUser(userOrder, creditToAdd, currency)
          alert(`Successfully cancelled + created credit for ${userOrder.id}`)
        })
      })

      setTimeout(() => {
        getItemsToRender(ids)
      }, 500)

    } catch(err) {
      alert('Unable to create credits')
      console.log(err)
    }

    setUpdating(false)
  }

  const handleCreateCreditForUser = async (userOrder, creditToAdd, currency) => {
    // create new credit
    var creditResult = await props.client.mutate({
                           mutation: CREDIT_CREATE_MUTATION,
                           variables: {
                             currency: currency,
                             initialAmount: creditToAdd,
                             remainingAmount: creditToAdd,
                             orderId: userOrder.id,
                             userId: userOrder.user.id
                           }
                         })

    var credit = creditResult.data.purchaseCreditCreate
    await handleCancelOrder(userOrder.id, credit)
  }

  useEffect(() => {
    const fetchItems = async () => {
      await getItemsToRender(ids)
    }

    if (loading) {
      fetchItems()
    }
  }, [ids, loading])

  return (
    <Box sx={{ width: '100%' }}>
      <div className='data'>
        <Typography sx={{ flex: '1 1 100%' }} variant="h3" className="page-title" component="div">
          Order Details
        </Typography>

        {loading &&
          <CircularProgress />
        }

        {!orders && error &&
          <h2>Error</h2>
        }

        {orders && !loading &&
          <div>
            {!updating &&
              <Stack className="buttons-stack">
                <div>
                  <Button variant="contained"
                          className="cancel-button button"
                          onClick={() => { handleCancelOrders() }}>
                    Just cancel {orders.length} order(s)
                  </Button>
                </div>

                <div>
                  <Button variant="contained"
                          className="credit-button button"
                          onClick={() => { handleCreateCredits(uniqueUserEmails) }}>
                    Cancel + Create credit for {orders.length} order(s)
                  </Button>
                </div>

                <div>
                  <a target="_blank" href={`mailto:?bcc=${uniqueUserEmails}&subject=Important%20Message%20About%20Your%20Show%20Tonight`}>
                    <Button variant="contained" className="email-button button">Email {uniqueUserEmails.length} user(s)</Button>
                  </a>
                </div>
              </Stack>
            }

            {updating &&
              <CircularProgress />
            }

          <Grid container spacing={2} justifyContent="center">
            {orders.map((boxData, idx) => {
              return (
                <Grid item xs={12} md={6} lg={6} key={boxData.id}>
                  <OrderBox order={boxData} client={props.client} currentUser={props.currentUser} key={idx} />
                </Grid>
              )
            })}
          </Grid>
          </div>
        }
      </div>
    </Box>
  )
}

const LOCATIONS_LIST_QUERY = gql`
  query LocationsListQuery {
    locationsList(sort: { name: ASC }) {
      items {
        id
        name
      }
    }
  }
`

const RETAILERS_LIST_QUERY = gql`
  query RetailersListQuery {
    retailersList(sort: { name: ASC }) {
      items {
        id
        name
      }
    }
  }
`

const SHOWS_LIST_QUERY = gql`
  query ShowsListQuery {
    showsList(sort: { name: ASC }) {
      items {
        id
        isForOffBroadway

        location {
          name
        }

        runDateStart
        runDateEnd
        shortName
      }
    }
  }
`

const ORDER_QUERY = gql`
  query OrderQuery($id: ID) {
    order(id: $id) {
      id
      createdAt
      isForOffBroadway
      isOptingIntoExtendedHours
      isRequestingChildFriendly
      isRequestingPremium
      isRequestingTicketFlex
      isValidOrder
      notes
      numberOfTickets
      orderPlacedAt
      requestedShowDate
      requestedShowTime
      showPreference
      updateHistory

      excludedShows {
        items {
          id
          shortName
        }
      }

      location {
        id
        currency
        name
      }

      retailer {
        id
        name
      }

      orderStatus {
        id
        receivedConfirmationAt
        sentSpinEmailAt
        sentTicketsEmailAt
        sentTicketsTextAt
      }

      orderSurvey {
        hasHeardOfShow
        numberOfShowsSeen
        rating
        wouldHaveBoughtSeparately
        wouldRecommend
      }

      paymentDetails {
        id
        price
        additionalExclusionFee
        creditAmount
        discountAmount
        discountCode
        familyFee
        premiumFee
        restaurantFee
        serviceFee
        ticketFlexFee
        giftCardAmount
        subscriptionDiscountAmount

        promoCode {
          id
          code
        }

        giftCardCode {
          id
          code
        }
      }

      pointsRedemptionDetails {
        items {
          id
          pointsRedeemed
        }
      }

      restaurantReservationDetails {
        id
        preferences

        restaurant {
          name
        }
      }

      subscriptionUsed {
        id
      }

      ticketDetails {
        show {
          name
        }
      }

      user {
        id
        email
        firstName
        lastName

        purchaseCredits {
          items {
            id
            isExpired
            initialAmount
            remainingAmount
          }
        }
      }
    }
  }
`

const RESTAURANT_RESERVATION_DETAILS_CREATE_MUTATION = gql`
  mutation RestaurantReservationDetailsCreateMutation($orderId: ID!, $restaurantPreferences: String!) {
    restaurantReservationDetailCreate(data: {
      preferences: $restaurantPreferences
      order: {
        connect: {
          id: $orderId
        }
      }
    }) {
      id
    }
  }
`

const PAYMENT_DETAILS_UPDATE_MUTATION  = gql`
  mutation PaymentDetailsUpdateMutation($paymentDetailsId: ID!, $price: Float!, $additionalExclusionFee: Float,
                                        $familyFee: Float, $premiumFee: Float, $restaurantFee: Float, $serviceFee: Float,
                                        $ticketFlexFee: Float, $discountAmount: Float!, $giftCardAmount: Float,
                                        $creditAmount: Float, $subscriptionDiscountAmount: Float) {
    paymentDetailUpdate(data: {
      id: $paymentDetailsId,
      price: $price,
      additionalExclusionFee: $additionalExclusionFee,
      discountAmount: $discountAmount,
      familyFee: $familyFee,
      restaurantFee: $restaurantFee,
      subscriptionDiscountAmount: $subscriptionDiscountAmount,
      serviceFee: $serviceFee,
      ticketFlexFee: $ticketFlexFee,
      creditCardFee: 0,
      premiumFee: $premiumFee,
      processingFee: 0,
      giftCardAmount: $giftCardAmount,
      creditAmount: $creditAmount,
    }) {
      id
    }
  }
`

export default withApollo(graphql(ORDER_QUERY, {
  name: 'orderQuery',
  options: (ownProps) => {
    const id = queryString.parse(ownProps.location.search).id[0]

    return {
      variables: { id },
      notifyOnNetworkStatusChange: true
    }
  }
})(OrderDetails))
