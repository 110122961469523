import React, { Component } from 'react'
import { gql } from 'apollo-boost'
import { graphql, withApollo } from 'react-apollo'
import moment from 'moment-timezone'

import InfiniteCalendar from 'react-infinite-calendar'
import 'react-infinite-calendar/styles.css'

import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import Switch from '@material-ui/core/Switch'


const CALENDAR_OPTIONS = {
  theme: {
    accentColor: '#E7B203',
    headerColor: '#959595',
    selectionColor: '#E7B203',
    textColor: {
      active: '#FFF',
      default: '#333'
    },
    todayColor: '#E7B203',
    weekdayColor: '#959595'
  },
  displayOptions: {
    layout: 'landscape',
    showHeader: false,
    showOverlay: false,
    showTodayHelper: false,
    shouldHeaderAnimate: false,
    todayHelperRowOffset: 1,
  }
}

class DisabledDateDetails extends Component {

  state = {
    isEditingLocked: true,
  }

  _clearUserCopy() { if (this.state.disabledDate) { this.setState({ disabledDate: null }) } }

  _handleChangeLock(isInputChecked) {
    this.setState({ isEditingLocked: isInputChecked })

    if (isInputChecked) {
      this._updateFields()
    }
  }

  _handleChangeDisabledDate(e) { 
    var dateStr = moment(e).format('YYYY-MM-DD')
    this._updateDisabledDate('date', dateStr) 
  }

  _handleChangeIsActive(val) { this._updateDisabledDate('isActive', val) }
  _handleChangeIsForAllShows(val) { this._updateDisabledDate('isForAllShows', val) }
  _handleChangeIsForMatinee(val) { this._updateDisabledDate('isForMatinee', val) }
  _handleChangeIsForMatineeFamilyRoulette(val) { this._updateDisabledDate('isForMatineeFamilyRoulette', val) }
  _handleChangeIsForMatineePremiumRoulette(val) { this._updateDisabledDate('isForMatineePremiumRoulette', val) }
  _handleChangeIsForMatineeRegularRoulette(val) { this._updateDisabledDate('isForMatineeRegularRoulette', val) }
  _handleChangeIsForEvening(val) { this._updateDisabledDate('isForEvening', val) }
  _handleChangeIsForFamilyRoulette(val) { this._updateDisabledDate('isForFamilyRoulette', val) }
  _handleChangeIsForPremiumRoulette(val) { this._updateDisabledDate('isForPremiumRoulette', val) }
  _handleChangeIsForRegularRoulette(val) { this._updateDisabledDate('isForRegularRoulette', val) }
  _handleChangeLocation(val) { this._updateDisabledDate('location', val) }

  _loadLocations = async () => {
    try {
      var locationsQuery = await this.props.client.query({ query: LOCATIONS_LIST_QUERY })
      this.setState({ locationsQuery })

    } catch (err) {
      console.log('Unable to get locations data')
    }
  }

  _navigate(url) {
    this.props.history.push(url)
  }

  _refetchData() {
    this.props.disabledDateQuery.refetch();
  }

  _setDisabledDateCopy(disabledDate) {
    disabledDate.location = disabledDate.location.name

    this.setState({ disabledDate })
  }

  _updateDisabledDate(key, value) {
    if (!this.state.disabledDate) { return }

    var state = { disabledDate: { ...this.state.disabledDate } }
    state.disabledDate[key] = value

    this.setState(state)
  }

  _updateFields = async () => {
    if (!this.state.disabledDate) { return }

    const id = this.props.match.params.id
    try {
      await this.props.client.mutate({
        mutation: DISABLED_DATE_UPDATE_MUTATION,
        variables: {
          id,
          date: this.state.disabledDate.date,
          isActive: this.state.disabledDate.isActive,
          isForAllShows: this.state.disabledDate.isForAllShows,
          isForMatinee: this.state.disabledDate.isForMatinee,
          isForMatineeFamilyRoulette: this.state.disabledDate.isForMatineeFamilyRoulette,
          isForMatineePremiumRoulette: this.state.disabledDate.isForMatineePremiumRoulette,
          isForMatineeRegularRoulette: this.state.disabledDate.isForMatineeRegularRoulette,
          isForEvening: this.state.disabledDate.isForEvening,
          isForFamilyRoulette: this.state.disabledDate.isForFamilyRoulette,
          isForPremiumRoulette: this.state.disabledDate.isForPremiumRoulette,
          isForRegularRoulette: this.state.disabledDate.isForRegularRoulette,
          location: this.state.disabledDate.location
        }
      })
    } catch (err) {
      alert(`Unable to update disabled date: ${err.message}`)
    }

    this._refetchData()
  }

  componentDidUpdate() {
    var query = this.props.disabledDateQuery
    

    if (query && query.loading) { this._clearUserCopy() }
    else if (!this.state.disabledDate) {
      this._setDisabledDateCopy(query.disabledDate)
      this._loadLocations()
    }
  }

  render() {
    if (this.props.disabledDateQuery && this.props.disabledDateQuery.loading) {
      return <div>Loading</div>
    }

    if (this.props.disabledDateQuery && this.props.disabledDateQuery.error) {
      return <div>Error</div>
    }

    if (!this.state.disabledDate) {
      return <div>Loading</div>
    }

    const disabledDate = this.state.disabledDate

    const paperStyle = { height: '90%', width: '100%', textAlign: 'center', display: 'inline-block' }

    var flatButtonStyle = { height: '100%' }
    if (this.state.isEditingLocked) {
      flatButtonStyle.visibility = 'hidden'
    }

    return (
      <div>
        <h1>Date Details</h1>

        <Paper style={paperStyle}>

          <div className='flex mt2 items-start' style={{ display: 'inline-block', textAlign: 'left' }}>
            <div className='ml1'>
              <div>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.isEditingLocked}
                    onChange={(e, b) => { this._handleChangeLock(b) }}
                  />
                }
                label={"Editing " + (this.state.isEditingLocked ? "Locked" : "Unlocked")}
              />
              </div>

              <div>
                <InfiniteCalendar width={400}
                                  height={280}
                                  selected={disabledDate.date || moment().toDate()}
                                  theme={CALENDAR_OPTIONS.theme}
                                  displayOptions={CALENDAR_OPTIONS.displayOptions}
                                  rowHeight={56}
                                  onSelect={(e) => this._handleChangeDisabledDate(e)}
                />
              </div>

              <div className="padding">
                <FormControl>
                  <InputLabel style={{marginRight: '10px', width:"400px"}}>Location</InputLabel>
                  <Select value={this.state.disabledDate.location}
                          label="Location"
                          onChange={(e,k,p) => { this._handleChangeLocation(k.props.value) }}
                          disabled={this.state.isEditingLocked}>

                    {this.state.locationsQuery && this.state.locationsQuery.data.locationsList.items.map((location, idx) => (
                      <MenuItem key={idx} value={location.name}> {location.name} </MenuItem>
                    ))}

                  </Select>
                </FormControl>
              </div>

              <div>
                <FormControlLabel
                  control={<Checkbox checked={disabledDate.isActive}
                                     onChange={(e,b) => { this._handleChangeIsActive(b) }}
                                     disabled={this.state.isEditingLocked} />}
                  label="Active?"
                />
              </div>
              <div>
                <FormControlLabel
                  control={<Checkbox checked={disabledDate.isForAllShows}
                                     onChange={(e,b) => { this._handleChangeIsForAllShows(b) }}
                                     disabled={this.state.isEditingLocked} />}
                  label="Disable All Shows"
                />
              </div>
              <div>
                <FormControlLabel
                  control={<Checkbox checked={disabledDate.isForMatinee}
                                     onChange={(e,b) => { this._handleChangeIsForMatinee(b) }}
                                     disabled={this.state.isEditingLocked} />}
                  label="Disable Matinee (if matinee & evening are disabled be sure to disable all shows)"
                />
              </div>
              <div>
                <FormControlLabel
                  control={<Checkbox checked={disabledDate.isForMatineeFamilyRoulette}
                                     onChange={(e,b) => { this._handleChangeIsForMatineeFamilyRoulette(b) }}
                                     disabled={this.state.isEditingLocked} />}
                  label="Disable Matinee Family Roulette"
                />
              </div>
              <div>
                <FormControlLabel
                  control={<Checkbox checked={disabledDate.isForMatineePremiumRoulette}
                                     onChange={(e,b) => { this._handleChangeIsForMatineePremiumRoulette(b) }}
                                     disabled={this.state.isEditingLocked} />}
                  label="Disable Matinee Premium Roulette"
                />
              </div>
              <div>
                <FormControlLabel
                  control={<Checkbox checked={disabledDate.isForMatineeRegularRoulette}
                                     onChange={(e,b) => { this._handleChangeIsForMatineeRegularRoulette(b) }}
                                     disabled={this.state.isEditingLocked} />}
                  label="Disable Matinee Standard Roulette"
                />
              </div>
              <div>
                <FormControlLabel
                  control={<Checkbox checked={disabledDate.isForEvening}
                                     onChange={(e,b) => { this._handleChangeIsForEvening(b) }}
                                     disabled={this.state.isEditingLocked} />}
                  label="Disable Evening (if matinee & evening are disabled be sure to disable all shows)"
                />
              </div>
              <div>
                <FormControlLabel
                  control={<Checkbox checked={disabledDate.isForFamilyRoulette}
                                     onChange={(e,b) => { this._handleChangeIsForFamilyRoulette(b) }}
                                     disabled={this.state.isEditingLocked} />}
                  label="Disable Evening Family Roulette"
                />
              </div>
              <div>
                <FormControlLabel
                  control={<Checkbox checked={disabledDate.isForPremiumRoulette}
                                     onChange={(e,b) => { this._handleChangeIsForPremiumRoulette(b) }}
                                     disabled={this.state.isEditingLocked} />}
                  label="Disable Evening Premium Roulette"
                />
              </div>
              <div>
                <FormControlLabel
                  control={<Checkbox checked={disabledDate.isForRegularRoulette}
                                     onChange={(e,b) => { this._handleChangeIsForRegularRoulette(b) }}
                                     disabled={this.state.isEditingLocked} />}
                  label="Disable Evening Standard Roulette"
                />
              </div>
            </div>
          </div>
        </Paper>
      </div>
    )
  }
}

const DISABLED_DATE_QUERY = gql`
  query DisabledDateQuery($id: ID) {
    disabledDate(id: $id) {
      id
      date
      isActive
      isForAllShows
      isForMatinee
      isForMatineeFamilyRoulette
      isForMatineePremiumRoulette
      isForMatineeRegularRoulette
      isForEvening
      isForFamilyRoulette
      isForPremiumRoulette
      isForRegularRoulette

      location {
        id
        name
      }
    }
  }
`

const DISABLED_DATE_UPDATE_MUTATION = gql`
  mutation DisabledDateUpdateMutation($id: ID!, $date: Date, $isActive: Boolean, $isForAllShows: Boolean,
                                      $isForMatinee: Boolean $isForMatineeFamilyRoulette: Boolean,
                                      $isForMatineePremiumRoulette: Boolean, $isForMatineeRegularRoulette: Boolean,
                                      $isForEvening: Boolean, $isForFamilyRoulette: Boolean,
                                      $isForPremiumRoulette: Boolean, $isForRegularRoulette: Boolean, $location: String) {
    disabledDateUpdate(data: {
      id: $id
      date: $date,
      isActive: $isActive,
      isForAllShows: $isForAllShows,
      isForMatinee: $isForMatinee,
      isForMatineeFamilyRoulette: $isForMatineeFamilyRoulette,
      isForMatineePremiumRoulette: $isForMatineePremiumRoulette,
      isForMatineeRegularRoulette: $isForMatineeRegularRoulette,
      isForEvening: $isForEvening,
      isForFamilyRoulette: $isForFamilyRoulette,
      isForPremiumRoulette: $isForPremiumRoulette,
      isForRegularRoulette: $isForRegularRoulette

      location: {
        connect: { name: $location }
      }
    }) {
      id
    }
  }
`

const LOCATIONS_LIST_QUERY = gql`
  query LocationsListQuery {
    locationsList(sort: { name: ASC }) {
      items {
        id
        name
      }
    }
  }
`

export default withApollo(graphql(DISABLED_DATE_QUERY, {
  name: 'disabledDateQuery',
  options: (ownProps) => {
    const id = ownProps.match.params.id
    return {
      variables: { id },
      notifyOnNetworkStatusChange: true
    }
  }
})(DisabledDateDetails))
