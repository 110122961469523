import React, { useState, useEffect } from 'react'
import { gql } from 'apollo-boost'
import { graphql, withApollo } from 'react-apollo'
import moment from 'moment-timezone'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import { withRouter } from 'react-router'

import AdapterDateFns from '@mui/lab/AdapterDateFns'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Divider from '@mui/material/Divider'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import FormLabel from '@mui/material/FormLabel'
import InputLabel from '@mui/material/InputLabel'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body1,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
}))

const VenueData = (props) => {
  const { venue } = props

  const [requiredFieldBlankError, setRequiredFieldBlankError] = useState(false)
  const [saving, setSaving] = useState(false)
  const [locations, setLocations] = useState([])

  const [venueState, setVenueState] = useState({
    address: venue.address,
    location: venue.location.name,
    name: venue.name,
    venueType: venue.venueType,
  })

  const handleSaveChanges = async () => {
    try {
      setSaving(true)

      var { address, name, venueType, location } = venueState

      if (!address || !venueType || !name || !location) {
        setRequiredFieldBlankError(true)
        alert('Please fill in required fields')

        setSaving(false)
        return
      }

      var variables = {
        id: venue.id,
        name,
        address,
        venueType,
        location
      }

      var result = await props.client.mutate({
        mutation: VENUE_UPDATE_MUTATION,
        variables
      })

      alert('Venue successfully updated')
      setSaving(false)

    } catch (err) {
      setSaving(false)
      alert(`Did not save, error: ${err}`)
    }
  }

  const handleUpdateVenueState = (key, value) => {
    var newVenueState = {...venueState}

    newVenueState[key] = value
    setVenueState(newVenueState)
  }

  const loadLocations = async () => {
    try {
      var locationsQuery = await props.client.query({ query: LOCATIONS_LIST_QUERY })
      setLocations(locationsQuery.data.locationsList.items)

    } catch (err) {
      console.log('Unable to get locations data')
    }
  }

  useEffect(() => {
    loadLocations()
  }, [])

  return (
    <Item className="order-tile">
      <Typography sx={{ flex: '1 1 100%' }} variant="h5" className="order-tile-subtitle" component="div">
        {venue.name}
      </Typography>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header">
          <Typography className="accordion-title">Basic Details</Typography>
          <Divider />
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <Divider />

            <Stack justifyContent="space-around" direction="row" flexWrap="wrap">

              <Stack direction="column">
                <h3>Details</h3>

                <div className="padding first-entry">
                  <TextField label="ID"
                             defaultValue={venue.id}
                             disabled={true} />
                </div>

                <div className="padding">
                  <TextField label="Name"
                             helperText="*required field"
                             error={requiredFieldBlankError && !venueState.name}
                             defaultValue={venueState.name}
                             onChange={(e) => { handleUpdateVenueState('name', e.target.value) }} />
                </div>

                <div className="padding flex-basis-0">
                  <FormControl error={requiredFieldBlankError && !venueState.venueType}>
                    <InputLabel>Venue Type</InputLabel>
                    <Select value={venueState.venueType}
                            label="Venue Type"
                            onChange={(event) => handleUpdateVenueState('venueType', event.target.value)}>

                      <MenuItem key={'Theatre'} value={'Theatre'}> Theatre </MenuItem>
                      <MenuItem key={'Concert Hall'} value={'Concert Hall'}> Concert Hall </MenuItem>
                      <MenuItem key={'Museum'} value={'Museum'}> Museum </MenuItem>
                      <MenuItem key={'Stadium'} value={'Stadium'}> Stadium </MenuItem>
                      <MenuItem key={'Other'} value={'Other'}> Other </MenuItem>

                    </Select>
                    <FormHelperText>*required field</FormHelperText>
                  </FormControl>
                </div>
              </Stack>

              <Stack direction="column">
                <h3>Location</h3>

                <div className="padding flex-basis-0">
                  <FormControl error={requiredFieldBlankError && !venueState.location}>
                    <InputLabel>Location</InputLabel>
                    <Select value={venueState.location}
                            label="Location"
                            onChange={(event) => handleUpdateVenueState('location', event.target.value)}>

                        {locations && locations.map((locationMap, idx) => (
                          <MenuItem key={idx} value={locationMap.name}> {locationMap.name} </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>*required field</FormHelperText>
                  </FormControl>
                </div>

                <div className="padding">
                  <TextField label="Address"
                             helperText="*required field"
                             error={requiredFieldBlankError && !venueState.address}
                             defaultValue={venueState.address}
                             onChange={(e) => { handleUpdateVenueState('address', e.target.value) }} />
                </div>
              </Stack>

            </Stack>
          </div>

        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header">
          <Typography className="accordion-title">Shows List</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Divider />

          {venue.events.items.length > 0 && venue.events.items.map((event) =>
            (<ListItem key={event.id}>
               <a href={'/event/' + event.id}><ListItemText primary={event.shortName} /></a>
             </ListItem>))
           }

        </AccordionDetails>
      </Accordion>

      {!saving &&
        <div className="save-button">
          <Button variant="contained" onClick={handleSaveChanges}>
            Save changes
          </Button>
        </div>
      }

      {saving &&
        <CircularProgress />
      }

    </Item>
  )
}

function VenueDetails(props) {
  const [loading, setLoading] = useState(true)
  const [updating, setUpdating] = useState(false)
  const [error, setError] = useState(false)

  const [venue, setVenue] = useState([])

  const getVenueData = () => {
    try {
      var venue = props.venueQuery.venue

      if (venue) {
        setVenue(venue)
        setLoading(false)
      }

    } catch(err) {
      console.log(err)
      setError(true)
    }
  }

  useEffect(async () => {
    if (loading) {
      await getVenueData()
    }
  }, [props.venueQuery, loading])

  return (
    <Box sx={{ width: '100%' }}>
      <div className='data'>

        <Typography sx={{ flex: '1 1 100%' }} variant="h3" className="page-title" component="div">
          Venue Details
        </Typography>

        {loading &&
          <CircularProgress />
        }

        {!venue && error &&
          <h2>Error</h2>
        }

        {venue && !loading &&
          <VenueData venue={venue} client={props.client} />
        }

      </div>
    </Box>
  )
}

const VENUE_QUERY = gql`
  query VenueQuery($id: ID) {
    venue(id: $id) {
      id
      createdAt
      name
      venueType
      address

      location {
        id
        name
      }

      events {
        items {
          id
          shortName
        }
      }
    }
  }
`

const VENUE_UPDATE_MUTATION = gql`
  mutation VenueUpdateMutation($id: ID!, $name: String, $address: String, $venueType: String, $location: String) {
    venueUpdate(data: {
      id: $id
      name: $name
      venueType: $venueType
      address: $address

      location: {
        connect: { name: $location }
      }

    }) {
      id
    }
  }
`

const LOCATIONS_LIST_QUERY = gql`
  query LocationsListQuery {
    locationsList(sort: { name: ASC }) {
      items {
        id
        name
      }
    }
  }
`

export default withApollo(graphql(VENUE_QUERY, {
  name: 'venueQuery',
  options: (ownProps) => {
    const id = ownProps.match.params.id

    return {
      variables: { id },
      notifyOnNetworkStatusChange: true
    }
  }
})(VenueDetails))
