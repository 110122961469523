import React, { Component } from 'react'
import { gql } from 'apollo-boost'
import { graphql, withApollo } from 'react-apollo'
import moment from 'moment-timezone'
import { withRouter } from 'react-router'

import Button from '@material-ui/core/Button'
import Fab from '@material-ui/core/Fab'
import FontAwesome from 'react-fontawesome'
import Icon from '@material-ui/core/Icon'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import { ENTRIES_PER_PAGE } from '../constants'
import { getBeginningOfTimeStrYYYYMMDD, getParamsFromUrl, getTodayStrYYYYMMDD } from '../utils'


class DisabledDatesSummary extends Component {

  render() {
    const disabledDate = this.props.disabledDate
    const dateDisabled = (<a href={'/disabled-date/' + disabledDate.id}>{ disabledDate.date.split('T')[0] }</a>)

    return (
      <TableRow>
        <TableCell>{ dateDisabled }</TableCell>
        <TableCell>
          {disabledDate.isActive && <FontAwesome name="check"/>}
        </TableCell>
        <TableCell>
          {disabledDate.isForAllShows && <FontAwesome name="check"/>}
        </TableCell>
        <TableCell>
          {disabledDate.isForMatinee && <FontAwesome name="check"/>}
        </TableCell>
        <TableCell>
          {disabledDate.isForMatineeFamilyRoulette && <FontAwesome name="check"/>}
        </TableCell>
        <TableCell>
          {disabledDate.isForMatineePremiumRoulette && <FontAwesome name="check"/>}
        </TableCell>
        <TableCell>
          {disabledDate.isForMatineeRegularRoulette && <FontAwesome name="check"/>}
        </TableCell>
        <TableCell>
          {disabledDate.isForEvening && <FontAwesome name="check"/>}
        </TableCell>
        <TableCell>
          {disabledDate.isForFamilyRoulette && <FontAwesome name="check"/>}
        </TableCell>
        <TableCell>
          {disabledDate.isForPremiumRoulette && <FontAwesome name="check"/>}
        </TableCell>
        <TableCell>
          {disabledDate.isForRegularRoulette && <FontAwesome name="check"/>}
        </TableCell>
        <TableCell>
          {disabledDate.location.name}
        </TableCell>
      </TableRow>
    )
  }
}

class DisabledDatesList extends Component {

  state = { }

  _getItemsToRender = () => {
    return this.props.disabledDatesQuery.disabledDatesList.items
  }

  async _handleClickNew() {
    try {
      var yesterday = moment().add(-1, 'days').format('YYYY-MM-DD')

      const result = await this.props.client.mutate({
        mutation: DISABLED_DATE_CREATE_MUTATION,
        variables: {
          date: yesterday
        }
      })

      this.props.history.push(`/disabled-date/${result.data.disabledDateCreate.id}`)
    } catch(err) {
      alert(`Unable to create new disabled date: ${err.message}`)
    }
  }

  _nextPage = () => {
    const page = parseInt(this.props.match.params.page, 10)
    const nextPage = page + 1

    const wantsOrdersForToday = this.props.location.pathname.includes('today')
    const todayPart = wantsOrdersForToday ? '-today' : ''

    this.props.history.push(`/disabled-dates${todayPart}/${nextPage}`)
  }

  _previousPage = () => {
    const page = parseInt(this.props.match.params.page, 10)
    if (page > 1) {
      const nextPage = page - 1

      const wantsOrdersForToday = this.props.location.pathname.includes('today')
      const todayPart = wantsOrdersForToday ? '-today' : ''

      this.props.history.push(`/disabled-dates${todayPart}/${nextPage}`)
    }
  }

  render() {
    if (this.props.disabledDatesQuery && this.props.disabledDatesQuery.loading) {
      return <div>Loading</div>
    }

    if (this.props.disabledDatesQuery && this.props.disabledDatesQuery.error) {
      return <div>Error</div>
    }

    const itemsToRender = this._getItemsToRender()

    var islastPage = this.props.disabledDatesQuery.disabledDatesList.count - this.props.disabledDatesQuery.variables.skip < ENTRIES_PER_PAGE
    const page = parseInt(this.props.match.params.page, 10)
    var itemOffset = (page - 1) * ENTRIES_PER_PAGE

    return (
      <div>
        <div>
          <h1 style={{ display: 'inline-block' }}>Disabled Dates</h1>
          <Fab style={{ marginLeft: '10px' }}
               onClick={() => { this._handleClickNew() }}>
            <Icon className="fa fa-plus-circle" />
          </Fab>
          <h4 className="information-header">
            The add button makes an inactive date for yesterday as a placeholder for you to edit. It will fail if yesterday
            is already on the list. In that case, edit yesterday's to be what you need and then create a new date for
            yesterday and update to match old records so we can remember which days we have disabled.
          </h4>
        </div>

        <Paper>
          <Table>
            <TableHead>
              <TableRow style={{padding:"5px"}}>
                <TableCell>Date</TableCell>
                <TableCell>Is Active</TableCell>
                <TableCell>For All Shows</TableCell>
                <TableCell>Matinee</TableCell>
                <TableCell>M-Family</TableCell>
                <TableCell>M-Premium</TableCell>
                <TableCell>M-Regular</TableCell>
                <TableCell>Evening</TableCell>
                <TableCell>E-Family</TableCell>
                <TableCell>E-Premium</TableCell>
                <TableCell>E-Regular</TableCell>
                <TableCell>Location</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {itemsToRender.map((obj, index) => (
                <DisabledDatesSummary key={obj.id} disabledDate={obj} index={itemOffset + index}/>
              ))}
            </TableBody>
          </Table>
        </Paper>
        <div>
          <Button variant="contained"
                  disabled={this.props.match.params.page <= 1}
                  style={{ margin: '10px 10px 15px 0'}}
                  onClick={() => { this._previousPage() }}>
          Previous
          </Button>
          <Button variant="contained"
                  disabled={islastPage}
                  style={{ margin: '10px 10px 15px 0'}}
                  onClick={() => { this._nextPage() }}>
          Next
          </Button>
        </div>
      </div>
    )
  }
}

const DISABLED_DATES_LIST_QUERY = gql`
  query DisabledDatesListQuery($first: Int, $skip: Int) {
    disabledDatesList(
      first: $first,
      skip: $skip,
      sort: { date: DESC }
    ) {
      count

      items {
        id
        date
        isActive
        isForAllShows
        isForMatinee
        isForMatineeFamilyRoulette
        isForMatineePremiumRoulette
        isForMatineeRegularRoulette
        isForEvening
        isForFamilyRoulette
        isForPremiumRoulette
        isForRegularRoulette

        location {
          id
          name
        }
      }
    }
  }
`

const DISABLED_DATE_CREATE_MUTATION = gql`
  mutation DisabledDateCreateMutation($date: Date!) {
    disabledDateCreate(data: {
      date: $date,
      isActive: false,
      isForAllShows: false,
      isForMatinee: false,
      isForMatineeFamilyRoulette: false,
      isForMatineePremiumRoulette: false,
      isForMatineeRegularRoulette: false,
      isForEvening: false,
      isForFamilyRoulette: false,
      isForPremiumRoulette: false,
      isForRegularRoulette: false,

      location: {
        connect: { name: "Broadway" }
      }
    }) {
      id
    }
  }
`

export default withRouter(withApollo(graphql(DISABLED_DATES_LIST_QUERY, {
  name: 'disabledDatesQuery',
  options: (ownProps) => {
    const wantsOrdersForToday = ownProps.location.pathname.includes('today')
    var date = wantsOrdersForToday ? getTodayStrYYYYMMDD() : getBeginningOfTimeStrYYYYMMDD()

    return {
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
      variables: { ...getParamsFromUrl(ownProps), date }
    }
  }
})(DisabledDatesList)))
