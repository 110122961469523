import React, { Component } from 'react'
import { gql } from 'apollo-boost'
import { graphql, withApollo } from 'react-apollo'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import Paper from '@material-ui/core/Paper'
import Switch from '@material-ui/core/Switch'
import TextField from '@material-ui/core/TextField'


class Pickup extends Component {

  state = {
    isEditingLocked: true
  }

  _handleChangeLock(isInputChecked) {
    this.setState({ isEditingLocked: isInputChecked })

    if (isInputChecked) {
      this._updateTicketPickupFields()
    }
  }

  _handleChangeName(val) { this._updateTicketPickup('name', val) }
  _handleChangeDirections(val) { this._updateTicketPickup('directions', val) }

  _clearTicketPickupCopy() {
    if (this.state.ticketPickup) { this.setState({ ticketPickup: null }) }
  }

  _navigate(url) {
    this.props.history.push(url)
  }

  _refetchData() {
    this.props.ticketPickupQuery.refetch();
  }

  _setTicketPickupCopy() {
    this.setState({ ticketPickup: this.props.ticketPickupQuery.ticketPickup })
  }

  _updateTicketPickup(key, value) {
    if (!this.state.ticketPickup) { return }

    var state = { ticketPickup: { ...this.state.ticketPickup } }
    state.ticketPickup[key] = value

    this.setState(state)
  }

  _updateTicketPickupFields = async function() {
    if (!this.state.ticketPickup) { return }

    try {
      await this.props.client.mutate({
        mutation: TICKET_PICKUP_UPDATE_MUTATION,
        variables: {
          id: this.props.match.params.id,
          name: this.state.ticketPickup.name,
          directions: this.state.ticketPickup.directions
        }
      })
    } catch (err) {
      alert(`Error while updating ticket pickup: ${err.message}`)
    }

    this._refetchData()
  }

  componentDidUpdate() {
    const query = this.props.ticketPickupQuery

    if (query && query.loading) { this._clearTicketPickupCopy() }
    else if (!this.state.ticketPickup) { this._setTicketPickupCopy() }
  }

  render() {
    if (this.props.ticketPickupQuery && this.props.ticketPickupQuery.loading) {
      return <div>Loading</div>
    }

    if (this.props.ticketPickupQuery && this.props.ticketPickupQuery.error) {
      return <div>Error</div>
    }

    if (!this.state.ticketPickup) {
      return <div>Loading</div>
    }

    const ticketPickup = this.state.ticketPickup

    const paperStyle = { height: '90%', width: '100%', textAlign: 'center', display: 'inline-block' }

    var flatButtonStyle = { height: '100%' }
    if (this.state.isEditingLocked) {
      flatButtonStyle.visibility = 'hidden'
    }

    return (
      <div>
        <h1>Pickup Details</h1>

        <Paper style={paperStyle}>

          <div className='flex mt2 items-start' style={{ display: 'inline-block', textAlign: 'left' }}>
            <div>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.isEditingLocked}
                    onChange={(e, b) => { this._handleChangeLock(b) }}
                  />
                }
                label={"Editing " + (this.state.isEditingLocked ? "Locked" : "Unlocked")}
              />
            </div>

            <div className='ml1'>
              <div className="padding">
                <TextField defaultValue={ticketPickup.name}
                           disabled={this.state.isEditingLocked}
                           style={{width:"350px", paddingTop:"10px"}}
                           onChange={(e) => { this._handleChangeName(e.target.value) }}
                           label="Name"/>
              </div>

              <div className="padding">
                <TextField defaultValue={ticketPickup.directions}
                           disabled={this.state.isEditingLocked}
                           style={{width:"350px", paddingTop:"10px"}}
                           onChange={(e) => { this._handleChangeDirections(e.target.value) }}
                           label="Directions"/>
              </div>
            </div>
          </div>

        </Paper>
      </div>
    )
  }
}

const TICKET_PICKUP_QUERY = gql`
  query TicketPickupQuery($id: ID) {
    ticketPickup(id: $id) {
      id
      name
      directions
    }
  }
`

const TICKET_PICKUP_UPDATE_MUTATION = gql`
  mutation TicketPickupUpdateMutation($id: ID!, $name: String, $directions: String) {
    ticketPickupUpdate(data: {
      id: $id,
      name: $name,
      directions: $directions
    }) {
      id
    }
  }
`

export default withApollo(graphql(TICKET_PICKUP_QUERY, {
  name: 'ticketPickupQuery',
  options: (ownProps) => {
    const id = ownProps.match.params.id

    return {
      variables: { id },
      notifyOnNetworkStatusChange: true
    }
  }
})(Pickup))
