import React, { Component } from 'react'
import { gql } from 'apollo-boost'
import { graphql, withApollo } from 'react-apollo'
import moment from 'moment-timezone'

import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Select from '@material-ui/core/Select'
import Switch from '@material-ui/core/Switch'
import TextField from '@material-ui/core/TextField'


class CreditDetails extends Component {

  state = {
    isEditingLocked: true
  }

  _handleChangeLock(isInputChecked) {
    this.setState({ isEditingLocked: isInputChecked })

    if (isInputChecked) {
      this._updateCreditFields()
    }
  }

  _handleChangeInitialAmount(val) { this._updateCredit('initialAmount', val) }
  _handleChangeIsExpired(val) { this._updateCredit('isExpired', val) }
  _handleChangeRemainingAmount (val) { this._updateCredit('remainingAmount', val) }
  _handleChangeCurrency (val) { this._updateCredit('currency', val) }
  _handleChangeNotes (val) { this._updateCredit('notes', val) }
  _handleChangeType (val) { this._updateCredit('creditType', val) }

  _refetchData() {
    this.props.purchaseCreditQuery.refetch()
  }

  _setCredit() {
    var purchaseCreditQuery = { ...this.props.purchaseCreditQuery }

    this.setState({
      credit: purchaseCreditQuery.purchaseCredit
    })
  }

  _updateCredit(key, value) {
    if (!this.state.credit) { return }

    var state = { credit: { ...this.state.credit } }
    state.credit[key] = value

    this.setState(state)
  }

  _updateCreditFields = async function() {
    if (!this.state.credit) { return }

    const credit = this.state.credit

    try {
      const id = this.props.match.params.id

      await this.props.client.mutate({
        mutation: PURCHASE_CREDIT_UPDATE_MUTATION,
        variables: {
          id: id,
          currency: credit.currency,
          isExpired: credit.isExpired,
          initialAmount: parseFloat(credit.initialAmount),
          remainingAmount: parseFloat(credit.remainingAmount),
          notes: credit.notes,
          creditType: credit.creditType
        }
      })
    } catch (err) {
      alert(`Unable to update credit: ${err.message}`)
    }

    this._refetchData()
  }

  componentDidUpdate() {
    if (!this.state.credit) { this._setCredit() }
  }

  render() {
    if (this.props.purchaseCreditQuery && this.props.purchaseCreditQuery.loading) {
      return <div>Loading</div>
    }

    if (this.props.purchaseCreditQuery && this.props.purchaseCreditQuery.error) {
      return <div>Error</div>
    }

    if (!this.state.credit) {
      return <div>Loading</div>
    }

    const credit = this.state.credit

    const paperStyle = { height: '90%', width: '100%', textAlign: 'center', display: 'inline-block' }

    var flatButtonStyle = { height: '100%' }
    if (this.state.isEditingLocked) {
      flatButtonStyle.visibility = 'hidden'
    }

    return (
      <div>
        <h1>Purchase Credit Details</h1>

        <Paper style={paperStyle}>

          <div className='flex mt2 items-start' style={{ display: 'inline-block', textAlign: 'left' }}>
            <div>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.isEditingLocked}
                    onChange={(e, b) => { this._handleChangeLock(b) }}
                  />
                }
                label={"Editing " + (this.state.isEditingLocked ? "Locked" : "Unlocked")}
              />
            </div>

            <div className='ml1'>
              <p><b>Created At: </b>{moment(credit.createdAt).format('MM-DD-YYYY HH:MM')}</p>
              <p><b>User: </b>{credit.user.email}</p>
              <br/>
              
              <span>
                <div>
                  <FormControlLabel
                    control={<Checkbox checked={credit.isExpired}
                                       onChange={(e,b) => { this._handleChangeIsExpired(b) }}
                                       disabled={this.state.isEditingLocked} />}
                    label="Is Expired"
                  />
                </div>

                <div className="input-wrapper">
                  <TextField value={ credit.remainingAmount || 0 }
                             label="Remaining Amount"
                             disabled={this.state.isEditingLocked}
                             onChange={(e) => { this._handleChangeRemainingAmount(e.target.value) }}
                  />
                </div>

                <div className="input-wrapper">
                  <TextField value={ credit.initialAmount || 0 }
                             label="Initial Amount"
                             disabled={this.state.isEditingLocked}
                             onChange={(e) => { this._handleChangeInitialAmount(e.target.value) }}
                  />
                </div>

                <div className="input-wrapper">
                  <TextField value={ credit.notes }
                             label="Notes"
                             disabled={this.state.isEditingLocked}
                             onChange={(e) => { this._handleChangeNotes(e.target.value) }}
                  />
                </div>

                <div className="input-wrapper">
                  <FormControl>
                    <InputLabel style={{marginRight: '10px', width:"400px"}}>Currency</InputLabel>
                    <Select value={credit.currency}
                            label="Currency"
                            onChange={(e,k,p) => { this._handleChangeCurrency(k.props.value) }}
                            disabled={this.state.isEditingLocked}>

                      <MenuItem key="USD" value="USD"> USD </MenuItem>
                      <MenuItem key="GBP" value="GBP"> GBP </MenuItem>

                    </Select>
                  </FormControl>
                </div>

                <div className="input-wrapper">
                  <FormControl>
                    <InputLabel style={{marginRight: '10px', width:"400px"}}>Credit Type</InputLabel>
                    <Select value={credit.creditType}
                            label="Type"
                            onChange={(e,k,p) => { this._handleChangeType(k.props.value) }}
                            disabled={this.state.isEditingLocked}>

                      <MenuItem key="covidCredit" value="covidCredit"> COVID Credit </MenuItem>
                      <MenuItem key="credit" value="credit"> Credit </MenuItem>
                      <MenuItem key="other" value="other"> Other </MenuItem>

                    </Select>
                  </FormControl>
                </div>
              </span>

            </div>
          </div>
        </Paper>
      </div>
    )
  }
}

const PURCHASE_CREDIT_QUERY = gql`
  query PurchaseCreditQuery($id: ID!) {
    purchaseCredit(id: $id) {
      id
      createdAt
      currency
      isExpired
      initialAmount
      notes
      remainingAmount
      creditType

      user {
        id
        email
      }
    }
  }
`

const PURCHASE_CREDIT_UPDATE_MUTATION = gql`
  mutation PurchaseCreditUpdateMutation($id: ID!, $isExpired: Boolean!, $remainingAmount: Float, $initialAmount: Float,
                                        $currency: String, $notes: String, $creditType: String) {
    purchaseCreditUpdate(data: {
      id: $id,
      isExpired: $isExpired,
      remainingAmount: $remainingAmount,
      initialAmount: $initialAmount,
      notes: $notes,
      creditType: $creditType,
      currency: $currency
    }) {
      id
    }
  }
`

export default withApollo(graphql(PURCHASE_CREDIT_QUERY, {
  name: 'purchaseCreditQuery',
  options: (ownProps) => {
    const id = ownProps.match.params.id

    return {
      variables: { id },
      notifyOnNetworkStatusChange: true
    }
  }
})(CreditDetails))
