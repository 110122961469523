import React, { Component } from 'react'
import { gql } from 'apollo-boost'
import { graphql, withApollo } from 'react-apollo'
import moment from 'moment-timezone'

import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import PropTypes from 'prop-types'
import Select from '@material-ui/core/Select'
import Switch from '@material-ui/core/Switch'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

import BRClient from '../broadwayRouletteClient'
import { getTodayStrYYYYMMDD } from '../utils'


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={'span'} >{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function tabInfo(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  }
}

class AlgorithmConfiguration extends Component {

  state = {
    expandedBroadwayPanel: true,
    expandedWestEndPanel: true,
    location: "Broadway",
    isEditingLocked: true,
    openTabName: 0
  }

  _clearConfigCopy() {
    if (this.state.config) { this.setState({ config: null, configKeyToId: null }) }
  }

  _copyConfig(key) {
    return JSON.parse(JSON.stringify(this.state.config[key]))
  }

  _handleChangeEndDate(newDate) {
    this.setState({ endDate: newDate })
  }

  _handleChangeLock(isInputChecked) {
    this.setState({ isEditingLocked: isInputChecked })

    this._updateConfigFields()
  }

  _handleChangeShowPartnerPriority(id, isInputChecked) {
    var config = { ...this.state.config }
    config.prioritizedShowPartners[`${id}`] = isInputChecked

    this.setState(config)
  }

  _handleChangeStartDate(newDate) {
    this.setState({ startDate: newDate })
  }

  _handleChangeShowTicketQuota(id, ticketCount) {
    var config = { ...this.state.config }
    config.ticketQuotas[`${id}`] = ticketCount
  }

  _handleChangeWeight(newWeight, category) {
    var config = { ...this.state.config }
    config.matchingAlgorithm[`${category}`] = parseInt(newWeight, 10)
  }

  _handleExpandBroadwayPanel() {
    this.setState({ expandedBroadwayPanel: !this.state.expandedBroadwayPanel })
  }

  _handleExpandWestEndPanel() {
    this.setState({ expandedWestEndPanel: !this.state.expandedWestEndPanel })
  }

  _handleTabSwitch(value) {
    this.setState({ openTabName: value })
  }

  _loadLocations = async () => {
    try {
      var locationsQuery = await this.props.client.query({ query: LOCATIONS_LIST_QUERY })
      this.setState({ locationsQuery })

    } catch (err) {
      console.log('Unable to get locations data')
    }
  }

  _loadShows = async () => {
    try {
      var showsQuery = this.props.client.query({ query: SHOWS_LIST_QUERY })

      this.setState({ showsQuery })

      showsQuery.then(result => {
        this.setState({ shows: result.data.showsList.items })
      })
    } catch (err) {
      console.log(`Unable to get data for user: ${err.message}`)
    }
  }

  _navigate(url) {
    this.props.history.push(url)
  }

  _refetchData() {
    this.props.configQuery.refetch()
  }

  _runMatchingAlgorithmByDay() {
    var startDate = moment(this.state.startDate).format('YYYY-MM-DD')
    var endDate = moment(this.state.endDate).format('YYYY-MM-DD')
    var location = this.state.location

    BRClient.runMatchingAlgorithmByDay(startDate, endDate, location)
    .then(result => {
      if (result.status !== 200) {
        alert('Matching algorithm failed, try again.')
      }
    })
    .catch(err => {
      alert('Matching algorithm failed, try again.')
    })
  }

  _setConfigCopy() {
    var configurations = this.props.configQuery.configJsonsList.items
    var config = {}
    var configKeyToId = {}
    configurations.forEach((kvp) => {
      if (kvp.key === "matchingAlgorithm" || kvp.key === "prioritizedShowPartners" || kvp.key === "ticketQuotas") {
        config[kvp.key] = kvp.value
        configKeyToId[kvp.key] = kvp.id
      }
    })
    this.setState({ config, configKeyToId })
  }

  _updateConfigFields = async function() {
    if (!this.state.config) { return }

    var configKey
    if (this.state.openTabName === 0) {
      configKey = "matchingAlgorithm"
    } else if (this.state.openTabName === 1) {
      configKey = "prioritizedShowPartners"
    } else if (this.state.openTabName === 2) {
      configKey = "ticketQuotas"
    }
    var config = this.state.config[configKey]

    if (!config) { return }

    try {
      await this.props.client.mutate({
        mutation: CONFIG_JSON_UPDATE_MUTATION,
        variables: {
          id: this.state.configKeyToId[configKey],
          key: configKey,
          value: config
        }
      })
    } catch (err) {
      alert(`Error while updating config: ${err.message}`)
    }
    this._refetchData()
  }

  componentDidMount() {
    this._loadShows()
    this._loadLocations()
  }

  componentDidUpdate() {
    const query = this.props.configQuery

    if (query && query.loading) { this._clearConfigCopy() }
    else if (!this.state.config) { this._setConfigCopy() }
  }

  render() {
    if (this.props.configQuery && this.props.configQuery.loading)
      return <div>Loading</div>

    if (this.props.configQuery && this.props.configQuery.error)
      return <div>Error</div>

    return (
      <div>
        <h1>Algorithm Configuration</h1>
        <Paper className='config-panel'>
          <div>
            <FormControlLabel
              control={
                <Switch
                  checked={this.state.isEditingLocked}
                  onChange={(e, b) => { this._handleChangeLock(b) }}
                />
              }
              label={"Editing " + (this.state.isEditingLocked ? "Locked" : "Unlocked")}
            />
          </div>
          <div>
            <Tabs onChange={(e, value) => { this._handleTabSwitch(value) }} value={this.state.openTabName}>
              <Tab label="Configure Weights" {...tabInfo(0)} />
              <Tab label="Show Partners Priority Configuration" {...tabInfo(1)} />
              <Tab label="Ticket Quotas" {...tabInfo(2)} />
              <Tab label="Run Algorithm" {...tabInfo(3)} />
            </Tabs>

            <TabPanel value={this.state.openTabName} index={0}>
              <div className='ml1 configuration-content'>

              {this.state.config &&
                <div>
                  <h2>Configure Weights</h2>
                  <h4>All weights should be a whole number, and all of them should add up to 100</h4>

                  <div className="input-wrapper">
                    <TextField defaultValue={this.state.config.matchingAlgorithm.showRating || " "}
                               disabled={this.state.isEditingLocked}
                               onChange={(e, v) => { this._handleChangeWeight(e.target.value, "showRating") }}
                               label="Show Rating Weight "/>
                  </div>

                  <div className="input-wrapper">
                    <TextField defaultValue={this.state.config.matchingAlgorithm.partnerPriority || " "}
                               disabled={this.state.isEditingLocked}
                               onChange={(e, v) => { this._handleChangeWeight(e.target.value, "partnerPriority") }}
                               label="Partnership Priority Weight"/>
                  </div>

                  <div className="input-wrapper">
                    <TextField defaultValue={this.state.config.matchingAlgorithm.showRebook || " "}
                               disabled={this.state.isEditingLocked}
                               onChange={(e, v) => { this._handleChangeWeight(e.target.value, "showRebook") }}
                               label="Show Rebook Rate Weight"/>
                  </div>

                  <div className="input-wrapper">
                    <TextField defaultValue={this.state.config.matchingAlgorithm.COGS || " "}
                               disabled={this.state.isEditingLocked}
                               onChange={(e, v) => { this._handleChangeWeight(e.target.value, "COGS") }}
                               label="COGS Weight"/>
                  </div>
                </div>
              }

              {!this.state.config &&
                <div> LOADING </div>
              }
            </div>
            </TabPanel>

            <TabPanel value={this.state.openTabName} index={1}>
              {this.state.shows && this.state.config &&
               <div>
                 <h2>Show Partners Priority Configuration</h2>

                 <Accordion expanded={this.state.expandedBroadwayPanel} className="accordion">
                   <AccordionSummary expandIcon={<ExpandMoreIcon/>}
                                     aria-controls="panel1a-content"
                                     id="panel1a-header"
                                     onClick={() => this._handleExpandBroadwayPanel()}>
                     <h3>Broadway</h3>
                   </AccordionSummary>

                   <AccordionDetails>
                     <div className='ml1 configuration-content align-left'>
                       {this.state.shows.filter(show => show.location.name === 'Broadway').map(show => {
                         return (
                           <FormControlLabel key={show.name}
                                             style={{display: "block"}}
                                             control={
                                               <Switch disabled={this.state.isEditingLocked}
                                                       checked={this.state.config.prioritizedShowPartners[`${show.id}`]}
                                                       onChange={(e, v) => { this._handleChangeShowPartnerPriority(show.id, v)}} />
                                             }
                                             label={show.name} />
                         )
                       })}
                     </div>
                   </AccordionDetails>
                 </Accordion>

                 <Accordion expanded={this.state.expandedWestEndPanel} className="accordion">
                   <AccordionSummary expandIcon={<ExpandMoreIcon/>}
                                     aria-controls="panel1a-content"
                                     id="panel1a-header"
                                     onClick={() => this._handleExpandWestEndPanel()}>
                     <h3>West End</h3>
                   </AccordionSummary>

                   <AccordionDetails>
                     <div className='ml1 configuration-content align-left'>
                       {this.state.shows.filter(show => show.location.name === 'West End').map(show => {
                         return (
                           <FormControlLabel key={show.name}
                                             style={{display: "block"}}
                                             control={
                                               <Switch disabled={this.state.isEditingLocked}
                                                       checked={this.state.config.prioritizedShowPartners[`${show.id}`]}
                                                       onChange={(e, v) => { this._handleChangeShowPartnerPriority(show.id, v)}} />
                                             }
                                             label={show.name} />
                         )
                       })}
                     </div>
                   </AccordionDetails>
                 </Accordion>

               </div>
             }
             {!this.state.shows && <p>Loading</p>}
            </TabPanel>

            <TabPanel value={this.state.openTabName} index={2}>
              <div className='ml1 configuration-content'>

                {this.state.config && this.state.shows &&
                  <div>
                    <h2>Ticket Quotas</h2>
                    <p className="quotas-description">
                      Configure how many tickets the algorithm will assign per week to eligible orders. Random show selection
                      will not occur until all quotas have been met. A show can still be randomly selected if the quota
                      has been met.
                    </p>

                    <Accordion expanded={this.state.expandedBroadwayPanel} className="accordion">
                      <AccordionSummary expandIcon={<ExpandMoreIcon/>}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        onClick={() => this._handleExpandBroadwayPanel()}>
                        <h3>Broadway</h3>
                      </AccordionSummary>

                      <AccordionDetails>
                        <div className='ml1 configuration-content'>
                          {this.state.shows.filter(show => this.state.config.prioritizedShowPartners[`${show.id}`])
                                           .filter(show => show.location.name === 'Broadway')
                                           .map(show => {
                            return (
                              <div className="show-quota">
                                <p className="show-name">{show.shortName}</p>

                                <TextField className="show-tickets"
                                           defaultValue={this.state.config.ticketQuotas[`${show.id}`] || 0}
                                           disabled={this.state.isEditingLocked}
                                           onChange={(e, v) => { this._handleChangeShowTicketQuota(show.id, e.target.value) }}
                                           label="Ticket Quota "/>
                              </div>
                            )
                          })}
                        </div>
                     </AccordionDetails>
                   </Accordion>

                    <Accordion expanded={this.state.expandedWestEndPanel} className="accordion">
                      <AccordionSummary expandIcon={<ExpandMoreIcon/>}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        onClick={() => this._handleExpandWestEndPanel()}>
                        <h3>West End</h3>
                      </AccordionSummary>

                      <AccordionDetails>
                        <div className='ml1 configuration-content'>
                          {this.state.shows.filter(show => this.state.config.prioritizedShowPartners[`${show.id}`])
                                           .filter(show => show.location.name === 'West End')
                                           .map(show => {
                            return (
                              <div className="show-quota">
                                <p className="show-name">{show.shortName}</p>

                                <TextField className="show-tickets"
                                           defaultValue={this.state.config.ticketQuotas[`${show.id}`] || 0}
                                           disabled={this.state.isEditingLocked}
                                           onChange={(e, v) => { this._handleChangeShowTicketQuota(show.id, e.target.value) }}
                                           label="Ticket Quota "/>
                              </div>
                            )
                          })}
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                }

                {(!this.state.config || !this.state.shows) &&
                  <div> LOADING </div>
                }
              </div>
            </TabPanel>

            <TabPanel value={this.state.openTabName} index={3}>
              <div className='ml1 configuration-content'>
                <div>
                  <h2>Run Algorithm</h2>

                  <div className="padding">
                    <FormControl>
                      <InputLabel style={{marginRight: '10px', width:"400px"}}>Location</InputLabel>
                      <Select value={this.state.location}
                              label="Location"
                              onChange={(e,k,p) => { this.setState({ location: k.props.value}) }}>

                        {this.state.locationsQuery && this.state.locationsQuery.data.locationsList.items.map((location, idx) => (
                          <MenuItem key={idx} value={location.name}> {location.name} </MenuItem>
                        ))}

                      </Select>
                    </FormControl>
                  </div>

                  <div className="padding">
                     <TextField label="Start date"
                                type="date"
                                defaultValue={this.state.startDate}
                                onChange={(e) => { this._handleChangeStartDate(e.target.value)}}
                     />
                    <TextField label="End date"
                               type="date"
                               defaultValue={this.state.endDate}
                               onChange={(e) => { this._handleChangeEndDate(e.target.value)}}
                    />

                    <div className="padding">
                      <Button style={{marginRight: '10px',  backgroundColor:"#d50032"}}
                              onClick={() => { this._runMatchingAlgorithmByDay() }}>
                        RUN
                      </Button>
                    </div>

                  </div>
                </div>
              </div>
            </TabPanel>

          </div>
        </Paper>
      </div>
    )
  }
}

const CONFIG_JSONS_LIST_QUERY = gql`
  query ConfigJsonsListQuery {
    configJsonsList {
      items {
        id
        key
        value
      }
    }
  }
`

const CONFIG_JSON_UPDATE_MUTATION = gql`
  mutation ConfigJsonUpdateMutation($id: ID!, $key: String!, $value: JSON!) {
    configJsonUpdate(data: {
      id: $id,
      key: $key,
      value: $value
    }) {
      id
    }
  }
`

const LOCATIONS_LIST_QUERY = gql`
  query LocationsListQuery {
    locationsList(sort: { name: ASC }) {
      items {
        id
        name
      }
    }
  }
`

const SHOWS_LIST_QUERY = gql`
  query ShowsListQuery {
    showsList(filter: {
      isForOffBroadway: { equals: false },
      runDateEnd: { gte: "${getTodayStrYYYYMMDD()}" }
    }) {
      items {
        id
        shortName
        name
        runDateEnd

        location {
          id
          name
        }
      }
    }
  }
`

export default withApollo(graphql(CONFIG_JSONS_LIST_QUERY, {
  name: 'configQuery',
  options: () => {
    return {
      notifyOnNetworkStatusChange: true
    }
  }
})(AlgorithmConfiguration))