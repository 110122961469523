import React, { Component } from 'react'
import Auth0Lock from 'auth0-lock'
import { withRouter } from 'react-router-dom'

import { GRAPHQL_AUTH_TOKEN_LOCAL_STORAGE } from '../constants'
import { brRed } from '../colors'


class LoginAuth0 extends Component {
  constructor (props) {
    super(props)

    var options = {
      configurationBaseUrl: 'https://cdn.auth0.com',
      allowSignUp: false,
      auth: {
        responseType: 'token id_token',
        params: {
          scope: 'openid profile email'
        }
      },
      theme: {
        logo: 'https://admin.broadwayroulette.com/logo.png',
        primaryColor: brRed,
      }
    }

    this._lock = new Auth0Lock(props.clientId, props.domain, options)
  }

  _showLogin = () => {
    this._lock.show()
  }

  render() {
    return (
      <div>
        <span onClick={this._showLogin} className='dib pa3 white dim pointer'
              style={{ backgroundColor: '#d50032' }} >
          Log in with Auth0
        </span>
      </div>
    )
  }

  componentDidMount() {
    this._lock.on('authenticated', (authResult) => {
      console.log('authenticated, getting user info')
      this._lock.getUserInfo(authResult.accessToken, (error, profile) => {
        console.log('got user info')
        if (error) {
          console.log(error)
          return
        }
        
        window.localStorage.setItem(GRAPHQL_AUTH_TOKEN_LOCAL_STORAGE, authResult.idToken)
        this.props.history.push('/')

        if (window.location.pathname === '/') {
          window.location.reload()
        }

      })
    })
  }
}

export default withRouter(LoginAuth0)
