import React, { useState, Component } from 'react'
import { withRouter } from 'react-router'
import { useHistory } from 'react-router-dom'

import AssessmentIcon from '@mui/icons-material/Assessment'
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard'
import CelebrationIcon from '@mui/icons-material/Celebration'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import CodeIcon from '@mui/icons-material/Code'
import CreditScoreIcon from '@mui/icons-material/CreditScore'
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber'
import Divider from '@mui/material/Divider'
import Drawer from '@mui/material/Drawer'
import EventBusyIcon from '@mui/icons-material/EventBusy'
import FestivalIcon from '@mui/icons-material/Festival'
import HelpIcon from '@mui/icons-material/Help'
import IconButton from '@mui/material/IconButton'
import InboxIcon from '@mui/icons-material/MoveToInbox'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import LocalActivityIcon from '@mui/icons-material/LocalActivity'
import LoyaltyIcon from '@mui/icons-material/Loyalty'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import LocationCityIcon from '@mui/icons-material/LocationCity'
import MenuIcon from '@mui/icons-material/Menu'
import PanToolIcon from '@mui/icons-material/PanTool'
import PersonIcon from '@mui/icons-material/Person'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import PointOfSaleIcon from '@mui/icons-material/PointOfSale'
import RestaurantIcon from '@mui/icons-material/Restaurant'
import SettingsIcon from '@mui/icons-material/Settings'
import Stack from '@mui/material/Stack'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import StorefrontIcon from '@mui/icons-material/Storefront'
import TheaterComedyIcon from '@mui/icons-material/TheaterComedy'

import { GRAPHQL_AUTH_TOKEN_LOCAL_STORAGE } from '../constants'

const FAQ_LINK = 'https://docs.google.com/document/d/16mY0-KEhcJSJE-jW6IAuXhVbpEPf4Jzcm1MR_AvDYXc/edit?usp=sharing'

function HeaderListItem(props) {
  const { text, icon, handleDrawerClose } = props
  const history = useHistory()

  const handleClick = (text) => {
    handleDrawerClose()
    history.push(`/${text}`)
  }

  return (
    <ListItem button key={text} onClick={() => { handleClick(text) }}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={text.replace(/-/g, ' ')} />
    </ListItem>
  )
}

function Header(props) {
  const { user, children } = props

  const [open, setOpen] = useState(false)

  const logout = () => {
    window.localStorage.removeItem(GRAPHQL_AUTH_TOKEN_LOCAL_STORAGE)
    window.location.reload()
  }

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  var shouldShowAdminTabs = user.email !== 'guestservices@todaytix.com'

  return (
    <div className='flex pa1'>
      <Stack justifyContent="space-around" direction="column" flexWrap="wrap">
        <Drawer className='drawer'
                variant="persistent"
                anchor="left"
                open={open}>

          <div className='drawer-header'>
            <IconButton onClick={handleDrawerClose}>
              <ChevronRightIcon color="white" />
            </IconButton>
          </div>

          <List>
            <HeaderListItem text={'Ticket-Assignments'} icon={<ConfirmationNumberIcon color="white" />} handleDrawerClose={handleDrawerClose} />
            <HeaderListItem text={'Orders'} icon={<PointOfSaleIcon color="white" />} handleDrawerClose={handleDrawerClose} />

            {shouldShowAdminTabs &&
              <div>
                <HeaderListItem text={'Event-Assignments'} icon={<LocalActivityIcon color="white" />} handleDrawerClose={handleDrawerClose} />
                <HeaderListItem text={'Event-Orders'} icon={<CelebrationIcon color="white" />} handleDrawerClose={handleDrawerClose} />
                <HeaderListItem text={'Subscriptions'} icon={<StarBorderIcon color="white" />} handleDrawerClose={handleDrawerClose} />
                <HeaderListItem text={'Restaurant-Reservations'} icon={<RestaurantIcon color="white" />} handleDrawerClose={handleDrawerClose} />
              </div>
            }

            <Divider />

            <HeaderListItem text={'Users'} icon={<PersonIcon color="white" />} handleDrawerClose={handleDrawerClose} />

            {shouldShowAdminTabs &&
              <div>
                <HeaderListItem text={'Pickups'} icon={<PanToolIcon color="white" />} handleDrawerClose={handleDrawerClose} />
                <HeaderListItem text={'Shows'} icon={<TheaterComedyIcon color="white" />} handleDrawerClose={handleDrawerClose} />
                <HeaderListItem text={'Events'} icon={<FestivalIcon color="white" />} handleDrawerClose={handleDrawerClose} />

                <Divider />

                <HeaderListItem text={'Restaurants'} icon={<StorefrontIcon color="white" />} handleDrawerClose={handleDrawerClose} />
                <HeaderListItem text={'Theaters'} icon={<LocationCityIcon color="white" />} handleDrawerClose={handleDrawerClose} />
                <HeaderListItem text={'Venues'} icon={<LocationOnIcon color="white" />} handleDrawerClose={handleDrawerClose} />
              </div>
            }

            <Divider />


            <HeaderListItem text={'Gift-Cards'} icon={<CardGiftcardIcon color="white" />} handleDrawerClose={handleDrawerClose} />
            <HeaderListItem text={'Purchase-Codes'} icon={<LoyaltyIcon color="white" />} handleDrawerClose={handleDrawerClose} />
            <HeaderListItem text={'Referrals'} icon={<PeopleAltIcon color="white" />} handleDrawerClose={handleDrawerClose} />
            <HeaderListItem text={'Credits'} icon={<CreditScoreIcon color="white" />} handleDrawerClose={handleDrawerClose} />

            <Divider />

            {shouldShowAdminTabs &&
              <div>
                <HeaderListItem text={'Algorithm'} icon={<CodeIcon color="white" />} handleDrawerClose={handleDrawerClose} />
                <HeaderListItem text={'Config'} icon={<PanToolIcon color="white" />} handleDrawerClose={handleDrawerClose} />
                <HeaderListItem text={'Disabled-Dates'} icon={<EventBusyIcon color="white" />} handleDrawerClose={handleDrawerClose} />
                <HeaderListItem text={'Reports'} icon={<AssessmentIcon color="white" />} handleDrawerClose={handleDrawerClose} />
              </div>
            }

            <Divider />

            <a target="_blank" rel="noopener noreferrer" href={FAQ_LINK}>
              <ListItem button key={'FAQ'}>
                <ListItemIcon><HelpIcon color="white" /></ListItemIcon>
                <ListItemText primary={'FAQ'} />
              </ListItem>
            </a>

            <ListItem button key={'Logout'} onClick={logout}>
              <ListItemIcon><InboxIcon color="white" /></ListItemIcon>
              <ListItemText primary={'Logout'} />
            </ListItem>
          </List>

        </Drawer>

        <div className="header-nav">
          <IconButton aria-label="open drawer"
                      onClick={handleDrawerOpen}
                      edge="start"
                      className="hamburger">
            <MenuIcon />
            <div className='title'>Broadway Roulette – Admin Portal</div>
          </IconButton>
        </div>
      </Stack>

      { children }

    </div>
  )
}

export default withRouter(Header)
