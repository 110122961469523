import React, { Component } from 'react'
import { gql } from 'apollo-boost'
import { graphql, withApollo } from 'react-apollo'
import moment from 'moment-timezone'
import { withRouter } from 'react-router'
import _ from 'underscore'

import Button from '@material-ui/core/Button'
import Fab from '@material-ui/core/Fab'
import FontAwesome from 'react-fontawesome'
import Icon from '@material-ui/core/Icon'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'

import { ENTRIES_PER_PAGE } from '../constants'
import { getBeginningOfTimeStrYYYYMMDD, getParamsFromUrl, getTodayStrYYYYMMDD } from '../utils'


class PurchaseCodeSummary extends Component {

  render() {
    const code = this.props.purchaseCode

    const objId = (<a href={'/purchase-code/' + code.id}>
                     Code …{code.id.substr(code.id.length-4)}
                   </a>)

    const dateFmt = 'MM-DD-YYYY'

    var dateRange = ''
    if (code.discountStartDate) {
      dateRange = `${moment(code.discountStartDate).format(dateFmt)} . . ${moment(code.discountEndDate).format(dateFmt)}`
    }

    return (
      <TableRow>
        <TableCell>{objId}</TableCell>
        <TableCell>{code.code}</TableCell>
        <TableCell style={{ width: '200px' }}>{dateRange}</TableCell>
        <TableCell>{code.discountType}</TableCell>
        <TableCell>{code.isOneTimeUsePerUser && <FontAwesome name="check"/>}</TableCell>
        {(code.discountType !== 'Percentage') &&
          <TableCell>{code.currency === 'GBP' ? '£' : '$' }{code.discountAmountInUsd}</TableCell>
        }
        {(code.discountType === 'Percentage') &&
          <TableCell>{code.percentageOff}%</TableCell>
        }
        <TableCell>{code.currency}</TableCell>
        <TableCell>{code.isExpired && <FontAwesome name="check"/>}</TableCell>
      </TableRow>
    )
  }
}

class PurchaseCodeList extends Component {

  state = {
    searchedItems: null,
    searchText: ''
  }

  _executeSearch = async () => {
    var searchText = this.state.searchText

    const result = await this.props.client.query({
      query: PURCHASE_CODES_LIST_QUERY,
      variables: { searchText }
    })

    console.log(result)

    const purchaseCodes = result.data.purchaseCodesList.items
    this.setState({ searchedItems: purchaseCodes })
  }

  _getItemsToRender = () => {
    return this.props.allPurchaseCodesQuery.purchaseCodesList.items
  }

  async _handleClickNew() {
    try {
      const result = await this.props.client.mutate({
        mutation: PURCHASE_CODE_CREATE_MUTATION,
      })

      this.props.history.push(`/purchase-code/${result.data.purchaseCodeCreate.id}`)
    } catch(err) {
      alert(`Unable to create purchase code: ${err.message}`)
    }
  }

  _lazyUpdatePurchaseCodes = _.debounce(this._updatePurchaseCodes, 500)

  _nextPage = () => {
    const page = parseInt(this.props.match.params.page, 10)
    const nextPage = page + 1

    const wantsOrdersForToday = this.props.location.pathname.includes('today')
    const todayPart = wantsOrdersForToday ? '-today' : ''

    this.props.history.push(`/purchase-codes${todayPart}/${nextPage}`)
  }

  _previousPage = () => {
    const page = parseInt(this.props.match.params.page, 10)
    if (page > 1) {
      const nextPage = page - 1

      const wantsOrdersForToday = this.props.location.pathname.includes('today')
      const todayPart = wantsOrdersForToday ? '-today' : ''

      this.props.history.push(`/purchase-codes${todayPart}/${nextPage}`)
    }
  }

  _updatePurchaseCodes() {
    var isSearching = !!this.state.searchText

    if (isSearching) {
      this._executeSearch()
    } else {
      this.setState({ searchedItems: null })
    }

    this.setState({ isSearchDirty: false })
  }

  _updateSearchText = (text) => {
    this.setState({ searchText: text, isSearchDirty: true })
  }

  componentDidUpdate() {
    if (this.state.isSearchDirty) {
      this._lazyUpdatePurchaseCodes()
    }
  }

  render() {
    if (this.props.allPurchaseCodesQuery && this.props.allPurchaseCodesQuery.loading) {
      return <div>Loading</div>
    }

    if (this.props.allPurchaseCodesQuery && this.props.allPurchaseCodesQuery.error) {
      return <div>Error</div>
    }

    const itemsToRender = this.state.searchedItems || this._getItemsToRender()

    var islastPage = this.props.allPurchaseCodesQuery.purchaseCodesList.count - this.props.allPurchaseCodesQuery.variables.skip < ENTRIES_PER_PAGE
    var itemOffset = 0
    if (!this.state.searchedItems) {
      const page = parseInt(this.props.match.params.page, 10)
      itemOffset = (page - 1) * ENTRIES_PER_PAGE
    }

    return (
      <div class="list-page">
        <div>
          <h1 style={{ display: 'inline-block' }}>Purchase Codes</h1>
          <Fab style={{ marginLeft: '10px' }}
               onClick={() => { this._handleClickNew() }}>
            <Icon className="fa fa-plus-circle" />
          </Fab>
        </div>

        <Paper>
          <TextField value= {this.state.searchText || ""}
                     label="Search for code"
                     onChange={(e) => { this._updateSearchText(e.target.value) }}
                     style={{ width: '50%', margin: '10px', paddingTop:"10px" }}/>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Code</TableCell>
                <TableCell style={{ width: '200px' }}>Date Range</TableCell>
                <TableCell>Discount Type</TableCell>
                <TableCell>One User Per User</TableCell>
                <TableCell>Discount Amount</TableCell>
                <TableCell>Currency</TableCell>
                <TableCell>Expired?</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {itemsToRender.map((purchaseCode, index) => (
                <PurchaseCodeSummary key={purchaseCode.id}
                                     purchaseCode={purchaseCode}
                                     index={itemOffset + index}/>
              ))}
            </TableBody>
          </Table>
        </Paper>
        {!this.state.searchText &&
          (<div>
            <Button variant="contained"
                    disabled={this.props.match.params.page <= 1}
                    style={{ margin: '10px 10px 15px 0'}}
                    onClick={() => { this._previousPage() }}>
            Previous
            </Button>
            <Button variant="contained"
                    disabled={islastPage}
                    style={{ margin: '10px 10px 15px 0'}}
                    onClick={() => { this._nextPage() }}>
            Next
            </Button>
          </div>)
        }
      </div>
    )
  }
}

const PURCHASE_CODES_LIST_QUERY = gql`
  query PurchaseCodesListQuery($applicableNewEmail: String, $first: Int, $skip: Int, $searchText: String) {
    purchaseCodesList(
      first: $first,
      skip: $skip,
      sort: { createdAt : DESC },
      filter: {
        applicableNewEmail: { equals: $applicableNewEmail }
        code: { contains: $searchText },
        purchaseCodeType: {equals: "Discount"} },

    ) {
      count

      items {
        id
        code
        currency
        isExpired
        isOneTimeUsePerUser
        discountStartDate
        discountEndDate
        discountAmountInUsd
        discountType
        purchaseCodeType
        initialLifetimeAmountInUsd
        remainingLifetimeAmountInUsd
        percentageOff
        numberOfUses
      }
    }
  }
`

const PURCHASE_CODE_CREATE_MUTATION = gql`
  mutation PurchaseCodeCreateMutation {
    purchaseCodeCreate(data: {
      code: "Fill me out"
      currency: "USD"
      blackoutDates: []
      isExpired: false
      isOneTimeUse: false
      discountType: "PerTicket"
      purchaseCodeType: "Discount"
    }) {
      id
    }
  }
`


export default withRouter(withApollo(graphql(PURCHASE_CODES_LIST_QUERY, {
  name: 'allPurchaseCodesQuery',
  options: (ownProps) => {
    const wantsOrdersForToday = ownProps.location.pathname.includes('today')
    var date = wantsOrdersForToday ? getTodayStrYYYYMMDD() : getBeginningOfTimeStrYYYYMMDD()
    return {
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
      variables: { ...getParamsFromUrl(ownProps), date }
    }
  }
})(PurchaseCodeList)))