import React, { Component } from 'react'
import { gql } from 'apollo-boost'
import { graphql, withApollo } from 'react-apollo'

import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import PropTypes from 'prop-types'
import Select from '@material-ui/core/Select'
import Switch from '@material-ui/core/Switch'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

import './Configuration.css'

const HOURS = [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]


function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={'span'} >{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function tabInfo(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  }
}

function notificationTabInfo(index) {
  return {
    id: `notification-tab-${index}`,
    'aria-controls': `notification-tabpanel-${index}`,
  };
}

class NotificationsPanel extends Component {

  state = {
    openNotificationTabName: 0,
    config: []
  }

  _handleChangeNotifications(key, val) {
    var config = this.props.notificationSchedule
    config[key] = val

    this.props.updateConfig(this.props.notificationScheduleKey, config)
  }

  _handleNotificationTabSwitch(value) {
    this.setState({ openNotificationTabName: value })
  }

  render() {

    var notificationSchedule = this.props.notificationSchedule

    return (
      <div>
        <Tabs onChange={(e, value) => { this._handleNotificationTabSwitch(value) }}
              value={this.state.openNotificationTabName}>
          <Tab style={{flex: 1}} label="Spinning Tomorrow" {...tabInfo(0)} />
          <Tab style={{flex: 1}} label="Spinning The Wheel - Evening" {...tabInfo(1)} />
          <Tab style={{flex: 1}} label="Spinning The Wheel - Matinee" {...tabInfo(2)} />
          <Tab style={{flex: 1}} label="Evening Tickets" {...tabInfo(3)} />
          <Tab style={{flex: 1}} label="Matinee Tickets" {...tabInfo(4)} />
          <Tab style={{flex: 1}} label="First Spin" {...tabInfo(5)} />
          <Tab style={{flex: 1}} label="Reminder" {...tabInfo(6)} />
          <Tab style={{flex: 1}} label="Follow Up" {...tabInfo(7)} />
          <Tab style={{flex: 1}} label="Gift Card" {...tabInfo(8)} />
        </Tabs>
        <TabPanel value={this.state.openNotificationTabName} index={0}>
          <h2>Spinning Tomorrow</h2>
          <div className="configuration-content">
            <NotificationConfiguration onChange={obj => {
                                                  this._handleChangeNotifications('spinningTomorrow', obj)
                                                }}
                                       disabled={this.props.isEditingLocked}
                                       config={notificationSchedule.spinningTomorrow}/>
          </div>
        </TabPanel>

        <TabPanel value={this.state.openNotificationTabName} index={1}>
          <h2>Spinning The Wheel - Evening</h2>
          <div className="configuration-content">
            <NotificationConfiguration onChange={obj => {
                                                  this._handleChangeNotifications('spinning', obj)
                                                }}
                                       disabled={this.props.isEditingLocked}
                                       config={notificationSchedule.spinning}/>
          </div>
        </TabPanel>

        <TabPanel value={this.state.openNotificationTabName} index={2}>
          <h2>Spinning The Wheel - Matinee</h2>
          <div className="configuration-content">
            <NotificationConfiguration onChange={obj => {
                                                  this._handleChangeNotifications('spinningMatinee', obj)
                                                }}
                                       disabled={this.props.isEditingLocked}
                                       config={notificationSchedule.spinningMatinee}/>
          </div>
        </TabPanel>

        <TabPanel value={this.state.openNotificationTabName} index={3}>
          <h2>Evening Tickets</h2>
          <div className="configuration-content">
            <NotificationConfiguration onChange={obj => {
                                                  this._handleChangeNotifications('eveningTickets', obj)
                                                }}
                                       disabled={this.props.isEditingLocked}
                                       config={notificationSchedule.eveningTickets}/>

          </div>
        </TabPanel>

        <TabPanel value={this.state.openNotificationTabName} index={4}>
          <h2>Matinee Tickets</h2>
          <div className="configuration-content">
            <NotificationConfiguration onChange={obj => {
                                                  this._handleChangeNotifications('matineeTickets', obj)
                                                }}
                                       disabled={this.props.isEditingLocked}
                                       config={notificationSchedule.matineeTickets}/>

          </div>
        </TabPanel>

        <TabPanel value={this.state.openNotificationTabName} index={5}>
          <h2>3 Days Before First Spin</h2>
          <div className="configuration-content">
            <NotificationConfiguration onChange={obj => {
                                                  this._handleChangeNotifications('firstSpin', obj)
                                                }}
                                       disabled={this.props.isEditingLocked}
                                       config={notificationSchedule.firstSpin}/>
          </div>
        </TabPanel>

        <TabPanel value={this.state.openNotificationTabName} index={6}>
          <h2>Ticket Reminder</h2>
          <div className="configuration-content">
            <NotificationConfiguration onChange={obj => {
                                                  this._handleChangeNotifications('reminder', obj)
                                                }}
                                       disabled={this.props.isEditingLocked}
                                       config={notificationSchedule.reminder}/>
          </div>
        </TabPanel>

        <TabPanel value={this.state.openNotificationTabName} index={7}>
          <h2>Follow Up</h2>
          <div className="configuration-content">
            <NotificationConfiguration onChange={obj => {
                                                  this._handleChangeNotifications('followUp', obj)
                                                }}
                                       disabled={this.props.isEditingLocked}
                                       config={notificationSchedule.followUp}/>
          </div>
        </TabPanel>

        <TabPanel value={this.state.openNotificationTabName} index={8}>
          <h2>Delayed Gift Card</h2>
          <div className="configuration-content">
            <NotificationConfiguration onChange={obj => {
                                                  this._handleChangeNotifications('giftcard', obj)
                                                }}
                                       disabled={this.props.isEditingLocked}
                                       config={notificationSchedule.giftcard}/>
          </div>
        </TabPanel>
      </div>
    )

  }
}

class NotificationConfiguration extends Component {

  _handleChangeTime(time, value) {
    var rootConfig = this.props.config || {}

    var config = { ...rootConfig }
    config[time] = value

    this.props.onChange(config)
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.disabled === this.props.disabled &&
        nextProps.config === this.props.config) {
      return false
    } else {
      return true
    }
  }

  render() {
    var config = this.props.config || {}

    var { defaultTime, monday, tuesday, wednesday, thursday, friday, saturday, sunday } = config

    var XMs = ['AM', 'PM']
    var minutes = ['00', '30']

    var times = ['']
    XMs.forEach(xm => {
      HOURS.forEach(hour => {
        minutes.forEach(minutes => {
          var time = `${hour}:${minutes} ${xm}`
          times.push(time)
        })
      })
    })

    var options = times.map((time, idx) => {
      return <MenuItem key={idx} value={time}>{time}</MenuItem>
    })

    return (
      <div className="notification-times">
        <div className="input-wrapper notification-input">
          <FormControl>
            <InputLabel>Default Notification Time</InputLabel>
            <Select value={defaultTime || ""}
                    disabled={this.props.disabled}
                    onChange={(e,k,p) => { this._handleChangeTime('defaultTime', e.target.value) }}>
              {options}
            </Select>
          </FormControl>
        </div>
        <div className="input-wrapper notification-input">
          <FormControl>
            <InputLabel>Monday Override</InputLabel>
            <Select value={monday || ""}
                    disabled={this.props.disabled}
                    onChange={(e,k,p) => { this._handleChangeTime('monday', e.target.value) }}>
              {options}
            </Select>
          </FormControl>
        </div>
        <div className="input-wrapper notification-input">
          <FormControl>
          <InputLabel>Tuesday Override</InputLabel>
            <Select value={tuesday || ""}
                    disabled={this.props.disabled}
                    onChange={(e,k,p) => { this._handleChangeTime('tuesday', e.target.value) }}>
              {options}
            </Select>
          </FormControl>
        </div>
        <div className="input-wrapper notification-input">
          <FormControl>
            <InputLabel>Wednesday Override</InputLabel>
            <Select value={wednesday || ""}
                    disabled={this.props.disabled}
                    onChange={(e,k,p) => { this._handleChangeTime('wednesday', e.target.value) }}>
              {options}
            </Select>
          </FormControl>
        </div>
        <div className="input-wrapper notification-input">
          <FormControl>
            <InputLabel>Thursday Override</InputLabel>
            <Select value={thursday || ""}
                    disabled={this.props.disabled}
                    onChange={(e,k,p) => { this._handleChangeTime('thursday', e.target.value) }}>
              {options}
            </Select>
          </FormControl>
        </div>
        <div className="input-wrapper notification-input">
          <FormControl>
            <InputLabel>Friday Override</InputLabel>
            <Select value={friday || ""}
                    disabled={this.props.disabled}
                    onChange={(e,k,p) => { this._handleChangeTime('friday', e.target.value) }}>
              {options}
            </Select>
          </FormControl>
        </div>
        <div className="input-wrapper notification-input">
          <FormControl>
            <InputLabel>Saturday Override</InputLabel>
            <Select value={saturday || ""}
                    disabled={this.props.disabled}
                    onChange={(e,k,p) => { this._handleChangeTime('saturday', e.target.value) }}>
              {options}
            </Select>
          </FormControl>
        </div>
        <div className="input-wrapper notification-input">
          <FormControl>
            <InputLabel>Sunday Override</InputLabel>
            <Select value={sunday || ""}
                    disabled={this.props.disabled}
                    onChange={(e,k,p) => { this._handleChangeTime('sunday', e.target.value) }}>
              {options}
            </Select>
          </FormControl>
        </div>
      </div>
    )
  }
}

class Configuration extends Component {

  state = {
    isEditingLocked: true,
    location: "Broadway",
    openTabName: 0
  }

  _copyConfig(key) {
    return JSON.parse(JSON.stringify(this.state.config[key]))
  }

  _handleChangeConfigTime(time, val) {
    var config = this._copyConfig('configTimesLondon')
    config[time] = val

    this._updateConfig('configTimesLondon', config)
  }

  _handleChangeDRIDirections(val) {
    var config = this._copyConfig('dri')
    config.directions = val

    this._updateConfig('dri', config)
  }

  _handleChangeLock(isInputChecked) {
    this.setState({ isEditingLocked: isInputChecked })

    if (isInputChecked) {
      this._updateConfigFields()
    }
  }

  _handleChangeRateLimitPeriod(val) {
    var config = this._copyConfig('frequencyCap')
    config.rateLimitPeriod = Number.parseInt(val, 10)

    this._updateConfig('frequencyCap', config)
  }

  _handleChangeShowRateLimit(val) {
    var config = this._copyConfig('frequencyCap')
    config.showRateLimit = Number.parseInt(val, 10)

    this._updateConfig('frequencyCap', config)
  }

  _handleTabSwitch(value) {
    this.setState({ openTabName: value })
  }

  _updateConfig(key, newSubConfig) {
    if (!this.state.config) { return }

    var config = { ...this.state.config }
    config[key] = newSubConfig

    this.setState({ config })
  }

  _updateConfigFields = async function() {
    if (!this.state.config) { return }

    var configKey
    switch (this.state.openTabName) {
      case 0:
        configKey = "frequencyCap"
        break;
      case 1:
        configKey = "notificationScheduleNY"
        break;
      case 2:
        configKey = "notificationScheduleLondon"
        break;
      case 3:
        configKey = "configTimesLondon"
        break;
      default:
        configKey = ""
        break;
    }

    var config = this.state.config[configKey]

    if (!config) { return }

    try {
      await this.props.client.mutate({
        mutation: CONFIG_JSON_UPDATE_MUTATION,
        variables: {
          id: this.state.configKeyToId[configKey],
          key: configKey,
          value: config
        }
      })
    } catch (err) {
      alert(`Error while updating config: ${err.message}`)
    }

    this._refetchData()
  }

  _refetchData() {
    this.props.configQuery.refetch()
  }

  _navigate(url) {
    this.props.history.push(url)
  }

  _clearConfigCopy() {
    if (this.state.config) { this.setState({ config: null, configKeyToId: null }) }
  }

  _setConfigCopy() {
    var configurations = this.props.configQuery.configJsonsList.items

    var config = {}
    var configKeyToId = {}
    configurations.forEach((kvp) => {
      config[kvp.key] = kvp.value
      configKeyToId[kvp.key] = kvp.id
    })

    this.setState({ config, configKeyToId })
  }

  _updateConfigState(newConfig) {
    this.setState({ config: newConfig })
  }

  componentDidUpdate() {
    const query = this.props.configQuery

    if (query && query.loading) { this._clearConfigCopy() }
    else if (!this.state.config) { this._setConfigCopy() }
  }

  render() {
    if (this.props.configQuery && this.props.configQuery.loading) {
      return <div>Loading</div>
    }

    if (this.props.configQuery && this.props.configQuery.error) {
      return <div>Error</div>
    }

    if (!this.state.config) {
      return <div>Loading</div>
    }

    var dri = this.state.config && this.state.config.dri ? this.state.config.dri : null
    var frequencyCap = this.state.config && this.state.config.frequencyCap ? this.state.config.frequencyCap : null
    var configTimesLondon = this.state.config && this.state.config.configTimesLondon ? this.state.config.configTimesLondon : null

    return (
      <div>
        <h1>Configuration</h1>

        <Paper className='config-panel'>

          <div>
            <FormControlLabel
              control={
                <Switch
                  checked={this.state.isEditingLocked}
                  onChange={(e, b) => { this._handleChangeLock(b) }}
                />
              }
              label={"Editing " + (this.state.isEditingLocked ? "Locked" : "Unlocked")}
            />
          </div>

          <Tabs onChange={(e, value) => { this._handleTabSwitch(value) }} value={this.state.openTabName}>
            <Tab label="Frequency Cap" {...notificationTabInfo(0)} />
            <Tab label="NY Notifications" {...notificationTabInfo(1)} />
            <Tab label="London Notifications" {...notificationTabInfo(2)} />
            <Tab label="London Order Cutoff Times" {...notificationTabInfo(3)} />
          </Tabs>

          <TabPanel value={this.state.openTabName} index={0}>
            {(frequencyCap && dri) &&
              <div className='ml1 configuration-content'>
                <div>
                  <h2 style={{}}>Frequency Cap</h2>

                  <div className="input-wrapper">
                    <TextField defaultValue={frequencyCap.showRateLimit || ''}
                               disabled={this.state.isEditingLocked}
                               onChange={(e, v) => { this._handleChangeShowRateLimit(e.target.value) }}
                               label="Show Rate Limit (in shows)"/>
                  </div>

                  <div className="input-wrapper">
                    <TextField defaultValue={frequencyCap.rateLimitPeriod || ''}
                               disabled={this.state.isEditingLocked}
                               onChange={(e) => { this._handleChangeRateLimitPeriod(e.target.value) }}
                               label="Rate Limit Period (in days)"/>
                  </div>

                  <div>
                    <h2>Designated Representative</h2>
                    <div className="input-wrapper">
                      <TextField defaultValue={dri.directions || ''}
                                 disabled={this.state.isEditingLocked}
                                 label="Directions for Getting in Touch"
                                 multiline={true}
                                 onChange={(e) => { this._handleChangeDRIDirections(e.target.value) }}
                                 rows={3}
                                 style={{paddingTop:"10px"}} />
                    </div>
                  </div>
                </div>
              </div>
            }
            {(!frequencyCap || !dri) && <p>Loading</p>}
          </TabPanel>

          <TabPanel value={this.state.openTabName} index={1}>
            <div>
              <h2>NY Notifications</h2>
            </div>

            <p>You can edit the notification schedules here. All times are in New York time, EST (GMT-4).</p>

            {this.state.config.notificationScheduleNY &&
              <NotificationsPanel notificationSchedule={ this.state.config.notificationScheduleNY }
                                  notificationScheduleKey="notificationScheduleLondon"
                                  isEditingLocked={ this.state.isEditingLocked }
                                  updateConfig={ this._updateConfig.bind(this) } />
            }

            {!this.state.config.notificationScheduleNY && <p>Loading</p>}
          </TabPanel>

          <TabPanel value={this.state.openTabName} index={2}>
            <div>
              <h2>London Notifications</h2>
            </div>

            <p>You can edit the notification schedules here. All times are in London time, BST (GMT+1).</p>

            {this.state.config.notificationScheduleLondon &&
              <NotificationsPanel notificationSchedule={ this.state.config.notificationScheduleLondon }
                                  notificationScheduleKey="notificationScheduleLondon"
                                  isEditingLocked={ this.state.isEditingLocked }
                                  updateConfig={ this._updateConfig.bind(this) } />
            }

            {!this.state.config.notificationScheduleLondon && <p>Loading</p>}
          </TabPanel>

          <TabPanel value={this.state.openTabName} index={3}>
            {configTimesLondon &&
                <div className='ml1 configuration-content'>
                  <div>
                    <h2 style={{}}>Order Cutoff Times London</h2>

                    <div className="input-wrapper">
                      <TextField defaultValue={configTimesLondon.matinee || ''}
                                 disabled={this.state.isEditingLocked}
                                 onChange={(e, v) => { this._handleChangeConfigTime('matinee', e.target.value) }}
                                 label="Matinee cutoff"/>
                    </div>

                    <div className="input-wrapper">
                      <TextField defaultValue={configTimesLondon.evening || ''}
                                 disabled={this.state.isEditingLocked}
                                 onChange={(e, v) => { this._handleChangeConfigTime('evening', e.target.value) }}
                                 label="Evening cutoff"/>
                    </div>
                  </div>
                </div>
            }
            {!configTimesLondon && <p>Loading</p>}
          </TabPanel>

        </Paper>
      </div>
    )
  }
}

const CONFIG_JSONS_LIST_QUERY = gql`
  query ConfigJsonsListQuery {
    configJsonsList {
      items {
        id
        key
        value
      }
    }
  }
`

const CONFIG_JSON_UPDATE_MUTATION = gql`
  mutation ConfigJsonUpdateMutation($id: ID!, $key: String!, $value: JSON!) {
    configJsonUpdate(data: {
      id: $id,
      key: $key,
      value: $value
    }) {
      id
    }
  }
`

export default withApollo(graphql(CONFIG_JSONS_LIST_QUERY, {
  name: 'configQuery',
  options: () => {
    return {
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    }
  }
})(Configuration))
