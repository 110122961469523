import React, { useState, useEffect } from 'react'
import { gql } from 'apollo-boost'
import { graphql, withApollo } from 'react-apollo'
import moment from 'moment-timezone'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import { withRouter } from 'react-router'

import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { alpha } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import CheckIcon from '@mui/icons-material/Check'
import CircularProgress from '@mui/material/CircularProgress'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'
import FilterListIcon from '@mui/icons-material/FilterList'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import IconButton from '@mui/material/IconButton'
import InputLabel from '@mui/material/InputLabel'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import MenuItem from '@mui/material/MenuItem'
import MobileDatePicker from '@mui/lab/MobileDatePicker'
import Paper from '@mui/material/Paper'
import Popover from '@mui/material/Popover'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import TextField from '@mui/material/TextField'
import Toolbar from '@mui/material/Toolbar'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { visuallyHidden } from '@mui/utils'

import {
  getBeginningOfTimeStrYYYYMMDD,
  getTenYearsFromNowYYYYMMDD,
  getParamsFromUrl,
  getTodayStrYYYYMMDD
} from '../utils'

import { brRed } from '../colors'


const FILTER_ADMINS = `
  {AND: [{
    user: {
      email: { not_equals: "tracy@broadwayroulette.com" }
    }
  }, {
    user: {
      email: { not_contains: "tracythuynh" }
    }
  }, {
    user: {
      email: { not_equals: "orders@broadwayroulette.com" }
    }
  }, {
    user: {
      email: { not_equals: "devteam@broadwayroulette.com" }
    }
  }]}
`

const headCells = [
  {
    id: 'id',
    label: 'Id',
  },
  {
    id: 'customer',
    label: 'Customer',
  },
  {
    id: 'orderPlacedAt',
    label: 'Date Placed',
  },
  {
    id: 'numberOfTickets',
    label: 'Tickets',
  },
  {
    id: 'location',
    label: 'Location',
  },
  {
    id: 'requestedShowDate',
    label: 'Show Date',
  },
  {
    id: 'requestedShowTime',
    label: 'Show Time',
  },
  {
    id: 'showPreference',
    label: 'Preference',
  },
  {
    id: 'spinType',
    label: 'Spin Type',
  },
  {
    id: 'restaurant',
    label: 'Restaurant?',
  },
]

const getDateRange = (ownProps) => {
  const queryParams = queryString.parse(ownProps.location.search)

  var startOfPeriod = queryParams.startDate
    ? moment(queryParams.startDate).format('YYYY-MM-DD')
    : getBeginningOfTimeStrYYYYMMDD

  var endOfPeriod = queryParams.endDate
    ? moment(queryParams.endDate).format('YYYY-MM-DD')
    : getTenYearsFromNowYYYYMMDD

  return [startOfPeriod, endOfPeriod]
}

function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  )
}

function createData(orders) {
  var formattedData = orders && orders.map(order => {
    let spinType = order.isRequestingChildFriendly ? 'Family-Friendly' : 'Regular'
    spinType = order.isRequestingPremium ? 'Premium' : spinType

    return {
      id: order.id,
      user: order.user,
      customer: toTitleCase(order.user.lastName),
      orderPlacedAt: order.orderPlacedAt,
      requestedShowDate: order.requestedShowDate,
      requestedShowTime: order.requestedShowTime,
      numberOfTickets: order.numberOfTickets,
      spinType: spinType,
      showPreference: order.showPreference,
      location: order.location.name,
      restaurant: order.restaurantReservationDetails ? '✔️' : '❌'
    }
  })

  return formattedData
}

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

const FilterPopover = (props) => {
  const {
    startDate,
    endDate,
    executeSearch,
    isSearchingForFamilyFriendly,
    isSearchingForPremium,
    isSearchingForInvalidOrder,
    location,
    orderIdSearch,
    searchText,
    setStartDate,
    setEndDate,
    setLocation,
    setOrderIdSearch,
    setIsSearchingForFamilyFriendly,
    setIsSearchingForPremium,
    setIsSearchingForInvalidOrder,
    setSearchText,
    setShowName
  } = props.data

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClickButton = (event) => {
    executeSearch()
    handleClose()
  }

  const open = Boolean(anchorEl)
  const id = open ? 'popover' : undefined

  return (
    <div>

      <Tooltip title="Filter list">
        <IconButton aria-describedby={id} onClick={handleClick}>
          <FilterListIcon />
        </IconButton>
      </Tooltip>

      <Popover id={id}
               open={open}
               anchorEl={anchorEl}
               onClose={handleClose}
               anchorOrigin={{
                 vertical: 'bottom',
                 horizontal: 'left',
               }}>

        <div className="filters">
          <h3>Filters</h3>
          <Stack spacing={3} direction="column">
            <div>
              <LocalizationProvider dateAdapter={AdapterDateFns}>

                <Stack spacing={2} direction="column" className="hidemobile stack">
                    <DesktopDatePicker label="Show Start Date"
                                       inputFormat="MM/dd/yyyy"
                                       value={startDate}
                                       onChange={(newValue) => setStartDate(newValue)}
                                       clearable={true}
                                       renderInput={(params) => <TextField {...params} />} />

                    <DesktopDatePicker label="Show End Date"
                                       inputFormat="MM/dd/yyyy"
                                       value={endDate}
                                       onChange={(newValue) => setEndDate(newValue)}
                                       clearable={true}
                                       renderInput={(params) => <TextField {...params} />} />
                </Stack>

                <Stack spacing={2} direction="column" className="showmobile stack">
                  <MobileDatePicker label="Start date"
                                    inputFormat="MM/dd/yyyy"
                                    value={startDate}
                                    onChange={(newValue) => setStartDate(newValue)}
                                    renderInput={(params) => <TextField {...params} />} />

                  <MobileDatePicker label="End date"
                                    inputFormat="MM/dd/yyyy"
                                    value={endDate}
                                    onChange={(newValue) => setEndDate(newValue)}
                                    renderInput={(params) => <TextField {...params} />} />
                </Stack>
              </LocalizationProvider>
            </div>

            <div>
              <TextField id="outlined-basic"
                         label="Email or Name"
                         variant="outlined"
                         onChange={(event) => setSearchText(event.target.value)} />
            </div>

            <div>
              <TextField id="outlined-basic"
                         label="Order ID"
                         variant="outlined"
                         onChange={(event) => setOrderIdSearch(event.target.value)} />
            </div>

            <div>
              <TextField id="outlined-basic"
                         label="Show Name"
                         variant="outlined"
                         onChange={(event) => setShowName(event.target.value)} />
            </div>

            <div>
              <Stack spacing={2} direction="column">
                <FormControlLabel
                  control={<Checkbox checked={isSearchingForInvalidOrder}
                                     className="search-checkbox invalid-order-checkbox"
                                     onChange={(event) => setIsSearchingForInvalidOrder(event.target.checked)} />}
                  label="Show Only Cancelled Orders" />

                <FormControlLabel
                  control={<Checkbox checked={isSearchingForPremium}
                                     className="search-checkbox"
                                     onChange={(event) => setIsSearchingForPremium(event.target.checked)} />}
                  label="Show Only Premium" />

                <FormControlLabel
                  control={<Checkbox checked={isSearchingForFamilyFriendly}
                                     className="search-checkbox"
                                     onChange={(event) => setIsSearchingForFamilyFriendly(event.target.checked)} />}
                  label="Show Only Family Friendly" />
              </Stack>
            </div>

            <div>
              <Stack spacing={2} direction="row">

                <FormControl fullWidth>
                  <InputLabel id="select-label">Location</InputLabel>
                  <Select labelId="select-label"
                          id="select"
                          value={location}
                          label="Location"
                          onChange={(event) => setLocation(event.target.value)}>
                    <MenuItem value="Broadway">Broadway</MenuItem>
                    <MenuItem value="West End">West End</MenuItem>
                  </Select>
                </FormControl>

              </Stack>
            </div>

            <Button variant="contained" onClick={handleClickButton}>Search</Button>
          </Stack>
        </div>

      </Popover>
    </div>
  )
}

const EnhancedTableHead = (props) => {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>

        <TableCell padding="checkbox">
          <Checkbox color="primary"
                    indeterminate={numSelected > 0 && numSelected < rowCount}
                    checked={rowCount > 0 && numSelected === rowCount}
                    onChange={onSelectAllClick}
                    inputProps={{ 'aria-label': 'select all orders' }} />
        </TableCell>

        {headCells.map((headCell) => (
          <TableCell key={headCell.id}
                     align='left'
                     padding={'normal'}
                     sortDirection={orderBy === headCell.id ? order : false}>

            <TableSortLabel active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>

          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const EnhancedTableToolbar = (props) => {
  const { history, numSelected, selected } = props

  const handleOpenOrders = (event) => {
    var url = '?'

    selected.map((id, idx) => {
      if (idx < selected.length - 1) {
        var urlSegment = `id=${id}&`
        url = url.concat(urlSegment)
      } else {
        var urlSegment = `id=${id}`
        url = url.concat(urlSegment)
      }
    })

    history.push(`/order${url}`)
  }

  return (
    <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 }, ...(numSelected > 0 && {
             bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
      }),
    }}>
      {numSelected > 0 ? (
        <Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography sx={{ flex: '1 1 100%' }} variant="h3" className="page-title" component="div">
          Orders
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Open in new page">
          <Button variant="contained" onClick={handleOpenOrders}>View</Button>
        </Tooltip>
      ) : (
        <FilterPopover data={props}/>
      )}
    </Toolbar>
  )
}

function OrdersList(props) {
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('orderPlacedAt')

  const [selected, setSelected] = useState([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(100)

  const [location, setLocation] = useState('Broadway')
  const [endDate, setEndDate] = useState(null)
  const [startDate, setStartDate] = useState(null)
  const [searchText, setSearchText] = useState('')
  const [showName, setShowName] = useState('')
  const [orderIdSearch, setOrderIdSearch] = useState('')

  const [loadingSearch, setLoadingSearch] = useState(false)
  const [error, setError] = useState(false)
  const [searchedItems, setSearchedItems] = useState(null)

  const [isSearchingForFamilyFriendly, setIsSearchingForFamilyFriendly] = useState(false)
  const [isSearchingForPremium, setIsSearchingForPremium] = useState(false)
  const [isSearchingForInvalidOrder, setIsSearchingForInvalidOrder] = useState(false)

  const history = useHistory()

  const executeSearch = async () => {
    try {
      setLoadingSearch(true)

      var endOfPeriod = endDate ? moment(endDate).format('YYYY-MM-DD') : getTenYearsFromNowYYYYMMDD
      var startOfPeriod = startDate ? moment(startDate).format('YYYY-MM-DD') : getBeginningOfTimeStrYYYYMMDD

      var orderIdSearchFilter = orderIdSearch ? `{ id: { contains: "${orderIdSearch}" }}` : ''
      var ticketDetailsFilter = showName ? `{ ticketDetails: { show: { name: { contains: "${showName}" }} }}` : ''
      var premiumOnlyFilter = isSearchingForPremium ? `{ isRequestingPremium: { equals: true }}` : ''
      var familyOnlyFilter = isSearchingForFamilyFriendly ? `{ isRequestingChildFriendly: { equals: true }}` : ''
      var invalidOnlyFilter = !isSearchingForInvalidOrder

      var filterOrderQuery = gql`
        query OrdersListQuery($searchText: String, $location: String, $startOfPeriod: Date, $endOfPeriod: Date) {
          ordersList (
            first: 1000,
            sort: { createdAt : DESC },
            filter: {
              AND: [
                { requestedShowDate: { gte: $startOfPeriod }},
                { requestedShowDate: { lte: $endOfPeriod }},
                { isForOffBroadway: { equals: false }},
                { isValidOrder: { equals: ${invalidOnlyFilter} }},
                { location: { name: { equals: $location }}}

                ${orderIdSearchFilter}
                ${ticketDetailsFilter}
                ${premiumOnlyFilter}
                ${familyOnlyFilter}

                {OR: [{
                  user: {
                    firstName: { contains: $searchText }
                  }
                }, {
                  user: {
                    lastName: { contains: $searchText }
                  }
                }, {
                  user: {
                    email: { contains: $searchText }
                  }
                }]},

                ${FILTER_ADMINS}
              ]
            }
          ) {
            count

            items {
              id
              createdAt
              clientVersion
              numberOfTickets
              requestedShowDate
              requestedShowTime
              orderPlacedAt
              showPreference
              isRequestingChildFriendly
              isRequestingPremium

              location {
                id
                name
              }

              paymentDetails {
                id
                discountAmount
              }

              restaurantReservationDetails {
                id
              }

              user {
                id
                email
                firstName
                lastName
              }
            }
          }
        }
      `

      var result = await props.client.query({
        query: filterOrderQuery,
        variables: {
          searchText,
          endOfPeriod,
          startOfPeriod,
          location,
        }
      })

      const orders = result.data.ordersList.items
      setSearchedItems(orders)

      setSearchText('')
      setLocation('Broadway')
      setPage(0)
      setEndDate(null)
      setStartDate(null)
      setLoadingSearch(false)

    } catch(err) {
      setError(true)
      setLoadingSearch(false)
      console.log(err)
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      )
    }

    setSelected(newSelected)
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const getItemsToRender = () => {
    return (searchedItems || props.ordersListQuery.ordersList && props.ordersListQuery.ordersList.items)
  }

  useEffect(() => {
    history.push(`/orders/${page + 1}${props.location.search}`)
  }, [page])

  useEffect(() => {
    var pageNumber = props.location.pathname.slice(8)
    setPage(pageNumber - 1)
  }, [])

  const isSelected = (name) => selected.indexOf(name) !== -1
  const items = getItemsToRender()
  const rows = createData(items)

  const emptyRows = page > 0 && rows ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0

  return (
    <Box sx={{ width: '100%' }}>
      <div className='data'>
        <EnhancedTableToolbar endDate={endDate}
                              executeSearch={executeSearch}
                              history={history}
                              isSearchingForFamilyFriendly={isSearchingForFamilyFriendly}
                              isSearchingForPremium={isSearchingForPremium}
                              isSearchingForInvalidOrder={isSearchingForInvalidOrder}
                              location={location}
                              orderIdSearch={orderIdSearch}
                              searchText={searchText}
                              selected={selected}
                              startDate={startDate}
                              numSelected={selected.length}
                              setEndDate={setEndDate}
                              setLocation={setLocation}
                              setOrderIdSearch={setOrderIdSearch}
                              setIsSearchingForFamilyFriendly={setIsSearchingForFamilyFriendly}
                              setIsSearchingForPremium={setIsSearchingForPremium}
                              setIsSearchingForInvalidOrder={setIsSearchingForInvalidOrder}
                              setSearchText={setSearchText}
                              setShowName={setShowName}
                              setStartDate={setStartDate} />

        {(loadingSearch || !rows && props.ordersListQuery && props.ordersListQuery.loading) &&
          <CircularProgress />
        }

        {(!rows && props.ordersListQuery && props.ordersListQuery.error) || error &&
          <h2>Error</h2>
        }

        {rows && !loadingSearch &&
          <div>
            <TableContainer>
              <Table sx={{ minWidth: 750 }}
                     aria-labelledby="table-title"
                     size='medium'>

                <EnhancedTableHead numSelected={selected.length}
                                   order={order}
                                   orderBy={orderBy}
                                   onSelectAllClick={handleSelectAllClick}
                                   onRequestSort={handleRequestSort}
                                   rowCount={rows.length}/>
                <TableBody>

                  {rows.slice().sort(getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.id)
                      const labelId = `enhanced-table-checkbox-${index}`
                      const fullName = `${toTitleCase(row.user.firstName)} ${toTitleCase(row.user.lastName)}`


                      const id = (<a href={'/order?id=' + row.id}>{row.id.substr(row.id.length-6)}</a>)
                      const userName = (<a href={'/user/' + row.user.id}>{fullName}</a>)

                      return (
                        <TableRow hover
                                  onClick={(event) => handleClick(event, row.id)}
                                  role="checkbox"
                                  aria-checked={isItemSelected}
                                  tabIndex={-1}
                                  key={row.id}
                                  selected={isItemSelected}>

                          <TableCell padding="checkbox">
                            <Checkbox color="primary" checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} />
                          </TableCell>
                          <TableCell component="th"
                                     id={labelId}
                                     scope="row"
                                     padding="none">
                            {id}
                          </TableCell>
                          <TableCell align="left">{userName}</TableCell>
                          <TableCell align="left">{moment(row.orderPlacedAt).format('YYYY-MM-DD hh:mm A')}</TableCell>
                          <TableCell align="left">{row.numberOfTickets}</TableCell>
                          <TableCell align="left">{row.location}</TableCell>
                          <TableCell align="left">{moment(row.requestedShowDate).format('YYYY-MM-DD')}</TableCell>
                          <TableCell align="left">{row.requestedShowTime}</TableCell>
                          <TableCell align="left">{row.showPreference}</TableCell>
                          <TableCell align="left">{row.spinType}</TableCell>
                          <TableCell align="left">{row.restaurant}</TableCell>
                        </TableRow>
                      )
                    })}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }} >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}

                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination rowsPerPageOptions={[5, 10, 25, 50, 100]}
                             component="div"
                             count={rows.length}
                             rowsPerPage={rowsPerPage}
                             page={page}
                             onPageChange={handleChangePage}
                             showFirstButton={true}
                             showLastButton={true}
                             onRowsPerPageChange={handleChangeRowsPerPage}/>
          </div>
        }
      </div>
    </Box>
  )
}

const ORDERS_LIST_QUERY = gql`
  query OrdersListQuery($searchText: String, $location: String, $startOfPeriod: Date, $endOfPeriod: Date) {
    ordersList(
      first: 1000,
      sort: { createdAt : DESC },
      filter: {
        AND: [
          { requestedShowDate: { gte: $startOfPeriod }},
          { requestedShowDate: { lte: $endOfPeriod }},
          { isForOffBroadway: { equals: false }},
          { isValidOrder: { equals: true }},
          { location: { name: { equals: $location }}}

          {OR: [{
            user: {
              firstName: { contains: $searchText }
            }
          }, {
            user: {
              lastName: { contains: $searchText }
            }
          }, {
            user: {
              email: { contains: $searchText }
            }
          }]},

          ${FILTER_ADMINS}
        ]
      }
    ) {
      count

      items {
        id
        createdAt
        clientVersion
        numberOfTickets
        requestedShowDate
        requestedShowTime
        orderPlacedAt
        showPreference
        isRequestingChildFriendly
        isRequestingPremium

        location {
          id
          name
        }

        paymentDetails {
          id
          discountAmount
        }

        restaurantReservationDetails {
          id
        }

        user {
          id
          email
          firstName
          lastName
        }
      }
    }
  }
`

const ORDER_CREATE_MUTATION = gql`
  mutation OrderCreateMutation($userId: ID!) {
    orderCreate(data: {
      user: {
        connect: { id: $userId }
      }

      clientVersion: "admin"
      requestedShowDate: "${getTodayStrYYYYMMDD()}"
      numberOfTickets: 1
      orderPlacedAt: "${moment().toISOString()}"
      isRequestingChildFriendly: false
      showPreference: "Both"

      location: {
        connect: { name: "Broadway" }
      }

      orderStatus: {
        create: {}
      }

      paymentDetails: {
        create: {
          discountCode: ""
          creditCardFee: 0.0
          discountAmount: 0.0
          price: 0.0
          processingFee: 0.0
        }
      }

      ticketDetails: {
        create: {}
      }
    }
  ) {
      id
    }
  }
`

export default withRouter(withApollo(graphql(ORDERS_LIST_QUERY, {
  name: 'ordersListQuery',
  options: (ownProps) => {
    var [startOfPeriod, endOfPeriod] = getDateRange(ownProps)

    return {
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
      variables: { ...getParamsFromUrl(ownProps), startOfPeriod, endOfPeriod }
    }
  }
})(OrdersList)))