const moment = require('moment-timezone')

export const BR_API_KEY = process.env.REACT_APP_BR_API_KEY

export const GRAPHQL_API_URL = 'https://api.8base.com/ck5o3an76000508ladchh9i4h'
export const GRAPHQL_AUTH_CLIENT_ID = process.env.REACT_APP_GRAPHQL_AUTH_CLIENT_ID
export const GRAPHQL_AUTH_DOMAIN = 'auth.broadwayroulette.com'
export const GRAPHQL_AUTH_TOKEN_LOCAL_STORAGE = 'eight-base-auth-token'

export const AWS_ACCESS_KEY_ID = process.env.REACT_APP_AWS_ACCESS_KEY_ID
export const AWS_ACCESS_KEY = process.env.REACT_APP_AWS_ACCESS_KEY
export const AWS_TICKETS_BUCKET_URL = process.env.REACT_APP_AWS_TICKETS_BUCKET_URL
export const AWS_GOLDSTAR_TICKETS_BUCKET_URL = process.env.REACT_APP_AWS_GOLDSTAR_TICKETS_BUCKET_URL
export const AWS_MEDIA_BUCKET_URL = process.env.REACT_APP_AWS_MEDIA_BUCKET_URL

export const ENTRIES_PER_PAGE = 100

export const SPECIAL_SHOW_IDS = [
  'cjopzr2ny022p017468vhqt70', 
  'cjfbf383p8g4301598tkx7680', 
  'cjfbf36wh826e0163dw9s4yaj',
  'ck7dy7nxr0l700181w1ec4knt',
  'ck946ydow00d707l5374fezdr'
]

export const LOCALE = 'America/New_York'
export const TODAY = moment().tz(LOCALE).format('YYYY-MM-DD')

export const ALLOWABLE_DAYS_AHEAD_TO_PLACE_ORDER = 120